const notesAction = {
  CHANGE_NOTE: 'CHANGE_NOTE',
  FETCH_NOTES: 'FETCH_NOTES',
  RECEIVE_NOTES: 'RECEIVE_NOTES',
  ADD_NOTE: 'ADD_NOTE',
  RECEIVE_NOTE: 'RECEIVE_NOTE',
  EDIT_NOTE: 'EDIT_NOTE',
  SAVE_NOTE: 'SAVE_NOTE',
  DELETE_NOTE: 'DELETE_NOTE',
  CHANGE_COLOR: 'CHANGE_COLOR',
  
  fetchNotes: prospectId => {
    return (dispatch) => {
      dispatch({
        type: notesAction.FETCH_NOTES,
        prospectId
      });
    };
  },
  
  changeNote: id => {
    return (dispatch, getState) => {
      const notes = getState().Notes.notes;
      const seectedColor = notes[notes.findIndex(note => note.id === id)].color;
      dispatch({
        type: notesAction.CHANGE_NOTE,
        selectedId: id,
        seectedColor
      });
    };
  },
  
  addNote: (prospectId) => {
    return (dispatch, getState) => {
      const newNote = {
        note: 'New Note',
        color: getState().Notes.seectedColor,
        prospect: prospectId
      };
      const notes = getState().Notes.notes;
      dispatch({
        type: notesAction.ADD_NOTE,
        newNote,
        oldNotes: notes,
        prospectId
      });
    };
  },
  
  editNote: (id, newNote) => {
    return (dispatch, getState) => {
      const oldNotes = getState().Notes.notes;
      const notes = [];
      oldNotes.forEach(note => {
        if (note.id !== id) {
          notes.push(note);
        } else {
          note.note = newNote;
          notes.push(note);
        }
      });
      dispatch({
        type: notesAction.EDIT_NOTE,
        notes
      });
    };
  },
  
  saveNote: (id, newText) => {
    return (dispatch, getState) => {
      dispatch({
        type: notesAction.SAVE_NOTE,
        newText,
        id
      });
    };
  },
  
  deleteNote: id => {
    return (dispatch, getState) => {
      const oldNotes = getState().Notes.notes;
      const notes = [];
      oldNotes.forEach(note => {
        if (note.id !== id) {
          notes.push(note);
        }
      });
      let selectedId = getState().Notes.selectedId;
      if (selectedId === id) {
        if (notes.length === 0) {
          selectedId = undefined;
        } else {
          selectedId = notes[0].id;
        }
      }
      dispatch({
        type: notesAction.DELETE_NOTE,
        notes,
        deleteId: id,
        selectedId
      });
    };
  },
  changeColor: seectedColor => {
    return (dispatch, getState) => {
      const oldNotes = getState().Notes.notes;
      const selectedId = getState().Notes.selectedId;
      const notes = [];
      oldNotes.forEach(note => {
        if (note.id !== selectedId) {
          notes.push(note);
        } else {
          note.color = seectedColor;
          notes.push(note);
        }
      });
      dispatch({
        type: notesAction.CHANGE_COLOR,
        notes,
        seectedColor,
        selectedId
      });
    };
  }
};
export default notesAction;
