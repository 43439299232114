const actions = {
  FETCH_OFFER_TEMPLATES: 'FETCH_OFFER_TEMPLATES',
  FETCH_OFFER_TEMPLATES_ERROR: 'FETCH_OFFER_TEMPLATES_ERROR',
  RECEIVE_OFFER_TEMPLATES: 'RECEIVE_OFFER_TEMPLATES',
  
  FETCH_OFFER_TEMPLATE: 'FETCH_OFFER_TEMPLATE',
  FETCH_OFFER_TEMPLATE_ERROR: 'FETCH_OFFER_TEMPLATE_ERROR',
  RECEIVE_OFFER_TEMPLATE: 'RECEIVE_OFFER_TEMPLATE',
  
  CREATE_OFFER_TEMPLATE: 'CREATE_OFFER_TEMPLATE',
  CREATE_OFFER_TEMPLATE_ERROR: 'CREATE_OFFER_TEMPLATE_ERROR',
  OFFER_TEMPLATE_CREATED: 'OFFER_TEMPLATE_CREATED',
  
  PATCH_OFFER_TEMPLATE: 'PATCH_OFFER_TEMPLATE',
  PATCH_OFFER_TEMPLATE_ERROR: 'PATCH_OFFER_TEMPLATE_ERROR',
  OFFER_TEMPLATE_PATCHED: 'OFFER_TEMPLATE_PATCHED',
  
  DELETE_OFFER_TEMPLATE: 'DELETE_OFFER_TEMPLATE',
  DELETE_OFFER_TEMPLATE_ERROR: 'DELETE_OFFER_TEMPLATE_ERROR',
  OFFER_TEMPLATE_DELETED: 'OFFER_TEMPLATE_DELETED',
  
  SEND_OFFER_TEMPLATE: 'SEND_OFFER_TEMPLATE',
  SEND_OFFER_TEMPLATE_ERROR: 'SEND_OFFER_TEMPLATE_ERROR',
  OFFER_TEMPLATE_SENT: 'OFFER_TEMPLATE_SENT',
  
  fetchOfferTemplate: (offerTemplateId) => ({
    type: actions.FETCH_OFFER_TEMPLATE,
    offerTemplateId
  }),
  
  createOfferTemplate: (offerTemplate) => ({
    type: actions.CREATE_OFFER_TEMPLATE,
    offerTemplate
  }),
  
  sendTestEmail: (email) => ({
    type: actions.SEND_OFFER_TEMPLATE,
    email
  }),
  
};
export default actions;
