const options = {
  leadsMenu: [
    {
      key: 'prospects',
      label: 'Prospects',
      leftIcon: 'ion-ios-people',
    },
    {
      key: 'lead-sources',
      label: 'Lead Sources',
      leftIcon: 'ion-cube',
    }
  ],
  
  reportsMenu: [
    {
      key: 'campaigns',
      label: 'Campaigns',
      leftIcon: 'ion-cube',
    }
  ]
};
export default options;
