import actions, {ACTIVITY_TYPE} from './actions';

const initState = {
  activity: {
    [ACTIVITY_TYPE.EXTERNAL_EMAIL_TO_PROSPECT]: [],
    [ACTIVITY_TYPE.SINGLE_SMS_TO_PROSPECT]: [],
  },
  isFetchingActivity: false,
  isCreatingActivity: false,
};

export default function activityReducer(state = initState, action) {
  switch (action.type) {

    case actions.FETCH_ACTIVITY:
      return {
        ...state,
        activity: {
          ...state.activity,
          [action.activityType]: []
        },
        isFetchingActivity: true
      };

    case actions.FETCH_ACTIVITY_ERROR:
      return {
        ...state,
        isFetchingActivity: false
      };

    case actions.ACTIVITY_RECEIVED:
      let receivedType = {...initState.activity};
      action.activity.forEach(activity => receivedType[activity.type].push(activity));
      return {
        ...state,
        activity: {
          ...state.activity,
          ...receivedType
        },
        isFetchingActivity: false
      };

    case actions.CREATE_ACTIVITY:
      return {
        ...state,
        isCreatingActivity: true
      };

    case actions.CREATE_ACTIVITY_ERROR:
      return {
        ...state,
        isCreatingActivity: false
      };

    case actions.ACTIVITY_CREATED:
      return {
        ...state,
        activity: {
          ...state.activity,
          [action.activity.type]: [
              ...state.activity[action.activity.type],
              action.activity
          ]
        },
        isCreatingActivity: false
      };

    default:
      return state;
  }
}
