const actions = {

  SEND_SINGLE_SMS: 'SEND_SINGLE_SMS',
  SEND_SINGLE_SMS_ERROR: 'SEND_SINGLE_SMS_ERROR',
  SINGLE_SMS_SENT: 'SINGLE_SMS_SENT',

  sendSingleSms: (smsName, message, prospect_id, phone, email) => ({
    type: actions.SEND_SINGLE_SMS,
    smsName,
    message,
    prospect_id,
    phone,
    email
  }),
  
};
export default actions;
