import actions from './actions';

const initState = {
  isFetching: false,
  isExporting: false,
  isPatchingProspect: false,
  
  prospects: [],
  totalProspects: 0,
  totalFiltered: 0,
  perPage: 20,
  page: 1,
  
  prospect: null,
  isSettingStatus: false,
  filteredProspects: [],
  
  errorMessage: '',
  errorId: 0
};

export default function prospectReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.FETCH_PROSPECTS:
    case actions.CREATE_PROSPECTS_AUDIENCE:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.CREATE_PROSPECTS_AUDIENCE_ERROR:
    case actions.PROSPECTS_AUDIENCE_CREATED:
      return {
        ...state,
        isFetching: false
      };
      
    case actions.DELETE_PROSPECT:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.DELETE_PROSPECT_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.PROSPECT_DELETED:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.SET_PROSPECTS_PAGE:
      return {
        ...state,
        page: action.page
      };
  
    case actions.RECEIVE_PROSPECTS:
      return {
        ...state,
        isFetching: false,
        prospects: action.prospects,
        filteredProspects: action.prospects,
        totalProspects: action.totalProspects,
        totalFiltered: action.totalFiltered,
        page: action.page,
      };
  
    case actions.FETCH_PROSPECT_DATA:
      return {
        ...state,
        isFetching: true,
        prospect: initState.prospect
      };
  
    case actions.RECEIVE_PROSPECT_DATA:
      return {
        ...state,
        isFetching: false,
        prospect: action.prospect
      };
  
    case actions.FETCH_PROSPECT_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage
      };
  
      
    case actions.SET_PROSPECT_STATUS:
      return {
        ...state,
        isSettingStatus: true,
      };
  
    case actions.SET_PROSPECT_STATUS_ERROR:
      return {
        ...state,
        isSettingStatus: false,
        errorMessage: action.errorMessage
      };
  
    case actions.PROSPECT_STATUS_SET:
      return {
        ...state,
        isSettingStatus: false,
      };
  
  
    case actions.ASSIGN_PROSPECT_USER:
      return {
        ...state,
        isFetching: true,
      };
  
    case actions.ASSIGN_PROSPECT_USER_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage
      };
  
    case actions.PROSPECT_USER_ASSIGNED:
      return {
        ...state,
        isFetching: false,
      };

    case actions.EDIT_PROSPECT:
      return {
        ...state,
        isPatchingProspect: true
      };

    case actions.EDIT_PROSPECT_ERROR:
      let error = state.errorId;
      return {
        ...state,
        isPatchingProspect: false,
        errorMessage: action.errorMessage,
        errorId: ++error
      };
  
    case actions.DELETE_PROSPECT_BASIC_FIELD:
      return {
        ...state,
        isFetching: true,
      };
  
    case actions.DELETE_PROSPECT_BASIC_FIELD_ERROR:
      return {
        ...state,
        isFetching: false,
      };
  
    case actions.PROSPECT_BASIC_FIELD_DELETED:
      return {
        ...state,
        isFetching: false,
      };

    case actions.ADD_PROSPECT_CUSTOM_FIELD:
      return {
        ...state,
        isFetching: true,
      };
  
    case actions.ADD_PROSPECT_FIELD_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage
      };
  
    case actions.PROSPECT_FIELD_ADDED:
      return {
        ...state,
        isFetching: false,
      };
  
    case actions.EXPORT_PROSPECTS:
      return {
        ...state,
        isExporting: true,
      };
      
    case actions.PROSPECTS_EXPORTED:
      prepareDownloadLink(action.csvString);
      return {
        ...state,
        isExporting: false,
      };
  
    case actions.EXPORT_PROSPECTS_WITH_NOTES:
      return {
        ...state,
        isExporting: true,
      };
  
    case actions.EXPORT_ATHLON_ACCOUNT_TEMPLATE:
      return {
        ...state,
        isExporting: true,
      };
  
    case actions.PROSPECTS_WITH_NOTES_EXPORTED:
      prepareDownloadLink(action.csvString);
      return {
        ...state,
        isExporting: false,
      };
  
    case actions.ATHLON_ACCOUNT_TEMPLATE_EXPORTED:
      prepareDownloadLink(action.csvString);
      return {
        ...state,
        isExporting: false,
      };
  
    case actions.PROSPECT_REMOVE_AGREE:
      return {
        ...state,
        isFetching: true,
      };
  
    case actions.PROSPECT_REMOVE_AGREE_ERROR:
      return {
        ...state,
        isFetching: false,
      };
  
    case actions.PROSPECT_AGREE_REMOVED:
      return {
        ...state,
        isFetching: false,
      };
    
    default:
      return state;
  }
}

const prepareDownloadLink = (csvString) =>
{
  let blob = new Blob([csvString]);
  if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
    window.navigator.msSaveBlob(blob, "prospect_export.csv");
  else
  {
    let a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
    a.download = "prospect_export.csv";
    document.body.appendChild(a);
    a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
    document.body.removeChild(a);
  }
};
