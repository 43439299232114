import actions from './actions';

const initState = {
  leadSources: [],
  leadSource: null,
  isFetching: false,
  isCreating: false,
};

export default function leadSourceReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    // FETCHING
    case actions.FETCH_LEAD_SOURCES:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.RECEIVE_LEAD_SOURCES:
      return {
        ...state,
        isFetching: false,
        leadSource: null,
        leadSources: action.leadSources
      };
      
    case actions.FETCH_ERROR_LEAD_SOURCES:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage
      };
  
    // CREATING & PATCHING
    case actions.CREATE_LEAD_SOURCE:
    case actions.PATCH_LEAD_SOURCE:
      return {
        ...state,
        isCreating: true
      };
  
    case actions.CREATE_LEAD_SOURCE_ERROR:
    case actions.PATCH_LEAD_SOURCE_ERROR:
      return {
        ...state,
        isCreating: false,
        errorMessage: action.errorMessage
      };
  
    case actions.LEAD_SOURCE_CREATED:
    case actions.LEAD_SOURCE_PATCHED:
      return {
        ...state,
        isCreating: false,
      };
  
    // DELETING
    case actions.DELETE_LEAD_SOURCE:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.DELETE_LEAD_SOURCE_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.LEAD_SOURCE_DELETED:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage
      };
  
    case actions.CREATE_LEAD_SOURCE_RECEIPT:
    case actions.PATCH_LEAD_SOURCE_RECEIPT:
    case actions.DELETE_LEAD_SOURCE_RECEIPT:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.CREATE_LEAD_SOURCE_RECEIPT_ERROR:
    case actions.PATCH_LEAD_SOURCE_RECEIPT_ERROR:
    case actions.DELETE_LEAD_SOURCE_RECEIPT_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage
      };
  
    case actions.LEAD_SOURCE_RECEIPT_CREATED:
    case actions.LEAD_SOURCE_RECEIPT_PATCHED:
    case actions.LEAD_SOURCE_RECEIPT_DELETED:
      return {
        ...state,
        isFetching: false
      };
      
    default:
      return state;
  }
}
