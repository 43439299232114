import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import notifications from "../../components/feedback/notification";
import actions from './actions';
import LeadsFetch from "../../helpers/leadsFetch";

export function* fetchAgrees() {
  yield takeEvery(actions.FETCH_AGREES, function*() {

    const apiResponse = yield LeadsFetch.get('agrees', null)
      .then(response => (response))
      .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_AGREES,
        agrees: apiResponse.data.data
      });
      return;
    }

    yield put({
      type: actions.FETCH_AGREES_ERROR
    });
    yield put({
      type: 'PUSH_ERROR',
      response: apiResponse,
    });
  });
}

export function* createAgree() {
  yield takeEvery(actions.CREATE_AGREE, function*(payload) {
    const {agree} = payload;
    const apiResponse = yield LeadsFetch.post('agrees', agree)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.AGREE_CREATED
      });
      yield put({
        type: actions.FETCH_AGREES
      });
      notifications['success']({
        message: 'Ok!',
        description: 'New agree created.'
      });
      return;
    }
    
    notifications['error']({
      message: 'Server error!',
      description: apiResponse.data.message
    });
    
    yield put({
      type: actions.CREATE_AGREE_ERROR
    });
  });
}

export function* deleteAgree() {
  yield takeEvery(actions.DELETE_AGREE, function*(payload) {
    const {agreeId} = payload;
    const apiResponse = yield LeadsFetch.delete('agrees/' + agreeId, null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.FETCH_AGREES
      });
      return;
    }
    
    notifications['error']({
      message: 'Not allowed!',
      description: apiResponse.data.message
    });
    
    yield put({
      type: actions.DELETE_AGREE_ERROR
    });
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchAgrees),
    fork(createAgree),
    fork(deleteAgree),
  ]);
}
