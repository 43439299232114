import actions from './actions';

const initState = {
    isFetchingReviews: false,
    reviews: [],
    updatingReview: null,
    isUpdatingReview: false,
    
    totalReviews: 0,
    perPage: 100,
    page: 1,
};

export default function googleReviewsReducer(state = initState, action) {
    switch (action.type) {
        
        case 'SET_ADVERTISER':
            return {
                ...initState
            };
            
            //FETCH
        case actions.FETCH_REVIEWS:
            return {
                ...state,
                isFetchingReviews: true,
                // locationsEmpty: false,
            };
    
        case actions.FETCH_REVIEWS_ERROR:
            return {
                ...state,
                isFetchingReviews: false,
                // locationsEmpty: true,
            };
    
        case actions.REVIEWS_RECEIVED:

            return {
                ...state,
                isFetchingReviews: false,
                reviews: action.reviews,
                totalReviews: action.totalReviews,
                page: action.page
                // locationsEmpty: locationsEmpty
            };
    
            //UPDATE
        case actions.UPDATE_REVIEW:
            return {
                ...state,
                isUpdatingReview: true,
                updatingReview: action.id
            };
    
        case actions.UPDATE_REVIEW_ERROR:
            return {
                ...state,
                isUpdatingReview: false,
                updatingReview: initState.updatingReview
            };
    
        case actions.REVIEW_UPDATED:
            
            return {
                ...state,
                isUpdatingReview: false,
                updatingReview: initState.updatingReview
            };
    
            //REPLY
        case actions.REPLY_REVIEW:
            return {
                ...state,
                isUpdatingReview: true,
                updatingReview: action.id
            };
    
        case actions.REPLY_REVIEW_ERROR:
            return {
                ...state,
                isUpdatingReview: false,
                updatingReview: initState.updatingReview
            };
    
        case actions.REVIEW_REPLIED:
            let preReplyReviews = [...state.reviews];
            for(let i = 0; i < preReplyReviews.length; i++)
            {
                if(preReplyReviews[i].id == action.review.id)
                {
                    preReplyReviews[i] = action.review;
                    break;
                }
            }
            
            return {
                ...state,
                isUpdatingReview: false,
                updatingReview: initState.updatingReview,
                reviews: preReplyReviews
            };
    
            //DELETE
        case actions.DELETE_REVIEW_REPLY:
            return {
                ...state,
                isUpdatingReview: true,
                updatingReview: action.id
            };
    
        case actions.DELETE_REVIEW_REPLY_ERROR:
            return {
                ...state,
                isUpdatingReview: false,
                updatingReview: initState.updatingReview
            };
    
        case actions.REVIEW_REPLY_DELETED:
            let preDeleteReplies = [...state.reviews];
            for(let i = 0; i < preDeleteReplies.length; i++)
            {
                if(preDeleteReplies[i].id == state.updatingReview)
                {
                    preDeleteReplies[i] = action.review;
                    break;
                }
            }
            
            return {
                ...state,
                isUpdatingReview: false,
                updatingReview: initState.updatingReview,
                reviews: preDeleteReplies
            };
        
        
        default:
            return state;
    }
}
