import React, { Component } from 'react';
import Popconfirm from '../feedback/popconfirm';
import {palette} from 'styled-theme';
import Button from '../uielements/button';

export default class extends Component {
  render() {
    const { index, onDeleteCell, placement = 'top' } = this.props;
    return (
      <Popconfirm
        title="Sure to delete?"
        okText="DELETE"
        cancelText="No"
        placement={placement}
        onConfirm={() => onDeleteCell(index)}
      >
        <Button
          type="danger"
          icon="delete"
          size={"small"}
        />
      </Popconfirm>
    );
  }
}
