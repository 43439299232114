import React, { Component } from 'react';
import { connect } from 'react-redux';
import notifications from '../components/feedback/notification';
import { push } from 'connected-react-router';
import authAction from '../redux/auth/actions';
import errorAction from '../redux/error/actions';


class ApiErrorHandler extends Component {
  
  componentDidUpdate(prevProps, prevState, snapshot)
  {
    if(this.props.errorResponse != null)
    {
      this.handleError();
    }
  }
  
  handleError = () => {
    const {errorResponse, logout} = this.props;
    const {status, data} = errorResponse;
    if(status == 403)
    {
      notifications['error']({
        message: 'Not allowed',
        description: 'You have no permission to that resource.'
      });
      return;
    }

    if(status == 401)
    {
      if(data && data.action && data.action == 'relog')
      {
        // notifications['error']({
        //   message: 'Session expired',
        //   description: 'Log in again to continue.'
        // });
        // push('/signin');
        logout();
        return;
      }
    }
  };
  
  render() {
    const {children} = this.props;
    return (
      <div className="errorHandlingWrapper">
        {children}
      </div>
    );
  }
  
}

const {logout} = authAction;
const {clearError} = errorAction;

function mapStateToProps(state) {
  return {
    errorResponse: state.Error.errorResponse,
  };
}
export default connect(
  mapStateToProps,
  {
    logout,
    clearError
  }
)(ApiErrorHandler);
