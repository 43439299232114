import { jwtConfig } from '../settings';

const customHeader = () => ({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined,
});

const base = (method, url, data = null, replaceHeader = null) => {
    const header = replaceHeader == null ? customHeader() : replaceHeader;
    
    const advertiserId = localStorage.getItem('advertiser_id');
    
    const init = {
        method,
        headers: header,
    };
    
    if(data !== null) init.body = JSON.stringify(data);
    
    return fetch(`${jwtConfig.deliveryApiUrl}${advertiserId}/${url}`, init)
        .then(r =>  r.json().then(data => ({status: r.status, data})))
        .then(obj => {
            if(obj.status == 401)
            {
                // alert('Your session has been invalidated. Please log in again.');
                window.location.href = '/logout#invalidated';
            }
            else
            {
                return (obj);
            }
        })
        .catch(error => ({ error: error }));
};

const DeliveryFetch = {};
['get', 'post', 'put', 'PATCH', 'delete'].forEach(method => {
    DeliveryFetch[method] = base.bind(null, method);
});
export default DeliveryFetch;
