const actions = {
  
  CREATE_CONTACT_LIST: 'CREATE_CONTACT_LIST',
  CREATE_CONTACT_LIST_ERROR: 'CREATE_CONTACT_LIST_ERROR',
  CONTACT_LIST_CREATED: 'CONTACT_LIST_CREATED',
  
  UPDATE_CONTACT_LIST: 'UPDATE_CONTACT_LIST',
  UPDATE_CONTACT_LIST_ERROR: 'UPDATE_CONTACT_LIST_ERROR',
  CONTACT_LIST_UPDATED: 'CONTACT_LIST_UPDATED',
  
  FETCH_CONTACT_LISTS: 'FETCH_CONTACT_LISTS',
  RECEIVE_CONTACT_LISTS: 'RECEIVE_CONTACT_LISTS',
  
  FETCH_CONTACT_LIST: 'FETCH_CONTACT_LIST',
  FETCH_CONTACT_LIST_ERROR: 'FETCH_CONTACT_LIST_ERROR',
  RECEIVE_CONTACT_LIST: 'RECEIVE_CONTACT_LIST',
  
  SEND_TEST_CONTACT_LIST: 'SEND_TEST_CONTACT_LIST',
  SEND_TEST_CONTACT_LIST_ERROR: 'SEND_TEST_CONTACT_LIST_ERROR',
  TEST_CONTACT_LIST_SENT: 'TEST_CONTACT_LIST_SENT',
  
  DELETE_CONTACT_LIST: 'DELETE_CONTACT_LIST',
  DELETE_CONTACT_LIST_ERROR: 'DELETE_CONTACT_LIST_ERROR',
  CONTACT_LIST_DELETED: 'CONTACT_LIST_DELETED',
  
  IMPORT_CONTACTS_TO_LIST: 'IMPORT_CONTACTS_TO_LIST',
  IMPORT_CONTACTS_TO_LIST_ERROR: 'IMPORT_CONTACTS_TO_LIST_ERROR',
  CONTACTS_IMPORTED_TO_LIST: 'CONTACTS_IMPORTED_TO_LIST',
  
  CLEAR_CONTACT_LIST: 'CLEAR_CONTACT_LIST',

  fetchContactLists: () => ({
    type: actions.FETCH_CONTACT_LISTS
  }),
  
  clearContactList: () => ({
    type: actions.CLEAR_CONTACT_LIST
  }),
  
  fetchContactList: (contactListId) => ({
    type: actions.FETCH_CONTACT_LIST,
    contactListId
  }),
  
  deleteContactList: (contactListId) => ({
    type: actions.DELETE_CONTACT_LIST,
    contactListId
  }),
  
  createContactList: (contactList) => ({
    type: actions.CREATE_CONTACT_LIST,
    contactList
  }),
  
  updateContactList: (contactList, contactListId) => ({
    type: actions.UPDATE_CONTACT_LIST,
    contactList,
    contactListId
  }),

  displayContactList: (contactList) => ({
    type: actions.RECEIVE_CONTACT_LIST,
    contactList
  }),
  
  importContactsToList: (contactListId, contactsArray, routerHistory) => ({
    type: actions.IMPORT_CONTACTS_TO_LIST,
    contactsArray,
    contactListId,
    routerHistory
  }),

};
export default actions;
