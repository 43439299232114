const actions = {
  FETCH_SOURCES: 'FETCH_SOURCES',
  FETCH_SOURCES_ERROR: 'FETCH_SOURCES_ERROR',
  RECEIVE_SOURCES: 'RECEIVE_SOURCES',
  
  PATCH_SOURCE: 'PATCH_SOURCE',
  PATCH_SOURCE_ERROR: 'PATCH_SOURCE_ERROR',
  SOURCE_PATCHED: 'SOURCE_PATCHED',
  
  DELETE_SOURCE: 'DELETE_SOURCE',
  DELETE_SOURCE_ERROR: 'DELETE_SOURCE_ERROR',
  SOURCE_DELETED: 'SOURCE_DELETED',
  
  SET_SOURCE: 'SET_SOURCE',
  
  FETCH_SOURCE: 'FETCH_SOURCE',
  FETCH_SOURCE_ERROR: 'FETCH_SOURCE_ERROR',
  RECEIVE_SOURCE: 'RECEIVE_SOURCE',
  
  FETCH_SOURCE_REPORTS: 'FETCH_SOURCE_REPORTS',
  FETCH_SOURCE_REPORTS_ERROR: 'FETCH_SOURCE_REPORTS_ERROR',
  RECEIVE_SOURCE_REPORTS: 'RECEIVE_SOURCE_REPORTS',
  
  QUEUE_SOURCE: 'QUEUE_SOURCE',
  DEQUEUE_SOURCE: 'DEQUEUE_SOURCE',
  SET_QUEUED_MARGIN: 'SET_QUEUED_MARGIN',
  SET_QUEUED_UNIQUE: 'SET_QUEUED_UNIQUE',
  
  PROCESS_QUEUED_SOURCES: 'PROCESS_QUEUED_SOURCES',
  PROCESS_QUEUED_SOURCES_ERROR: 'PROCESS_QUEUED_SOURCES_ERROR',
  QUEUED_SOURCES_PROCESSED: 'QUEUED_SOURCES_PROCESSED',
  
  REQUEST_SOURCE_SUBTYPE_UPDATE: 'REQUEST_SOURCE_SUBTYPE_UPDATE',
  REQUEST_SOURCE_SUBTYPE_UPDATE_ERROR: 'REQUEST_SOURCE_SUBTYPE_UPDATE_ERROR',
  SOURCE_SUBTYPE_UPDATED: 'SOURCE_SUBTYPE_UPDATED',

  REDIRECT_SOURCE_AD_IDS: 'REDIRECT_SOURCE_AD_IDS',

  // fetchSource: (sourceId) => ({
  //   type: actions.FETCH_SOURCE,
  //   sourceId
  // }),
  
  setSource: (sourceId) => ({
    type: actions.SET_SOURCE,
    sourceId
  }),
  
  fetchCampaignSources: (campaignId) => ({
    type: actions.FETCH_SOURCES,
    campaignId
  }),
  
  patchSource: (source, sourceValues) => ({
    type: actions.PATCH_SOURCE,
    sourceValues,
    source
  }),
  
  deleteSource: (source) => ({
    type: actions.DELETE_SOURCE,
    source
  }),
  
  updateSourceSubType: (sourceTypeEnum, sourceSubTypeId, sourceSubTypeEnum) => ({
    type: actions.REQUEST_SOURCE_SUBTYPE_UPDATE,
    sourceTypeEnum,
    sourceSubTypeId,
    sourceSubTypeEnum,
  }),
  
  queueSource: (queuedSource, sourceType) => ({
    type: actions.QUEUE_SOURCE,
    queuedSource,
    sourceType
  }),
  
  dequeueSource: (queuedSource, sourceType) => ({
    type: actions.DEQUEUE_SOURCE,
    queuedSource,
    sourceType
  }),
  
  setQueuedMargin: (queuedSource, sourceType, margin) => ({
    type: actions.SET_QUEUED_MARGIN,
    queuedSource,
    sourceType,
    margin
  }),

  setQueuedUnique: (queuedSource, sourceType, unique) => ({
    type: actions.SET_QUEUED_UNIQUE,
    queuedSource,
    sourceType,
    unique
  }),
  
  processQueuedSources: (campaign) => ({
    type: actions.PROCESS_QUEUED_SOURCES,
    campaign
  }),

  // fetchCampaignData: (prospectId) => ({
  //   type: actions.FETCH_PROSPECT_DATA,
  //   prospectId: prospectId
  // }),
  //
  // setCampaign: (prospect, status) => ({
  //   type: actions.SET_CAMPAIGN,
  //   status,
  //   prospect
  // }),

  redirectOnLeads: (sourceIds) => ({
    type: actions.REDIRECT_SOURCE_AD_IDS,
    sourceIds
  }),
};
export default actions;
