import actions from './actions';

const initState = {
  isFetching: false,
  contactLists: [],
  contactList: null,
  contactCountInList: 0,
  page: 1
};

export default function contactListReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.CREATE_CONTACT_LIST:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.CREATE_CONTACT_LIST_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.CONTACT_LIST_CREATED:
      return {
        ...state,
        isFetching: false,
        contactList: action.contactList
      };
  
    case actions.UPDATE_CONTACT_LIST:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.UPDATE_CONTACT_LIST_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.CONTACT_LIST_UPDATED:
      return {
        ...state,
        isFetching: false,
        contactList: action.contactList
      };
  
    case actions.FETCH_CONTACT_LISTS:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.RECEIVE_CONTACT_LISTS:
      return {
        ...state,
        isFetching: false,
        contactLists: action.contactLists
      };
  
    case actions.FETCH_CONTACT_LIST:
      return {
        ...state,
        isFetching: true,
        contactList: null
      };
  
    case actions.FETCH_CONTACT_LIST_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.RECEIVE_CONTACT_LIST:
      return {
        ...state,
        isFetching: false,
        contactList: action.contactList,
        contactCountInList: action.count
      };
  
    case actions.SEND_TEST_CONTACT_LIST:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.SEND_TEST_CONTACT_LIST_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.TEST_CONTACT_LIST_SENT:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.DELETE_CONTACT_LIST:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.DELETE_CONTACT_LIST_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.CONTACT_LIST_DELETED:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.IMPORT_CONTACTS_TO_LIST:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.IMPORT_CONTACTS_TO_LIST_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.CONTACTS_IMPORTED_TO_LIST:
      return {
        ...state,
        isFetching: false
      };
      
    case actions.CLEAR_CONTACT_LIST:
    return {
      ...state,
      contactList: initState.contactList
    };
    
    default:
      return state;
  }
}
