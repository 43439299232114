const actions = {
  FETCH_CSV_TEMPLATES: 'FETCH_CSV_TEMPLATES',
  FETCH_CSV_TEMPLATES_ERROR: 'FETCH_CSV_TEMPLATES_ERROR',
  RECEIVE_CSV_TEMPLATES: 'RECEIVE_CSV_TEMPLATES',

  CREATE_CSV_TEMPLATE: 'CREATE_CSV_TEMPLATE',
  CREATE_CSV_TEMPLATE_ERROR: 'CREATE_CSV_TEMPLATE_ERROR',
  CSV_TEMPLATE_CREATED: 'CSV_TEMPLATE_CREATED',

  DELETE_CSV_TEMPLATE: 'DELETE_CSV_TEMPLATE',
  DELETE_CSV_TEMPLATE_ERROR: 'DELETE_CSV_TEMPLATE_ERROR',
  CSV_TEMPLATE_DELETED: 'CSV_TEMPLATE_DELETED',

  fetchCsvTemplates: () => ({
    type: actions.FETCH_CSV_TEMPLATES
  }),

  createTemplate: (template) => ({
    type: actions.CREATE_CSV_TEMPLATE,
    template
  }),

  deleteTemplate: (template) => ({
    type: actions.DELETE_CSV_TEMPLATE,
    template
  }),

};
export default actions;
