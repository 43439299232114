import actions from './actions';

const initState = {
    isFetching: false,
    versions: [],
    empty: false,
    isCreating: false
};

export default function googleLocationsVersionsReducer(state = initState, action) {
    switch (action.type) {
        
        case 'SET_ADVERTISER':
            return {
                ...initState
            };
        
        //FETCH
        case actions.FETCH_LOCATION_VERSIONS:
            return {
                ...state,
                isFetching: true,
                empty: false,
            };
        
        case actions.FETCH_LOCATION_VERSIONS_ERROR:
            return {
                ...state,
                isFetching: false,
                empty: true,
            };
        
        case actions.LOCATION_VERSIONS_RECEIVED:
            let empty = false;
            if(action.versions.length < 1)
                empty = true;
            
            return {
                ...state,
                isFetching: false,
                versions: action.versions,
                empty
            };
        
        //CREATE
        case actions.CREATE_LOCATION_VERSIONS:
            return {
                ...state,
                isCreating: true,
            };
        
        case actions.CREATE_LOCATION_VERSIONS_ERROR:
            return {
                ...state,
                isCreating: false,
            };
        
        case actions.LOCATION_VERSIONS_CREATED:
            
            return {
                ...state,
                isCreating: false,
            };
        
        //DELETE
        case actions.DELETE_LOCATION_VERSION:
            return {
                ...state,
                isFetching: true,
            };
        
        case actions.DELETE_LOCATION_VERSION_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        
        case actions.LOCATION_VERSION_DELETED:
            let deletedId = action.id;
            let postDeleteVersions = [...state.versions];
            for(let i = 0; i < postDeleteVersions.length; i++)
            {
                if(postDeleteVersions[i].id == deletedId)
                {
                    postDeleteVersions.splice(i, 1);
                    break;
                }
            }
            
            return {
                ...state,
                isFetching: false,
                versions: postDeleteVersions
            };
        
        
        default:
            return state;
    }
}
