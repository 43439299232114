const actions = {
  
  templateModel: {
  
  },
  
  CREATE_MAIL_TEMPLATE: 'CREATE_MAIL_TEMPLATE',
  CREATE_MAIL_TEMPLATE_ERROR: 'CREATE_MAIL_TEMPLATE_ERROR',
  MAIL_TEMPLATE_CREATED: 'MAIL_TEMPLATE_CREATED',
  
  UPDATE_MAIL_TEMPLATE: 'UPDATE_MAIL_TEMPLATE',
  UPDATE_MAIL_TEMPLATE_ERROR: 'UPDATE_MAIL_TEMPLATE_ERROR',
  MAIL_TEMPLATE_UPDATED: 'MAIL_TEMPLATE_UPDATED',
  
  FETCH_MAIL_TEMPLATES: 'FETCH_MAIL_TEMPLATES',
  RECEIVE_MAIL_TEMPLATES: 'RECEIVE_MAIL_TEMPLATES',
  
  FETCH_MAIL_TEMPLATE: 'FETCH_MAIL_TEMPLATE',
  FETCH_MAIL_TEMPLATE_ERROR: 'FETCH_MAIL_TEMPLATE_ERROR',
  RECEIVE_MAIL_TEMPLATE: 'RECEIVE_MAIL_TEMPLATE',
  
  SEND_TEST_EMAIL_TEMPLATE: 'SEND_TEST_EMAIL_TEMPLATE',
  SEND_TEST_EMAIL_TEMPLATE_ERROR: 'SEND_TEST_EMAIL_TEMPLATE_ERROR',
  TEST_EMAIL_TEMPLATE_SENT: 'TEST_EMAIL_TEMPLATE_SENT',
  
  DELETE_MAIL_TEMPLATE: 'DELETE_MAIL_TEMPLATE',
  DELETE_MAIL_TEMPLATE_ERROR: 'DELETE_MAIL_TEMPLATE_ERROR',
  MAIL_TEMPLATE_DELETED: 'MAIL_TEMPLATE_DELETED',
  
  CLONE_MAIL_TEMPLATE: 'CLONE_MAIL_TEMPLATE',
  CLONE_MAIL_TEMPLATE_ERROR: 'CLONE_MAIL_TEMPLATE_ERROR',
  CLONE_MAIL_TEMPLATE_DONE: 'CLONE_MAIL_TEMPLATE_DONE',
  
  CLEAR_MAIL_TEMPLATE: 'CLEAR_MAIL_TEMPLATE',

  fetchTemplates: () => ({
    type: actions.FETCH_MAIL_TEMPLATES
  }),
  
  clearTemplate: () => ({
    type: actions.CLEAR_MAIL_TEMPLATE
  }),
  
  cloneTemplate: (templateId) => ({
    type: actions.CLONE_MAIL_TEMPLATE,
    templateId
  }),
  
  fetchTemplate: (templateId) => ({
    type: actions.FETCH_MAIL_TEMPLATE,
    templateId
  }),
  
  deleteTemplate: (templateId) => ({
    type: actions.DELETE_MAIL_TEMPLATE,
    templateId
  }),
  
  createTemplate: (template) => ({
    type: actions.CREATE_MAIL_TEMPLATE,
    template
  }),
  
  updateTemplate: (template, templateId) => ({
    type: actions.UPDATE_MAIL_TEMPLATE,
    template,
    templateId
  }),
  
  sendTestTemplate: (templateId, testEmail) => ({
    type: actions.SEND_TEST_EMAIL_TEMPLATE,
    testEmail,
    templateId
  }),
  
};
export default actions;
