import {Col, Row} from "antd";
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
    // {
    //     path: '',
    //     component: asyncComponent(() => import('../dashboard')),
    // },
    // {
    //     path: 'campaigns/:campaignId/:sourceId',
    //     component: asyncComponent(() => import('./Source/single')),
    //     exact: true
    // },
    // {
    //     path: 'templates/:template_id',
    //     component: asyncComponent(() => import('./Template/edit')),
    //     exact: true
    // },
    {
        path: 'locations/:location_id',
        component: asyncComponent(() => import('./Location/edit')),
    },
    {
        path: 'locations',
        component: asyncComponent(() => import('./Location/')),
    },
    {
        path: 'location-campaigns/:campaign_id',
        component: asyncComponent(() => import('./LocationCampaign/edit')),
        exact: true
    },
    {
        path: 'location-campaigns',
        component: asyncComponent(() => import('./LocationCampaign/')),
    },
    {
        path: 'location-groups/:location_group_id',
        component: asyncComponent(() => import('./LocationGroups/edit')),
        exact: true
    },
    {
        path: 'location-groups',
        component: asyncComponent(() => import('./LocationGroups/')),
        exact: true
    },
    {
        path: 'reviews',
        component: asyncComponent(() => import('./LocationReviews/')),
    },
    // {
    //     path: 'audiences',
    //     component: asyncComponent(() => import('./ContactList/')),
    // },
    // {
    //     path: 'message-campaigns/:campaign_id',
    //     component: asyncComponent(() => import('./MessageCampaign/edit')),
    //     exact: true
    // },
    // {
    //     path: 'message-campaigns',
    //     component: asyncComponent(() => import('./MessageCampaign/')),
    // },
];

export default class GoogleBusinessRouter extends Component {
    
    
    render() {
        const { url, style } = this.props;
        return (
            <div style={style}>
                
                {routes.map(singleRoute => {
                    const { path, exact, ...otherProps } = singleRoute;
                    return (
                        <Route
                            exact={exact === false ? false : true}
                            key={singleRoute.path}
                            path={`${url}/${singleRoute.path}`}
                            {...otherProps}
                        />
                    );
                })}
            </div>
        );
    }
}
