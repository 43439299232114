import {all, takeEvery, put, fork, select} from 'redux-saga/effects';
import actions from './actions';
import DeliveryFetch from "../../../helpers/deliveryFetch";
import notifications from "../../../components/feedback/notification";

export function* fetchContacts() {
    yield takeEvery(actions.FETCH_CONTACTS, function*(payload) {
        
        let page = yield select((state) => state.Contacts.page);
        let contactList = yield select((state) => state.ContactLists.contactList);
    
        const apiResponse = yield DeliveryFetch.get(`contacts?contactListId=${contactList.id}&page=${page}`, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(apiResponse.status === 200)
        {
            const {validated_contacts, error_contacts, total_contacts} = apiResponse.data.data;
            console.log(validated_contacts);
            
            if(parseInt(page) > 0)
            {
                if(parseInt(100) * (parseInt(page)-1) > total_contacts)
                    page = 1;
            }
            
            yield put({
                type: actions.RECEIVE_CONTACTS,
                contacts: apiResponse.data.data.data,
                total_contacts: total_contacts,
                validated_contacts: validated_contacts,
                error_contacts: error_contacts,
                page,
            });
        }
    });
}

export function* afterPageSet() {
    yield takeEvery(actions.SET_CONTACTS_PAGE, function*() {
        
        yield put({
            type: actions.FETCH_CONTACTS
        });
        
    });
}

export function* fetchProspectContact() {
    yield takeEvery(actions.FETCH_PROSPECT_CONTACT, function*(payload) {
        
        const {prospectId} = payload;
        
        let query = '?prospect_id='+prospectId;
        const resp = yield DeliveryFetch.get('contacts'+query, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            
            yield put({
                type: actions.RECEIVE_PROSPECT_CONTACT,
                currentProspectContact: resp.data.data.length > 0
                    ? {...resp.data.data[0], context: 'list'} : {prospectId, context: 'na'}
            });
            return;
        }
        
        yield put({
            type: actions.FETCH_PROSPECT_CONTACT_ERROR,
            errorMessage: resp.error
        });
    });
}

export function* fetchProspectContactDetails() {
    yield takeEvery(actions.FETCH_PROSPECT_CONTACT_DETAILS, function*(payload) {
        
        const {contactId} = payload;
        
        const resp = yield DeliveryFetch.get('contacts/'+contactId, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            
            yield put({
                type: actions.RECEIVE_PROSPECT_CONTACT_DETAILS,
                currentProspectContact: {...resp.data.data, context: 'details'}
            });
            return;
        }
        
        yield put({
            type: actions.FETCH_PROSPECT_CONTACT_DETAILS_ERROR,
            errorMessage: resp.error
        });
    });
}

export function* fetchProspectContactCampaigns() {
    yield takeEvery(actions.FETCH_PROSPECT_CONTACT_CAMPAIGNS, function*(payload) {
        
        let {contactSentMessageIds} = payload;
        let query = '?contactSentMessages='+contactSentMessageIds[0];
        contactSentMessageIds.splice(0,1);
        contactSentMessageIds.forEach(csm => {
            query += '&contactSentMessages='+csm
        });

        const resp = yield DeliveryFetch.get('messagescampaigns'+query, null)
            .then(response => (response))
            .catch(error => ({error}));
    
        // console.log(resp);
        if(resp.status === 200)
        {
            
            yield put({
                type: actions.RECEIVE_PROSPECT_CONTACT_DETAILS,
                contactCampaigns: resp.data.data
            });
            return;
        }
        
        yield put({
            type: actions.FETCH_PROSPECT_CONTACT_DETAILS_ERROR,
            errorMessage: resp.error
        });
    });
}

export function* fetchProspectContactMessages() {
    yield takeEvery(actions.FETCH_PROSPECT_CONTACT_MESSAGES, function*(payload) {
        
        let {filters} = payload;
        let query = '?details=true';
        
        if(filters.contactId)
        {
            query += `&contact=${filters.contactId}`;
        }
    
        // filters
        
        const resp = yield DeliveryFetch.get('contactsentmessages'+query, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            yield put({
                type: actions.RECEIVE_PROSPECT_CONTACT_MESSAGES,
                contactMessages: resp.data.data
            });
            return;
        }
        
        yield put({
            type: actions.FETCH_PROSPECT_CONTACT_MESSAGES_ERROR,
            errorMessage: resp.error
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(fetchProspectContact),
        fork(fetchProspectContactDetails),
        fork(fetchProspectContactMessages),
        fork(fetchProspectContactCampaigns),
        fork(afterPageSet),
        fork(fetchContacts),
    ]);
}
