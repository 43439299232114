const actions = {
  CLEAR_USERS: 'CLEAR_USERS',
  
  FETCH_USERS: 'FETCH_USERS',
  RECEIVE_USERS: 'RECEIVE_USERS',
  
  SEARCH_USERS: 'SEARCH_USERS',
  RECEIVE_USER_RESULTS: 'RECEIVE_USER_RESULTS',
  
  INVITE_USER: 'INVITE_USER',
  INVITE_USER_ERROR: 'INVITE_USER_ERROR',
  USER_INVITED: 'USER_INVITED',
  
  UNINVITE_USER: 'UNINVITE_USER',
  UNINVITE_USER_ERROR: 'UNINVITE_USER_ERROR',
  USER_UNINVITED: 'USER_UNINVITED',
  
  PROMOTE_USER: 'PROMOTE_USER',
  PROMOTE_USER_ERROR: 'PROMOTE_USER_ERROR',
  USER_PROMOTED: 'USER_PROMOTED',
  
  fetchUsers: () => ({
    type: actions.FETCH_USERS
  }),
  
  searchUsers: (email) => ({
    type: actions.SEARCH_USERS,
    email
  }),
  
  inviteUser: (email, groupId, level) => ({
    type: actions.INVITE_USER,
    email,
    groupId,
    level
  }),
  
  uninviteUser: (id) => ({
    type: actions.UNINVITE_USER,
    id
  }),
  
  promoteUser: (id, role) => ({
    type: actions.PROMOTE_USER,
    id,
    role
  }),
  
};
export default actions;
