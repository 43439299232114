import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import ReportFetch from "../../helpers/reportFetch";
import AdvertiserApi from "../../helpers/advertiserApi";

export function* fetchAccounts() {
  yield takeEvery('FETCH_GOOGLE_ACCOUNTS', function*() {
    
    const apiResponse = yield AdvertiserApi.get('google-accounts', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_GOOGLE_ACCOUNTS,
        accounts: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_GOOGLE_ACCOUNTS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* fetchAdAccounts() {
  yield takeEvery('FETCH_GOOGLE_ADACCOUNTS', function*(payload) {
    const {account} = payload;
    const apiResponse = yield ReportFetch.get(`adwords/${account}/adaccounts`, null)
      .then(response => (response))
      .catch(error => ({error}));
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_GOOGLE_ADACCOUNTS,
        adAccounts: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_GOOGLE_ADACCOUNTS_ERROR,
      errorMessage: apiResponse.data.error.message
    });
  });
}

export function* fetchCampaigns() {
  yield takeEvery('FETCH_GOOGLE_CAMPAIGNS', function*(payload) {
    const {adAccount} = payload;
    let account = yield select((state) => state.Google.account);
    
    const apiResponse = yield ReportFetch.get(`adwords/${account}/adaccounts/${adAccount}/campaigns`, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_GOOGLE_CAMPAIGNS,
        campaigns: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_GOOGLE_CAMPAIGNS_ERROR,
      errorMessage: apiResponse.data.error.message
    });
  });
}

export function* fetchAdGroups() {
  yield takeEvery('FETCH_GOOGLE_ADGROUPS', function*(payload) {
    let account = yield select((state) => state.Google.account);
    let adAccount = yield select((state) => state.Google.adAccount);
    const {campaign} = payload;
    const {CampaignId, AdvertisingChannelSubType} = campaign;

    let query = '?';
    if(AdvertisingChannelSubType){
      query += 'support_type=' + AdvertisingChannelSubType.replaceAll(' ', '_').toUpperCase()
    }
    
    const apiResponse = yield ReportFetch.get(`adwords/${account}/adaccounts/${adAccount}/campaigns/${CampaignId}/adgroups`+encodeURI(query), null)
      .then(response => (response))
      .catch(error => ({error}));
    
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_GOOGLE_ADGROUPS,
        adGroups: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_GOOGLE_ADGROUPS_ERROR,
      errorMessage: apiResponse.data.error.message
    });
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchAccounts),
    fork(fetchAdAccounts),
    fork(fetchCampaigns),
    fork(fetchAdGroups),
  ]);
}
