const actions = {
  FETCH_AGREES: 'FETCH_AGREES',
  FETCH_AGREES_ERROR: 'FETCH_AGREES_ERROR',
  RECEIVE_AGREES: 'RECEIVE_AGREES',
  
  CREATE_AGREE: 'CREATE_AGREE',
  CREATE_AGREE_ERROR: 'CREATE_AGREE_ERROR',
  AGREE_CREATED: 'AGREE_CREATED',
  
  DELETE_AGREE: 'DELETE_AGREE',
  DELETE_AGREE_ERROR: 'DELETE_AGREE_ERROR',
  AGREE_DELETED: 'AGREE_DELETED',

  fetchAgrees: () => ({
    type: actions.FETCH_AGREES
  }),
  
  createAgree: (agree) => ({
    type: actions.CREATE_AGREE,
    agree
  }),
  
  deleteAgree: (agreeId) => ({
    type: actions.DELETE_AGREE,
    agreeId
  }),
  
};
export default actions;
