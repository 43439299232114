import {all, takeEvery, put, fork, select, call, take} from 'redux-saga/effects';
import {store} from './../../redux/store'
import actions from './actions';
import reportsActions from './../reports/actions';
import campaignActions from './../campaign/actions';
import sourceActions from './../source/actions';
import notifications from '../../components/feedback/notification';
import ReportFetch from "../../helpers/reportFetch";
import AdvertiserApi from "../../helpers/advertiserApi";

export function* fetchAccounts() {
  yield takeEvery(actions.FETCH_FACEBOOK_ACCOUNTS, function*() {
    
    const apiResponse = yield AdvertiserApi.get('facebook-accounts', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_FACEBOOK_ACCOUNTS,
        accounts: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_FACEBOOK_ACCOUNTS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* removeAccount() {
  yield takeEvery(actions.REMOVE_FACEBOOK_ACCOUNT, function*(payload) {
    const {account} = payload;
  
    const accountUsageResponse = yield ReportFetch.get('facebook/' + account + '/sources', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(accountUsageResponse.status !== 200)
    {
      notifications['error']({
        message: 'Account not deleted',
        description: 'We could not delete your account.'
      });
      yield put({
        type: actions.REMOVE_FACEBOOK_ACCOUNT_ERROR,
        errorMessage: ''
      });
      return;
    }
  
    if(accountUsageResponse.data.data > 0)
    {
      notifications['error']({
        message: 'Account not deleted',
        description: 'There are ' + accountUsageResponse.data.data + ' campaign sources that use this account. Delete sources first.'
      });
      yield put({
        type: actions.REMOVE_FACEBOOK_ACCOUNT_ERROR
      });
      return;
    }
  
    const apiResponse = yield AdvertiserApi.delete('facebook-accounts/' + account, null)
      .then(response => (response))
      .catch(error => ({error}));
  
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.FETCH_FACEBOOK_ACCOUNTS
      });
      return;
    }
    
    yield put({
      type: actions.REMOVE_FACEBOOK_ACCOUNT_ERROR,
      errorMessage: ''
    });
  });
}

export function* fetchAdAccounts() {
  yield takeEvery(actions.FETCH_FACEBOOK_ADACCOUNTS, function*(payload) {
    const {account} = payload;
    
    const apiResponse = yield ReportFetch.get(`facebook/${account}/adaccounts`, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_FACEBOOK_ADACCOUNTS,
        adAccounts: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_FACEBOOK_ADACCOUNTS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* fetchCampaigns() {
  yield takeEvery('FETCH_FACEBOOK_CAMPAIGNS', function*(payload) {
    let account = yield select((state) => state.Facebook.account);
    const {adAccount} = payload;
    let query = '?';
    if(adAccount)
    {
      query += 'adAccount=' + adAccount;
    }
    
    const apiResponse = yield ReportFetch.get(`facebook/${account}/campaigns` + query, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_FACEBOOK_CAMPAIGNS,
        campaigns: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_FACEBOOK_CAMPAIGNS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* fetchNewCampaigns() {
  yield takeEvery(actions.FETCH_FACEBOOK_NEW_CAMPAIGNS, function*(payload) {
    let account = yield select((state) => state.Facebook.account);

    const apiResponse = yield ReportFetch.get(`facebook/new-campaigns`, null)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_FACEBOOK_CAMPAIGNS,
        campaigns: apiResponse.data.data
      });
      return;
    }

    yield put({
      type: actions.FETCH_FACEBOOK_CAMPAIGNS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* createNewFacebookCampaign() {
  yield takeEvery(actions.CREATE_FACEBOOK_NEW_CAMPAIGN, function*(payload) {
    const facebookPrimaryAccount = yield select((state) => state.PrimaryAdAccount.facebookAccount);

    const { newCampaignConfiguration } = payload;

    const createCampaign = new Promise((resolve, reject) => store.dispatch({
        type: campaignActions.ASYNC_CREATE_CAMPAIGN,
        campaignValues: newCampaignConfiguration.campaign,
        resolve,
        reject
    }));

    createCampaign.then((campaign) => {
      pushAdSets(facebookPrimaryAccount, newCampaignConfiguration, campaign.data)
          .then((campaign)=>{
            store.dispatch({
              type: campaignActions.CAMPAIGN_CREATED,
              createdCampaign: campaign.data
            })
          })
    }).catch(error => notifications['error']({
      message: 'Error creating Campaign',
      description: error.message
    }));

  });
}

export function* pushNewFacebookCampaign() {
  yield takeEvery(actions.PUSH_FACEBOOK_NEW_CAMPAIGN, function*(payload) {
    const facebookPrimaryAccount = yield select((state) => state.PrimaryAdAccount.facebookAccount);
    const { newCampaignConfiguration, campaign } = payload;
    pushAdSets(facebookPrimaryAccount, newCampaignConfiguration, campaign)
  })
}

const pushAdSets = (primaryAdAccount, campaignConfiguration, campaign) => {
  const apiResponse = ReportFetch.get(`facebook/${primaryAdAccount.platform_account}/adsets?campaign=${campaignConfiguration.campaign.original_campaign.id}`, null);

  apiResponse.then(response => {
    if(response.status !== 200){
      notifications['error']({
        message: 'Fetch Ad Set error',
        description: response.data.message
      });
      return
    }

    const adSets = response.data.data;
    const originalCampaign = campaignConfiguration.campaign.original_campaign;
    adSets.forEach(adSet => {
      store.dispatch(sourceActions.queueSource({
        id: adSet.id,
        name: adSet.name,
        type: originalCampaign.objective,
        campaignName: originalCampaign.name,
        campaignId: originalCampaign.id,
        adAccountId: primaryAdAccount.ad_account,
        accountId: primaryAdAccount.platform_account,
        adAccountName: '',
        sourceType: campaignConfiguration.sources.type,
        margin: 0,
        unique: ''
      }, campaignConfiguration.sources.type))
    });
    store.dispatch(sourceActions.processQueuedSources(campaign))
  }).then(() => {
    store.dispatch(campaignActions.fetchCampaigns())
  }).then(()=>{
    store.dispatch({
      type: sourceActions.QUEUED_SOURCES_PROCESSED
    })
  });

  return apiResponse
};

export function* fetchAdsets() {
  yield takeEvery('FETCH_FACEBOOK_ADSETS', function*(payload) {
    let account = yield select((state) => state.Facebook.account);
    const {campaign} = payload;
    let query = '?';
    if(campaign)
    {
      query += 'campaign=' + campaign.id;
    }
    
    const apiResponse = yield ReportFetch.get(`facebook/${account}/adsets` + query, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_FACEBOOK_ADSETS,
        adSets: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_FACEBOOK_ADSETS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* getCreativePreview() {
  yield takeEvery(actions.FETCH_FACEBOOK_CREATIVE, function*(payload) {
    const {ad} = payload;
    let account = yield select((state) => state.Sources.source.additional_mapping.faceAccountId);

    const apiResponse = yield ReportFetch.get('facebook/' + account + '/creative/' + ad)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield store.dispatch(reportsActions.setModal(apiResponse.data.data));
      yield put({ type: reportsActions.UNSET_MODAL_FETCHING });
      return;
    }

    yield put({type: reportsActions.UNSET_MODAL_FETCHING});
    yield put({
      type: actions.FETCH_FACEBOOK_CREATIVE_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}



export default function* rootSaga() {
  yield all([
    fork(fetchAccounts),
    fork(fetchAdAccounts),
    fork(fetchCampaigns),
    fork(fetchNewCampaigns),
    fork(createNewFacebookCampaign),
    fork(pushNewFacebookCampaign),
    fork(fetchAdsets),
    fork(removeAccount),
    fork(getCreativePreview)
  ]);
}
