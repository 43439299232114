const calendarActions = {
  CALENDAR_VIEW: 'CALENDAR_VIEW',
  CALENDAR_EVENTS: 'CALENDAR_EVENTS',
  FETCH_PROSPECTS_WITH_DATE_FIELDS: 'FETCH_PROSPECTS_WITH_DATE_FIELDS',
  FETCH_PROSPECTS_WITH_DATE_FIELDS_ERROR: 'FETCH_PROSPECTS_WITH_DATE_FIELDS_ERROR',
  PROSPECTS_WITH_DATE_FIELDS_RECEIVED: 'PROSPECTS_WITH_DATE_FIELDS_RECEIVED',

  FETCH_RECENT_CALENDAR_EVENTS: 'FETCH_RECENT_CALENDAR_EVENTS',
  FETCH_RECENT_CALENDAR_EVENTS_ERROR: 'FETCH_RECENT_CALENDAR_EVENTS_ERROR',
  RECENT_CALENDAR_EVENTS_RECEIVED: 'RECENT_CALENDAR_EVENTS_RECEIVED',

  UPDATE_CALENDAR_EVENT: 'UPDATE_CALENDAR_EVENT',
  UPDATE_CALENDAR_EVENT_ERROR: 'UPDATE_CALENDAR_EVENT_ERROR',
  CALENDAR_EVENT_UPDATED: 'CALENDAR_EVENT_UPDATED',

  FETCH_DAILY_TASKS: 'FETCH_DAILY_TASKS',
  FETCH_DAILY_TASKS_ERROR: 'FETCH_DAILY_TASKS_ERROR',
  DAILY_TASKS_RECEIVED: 'DAILY_TASKS_RECEIVED',

  ADD_DAILY_TASK: 'ADD_DAILY_TASK',
  ADD_DAILY_TASK_ERROR: 'ADD_DAILY_TASK_ERROR',
  DAILY_TASK_ADDED: 'DAILY_TASK_ADDED',

  REMOVE_DAILY_TASK: 'REMOVE_DAILY_TASK',
  REMOVE_DAILY_TASK_ERROR: 'REMOVE_DAILY_TASK_ERROR',
  DAILY_TASK_REMOVED: 'DAILY_TASK_REMOVED',

  fetchProspectsWithDateFields: () => ({
    type: calendarActions.FETCH_PROSPECTS_WITH_DATE_FIELDS,
  }),

  fetchRecentCalendarEvents: (startDate, endDate) => ({
    type: calendarActions.FETCH_RECENT_CALENDAR_EVENTS,
    startDate,
    endDate
  }),

  updateCalendarEvent: (startDate, endDate, event) => ({
    type: calendarActions.UPDATE_CALENDAR_EVENT,
    startDate,
    endDate,
    event
  }),

  fetchDailyTasks: (startDate, endDate) => ({
    type: calendarActions.FETCH_DAILY_TASKS,
    startDate,
    endDate
  }),

  addDailyTask: (event) => ({
    type: calendarActions.ADD_DAILY_TASK,
    event
  }),

  removeDailyTask: (task) => ({
    type: calendarActions.REMOVE_DAILY_TASK,
    task
  }),

  changeView: view => ({
    type: calendarActions.CALENDAR_VIEW,
    view
  }),
  changeEvents: events => ({
    type: calendarActions.CALENDAR_EVENTS,
    events
  })
};
export default calendarActions;
