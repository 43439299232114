import React, { Component } from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import LayoutContentWrapper from '../../../../components/utility/layoutWrapper';
import LayoutContent from '../../../../components/utility/layoutContent';
import PageHeader from '../../../../components/utility/pageHeader';
import AddChart from '../../../../components/analysis/addChart';
import Button from "../../../../components/uielements/button";
import Input, {InputSearch, InputGroup, Textarea } from '../../../../components/uielements/input';
import Spin from "../../../../components/uielements/spin";
import Select, { SelectOption } from '../../../../components/uielements/select';
import {Checkbox, Col, Icon, Row, Modal, Card, Tooltip} from "antd";
import moment from "moment";
import Modals from "../../../../components/feedback/modal";
import chartsActions from './../../../../redux/campaignCharts/actions'
import BarChart from './bar';
import LineChart from './line';
import PieChart from './pie';
import DoughnutChart from "./doughnut";
import PercentageBarChart from "./percentageBar";

import basicStyle from '../../../../settings/basicStyle';
import ChartWrapper from "../../../Leads/Analysis/chart.style";
import Cards from "../../../../components/uielements/card";
import Popconfirm from "../../../../components/feedback/popconfirm";
import ContentHolder from "../../../../components/utility/contentHolder";

import { getTypeEnum, getSubTypeEnum, getSubTypeName } from './../../../Reports/Source/utility'

const { confirm } = Modal;
const Option = SelectOption;

export const defaultGoal = {
  id: null,
  name: '',
  source_fields_mapping: {},
  dataset_naming: {},
  dataset_properties: {},
  chart_type: 'bar',
  chart_size: 8,
  order_number: null,
  timespan: 'days',
  goal: 0,
  gain: false,
  published: true,
  xaxis_format: 0,
};

export const defaultDatasetProperties = {
  goal: 0,
};

export const X_AXIS_FORMAT_HIDING = 'X_AXIS_FORMAT_HIDING';
export const X_AXIS_FORMAT_SHORTEN = 'X_AXIS_FORMAT_SHORTEN';
export const X_AXIS_FORMAT_CURSIVE = 'X_AXIS_FORMAT_CURSIVE';

export const getXaxisFormatId = (xaxisFormatEnum) => {
  switch (xaxisFormatEnum) {
    case X_AXIS_FORMAT_HIDING: return 0;
    case X_AXIS_FORMAT_SHORTEN: return 1;
    case X_AXIS_FORMAT_CURSIVE: return 2;
  }
};

class CampaignCharts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      title: <Spin />,
      settingsModal: false,
      id: null,
      report: null,
      charts: [],
      goalSettings: defaultGoal,
      settingsVisible: false,
      comparePrimaryGoalSettings: {},
      exportChart: [],
      showLimitTooltip: false,
      showRangeTooltip: false,
      hoveredChartSettings: null,
      chartsRefs: {},
    };
  }

  componentWillMount() {
    const {clearCharts} = this.props;

    clearCharts();
  }

  componentDidMount()
  {
    const {fetchGoals, campaign} = this.props;

    fetchGoals(campaign);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.transformChartsLabels();
  }

  addChart = () => {
    const {addGoal, goals} = this.props;
    addGoal({
      order_number: goals.length ? [...goals].pop().order_number+1 : 0
    });
  };

  removeChart = (goal) => {
    const {campaign, removeGoal} = this.props;

    removeGoal(campaign, goal);
  };

  createChart = () => {
    const {goalSettings} = this.state;
    const {campaign, patchGoal, createChart} = this.props;

    if(!goalSettings.id)
      createChart(campaign, goalSettings);
    else
      patchGoal(campaign, goalSettings);

    this.handleSettingsCancel()
  };

  updateGoal = (props) => {
    this.setState({goalSettings: {...this.state.goalSettings, ...props}});
  };

  handleSettings = (goal = null) => {
    this.setState({settingsVisible: !this.state.settingsVisible});
    this.setState({goalSettings: {...this.state.goalSettings, ...defaultGoal, ...goal}}, () => {
      this.setState({comparePrimaryGoalSettings: {...this.state.goalSettings}});
    } );
  };

  handleSettingsCancel = () => {
    this.setState({settingsVisible: !this.state.settingsVisible});
    this.setState({
      goalSettings: defaultGoal,
      comparePrimaryGoalSettings: {},
      showRangeTooltip: false
    });
  };
  handleAbandonChanges = () => {
    let haveChanges = JSON.stringify(this.state.comparePrimaryGoalSettings) !== JSON.stringify(this.state.goalSettings);

    if(haveChanges){
      let cancel = () => { this.handleSettingsCancel() };
      confirm({
        title: 'Cancel settings',
        icon: <Icon type={'exclamation-circle'} />,
        content: 'Do you want to close the settings without saving changes?',
        onOk() {
          cancel()
        }
      });
    } else {
      this.handleSettingsCancel()
    }
  };


  addFieldsMappingDataset = () => {
    const {goalSettings} = this.state;
    let firstAvailableSource = this.getSourcesSubType().shift();
    let mapping = goalSettings.source_fields_mapping;
    let newDatasetIndex = parseInt(Object.keys( Object.keys(mapping).length > 0 ? mapping : {0:0} ).pop().match(/[0-9]+/).shift()) + 1;
    this.setState({ goalSettings:{
        ...goalSettings,
        source_fields_mapping:{
          ...mapping,
          [`dataset${newDatasetIndex}`]:{
            [firstAvailableSource.type]:{
              [firstAvailableSource.sub_type]:{
                field: Object.keys(firstAvailableSource.sources.pop().field_display).pop(),
                excludedSourcesId: []
              }
            }
          }
        },
        dataset_naming:{
          ...goalSettings.dataset_naming,
          [`dataset${newDatasetIndex}`]: 'New dataset'
        },
        dataset_properties:{
          ...goalSettings.dataset_properties,
          [`dataset${newDatasetIndex}`]: defaultDatasetProperties
        },
      }
    })
  };

  addFieldsMappingSource = (datasetIndex) => {
    let newSource = this.getFirstAvailableSource(datasetIndex);

    if(!newSource) return;

    this.setState({goalSettings: {
      ...this.state.goalSettings,
      source_fields_mapping: {
        ...this.state.goalSettings.source_fields_mapping,
        [datasetIndex]:{
          ...this.state.goalSettings.source_fields_mapping[datasetIndex],
          [newSource.type]:{
            ...this.state.goalSettings.source_fields_mapping[datasetIndex][newSource.type],
            [newSource.sub_type]: {field: Object.keys(newSource.field_display).pop(), excludedSourcesId: []}
          }
        }
      }
      }})
  };

  removeFieldsMappingSource = (datasetIndex, source_type) => {
    const {goalSettings} = this.state;
    const currentType = this.findSourceSubType(source_type);

    let removeSubType = this.removeKey(goalSettings.source_fields_mapping[datasetIndex][currentType.type], currentType.sub_type);
    this.setState({
      goalSettings : {
        ...goalSettings,
        source_fields_mapping:{
          ...goalSettings.source_fields_mapping,
          [datasetIndex]:{
            ...goalSettings.source_fields_mapping[datasetIndex],
            [currentType.type]:{
              ...removeSubType
            }
          }
        }
      }
    }, () => {
      const {goalSettings} = this.state;
      if(Object.keys(goalSettings.source_fields_mapping[datasetIndex][currentType.type]).length < 1){
        let removeType = this.removeKey(goalSettings.source_fields_mapping[datasetIndex], currentType.type);
        this.setState({
          goalSettings:{
            ...goalSettings,
            source_fields_mapping:{
              ...goalSettings.source_fields_mapping,
              [datasetIndex]:{
                ...removeType
              }
            }
          }
        }, () => {
          const {goalSettings} = this.state;
          if(Object.keys(goalSettings.source_fields_mapping[datasetIndex]).length < 1){
            this.removeDatasetName(datasetIndex);
            this.removeDatasetProperties(datasetIndex);
            let removeDataset = this.removeKey(goalSettings.source_fields_mapping, datasetIndex);
            this.setState({
              goalSettings:{
                ...goalSettings,
                source_fields_mapping:{
                  ...removeDataset
                }
              }
            })
          }
        })
      }
    })
  };

  updateFieldsMappingSource = (datasetIndex, source_type, new_source_type) => {
    const {goalSettings} = this.state;
    const currentType = this.findSourceSubType(source_type);
    const nextType = this.findSourceSubType(new_source_type);

    let removeSubType = this.removeKey(goalSettings.source_fields_mapping[datasetIndex][currentType.type], currentType.sub_type);
    this.setState({ goalSettings : {
        ...goalSettings,
        source_fields_mapping: {
          ...goalSettings.source_fields_mapping,
          [datasetIndex]:{
            ...goalSettings.source_fields_mapping[datasetIndex],
            [currentType.type]:{
              ...removeSubType
            }
          }
        }
      }}, () => {
      const {goalSettings} = this.state;
      this.setState({ goalSettings : {
          ...goalSettings,
          source_fields_mapping: {
            ...goalSettings.source_fields_mapping,
            [datasetIndex]:{
              ...goalSettings.source_fields_mapping[datasetIndex],
              [nextType.type]:{
                ...goalSettings.source_fields_mapping[datasetIndex][nextType.type],
                [nextType.sub_type]:{
                  field: Object.keys(nextType.field_display).pop(),
                  excludedSourcesId: []
                }
              }
            }
          }
        }}, () => {
        const {goalSettings} = this.state;
        if (Object.keys(goalSettings.source_fields_mapping[datasetIndex][currentType.type]).length < 1) {
          if(Object.keys(goalSettings.source_fields_mapping[datasetIndex]).length < 1){
            this.removeDatasetName(datasetIndex);
            this.removeDatasetProperties(datasetIndex);
          }
          let removeType = this.removeKey(goalSettings.source_fields_mapping[datasetIndex], currentType.type);
          this.setState({
            goalSettings: {
              ...goalSettings,
              source_fields_mapping: {
                ...goalSettings.source_fields_mapping,
                [datasetIndex]: {
                  ...removeType
                }
              }
            }
          })
        }
      })
    })
  };

  updateDatasetName = (datasetIndex, name) => {
    const {goalSettings} = this.state;
    this.setState({
      goalSettings:{
        ...goalSettings,
        dataset_naming: {
          ...goalSettings.dataset_naming,
          [datasetIndex]: name
        }
      }
    })
  };

  updateDatasetProperties = (datasetIndex, datasetProperties) => {
    const {goalSettings} = this.state;
    this.setState({
      goalSettings:{
        ...goalSettings,
        dataset_properties:{
          ...goalSettings.dataset_properties,
          [datasetIndex]:{
            ...goalSettings.dataset_properties[datasetIndex],
            ...datasetProperties
          }
        }
      }
    });
  };

  removeDatasetName = (datasetIndex) => {
    const {goalSettings} = this.state;
    delete goalSettings.dataset_naming[datasetIndex];
    this.setState({
      goalSettings:{
        ...goalSettings,
        dataset_naming: {
          ...goalSettings.dataset_naming
        }
      }
    })
  };

  removeDatasetProperties = (datasetIndex) => {
    const {goalSettings} = this.state;
    delete goalSettings.dataset_properties[datasetIndex];
    this.setState({
      goalSettings:{
        ...goalSettings,
        dataset_properties: {
          ...goalSettings.dataset_properties
        }
      }
    })
  };

  setDatasetGoal = (datasetIndex, goalValue) => {
    this.updateDatasetProperties(datasetIndex, {
      goal: (goalValue.toString().match(/[0-9]/g)||[]).join('')
    })
  };

  removeKey = (obj, deleteType) => {
    let clone = Object.assign({}, obj);
    delete clone[deleteType];
    return clone;
  };

  updateSourceField = (datasetIndex, source_type, field) => {
    const {goalSettings} = this.state;
    const currentType = this.findSourceSubType(source_type);
    this.setState({
      goalSettings : {
        ...goalSettings,
        source_fields_mapping: {
          ...goalSettings.source_fields_mapping,
          [datasetIndex]:{
            ...goalSettings.source_fields_mapping[datasetIndex],
            [currentType.type]:{
              ...goalSettings.source_fields_mapping[datasetIndex][currentType.type],
              [currentType.sub_type]:{
                ...goalSettings.source_fields_mapping[datasetIndex][currentType.type][currentType.sub_type],
                field
              }
            }
          }
        }
      }
    })
  };

  updateExcludedSources = (datasetIndex, source_type, sources_id) => {
    const {goalSettings} = this.state;
    const currentType = this.findSourceSubType(source_type);
    this.setState({
      goalSettings : {
        ...goalSettings,
        source_fields_mapping: {
          ...goalSettings.source_fields_mapping,
          [datasetIndex]:{
            ...goalSettings.source_fields_mapping[datasetIndex],
            [currentType.type]:{
              ...goalSettings.source_fields_mapping[datasetIndex][currentType.type],
              [currentType.sub_type]:{
                ...goalSettings.source_fields_mapping[datasetIndex][currentType.type][currentType.sub_type],
                excludedSourcesId: [...sources_id]
              }
            }
          }
        }
      }
    })
  };

  getFirstAvailableSource = (datasetIndex) => {
    return this.getSourcesSubType().filter(source => !Object.keys(this.getMappingSubType(datasetIndex)).includes(source.enum)).pop();
  };

  isolateSourcesSubType = () => {
    const {sources} = this.props;
    let types = [];
    sources.map(source => {
      if( types.filter(source_type => source_type.type === source.type && source_type.sub_type === source.sub_type ).length === 0 ){
        types.push({type: source.type, sub_type: source.sub_type, field_display: source.field_display, sources: []})
      }
      let typeIndex = types.findIndex(source_type => source_type.type === source.type && source_type.sub_type === source.sub_type);
      types[typeIndex].sources.push(source);
    });
    return types;
  };

  getSourcesSubType = () => {
    let sourcesSubType = [];
    this.isolateSourcesSubType().map(source => sourcesSubType.push({...source, enum: getSubTypeEnum(source)}));
    return sourcesSubType;
  };

  getMappingSubType = (datasetIndex) => {
    const {source_fields_mapping} = this.state.goalSettings;
    let mappingSubType = {};
    for(let source_type in source_fields_mapping[datasetIndex]){
      for(let source_subtype in source_fields_mapping[datasetIndex][source_type]){
        mappingSubType[getSubTypeEnum({type: parseInt(source_type), sub_type: parseInt(source_subtype)})] = source_fields_mapping[datasetIndex][source_type][source_subtype]
      }
    }
    return mappingSubType;
  };

  findSourceSubType = (string_enum) => {
    return this.getSourcesSubType().filter(source_type => source_type.enum === string_enum).shift();
  };


  isDatasetLimitReached = () => {
    const {chart_type, source_fields_mapping} = this.state.goalSettings;
    let datasetSize = Object.keys(source_fields_mapping).length;
    switch (chart_type) {
      case "line":
      case "bar":
        return {result: datasetSize >= 5, limit: 5};
      case "pie":
      case "doughnut":
        return {result: datasetSize >= 1, limit: 1};
      case "percentage":
        return {result: datasetSize >= 10, limit: 10};
      default:
        return {result: datasetSize >= 5, limit: 5};
    }
  };

  handleLimitTooltipEnter = () => {
    this.setState({showLimitTooltip: this.isDatasetLimitReached().result});
  };

  handleLimitTooltipLeave = () => {
    this.setState({showLimitTooltip: false});
  };

  getGoals = () => {
    const { goals } = this.props;
    return goals.sort((a,b)=> a.order_number > b.order_number ? 1 : (a.order_number<b.order_number ? -1 : 0) )
  };

  swapOrderWithPrevious = (goal) => {
    const {initialUpdateGoal} = this.props;
    let goals = this.getGoals();
    let prevGoal = null;
    for(let currGoal in goals){
      if(prevGoal && goal === goals[currGoal]){
        let a = prevGoal.order_number;
        let b = goal.order_number;
        prevGoal.order_number = b;
        goal.order_number = a;
        initialUpdateGoal(prevGoal);
        initialUpdateGoal(goal);
        return;
      }

      prevGoal = goals[currGoal]
    }
  };

  swapOrderWithNext = (goal) => {
    const {initialUpdateGoal} = this.props;
    let goals = this.getGoals();
    let prevGoal = null;
    for(let currGoal in goals){
      if(prevGoal){
        let a = prevGoal.order_number;
        let b = goals[currGoal].order_number;
        prevGoal.order_number = b;
        goals[currGoal].order_number = a;
        initialUpdateGoal(prevGoal);
        initialUpdateGoal(goals[currGoal]);
        return;
      }

      if(goals[currGoal] === goal)
        prevGoal = goals[currGoal]
    }
  };

  handleChartSizeRange = (value) => {
    if((value > 0 && value < 25) || value === '') {
      this.updateGoal({chart_size: parseInt(value) || ''});
      this.setState({ showRangeTooltip: false })
    } else {
      this.setState({ showRangeTooltip: true })
    }
  };

  exportChart = (goal) => {
    this.setState({
      exportChart:[
        ...this.state.exportChart,
        goal.id
      ]
    })
  };

  shouldExport = (goal) => {
    let shouldExport = this.state.exportChart.includes(goal.id);
    if(shouldExport)
      this.setState({
        exportChart: this.state.exportChart.filter(export_id => export_id !== goal.id)
      });
    return shouldExport;
  };

  transformChartsLabels = ()=>{
    const {chartsRefs} = this.state;
    for(let chartId in chartsRefs)
    {
      let chartRef = null;
      let chartElement = null;
      if((chartRef = chartsRefs[chartId].current) && (chartElement = document.getElementById(chartId))){
        if(chartRef.props.xaxis_format !== getXaxisFormatId(X_AXIS_FORMAT_CURSIVE))
          continue;
        let chartDom = null;
        let xAxisDom = null;
        let xAxisLabels = null;
        switch(true){
          case chartRef instanceof BarChart.WrappedComponent:
            chartDom = chartElement.getElementsByClassName('bar-chart')
            break;
          case chartRef instanceof LineChart.WrappedComponent:
            chartDom = chartElement.getElementsByClassName('line-chart')
            break;
        }
        if(chartDom && chartDom.length) {
          if (chartDom.length && (xAxisDom = chartElement.getElementsByClassName('x axis'))) {
            xAxisLabels = xAxisDom[0].childNodes;
            for (var i = 0; i < xAxisLabels.length; i++) {
              let label = chartRef.chartRef.data.labels[i];
              if(label === undefined) continue;
              xAxisLabels[i].getElementsByTagName('text')[0].setAttributeNS(null, "transform", "rotate(14 0 0) translate(" +
                  (label.length * 2 + 60 )+" "+
                  (label.length * .3 - 10) +")");
              xAxisLabels[i].getElementsByTagName('text')[0].innerHTML = label;
            }
          }
        }
      }
    }
  };

  render() {
    const { rowStyle, colStyle, gutter } = basicStyle;
    const {goals, queueGoalPatch, patchMultipleGoal, sources, isFetchingChart, user} = this.props;

    const {settingsVisible, showLimitTooltip, showRangeTooltip, hoveredChartSettings} = this.state;
    const {name, gain, chart_type, chart_size, timespan, goal, published, xaxis_format, source_fields_mapping, dataset_naming, dataset_properties} = this.state.goalSettings;
    let size = 1;

    const sources_type = this.getSourcesSubType();

    return (
        <ChartWrapper>
            <ContentHolder>
              <Modals
                  className={'filteringModal'}
                  visible={settingsVisible}
                  title={'Filters for: ' + name}
                  onCancel={() => this.handleAbandonChanges()}
                  footer={null}
              >
                <Row  style={{marginBottom: 15}}>
                  <Col sm={4} style={{paddingTop: 4}}>
                    Chart name:
                  </Col>
                  <Col sm={20}>
                    <Input value={name} onChange={(e) => this.updateGoal({name: e.target.value})}/>
                  </Col>
                </Row>

                <h3 style={{marginTop: 20, fontWeight: 600}}>Chart settings:</h3>

                <Row>
                  <Col sm={16} style={{marginTop: 10}}>
                    <Row>
                      {
                        Object.keys(source_fields_mapping).map(datasetIndex => {
                          const type_source_mapping = this.getMappingSubType(datasetIndex);
                          return (
                            <Card title={<Row>
                                <Col sm={10}>
                                  <Input value={dataset_naming[datasetIndex]} onChange={e => this.updateDatasetName(datasetIndex, e.target.value)}/>
                                </Col>
                                <Col sm={8} style={{float:'right'}}>
                                  <Col sm={4}>
                                    <label style={{color: 'rgba(0,0,0,.85)', fontSize:'14px'}}>Goal: </label>
                                  </Col>
                                  <Col sm={20}>
                                    <Input value={dataset_properties[datasetIndex].goal} onChange={e => this.setDatasetGoal(datasetIndex, e.target.value)} onBlur={()=> dataset_properties[datasetIndex].goal==='' ? this.setDatasetGoal(datasetIndex, 0):null }/>
                                  </Col>
                                </Col>
                              </Row>} style={{width:"100%", marginTop:10, marginBottom:10}}>
                              {Object.keys(type_source_mapping).map(mapping_type => { return <>
                                <Col sm={22}>
                                  <Row>
                                    <Col sm={16}>
                                      <Select value={mapping_type} style={{width:'100%'}} onChange={(next_type) => this.updateFieldsMappingSource(datasetIndex, mapping_type, next_type)}>{
                                        sources_type.filter( source_type => !Object.keys(type_source_mapping).includes(source_type.enum) || source_type.enum === mapping_type ).map((source_type)=>{
                                          return <SelectOption value={source_type.enum}>{getSubTypeName(source_type.enum)}</SelectOption>
                                        })
                                      }</Select>
                                    </Col>
                                    <Col sm={8}>
                                      <Select value={type_source_mapping[mapping_type]['field']} style={{width:'100%'}}  onChange={(field) => this.updateSourceField(datasetIndex, mapping_type, field)}>
                                        {sources_type.filter(source_type => source_type.enum == mapping_type).map((source)=>{
                                          return Object.keys(source.field_display).map((source_field)=>{
                                            return <SelectOption value={source_field}>{source.field_display[source_field]}</SelectOption>
                                          })
                                        })}
                                      </Select>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={22} offset={2}>
                                      <Select value={type_source_mapping[mapping_type]['excludedSourcesId']} mode="multiple" placeholder="Please select sources to exclude (Optional)" style={{width:"100%", marginTop:4, marginBottom: 20}} onChange={source_id => this.updateExcludedSources(datasetIndex, mapping_type, source_id)}>
                                        {
                                          sources_type.filter(source_type => source_type.enum == mapping_type).shift().sources.map(source=>{
                                            return <SelectOption value={source.id}>{source.name}</SelectOption>
                                          })
                                        }
                                      </Select>
                                    </Col>
                                  </Row>

                                </Col>
                                <Col sm={2}>
                                  <Button type={'danger'} size={'small'} icon={'minus'} style={{margin: '3px 10px'}} onClick={() => this.removeFieldsMappingSource(datasetIndex, mapping_type)}/>
                                </Col>
                              </>})}
                              <Col sm={24}><Col sm={2} offset={22}>
                                <Button type={'primary'} size={'small'} icon={'plus'} style={{margin: '7px 10px'}} onClick={() => this.addFieldsMappingSource(datasetIndex)}/>
                              </Col></Col>
                            </Card>
                          );
                        })
                      }
                      <Col sm={24}><Col sm={3} offset={21}>
                        <Tooltip placement="bottomLeft" visible={showLimitTooltip} title={`The maximum size of a dataset is limited to ${this.isDatasetLimitReached().limit}.`} onMouseEnter={()=>this.handleLimitTooltipEnter()} onMouseLeave={()=>this.handleLimitTooltipLeave()}>
                          <Button type={'primary'} size={'small'} icon={'plus'} style={{margin: '5px 10px'}} onClick={() => this.isDatasetLimitReached().result ? null : this.addFieldsMappingDataset()}/>
                        </Tooltip>
                      </Col></Col>
                    </Row>
                    <br/>
                  </Col>

                  <Col sm={3} offset={1}>
                    <Row>
                      Chart size (1-24): <Tooltip visible={showRangeTooltip} placement="right" title={`The size range is 1-24`}>
                      <Input style={{width: 200, float:'right'}} value={chart_size} onChange={(e) => this.handleChartSizeRange(e.target.value)} onMouseLeave={()=>this.setState({showRangeTooltip:false})}/>
                    </Tooltip>
                    </Row>
                    <Row>
                      Chart type: &nbsp;<Select
                        value={chart_type}
                        style={{width: 200, float:'right'}}
                        onChange={(value) => this.updateGoal({chart_type: value, timespan: ((value === 'bar' || value === 'line' || value === 'doughnut') ? 'days' : 'sources')})}>
                      <SelectOption value={'bar'}>Bar</SelectOption>
                      <SelectOption value={'line'}>Line</SelectOption>
                      <SelectOption value={'pie'}>Pie</SelectOption>
                      <SelectOption value={'doughnut'}>Numerical</SelectOption>
                      <SelectOption value={'percentage'}>Percentage bar</SelectOption>
                    </Select>
                    </Row>
                    <Row style={{marginTop:8}}>
                      Timespan: &nbsp;<Select
                        value={timespan}
                        style={{width: 200, float:'right'}}
                        onChange={(value) => this.updateGoal({timespan: value}) }>
                      {(() => {
                        let selectOptions = [];
                        if(chart_type === 'bar' || chart_type === 'line' || chart_type === 'doughnut'){
                          selectOptions.push([
                            <SelectOption value={'days'}>Day by Day</SelectOption>,
                            <SelectOption value={'months'}>Month by Month</SelectOption>,
                            <SelectOption value={'years'}>Year by Year</SelectOption>
                          ])
                        }
                        if(chart_type === 'bar' || chart_type === 'pie' || chart_type === 'doughnut') {
                          selectOptions.push(<SelectOption value={'sources'}>Sources total</SelectOption>)
                        }
                        if(chart_type === 'percentage') {
                          selectOptions.push(<SelectOption value={'sources'}>Dataset</SelectOption>)
                        }
                        // if(chart_type === 'doughnut') {
                        //   selectOptions.push(<SelectOption value={'time_period'}>Period of time</SelectOption>)
                        // }
                        return selectOptions;
                      })()}
                    </Select>
                    </Row>
                    <Row style={{marginTop:8}}>
                      Axis x format: &nbsp;<Select
                        value={xaxis_format}
                        style={{width: 200, float:'right'}}
                        onChange={(value) => this.updateGoal({xaxis_format: value}) }>
                      <SelectOption value={getXaxisFormatId(X_AXIS_FORMAT_HIDING)}>Overflow hidden</SelectOption>
                      <SelectOption value={getXaxisFormatId(X_AXIS_FORMAT_SHORTEN)}>Shortening</SelectOption>
                      <SelectOption value={getXaxisFormatId(X_AXIS_FORMAT_CURSIVE)}>At an angle</SelectOption>
                    </Select>
                    </Row>
                  </Col>

                  <Col sm={3} offset={1}>
                    <Row><Checkbox checked={gain} onChange={() => this.updateGoal({gain: !gain})}>Gain</Checkbox></Row>
                    <Row><Checkbox checked={published} onChange={() => this.updateGoal({published: !published})}>Public</Checkbox></Row>
                  </Col>
                </Row>

                <Row>
                  <Col sm={23}><Button type={'primary'} style={{float:'right'}} onClick={() => this.createChart()}>SAVE CHART</Button></Col>
                </Row>
              </Modals>

              <ContentHolder>
                {(goals) ? this.getGoals().map((goal, index) => {
                      if(!goal.published && !user.isAdmin()) return;
                      return <div className={ `chart-card ${hoveredChartSettings === index ? 'chart-card-active' : ''}` }
                                  onMouseEnter={()=>this.setState({hoveredChartSettings:index})}
                                  onMouseLeave={()=>this.setState({hoveredChartSettings:null})}
                      >
                        <Col md={goal.chart_size} sm={goal.chart_size} xs={goal.chart_size*2} style={colStyle}>
                          {user.isAdmin() ? <a className={`chart-settings ${hoveredChartSettings === index ? 'chart-settings-active' : ''}`} onClick={() => this.handleSettings(goal)}>Chart settings</a> : null}
                          <Cards
                              title={goal.name}
                              bordered={false}
                              extra={<div className={'chart-settings-nav'}>
                                <Button onClick={this.resizeChart} shape={'circle'}  size={'small'} icon={size == 2 ? 'fullscreen' : 'fullscreen-exit'} style={{marginLeft: 8, marginRight: 8, paddingTop: 2}} />
                                { user.isAdmin() ? <Popconfirm
                                    title="Sure to delete chart?"
                                    okText="DELETE"
                                    cancelText="No"
                                    placement={'top'}
                                    onConfirm={() => this.removeChart(goal)}
                                >
                                  <Button type={'danger'} shape={'circle'} size={'small'} icon={'delete'} style={{paddingTop: 2}} />
                                </Popconfirm> : null }

                                <Button type={'primary'} shape={'circle'} size={'small'} icon={'cloud-download'} style={{paddingTop: 2, marginLeft: 10}} onClick={() => this.exportChart(goal)} />

                                {user.isAdmin() ?
                                    <>
                                      <Button shape={'circle'} size={'small'} icon={'left'} onClick={() => this.swapOrderWithPrevious(goal)} style={{paddingTop: 2, marginLeft: 10}}/>
                                      <Button shape={'circle'} size={'small'} icon={'right'} onClick={() => this.swapOrderWithNext(goal)} style={{paddingTop: 2, marginLeft: 10}}/>
                                      { queueGoalPatch.length ? <Button className={'chart-settings-active'} shape={'round'} icon={'save'} onClick={() => patchMultipleGoal()} style={{paddingTop: 2, marginLeft: 10}}>Save order</Button> : null }
                                    </> : null }
                              </div>}
                          >
                          {(()=>{
                            if(!this.state.chartsRefs.hasOwnProperty(`chart${index}`) && ['bar','line'].includes(goal.chart_type)){
                              let chartRef = React.createRef();
                              this.setState({chartsRefs: {...this.state.chartsRefs, [`chart${index}`]:chartRef}});
                            }
                            switch (goal.chart_type || 'bar') {
                              case "bar":
                                return <BarChart
                                    key={'chart' + index}
                                    chartIndex={'chart' + index}
                                    goalId={goal.id}
                                    goalName={goal.name}
                                    goalSourceFieldsMapping={goal.source_fields_mapping}
                                    goalDatasetNaming={goal.dataset_naming}
                                    type={goal.type}
                                    timespan={goal.timespan}
                                    val_goal={goal.goal}
                                    xaxis_format={goal.xaxis_format}
                                    datasetProperties={goal.dataset_properties}
                                    gain={goal.gain}
                                    published={goal.published}
                                    shouldExport = {this.shouldExport(goal)}
                                    isLoading={isFetchingChart.includes(goal.id)}
                                    ref={this.state.chartsRefs[`chart${index}`]}
                                />
                              case "line":
                                return <LineChart
                                    key={'chart' + index}
                                    chartIndex={'chart' + index}
                                    goalId={goal.id}
                                    goalName={goal.name}
                                    goalSourceFieldsMapping={goal.source_fields_mapping}
                                    goalDatasetNaming={goal.dataset_naming}
                                    type={goal.type}
                                    timespan={goal.timespan}
                                    val_goal={goal.goal}
                                    xaxis_format={goal.xaxis_format}
                                    datasetProperties={goal.dataset_properties}
                                    gain={goal.gain}
                                    published={goal.published}
                                    shouldExport = {this.shouldExport(goal)}
                                    isLoading={isFetchingChart.includes(goal.id)}
                                    ref={this.state.chartsRefs[`chart${index}`]}
                                />
                              case "pie":
                                return <PieChart
                                    key={'chart' + index}
                                    chartIndex={'chart' + index}
                                    goalId={goal.id}
                                    goalName={goal.name}
                                    goalSourceFieldsMapping={goal.source_fields_mapping}
                                    goalDatasetNaming={goal.dataset_naming}
                                    type={goal.type}
                                    timespan={goal.timespan}
                                    val_goal={goal.goal}
                                    datasetProperties={goal.dataset_properties}
                                    gain={goal.gain}
                                    published={goal.published}
                                    shouldExport = {this.shouldExport(goal)}
                                    isLoading={isFetchingChart.includes(goal.id)}
                                />
                              case "doughnut":
                                return <DoughnutChart
                                    key={'funnel' + index}
                                    chartIndex={'funnel' + index}
                                    goal={goal}
                                    goalId={goal.id}
                                    goalName={goal.name}
                                    goalSourceFieldsMapping={goal.source_fields_mapping}
                                    goalDatasetNaming={goal.dataset_naming}
                                    type={goal.type}
                                    timespan={goal.timespan}
                                    val_goal={goal.goal}
                                    datasetProperties={goal.dataset_properties}
                                    gain={goal.gain}
                                    published={goal.published}
                                    shouldExport = {this.shouldExport(goal)}
                                    isLoading={isFetchingChart.includes(goal.id)}
                                />
                              case "percentage":
                                return <PercentageBarChart
                                    key={'percentageBar' + index}
                                    chartIndex={'percentageBar' + index}
                                    goalId={goal.id}
                                    goalName={goal.name}
                                    goalSourceFieldsMapping={goal.source_fields_mapping}
                                    goalDatasetNaming={goal.dataset_naming}
                                    type={goal.type}
                                    timespan={goal.timespan}
                                    val_goal={goal.goal}
                                    datasetProperties={goal.dataset_properties}
                                    gain={goal.gain}
                                    published={goal.published}
                                    shouldExport = {this.shouldExport(goal)}
                                    isLoading={isFetchingChart.includes(goal.id)}
                                />
                            }
                          })()}
                        </Cards>
                        </Col>
                      </div>
                }) : null}

                {user.isAdmin()
                ?
                  <Col md={6} sm={6} xs={24}>
                    <AddChart clickHandler={() => this.addChart()} />
                  </Col>
                : null}
                
              </ContentHolder>

            </ContentHolder>
        </ChartWrapper>
    );
  }
}

const {fetchGoals, addGoal, clearCharts, removeGoal, patchGoal, patchMultipleGoal, initialUpdateGoal, createChart} = chartsActions;

export default connect(
  state => ({
    goals: state.CampaignCharts.goals,
    queueGoalPatch: state.CampaignCharts.queueGoalPatch,
    campaign: state.Campaigns.campaign,
    sources: state.Sources.sources,
    isFetchingChart: state.CampaignCharts.isFetchingChart,
    user: state.Auth.user,
  }),
  { fetchGoals, addGoal, clearCharts, removeGoal, patchGoal, patchMultipleGoal, initialUpdateGoal, createChart }
)(CampaignCharts);
