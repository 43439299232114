import actions from './actions';

const initState = {
  account: '',
  accounts: [],
  adAccount: '',
  adAccounts: [],
  campaign: null,
  campaigns: [],
  insertionOrders: [],
  confirmedPartner: false,
  partner: '',
  partners: [],
  isFetchingAccounts: false,
  isFetchingAdAccounts: false,
  isFetchingCampaigns: false,
  isFetchingInsertionOrders: false,
  isFetchingPartners: false
};

export default function doubleclickReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
  
    case actions.FETCH_DOUBLECLICK_ACCOUNTS:
      return {
        ...initState,
        isFetchingAccounts: true,
      };
  
    case actions.FETCH_DOUBLECLICK_ACCOUNTS_ERROR:
      return {
        ...state,
        isFetchingAccounts: false,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_DOUBLECLICK_ACCOUNTS:
      return {
        ...state,
        isFetchingAccounts: false,
        accounts: action.accounts,
      };
  
    case actions.FETCH_DOUBLECLICK_ADACCOUNTS:
      return {
        ...state,
        account: action.account,
        adAccount: '',
        isFetchingAdAccounts: true,
      };
  
    case actions.FETCH_DOUBLECLICK_ADACCOUNTS_ERROR:
      return {
        ...state,
        isFetchingAdAccounts: false,
        adAccount: '',
        adAccounts: [],
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_DOUBLECLICK_ADACCOUNTS:
      return {
        ...state,
        isFetchingAdAccounts: false,
        adAccounts: action.adAccounts,
      };
    
    case actions.FETCH_DOUBLECLICK_CAMPAIGNS:
      return {
        ...state,
        isFetchingCampaigns: true,
        adAccount: action.adAccount,
        campaign: null
      };
      
    case actions.FETCH_DOUBLECLICK_CAMPAIGNS_ERROR:
      return {
        ...state,
        isFetchingCampaigns: false,
        campaign: null,
        adAccount: '',
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_DOUBLECLICK_CAMPAIGNS:
      return {
        ...state,
        isFetchingCampaigns: false,
        campaigns: action.campaigns,
        campaign: null
      };
  
    case actions.SET_DOUBLECLICK_CAMPAIGN:
      return {
        ...state,
        campaign: action.campaign
      };
  
    case actions.FETCH_DOUBLECLICK_INSERTION_ORDERS:
      return {
        ...state,
        isFetchingInsertionOrders: true,
        campaign: action.campaign
      };
  
    case actions.FETCH_DOUBLECLICK_INSERTION_ORDERS_ERROR:
      return {
        ...state,
        isFetchingInsertionOrders: false,
        campaign: null,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_DOUBLECLICK_INSERTION_ORDERS:
      return {
        ...state,
        isFetchingInsertionOrders: false,
        insertionOrders: action.insertionOrders
      };
  
    case actions.REMOVE_DOUBLECLICK_ACCOUNT:
      return {
        ...state,
        isFetchingAccounts: true,
      };
  
    case actions.REMOVE_DOUBLECLICK_ACCOUNT_ERROR:
      return {
        ...state,
        isFetchingAccounts: false,
        errorMessage: action.errorMessage
      };
  
    case actions.DOUBLECLICK_ACCOUNT_REMOVED:
      return {
        ...state,
        isFetchingAccounts: false,
      };

    case actions.FETCH_DOUBLECLICK_PARTNERS:
      return {
        ...state,
        isFetchingPartners: true
      };

    case actions.FETCH_DOUBLECLICK_PARTNERS_ERROR:
      return {
        ...state,
        isFetchingPartners: false,
        errorMessage: action.errorMessage
      };

    case actions.RECEIVE_DOUBLECLICK_PARTNERS:
      return {
        ...state,
        isFetchingPartners: false,
        partners: action.partners
      };

    case actions.ADD_DOUBLECLICK_PARTNER:
      return {
        ...state,
        confirmedPartner: false,
        partner: ''
      };

    case actions.ADD_DOUBLECLICK_PARTNER_ERROR:
      return {
        ...state,
        confirmedPartner: undefined,
        partner: '',
        errorMessage: action.errorMessage
      };

    case actions.ADD_DOUBLECLICK_PARTNER_CONFIRM:
      return {
        ...state,
        confirmedPartner: true,
        partner: action.partner
      };

    case actions.ADD_DOUBLECLICK_PARTNER_CONFIRM_ERROR:
      return {
        ...state,
        confirmedPartner: false
      };
    
    default:
      return state;
  }
}
