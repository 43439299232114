import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import {push} from 'connected-react-router';
import GoogleBusinessFetch from "../../../helpers/gooleBusinessFetch";
import notifications from "../../../components/feedback/notification";

export function* fetchLocationGroups() {
    yield takeEvery(actions.FETCH_LOCATION_GROUPS, function*() {
        const resp = yield GoogleBusinessFetch.get('location-groups', null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            yield put({
                type: actions.RECEIVE_LOCATION_GROUPS,
                locationGroups: resp.data.data
            });
            return;
        }
        
        notifications['error']({
            message: 'Cannot fetch Location Groups!',
            description: resp.data.message
        });
        
        yield put({
            type: actions.FETCH_LOCATION_GROUPS_ERROR,
            errorMessage: resp.data.message
        });
    });
}

export function* createLocationGroup() {
    yield takeEvery(actions.CREATE_LOCATION_GROUP, function*(payload) {
        const {values} = payload;
        
        const resp = yield GoogleBusinessFetch.post('location-groups', values)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            yield put({
                type: actions.LOCATION_GROUP_CREATED,
                locationGroup: resp.data.data
            });
            return;
        }
        
        notifications['error']({
            message: 'Cannot create Location Group!',
            description: resp.data.message
        });
        
        yield put({
            type: actions.CREATE_LOCATION_GROUP_ERROR,
            errorMessage: resp.data.message
        });
    });
}

export function* updateLocationGroup() {
    yield takeEvery(actions.UPDATE_LOCATION_GROUP, function*(payload) {
        const {values, id} = payload;
        
        const resp = yield GoogleBusinessFetch.PATCH('location-groups/'+id, values)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            yield put({
                type: actions.LOCATION_GROUP_UPDATED,
                updatedGroup: resp.data.data
            });
            return;
        }
        
        notifications['error']({
            message: 'Cannot update Location Group!',
            description: resp.data.message
        });
        
        yield put({
            type: actions.UPDATE_LOCATION_GROUP_ERROR,
            errorMessage: resp.data.message
        });
    });
}

export function* deleteLocationGroup() {
    yield takeEvery(actions.DELETE_LOCATION_GROUP, function*(payload) {
        const {values, id} = payload;
        
        const resp = yield GoogleBusinessFetch.delete('location-groups/'+id, values)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            yield put({
                type: actions.LOCATION_GROUP_DELETED,
                deletedGroupId: resp.data.data
            });
            return;
        }
        
        notifications['error']({
            message: 'Cannot delete Location Group!',
            description: resp.data.message
        });
        
        yield put({
            type: actions.DELETE_LOCATION_GROUP_ERROR,
            errorMessage: resp.data.message
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(fetchLocationGroups),
        fork(createLocationGroup),
        fork(updateLocationGroup),
        fork(deleteLocationGroup),
    ]);
}
