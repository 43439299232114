import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import LeadsFetch from "../../helpers/leadsFetch";

export function* fetchNotes() {
  yield takeEvery(actions.FETCH_NOTES, function*(payload) {
    const {prospectId} = payload;

    const query = '?prospect=' + prospectId;
    
    const apiResponse = yield LeadsFetch.get('notes' + query)
      .then(response => (response))
      .catch(error => ({error}));
  
    if(apiResponse.status === 200)
    {
      const notes = apiResponse.data;
      yield put({
        type: actions.RECEIVE_NOTES,
        notes
      });
    }
  });
}

export function* changeColor() {
  yield takeEvery(actions.CHANGE_COLOR, function*(payload) {
    const {seectedColor, selectedId} = payload;
    const apiResponse = yield LeadsFetch.PATCH('notes/' + selectedId, {color: seectedColor})
      .then(response => (response))
      .catch(error => ({error}));
  });
}

export function* addNote() {
  yield takeEvery(actions.ADD_NOTE, function*(payload) {
  
    const {newNote, oldNotes, prospectId} = payload;
    
    const apiResponse = yield LeadsFetch.post('notes', newNote)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 201)
    {
      newNote.id = apiResponse.data.id;
      newNote.created_time = apiResponse.data.created_time;
      newNote.user_id = apiResponse.data.user_id;
      const notes = [newNote, ...oldNotes];
      yield put({
        type: actions.RECEIVE_NOTE,
        newNote,
        notes
      });
    }

  });
}
export function* editNote() {
  yield takeEvery(actions.EDIT_NOTE, function*() {});
}
export function* saveNote() {
  yield takeEvery(actions.SAVE_NOTE, function*(payload) {
    const {newText, id} = payload;
  
    const apiResponse = yield LeadsFetch.PATCH('notes/' + id, {note: newText})
      .then(response => (response))
      .catch(error => ({error}));
    
  });
}
export function* deleteNote() {
  yield takeEvery(actions.DELETE_NOTE, function*(payload) {
    const {deleteId} = payload;
  
    const apiResponse = yield LeadsFetch.delete('notes/' + deleteId, null)
      .then(response => (response))
      .catch(error => ({error}));
  });
}
export default function* rootSaga() {
  yield all([
    fork(fetchNotes),
    fork(changeColor),
    fork(addNote),
    fork(saveNote),
    fork(editNote),
    fork(deleteNote),
  ]);
}
