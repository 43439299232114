import actions from './actions';

const initState = { statuses: [], isFetchingStatuses: false, clearForm: false};

export default function statusReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.FETCH_STATUSES:
    case actions.CREATE_STATUS:
    case actions.DELETE_STATUS:
      return {
        ...state,
        isFetchingStatuses: true
      };
  
    case actions.RECEIVE_STATUSES:
      return {
        ...state,
        isFetchingStatuses: false,
        statuses: action.statuses
      };
      
    case actions.CREATE_STATUS_ERROR:
    case actions.DELETE_STATUS_ERROR:
      return {
        ...state,
        isFetchingStatuses: false
      };
  
    case actions.STATUS_CREATED:
    case actions.STATUS_DELETED:
      return {
        ...state,
        isFetchingStatuses: false,
        clearForm: !state.clearForm
      };
      
    default:
      return state;
  }
}
