const actions = {
  FETCH_GOALS: 'FETCH_GOALS',
  FETCH_GOALS_ERROR: 'FETCH_GOALS_ERROR',
  RECEIVE_GOALS: 'RECEIVE_GOALS',

  ADD_GOAL: 'ADD_GOAL',

  REMOVE_GOAL: 'REMOVE_GOAL',
  REMOVE_GOAL_ERROR: 'REMOVE_GOAL_ERROR',
  RECEIVE_REMOVE_GOAL: 'RECEIVE_REMOVE_GOAL',

  PATCH_GOAL: 'PATCH_GOAL',
  PATCH_GOAL_ERROR: 'PATCH_GOAL_ERROR',
  RECEIVE_PATCH_GOAL: 'RECEIVE_PATCH_GOAL',

  INITIAL_UPDATE_GOAL: 'INITIAL_UPDATE_GOAL',

  PATCH_MULTIPLE_GOAL: 'PATCH_MULTIPLE_GOAL',
  PATCH_MULTIPLE_GOAL_ERROR: 'PATCH_MULTIPLE_GOAL_ERROR',
  RECEIVE_PATCH_MULTIPLE_GOAL: 'RECEIVE_PATCH_MULTIPLE_GOAL',

  FETCH_GOAL_TEMPLATES: 'FETCH_GOAL_TEMPLATES',
  FETCH_GOAL_TEMPLATES_ERROR: 'FETCH_GOAL_TEMPLATES_ERROR',
  RECEIVE_GOAL_TEMPLATES: 'RECEIVE_GOAL_TEMPLATES',

  ADD_GOAL_TEMPLATE: 'ADD_GOAL_TEMPLATE',
  ADD_GOAL_TEMPLATE_ERROR: 'ADD_GOAL_TEMPLATE_ERROR',
  RECEIVE_ADD_GOAL_TEMPLATE: 'RECEIVE_ADD_GOAL_TEMPLATE',

  REMOVE_GOAL_TEMPLATE: 'REMOVE_GOAL_TEMPLATE',
  REMOVE_GOAL_TEMPLATE_ERROR: 'REMOVE_GOAL_TEMPLATE_ERROR',
  RECEIVE_REMOVE_GOAL_TEMPLATE: 'RECEIVE_REMOVE_GOAL_TEMPLATE',

  SELECT_GOAL_TEMPLATE: 'SELECT_GOAL_TEMPLATE',
  SELECT_GOAL_TEMPLATE_ERROR: 'SELECT_GOAL_TEMPLATE_ERROR',
  RECEIVE_SELECT_GOAL_TEMPLATE: 'RECEIVE_SELECT_GOAL_TEMPLATE',

  FETCH_CHARTS: 'FETCH_CHARTS',
  FETCH_CHARTS_ERROR: 'FETCH_CHARTS_ERROR',
  RECEIVE_CHARTS: 'RECEIVE_CHARTS',

  FETCH_SOURCES_CHARTS: 'FETCH_SOURCES_CHARTS',
  FETCH_SOURCES_CHARTS_ERROR: 'FETCH_SOURCES_CHARTS_ERROR',
  RECEIVE_SOURCES_CHARTS: 'RECEIVE_SOURCES_CHARTS',

  ADD_CREATED_CHART: 'ADD_CREATED_CHART',
  ADD_CREATED_CHART_ERROR: 'ADD_CREATED_CHART_ERROR',
  RECEIVE_CREATED_CHART: 'RECEIVE_CREATED_CHART',

  PATCH_GOAL_LOCAL: 'PATCH_GOAL_LOCAL',
  PATCH_CHARTS_LOCAL: 'PATCH_CHARTS_LOCAL',

  CLEAR_STATE: 'CLEAR_STATE',

  fetchGoals: (campaign) => ({
    type: actions.FETCH_GOALS,
    campaign
  }),

  addGoal: (properties) => ({
    type: actions.ADD_GOAL,
    properties
  }),

  removeGoal: (campaign, goal) => ({
    type: actions.REMOVE_GOAL,
    campaign,
    goal
  }),

  patchGoal: (campaign, goal) => ({
    type: actions.PATCH_GOAL,
    campaign,
    goal
  }),

  initialUpdateGoal: (goal) => ({
    type: actions.INITIAL_UPDATE_GOAL,
    goal
  }),

  patchMultipleGoal: () => ({
    type: actions.PATCH_MULTIPLE_GOAL
  }),

  fetchGoalTemplates: () => ({
    type: actions.FETCH_GOAL_TEMPLATES
  }),

  addGoalTemplate: (goalTemplate) => ({
    type: actions.ADD_GOAL_TEMPLATE,
    goalTemplate
  }),

  removeGoalTemplate: (goalTemplate) => ({
    type: actions.REMOVE_GOAL_TEMPLATE,
    goalTemplate
  }),

  selectGoalTemplate: (goalTemplate) => ({
    type: actions.SELECT_GOAL_TEMPLATE,
    goalTemplate
  }),

  fetchChart: (goalId, fromDate, toDate) => ({
    type: actions.FETCH_CHARTS,
    goalId,
    fromDate,
    toDate
  }),

  fetchChartSources: (goalId, fromDate, toDate) => ({
    type: actions.FETCH_SOURCES_CHARTS,
    goalId,
    fromDate,
    toDate
  }),

  createChart: (campaign, goal) => ({
    type: actions.ADD_CREATED_CHART,
    campaign,
    goal
  }),

  patchGoalLocal: (goal) => ({
    type: actions.PATCH_GOAL_LOCAL,
    goal
  }),

  patchChartsLocal: (goal, chart) => ({
    type: actions.PATCH_CHARTS_LOCAL,
    goal,
    chart
  }),

  clearCharts: () => ({
    type: actions.CLEAR_STATE
  })

};
export default actions;
