import React, { Component } from 'react';
import {connect} from "react-redux";
import { Chart } from 'frappe-charts/dist/frappe-charts.esm.js'
import 'frappe-charts/dist/frappe-charts.min.css'
import chartsActions from "../../../../redux/campaignCharts/actions";
import Loader from "../../../../components/uielements/loader";
import './../../../App/global.css';
import { PercentageChart } from './charts.style'

const basicData = {
  labels: [
    '12am-3am',
    '3am-6am',
    '6am-9am',
    '9am-12pm',
    '12pm-3pm',
    '3pm-6pm',
    '6pm-9pm',
    '9pm-12am'
  ],
  
  datasets: [
    {
      title: 'Some Data',
      color: 'light-blue',
      values: [25, 40, 30, 35, 8, 52, 17, -4]
    },
    {
      title: 'Another Set',
      color: 'violet',
      values: [25, 50, -10, 15, 18, 32, 27, 14]
    },
    {
      title: 'Yet Another',
      color: 'blue',
      values: [15, 20, -3, -15, 58, 12, -17, 37]
    }
  ]
};

const basicConfig = {
  height: 150,
  format_tooltip_x: d => (d + '').toUpperCase(),
  format_tooltip_y: d => d + ' pts',
  barOptions: {
    height: 30,
    depth: 1
  }
};

const colors = [
  '#3498DB', '#16A085', '#2ECC71', '#F1C40F', '#F39C12',
  '#D35400', '#BDC3C7', '#7F8C8D', '#34495E', '#E74C3C',
  '#8E44AD', '#C0392B', '#9B59B6', '#85C1E9', '#73C6B6',
  '#F8C471', '#EDBB99', '#E5E7E9', '#85929E', '#7FB3D5',
  '#F9E79F', '#4A235A'
];

const defaultData = {
  labels: [],
  datasets: [
    {
      title: 'Some Data',
      values: []
    },
  ],
};

class PercentageBarChart extends Component
{

  constructor(props) {
    super(props);

    this.state = {
      data: {...defaultData}
    };
  }

  barChart = {
    header: 'Percentage Bar Chart',
    title: '',
    parent: '#' + this.props.chartIndex,
    parentId: this.props.chartIndex,
    type: 'percentage',
    data: basicData,
    show_dots: 0,
    colors: colors,
    tooltipOptions: {
      formatTooltipX: d => (d + '').toUpperCase(),
      formatTooltipY: d => {},
    },
    heatline: 1,
    axisOptions: {
      yAxisMode: 'span',
      xAxisMode: 'tick',
      xIsSeries: 1,
    },
    region_fill: 1,
    ...basicConfig
  };

  componentDidMount()
  {
    this.getFetchChart();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot)
  {
    const {goalId, goalSourceFieldsMapping, goalDatasetNaming, type, timespan, val_goal, gain, published, charts, isFetchingChart, fromDate, toDate, shouldExport} = this.props;

    if(prevProps.fromDate != fromDate || prevProps.toDate != toDate){
      this.getFetchChart()
    }

    if( prevProps.goalId !== goalId ||
        prevProps.goalSourceFieldsMapping !== goalSourceFieldsMapping ||
        prevProps.goalDatasetNaming !== goalDatasetNaming ||
        prevProps.type !== type ||
        prevProps.timespan !== timespan ||
        prevProps.val_goal !== val_goal ||
        prevProps.gain !== gain ||
        prevProps.published !== published){
      this.getFetchChart()
    }

    if(goalId)
      if(charts !== prevProps.charts  && !isFetchingChart.includes(goalId))
      {
        this.redrawChart();
      }

    if(shouldExport === true && this.chartRef){
      this.chartRef.export();
    }
  }

  getFetchChart = () => {
    const {goalId, timespan, fromDate, toDate, fetchChart, fetchChartSources} = this.props;
    if(goalId){
      if(timespan === 'sources')
        fetchChartSources(goalId,fromDate,toDate);
      else
        fetchChart(goalId,fromDate,toDate);
    }
  };

  redrawChart = () => {
    const {data} = this.state;
    const {goalId, charts} = this.props;

    if(charts.hasOwnProperty(goalId)){
      let newData = this.parseFieldsAndDataset(charts[goalId]);

      data.labels = newData.labels;
      data.datasets = newData.datasets;
    }

    this.setState({data}, () => {
      this.chartRef = new Chart(this.barChart.parent, {...this.barChart, data});
    })

  };

  parseFieldsAndDataset = (rawData) => {
    const {goalDatasetNaming} = this.props;

    if(this.isDataEmpty(rawData)) return {...defaultData};

    let floatValues = [];
    let floatArray = new Float64Array(10);
    let datasetCollection = {labels: [], values: floatArray};
    for(let dataset_index in rawData){
      datasetCollection.labels.push(goalDatasetNaming[dataset_index]);

      let sum = 0;
      for(let source_index in rawData[dataset_index]['sources']){
        sum += rawData[dataset_index]['sources'][source_index]
      }
      floatValues.push(sum.toFixed(2));
    }
    floatArray.set(floatValues);

    let newState = {
      ...this.state,
      data: {
        labels: datasetCollection.labels,
        datasets: [{
          name: `Percentage bar`,
          values: datasetCollection.values,
          chartType: "percentage"
        }]
      }
    };

    this.setState(newState);

    return newState.data;
  };

  isDataEmpty = (rawData) => {
    if(rawData.length < 1){
      this.setState({data: {...defaultData}});
      return true
    }
    return false
  };
  
  render() {
    const {isLoading} = this.props;

    return (
        <>
          <Loader visible={isLoading} text={'Chart is loading...'} />
          <PercentageChart style={{visibility: (isLoading ? 'hidden' : 'visible') }} id={this.barChart.parentId}/>
        </>
    )
  }
}

const {fetchChart, fetchChartSources} = chartsActions;

export default connect(
    state => ({
      isFetchingChart: state.CampaignCharts.isFetchingChart,
      charts: state.CampaignCharts.charts,
      fromDate: state.Campaigns.fromDate,
      toDate: state.Campaigns.toDate,
    }),
    {fetchChart, fetchChartSources}
)(PercentageBarChart);