const actions = {
  
  FETCH_FACEBOOK_ACCOUNTS: 'FETCH_FACEBOOK_ACCOUNTS',
  FETCH_FACEBOOK_ACCOUNTS_ERROR: 'FETCH_FACEBOOK_ACCOUNTS_ERROR',
  RECEIVE_FACEBOOK_ACCOUNTS: 'RECEIVE_FACEBOOK_ACCOUNTS',
  
  FETCH_FACEBOOK_ADACCOUNTS: 'FETCH_FACEBOOK_ADACCOUNTS',
  FETCH_FACEBOOK_ADACCOUNTS_ERROR: 'FETCH_FACEBOOK_ADACCOUNTS_ERROR',
  RECEIVE_FACEBOOK_ADACCOUNTS: 'RECEIVE_FACEBOOK_ADACCOUNTS',
  
  FETCH_FACEBOOK_CAMPAIGNS: 'FETCH_FACEBOOK_CAMPAIGNS',
  FETCH_FACEBOOK_CAMPAIGNS_ERROR: 'FETCH_FACEBOOK_CAMPAIGNS_ERROR',
  RECEIVE_FACEBOOK_CAMPAIGNS: 'RECEIVE_FACEBOOK_CAMPAIGNS',

  FETCH_FACEBOOK_NEW_CAMPAIGNS: 'FETCH_FACEBOOK_NEW_CAMPAIGNS',
  FETCH_FACEBOOK_NEW_CAMPAIGNS_ERROR: 'FETCH_FACEBOOK_NEW_CAMPAIGNS_ERROR',

  CREATE_FACEBOOK_NEW_CAMPAIGN: 'CREATE_FACEBOOK_NEW_CAMPAIGN',
  PUSH_FACEBOOK_NEW_CAMPAIGN: 'PUSH_FACEBOOK_NEW_CAMPAIGN',

  SET_FACEBOOK_CAMPAIGN: 'SET_FACEBOOK_CAMPAIGN',
  
  FETCH_FACEBOOK_ADSETS: 'FETCH_FACEBOOK_ADSETS',
  FETCH_FACEBOOK_ADSETS_ERROR: 'FETCH_FACEBOOK_ADSETS_ERROR',
  RECEIVE_FACEBOOK_ADSETS: 'RECEIVE_FACEBOOK_ADSETS',
  
  REMOVE_FACEBOOK_ACCOUNT: 'REMOVE_FACEBOOK_ACCOUNT',
  REMOVE_FACEBOOK_ACCOUNT_ERROR: 'REMOVE_FACEBOOK_ACCOUNT_ERROR',
  FACEBOOK_ACCOUNT_REMOVED: 'FACEBOOK_ACCOUNT_REMOVED',

  FETCH_FACEBOOK_CREATIVE: 'FETCH_FACEBOOK_CREATIVE',
  FETCH_FACEBOOK_CREATIVE_ERROR: 'FETCH_FACEBOOK_CREATIVE_ERROR',
  
  fetchAccounts: () => ({
    type: actions.FETCH_FACEBOOK_ACCOUNTS,
  }),
  
  fetchAdAccounts: (account) => ({
    type: actions.FETCH_FACEBOOK_ADACCOUNTS,
    account
  }),
  
  fetchCampaigns: (adAccount) => ({
    type: actions.FETCH_FACEBOOK_CAMPAIGNS,
    adAccount
  }),

  fetchNewCampaigns: () => ({
    type: actions.FETCH_FACEBOOK_NEW_CAMPAIGNS
  }),

  createFacebookNewCampaign: (newCampaignConfiguration) => ({
    type: actions.CREATE_FACEBOOK_NEW_CAMPAIGN,
    newCampaignConfiguration
  }),

  pushFacebookNewCampaign: (newCampaignConfiguration, campaign) => ({
    type: actions.PUSH_FACEBOOK_NEW_CAMPAIGN,
    newCampaignConfiguration,
    campaign
  }),

  setCampaign: (campaign) => ({
    type: actions.SET_FACEBOOK_CAMPAIGN,
    campaign
  }),
  
  fetchAdSets: (campaign) => ({
    type: actions.FETCH_FACEBOOK_ADSETS,
    campaign
  }),
  
  removeAccount: (account) => ({
    type: actions.REMOVE_FACEBOOK_ACCOUNT,
    account
  }),

  fetchCreative: (ad) => ({
    type: actions.FETCH_FACEBOOK_CREATIVE,
    ad
  }),
  
  // fetchCampaignData: (prospectId) => ({
  //   type: actions.FETCH_PROSPECT_DATA,
  //   prospectId: prospectId
  // }),
  //
  // setCampaign: (prospect, status) => ({
  //   type: actions.SET_CAMPAIGN,
  //   status,
  //   prospect
  // }),
  
};
export default actions;
