const actions = {
  
  FETCH_FACEBOOK_PAGES: 'FETCH_FACEBOOK_PAGES',
  FETCH_FACEBOOK_PAGES_ERROR: 'FETCH_FACEBOOK_PAGES_ERROR',
  RECEIVE_FACEBOOK_PAGES: 'RECEIVE_FACEBOOK_PAGES',
  
  FETCH_FACEBOOK_FORMS: 'FETCH_FACEBOOK_FORMS',
  FETCH_FACEBOOK_FORMS_ERROR: 'FETCH_FACEBOOK_FORMS_ERROR',
  RECEIVE_FACEBOOK_FORMS: 'RECEIVE_FACEBOOK_FORMS',
  
  FETCH_FACEBOOK_TEST_LEADS: 'FETCH_FACEBOOK_TEST_LEADS',
  FETCH_FACEBOOK_TEST_LEADS_ERROR: 'FETCH_FACEBOOK_TEST_LEADS_ERROR',
  RECEIVE_FACEBOOK_TEST_LEADS: 'RECEIVE_FACEBOOK_TEST_LEADS',
  
  SET_FACEBOOK_TEST_LEAD: 'SET_FACEBOOK_TEST_LEAD',
  CLEAR_FACEBOOK_LEAD_FORM: 'CLEAR_FACEBOOK_LEAD_FORM',
  
  fetchPages: (accountId) => ({
    type: actions.FETCH_FACEBOOK_PAGES,
    accountId
  }),
  
  fetchForms: (pageId, pageToken) => ({
    type: actions.FETCH_FACEBOOK_FORMS,
    pageId,
    pageToken
  }),
  
  fetchTestLeads: (formId, qualifiers, disclaimers) => ({
    type: actions.FETCH_FACEBOOK_TEST_LEADS,
    formId,
    qualifiers,
    disclaimers
  }),
  
  setTestLead: (lead) => ({
    type: actions.SET_FACEBOOK_TEST_LEAD,
    lead
  }),
  
  clearForm: () => ({
    type: actions.CLEAR_FACEBOOK_LEAD_FORM
  }),
  
};
export default actions;
