const actions = {
  FETCH_GOOGLE_ACCOUNTS: 'FETCH_GOOGLE_ACCOUNTS',
  FETCH_GOOGLE_ACCOUNTS_ERROR: 'FETCH_GOOGLE_ACCOUNTS_ERROR',
  RECEIVE_GOOGLE_ACCOUNTS: 'RECEIVE_GOOGLE_ACCOUNTS',
  
  FETCH_GOOGLE_ADACCOUNTS: 'FETCH_GOOGLE_ADACCOUNTS',
  FETCH_GOOGLE_ADACCOUNTS_ERROR: 'FETCH_GOOGLE_ADACCOUNTS_ERROR',
  RECEIVE_GOOGLE_ADACCOUNTS: 'RECEIVE_GOOGLE_ADACCOUNTS',
  
  FETCH_GOOGLE_CAMPAIGNS: 'FETCH_GOOGLE_CAMPAIGNS',
  FETCH_GOOGLE_CAMPAIGNS_ERROR: 'FETCH_GOOGLE_CAMPAIGNS_ERROR',
  RECEIVE_GOOGLE_CAMPAIGNS: 'RECEIVE_GOOGLE_CAMPAIGNS',
  
  SET_GOOGLE_CAMPAIGN: 'SET_GOOGLE_CAMPAIGN',
  
  FETCH_GOOGLE_ADGROUPS: 'FETCH_GOOGLE_ADGROUPS',
  FETCH_GOOGLE_ADGROUPS_ERROR: 'FETCH_GOOGLE_ADGROUPS_ERROR',
  RECEIVE_GOOGLE_ADGROUPS: 'RECEIVE_GOOGLE_ADGROUPS',
  
  fetchAccounts: () => ({
    type: actions.FETCH_GOOGLE_ACCOUNTS,
  }),
  
  fetchAdAccounts: (account) => ({
    type: actions.FETCH_GOOGLE_ADACCOUNTS,
    account
  }),
  
  fetchCampaigns: (adAccount) => ({
    type: actions.FETCH_GOOGLE_CAMPAIGNS,
    adAccount
  }),
  
  setCampaign: (campaign) => ({
    type: actions.SET_GOOGLE_CAMPAIGN,
    campaign
  }),
  
  fetchAdGroups: (campaign) => ({
    type: actions.FETCH_GOOGLE_ADGROUPS,
    campaign
  }),
  
  
  // fetchCampaignData: (prospectId) => ({
  //   type: actions.FETCH_PROSPECT_DATA,
  //   prospectId: prospectId
  // }),
  //
  // setCampaign: (prospect, status) => ({
  //   type: actions.SET_CAMPAIGN,
  //   status,
  //   prospect
  // }),
  
};
export default actions;
