export const ACTIVITY_TYPE = {
  'EXTERNAL_EMAIL_TO_PROSPECT': 1,
  'SINGLE_SMS_TO_PROSPECT': 2
};
const actions = {

  FETCH_ACTIVITY: 'FETCH_ACTIVITY',
  FETCH_ACTIVITY_ERROR: 'FETCH_ACTIVITY_ERROR',
  ACTIVITY_RECEIVED: 'ACTIVITY_RECEIVED',

  CREATE_ACTIVITY: 'CREATE_ACTIVITY',
  CREATE_ACTIVITY_ERROR: 'CREATE_ACTIVITY_ERROR',
  ACTIVITY_CREATED: 'ACTIVITY_CREATED',

  fetchActivity: (activityType = null, filter = {}) => ({
    type: actions.FETCH_ACTIVITY,
    activityType,
    filter
  }),

  createActivity: (activity) => ({
    type: actions.CREATE_ACTIVITY,
    activity
  }),

};
export default actions;
