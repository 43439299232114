const actions = {
  FETCH_CLIENTS: 'FETCH_CLIENTS',
  FETCH_CLIENTS_ERROR: 'FETCH_CLIENTS_ERROR',
  RECEIVE_CLIENTS: 'RECEIVE_CLIENTS',
  
  fetchClients: () => ({
    type: actions.FETCH_CLIENTS
  }),
  
};
export default actions;
