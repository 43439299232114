import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import DeliveryFetch from "../../../helpers/deliveryFetch";
import notifications from "../../../components/feedback/notification";

export function* fetchTemplates() {
  yield takeEvery(actions.FETCH_MAIL_TEMPLATES, function*() {
    
    const resp = yield DeliveryFetch.get('templates', null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.RECEIVE_MAIL_TEMPLATES,
        templates: resp.data.data
      });
      // return;
    }
    
    // yield put({
    //   type: actions.CREATE_MAIL_TEMPLATE_ERROR,
    //   errorMessage: resp.error
    // });
  });
}

export function* fetchTemplate() {
  yield takeEvery(actions.FETCH_MAIL_TEMPLATE, function*(payload) {
    const {templateId} = payload;
    
    const resp = yield DeliveryFetch.get('templates/' + templateId, null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.RECEIVE_MAIL_TEMPLATE,
        template: resp.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_MAIL_TEMPLATE_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* createTemplate() {
  yield takeEvery(actions.CREATE_MAIL_TEMPLATE, function*(payload) {
    const {template} = payload;
    
    const resp = yield DeliveryFetch.post('templates', template)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      notifications['success']({
        message: 'OK!',
        description: 'Template created.'
      });
      yield put({
        type: actions.MAIL_TEMPLATE_CREATED,
        template: resp.data.data
      });
      return;
    }
  
    notifications['error']({
      message: 'Server error!',
      description: resp.message
    });
    yield put({
      type: actions.CREATE_MAIL_TEMPLATE_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* updateTemplate() {
  yield takeEvery(actions.UPDATE_MAIL_TEMPLATE, function*(payload) {
    const {template, templateId} = payload;
    
    const resp = yield DeliveryFetch.PATCH('templates/' + templateId, template)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      notifications['success']({
        message: 'OK!',
        description: 'Template saved.'
      });
      yield put({
        type: actions.MAIL_TEMPLATE_UPDATED,
        template: resp.data.data
      });
      return;
    }
    
    notifications['error']({
      message: 'Server error!',
      description: resp.message
    });
    yield put({
      type: actions.UPDATE_MAIL_TEMPLATE_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* sendTestEmailTemplate() {
  yield takeEvery(actions.SEND_TEST_EMAIL_TEMPLATE, function*(payload) {
    const {testEmail, templateId} = payload;
    
    const resp = yield DeliveryFetch.post(`templates/${templateId}/send-test`, {testEmail})
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      notifications['success']({
        message: 'OK!',
        description: 'We sent test email to provided address.'
      });
      yield put({
        type: actions.TEST_EMAIL_TEMPLATE_SENT,
        template: resp.data.data
      });
      return;
    }
  
    notifications['error']({
      message: 'Server error!',
      description: resp.message
    });
    yield put({
      type: actions.SEND_TEST_EMAIL_TEMPLATE_ERROR,
      errorMessage: resp.data.message
    });
  });
}

export function* deleteTemplate() {
  yield takeEvery(actions.DELETE_MAIL_TEMPLATE, function*(payload) {
    const {templateId} = payload;
    
    const resp = yield DeliveryFetch.delete('templates/' + templateId, null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.FETCH_MAIL_TEMPLATES,
      });
      return;
    }
    
    yield put({
      type: actions.DELETE_MAIL_TEMPLATE_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* cloneTemplate() {
  yield takeEvery(actions.CLONE_MAIL_TEMPLATE, function*(payload) {
    const {templateId} = payload;
    
    const resp = yield DeliveryFetch.post(`templates/${templateId}/clone`, null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.FETCH_MAIL_TEMPLATES,
      });
      return;
    }
    
    yield put({
      type: actions.CLONE_MAIL_TEMPLATE_ERROR,
      errorMessage: resp.error
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(createTemplate),
    fork(fetchTemplates),
    fork(fetchTemplate),
    fork(sendTestEmailTemplate),
    fork(updateTemplate),
    fork(deleteTemplate),
    fork(cloneTemplate),
  ]);
}
