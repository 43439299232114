import actions from './actions';

const initState = {
  users: [],
  isFetching: false,
  isSearching: false,
  foundUser: null,
  isInviting: false,
  invited: false,
  removing: null,
};

export default function userReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.FETCH_USERS:
      return {
        ...state,
        isFetching: true
      };
    
    case actions.RECEIVE_USERS:
      return {
        ...state,
        isFetching: false,
        users: action.users
      };
      
    case actions.SEARCH_USERS:
      return {
        ...state,
        isSearching: true,
        foundUser: null
      };
  
    case actions.RECEIVE_USER_RESULTS:
      return {
        ...state,
        isSearching: false,
        foundUser: action.foundUser
      };
  
    case actions.INVITE_USER:
      return {
        ...state,
        isInviting: true,
        invited: false,
      };
  
    case actions.INVITE_USER_ERROR:
      return {
        ...state,
        isInviting: false,
        invited: false,
      };
      
    case actions.USER_INVITED:
      return {
        ...state,
        isInviting: false,
        invited: true,
      };
  
    case actions.UNINVITE_USER:
      return {
        ...state,
        removing: action.id
      };
  
    case actions.UNINVITE_USER_ERROR:
      return {
        ...state,
        removing: null
      };
  
    case actions.USER_UNINVITED:
      return {
        ...state,
        removing: null
      };
  
    case actions.PROMOTE_USER:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.PROMOTE_USER_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.USER_PROMOTED:
      return {
        ...state,
        isFetching: false
      };
      
    default:
      return state;
  }
}
