export const advertiserRules = {
  
  name: {
    rules: [
      {
        type: 'string',
        min: 3,
        max: 200,
        message: 'Invalid advertiser name',
      },
      {
        required: true,
        message: 'Enter advertiser name!',
      },
    ],
  }
};
