const actions = {
  
  FETCH_ADFORM_ACCOUNTS: 'FETCH_ADFORM_ACCOUNTS',
  FETCH_ADFORM_ACCOUNTS_ERROR: 'FETCH_ADFORM_ACCOUNTS_ERROR',
  RECEIVE_ADFORM_ACCOUNTS: 'RECEIVE_ADFORM_ACCOUNTS',
  
  FETCH_ADFORM_ADACCOUNTS: 'FETCH_ADFORM_ADACCOUNTS',
  FETCH_ADFORM_ADACCOUNTS_ERROR: 'FETCH_ADFORM_ADACCOUNTS_ERROR',
  RECEIVE_ADFORM_ADACCOUNTS: 'RECEIVE_ADFORM_ADACCOUNTS',
  
  FETCH_ADFORM_CAMPAIGNS: 'FETCH_ADFORM_CAMPAIGNS',
  FETCH_ADFORM_CAMPAIGNS_ERROR: 'FETCH_ADFORM_CAMPAIGNS_ERROR',
  RECEIVE_ADFORM_CAMPAIGNS: 'RECEIVE_ADFORM_CAMPAIGNS',
  
  SET_ADFORM_CAMPAIGN: 'SET_ADFORM_CAMPAIGN',
  
  FETCH_ADFORM_ADSETS: 'FETCH_ADFORM_ADSETS',
  FETCH_ADFORM_ADSETS_ERROR: 'FETCH_ADFORM_ADSETS_ERROR',
  RECEIVE_ADFORM_ADSETS: 'RECEIVE_ADFORM_ADSETS',
  
  REMOVE_ADFORM_ACCOUNT: 'REMOVE_ADFORM_ACCOUNT',
  REMOVE_ADFORM_ACCOUNT_ERROR: 'REMOVE_ADFORM_ACCOUNT_ERROR',
  ADFORM_ACCOUNT_REMOVED: 'ADFORM_ACCOUNT_REMOVED',
  
  fetchAccounts: () => ({
    type: actions.FETCH_ADFORM_ACCOUNTS,
  }),
  
  fetchAdAccounts: (account) => ({
    type: actions.FETCH_ADFORM_ADACCOUNTS,
    account
  }),
  
  fetchCampaigns: (account) => ({
    type: actions.FETCH_ADFORM_CAMPAIGNS,
    account
  }),
  
  setCampaign: (campaign) => ({
    type: actions.SET_ADFORM_CAMPAIGN,
    campaign
  }),
  
  fetchAdSets: (campaign) => ({
    type: actions.FETCH_ADFORM_ADSETS,
    campaign
  }),
  
  removeAccount: (account) => ({
    type: actions.REMOVE_ADFORM_ACCOUNT,
    account
  }),
  
  // fetchCampaignData: (prospectId) => ({
  //   type: actions.FETCH_PROSPECT_DATA,
  //   prospectId: prospectId
  // }),
  //
  // setCampaign: (prospect, status) => ({
  //   type: actions.SET_CAMPAIGN,
  //   status,
  //   prospect
  // }),
  
};
export default actions;
