import actions from './actions';

const initState = { errorResponse: null};

export default function errorReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.CLEAR_ERROR:
      return {
        ...state,
        errorResponse: null
      };
    
    case actions.PUSH_ERROR:
      return {
        ...state,
        errorResponse: action.response
      };
    
    default:
      return state;
  }
}
