import actions from './actions';

const initState = {
  account: '',
  accounts: [],
  adAccount: '',
  adAccounts: [],
  campaign: null,
  campaigns: [],
  adSets: [],
  isFetchingAccounts: false,
  isFetchingAdAccounts: false,
  isFetchingCampaigns: false,
  isFetchingAdsets: false,
};

export default function facebookReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
  
    case actions.FETCH_FACEBOOK_ACCOUNTS:
      return {
        ...initState,
        isFetchingAccounts: true,
      };
  
    case actions.FETCH_FACEBOOK_ACCOUNTS_ERROR:
      return {
        ...state,
        isFetchingAccounts: false,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_FACEBOOK_ACCOUNTS:
      return {
        ...state,
        isFetchingAccounts: false,
        accounts: action.accounts,
      };
  
    case actions.FETCH_FACEBOOK_ADACCOUNTS:
      return {
        ...state,
        account: action.account,
        adAccount: '',
        isFetchingAdAccounts: true,
      };
  
    case actions.FETCH_FACEBOOK_ADACCOUNTS_ERROR:
      return {
        ...state,
        isFetchingAdAccounts: false,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_FACEBOOK_ADACCOUNTS:
      return {
        ...state,
        isFetchingAdAccounts: false,
        adAccounts: action.adAccounts,
      };
    
    case actions.FETCH_FACEBOOK_CAMPAIGNS:
      return {
        ...state,
        isFetchingCampaigns: true,
        adAccount: action.adAccount,
        campaign: null
      };

    case actions.FETCH_FACEBOOK_NEW_CAMPAIGNS:
      return {
        ...state,
        isFetchingCampaigns: true
      };
      
    case actions.FETCH_FACEBOOK_CAMPAIGNS_ERROR:
      return {
        ...state,
        isFetchingCampaigns: false,
        campaign: null,
        adAccount: '',
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_FACEBOOK_CAMPAIGNS:
      return {
        ...state,
        isFetchingCampaigns: false,
        campaigns: action.campaigns,
        campaign: null
      };
  
    case actions.SET_FACEBOOK_CAMPAIGN:
      return {
        ...state,
        campaign: action.campaign
      };
  
    case actions.FETCH_FACEBOOK_ADSETS:
      return {
        ...state,
        isFetchingAdsets: true,
        campaign: action.campaign
      };
  
    case actions.FETCH_FACEBOOK_ADSETS_ERROR:
      return {
        ...state,
        isFetchingAdsets: false,
        campaign: null,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_FACEBOOK_ADSETS:
      return {
        ...state,
        isFetchingAdsets: false,
        adSets: action.adSets
      };
  
    case actions.REMOVE_FACEBOOK_ACCOUNT:
      return {
        ...state,
        isFetchingAccounts: true,
      };
  
    case actions.REMOVE_FACEBOOK_ACCOUNT_ERROR:
      return {
        ...state,
        isFetchingAccounts: false,
        errorMessage: action.errorMessage
      };
  
    case actions.FACEBOOK_ACCOUNT_REMOVED:
      return {
        ...state,
        isFetchingAccounts: false,
      };
      
    
    default:
      return state;
  }
}
