import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import LeadsFetch from "../../helpers/leadsFetch";

export function* fetchAds() {
  yield takeEvery(actions.FETCH_ADS, function*(payload) {
    const {prospect} = payload;

    const apiResponse = yield LeadsFetch.get(`lead-ads/${prospect.id}`)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_ADS,
        ads: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_ADS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* fetchAdFacebook() {
  yield takeEvery(actions.FETCH_AD_FACEBOOK, function*(payload) {
    const {ad} = payload;

    const apiResponse = yield LeadsFetch.get(`ad-facebook/${ad.lead.id}/${ad.ad.ad_data.ad_id}`)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_AD_FACEBOOK,
        ad: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_AD_FACEBOOK_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchAds),
    fork(fetchAdFacebook),
  ]);
}
