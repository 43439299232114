import actions from './actions';

const notes = [];


const colors = ['#42a5f5', '#7ED321', '#de1b1b', '#511E78', '#ff9009'];
const initState = {
    notes,
    colors,
    fetchingNotes: true,
    postingNote: false,
    seectedColor: 0
};

export default function oppoReducer(state = initState, action) {
    switch (action.type) {
        
        case 'SET_ADVERTISER':
            return {
                ...initState
            };
        
        case actions.CHANGE_OPPO:
            return {
                ...state,
                selectedId: action.selectedId
            };
        case actions.ADD_OPPO:
            return {
                ...state,
                newNote: action.newNote,
                postingNote: true,
            };
        case actions.RECEIVE_OPPO:
            return {
                ...state,
                notes: action.notes,
                postingNote: false,
                selectedId: action.newNote.id
            };
        case actions.RECEIVE_OPPOS:
            return {
                ...state,
                notes: action.notes,
                selectedId: action.notes.length > 0 ? action.notes[0].id : state.selectedId,
                fetchingNotes: false
            };
        case actions.EDIT_OPPO:
            return {
                ...state,
                notes: action.notes
            };
        case actions.DELETE_OPPO:
            return {
                ...state,
                notes: action.notes,
                selectedId: action.selectedId
            };
        default:
            return state;
    }
}
