import actions from './actions';

const initState = {
  reports: [],
  deletingReport: null,
  modal: {additional_mapping:{}},
  isFetching: false,
  isCreating: false,
  isModalOpen: false,
  isModalFetching: false,
};


export default function reportReducers(state = initState, action) {
  switch (action.type) {
    
    // case actions.FETCH_REPORTS:
    //   return {
    //     ...state,
    //     isFetching: true,
    //     reports: initState.reports
    //   };
    //
    // case actions.FETCH_REPORTS_ERROR:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     errorMessage: action.errorMessage
    //   };
    //
    // case actions.RECEIVE_REPORTS:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     reports: action.reports
    //   };
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
  
    case actions.CREATE_REPORT:
      return {
        ...state,
        isCreating: true,
      };
  
    case actions.CREATE_REPORT_ERROR:
      return {
        ...state,
        isCreating: false,
        errorMessage: action.errorMessage
      };
  
    case actions.REPORT_CREATED:
      return {
        ...state,
        isCreating: false,
      };
  
    case actions.DELETE_REPORT:
      return {
        ...state,
        deletingReport: action.reportId,
      };
  
    case actions.DELETE_REPORT_ERROR:
      return {
        ...state,
        deletingReport: null,
        errorMessage: action.errorMessage
      };
  
    case actions.REPORT_DELETED:
      return {
        ...state,
        deletingReport: null
      };

    case actions.SET_MODAL:
      return {
        ...state,
        modal: action.modal
      };

    case actions.SET_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: true
      };

    case actions.SET_MODAL_CLOSE:
      return {
        ...state,
        isModalOpen: false
      };

    case actions.SET_MODAL_FETCHING:
      return {
        ...state,
        isModalFetching: true
      };

    case actions.UNSET_MODAL_FETCHING:
      return {
        ...state,
        isModalFetching: false
      };
      
    default:
      return state;
  }
}
