import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import { push } from 'connected-react-router';
import ReportsFetch from "../../helpers/reportFetch";
import LeadsFetch from "../../helpers/leadsFetch";
import SuperFetch from "../../helpers/superFetch";
import AdvertiserApi from "../../helpers/advertiserApi";


export function* fetchAdvertisers() {
  yield takeEvery(actions.FETCH_ADVERTISERS, function*() {

    const apiResponse = yield SuperFetch.get('api/advertisers', null)
      .then(response => (response))
      .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_ADVERTISERS,
        advertisers: apiResponse.data.data
      });
      return;
    }

    yield put({
      type: actions.FETCH_ADVERTISERS_ERROR
    });
    yield put({
      type: 'PUSH_ERROR',
      response: apiResponse,
    });
  });
}

export function* refetchAdvertisers() {
  yield takeEvery('ADVERTISER_SET', function*() {

    const apiResponse = yield SuperFetch.get('api/advertisers', null)
      .then(response => (response))
      .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_ADVERTISERS,
        advertisers: apiResponse.data.data
      });
      return;
    }

    yield put({
      type: actions.FETCH_ADVERTISERS_ERROR
    });
    yield put({
      type: 'PUSH_ERROR',
      response: apiResponse,
    });
  });
}

export function* changeAdvertiser() {
  yield takeEvery(actions.CHANGE_ADVERTISER, function*(payload) {
    const {advertiser} = payload;
  
    yield localStorage.setItem('advertiser_id', advertiser);
    
    yield put({
      type: 'REAUTHORIZE_REQUEST'
    });
    yield put(push('/dashboard'));
  });
}

export function* createAdvertiser() {
  yield takeEvery(actions.CREATE_ADVERTISER, function*(payload) {
    const {advertiser} = payload;

    const apiResponse = yield SuperFetch.post('api/advertisers', {
      name: advertiser.name
    })
      .then(response => (response))
      .catch(error => ({error}))

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.ADVERTISER_CREATED,
        createdAdvertiser: apiResponse.data.data
      });

      yield put({
        type: actions.FETCH_ADVERTISERS
      });
      return;
    }
  
    yield put({
      type: actions.CREATE_ADVERTISER_ERROR
    });
    
    yield put({
      type: 'PUSH_ERROR',
      response: apiResponse,
    });
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchAdvertisers),
    fork(changeAdvertiser),
    fork(createAdvertiser),
    fork(refetchAdvertisers),
  ]);
}
