import actions from './actions';

const initState = {
  isFetching: false,
  smsNames: []
};

export default function smsNamesReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
  
    case actions.FETCH_SMS_NAMES:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.FETCH_SMS_NAMES_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.RECEIVE_SMS_NAMES:
      return {
        ...state,
        isFetching: false,
        smsNames: action.smsNames
      };
    
    default:
      return state;
  }
}
