import React, { Component } from 'react';
import {connect} from "react-redux";
import chartsActions from "../../../../redux/campaignCharts/actions";
import Loader from "../../../../components/uielements/loader";
import { daysDifference } from "../../../../helpers/utility";

import {ChartDonut, ChartLabel} from '@patternfly/react-charts';

const basicData = [
    { x: 'Source 1', y: 35 },
    { x: 'Source 2', y: 55 },
    { x: 'Source 3', y: 10 }
];

const colors = [
  '#3498DB', '#16A085', '#2ECC71', '#F1C40F', '#F39C12',
  '#D35400', '#BDC3C7', '#7F8C8D', '#34495E', '#E74C3C',
  '#8E44AD', '#C0392B', '#9B59B6', '#85C1E9', '#73C6B6',
  '#F8C471', '#EDBB99', '#E5E7E9', '#85929E', '#7FB3D5',
  '#F9E79F', '#4A235A'
];

const defaultData = {
  title: 'Empty dataset',
  data: [
    {x:'', y:0}
  ],
  total: 0,
  goal: 0,
};

class DoughnutChart extends Component
{

  constructor(props) {
    super(props);

    this.state = {...defaultData};
  }

  componentDidMount()
  {
    const {goalId, fromDate, toDate} = this.props;

    if(goalId)
      this.getFetchChart(goalId,fromDate,toDate);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot)
  {
    const {goalId, goalSourceFieldsMapping, type, timespan, val_goal, gain, published, charts, isFetchingChart, fromDate, toDate} = this.props;

    if(prevProps.fromDate != fromDate || prevProps.toDate != toDate){
      this.getFetchChart(goalId,fromDate,toDate);
    }

    if( prevProps.goalId !== goalId ||
        prevProps.goalSourceFieldsMapping !== goalSourceFieldsMapping ||
        prevProps.type !== type ||
        prevProps.timespan !== timespan ||
        prevProps.val_goal !== val_goal ||
        prevProps.gain !== gain ||
        prevProps.published !== published){
      this.getFetchChart(goalId,fromDate,toDate);
    }

    if(goalId)
      if(charts !== prevProps.charts  && !isFetchingChart.includes(goalId))
      {
        this.redrawChart();
      }
  }

  getFetchChart = () => {
    const {goalId, timespan, fromDate, toDate, fetchChart, fetchChartSources} = this.props;
    if(goalId){
      if(timespan === 'sources')
        fetchChartSources(goalId,fromDate,toDate);
      else if(timespan === 'time_period')
        this.prepareTimePeriodChart();
      else
        fetchChart(goalId,fromDate,toDate);
    }
  };
  
  redrawChart = () => {
    const {goalId, charts} = this.props;

    if(charts.hasOwnProperty(goalId)){
      let newData = this.parseFieldsAndDataset(charts[goalId]);

      this.setState({
        data: newData
      })
    }

  };

  parseFieldsAndDataset = (rawData) => {
    const {goalName, timespan, goalDatasetNaming, datasetProperties} = this.props;

    if(this.isDataEmpty(rawData)) return {...defaultData.data};

    let chartData = [];
    let primaryDatasetIndex = Object.keys(rawData).shift();
    let primaryDataset = rawData[primaryDatasetIndex][timespan ? timespan : 'days'];

    let totalValue = 0;
    let countValues = 0;
    for(let label in primaryDataset){
      chartData.push({ x: label, y:  Number.isInteger(primaryDataset[label]) ? primaryDataset[label] : primaryDataset[label].toFixed(2) });
      totalValue += parseFloat(primaryDataset[label].toFixed(2));
      if(primaryDataset[label] != 0) countValues++;
    }

    let totalResult = this.isAverageCounting() ? (countValues ? totalValue / countValues : totalValue)  : totalValue;

    this.setState({
      title: goalDatasetNaming[primaryDatasetIndex],
      total: totalResult % 1 === 0 ? totalResult : totalResult.toFixed(2),
      goal: datasetProperties[primaryDatasetIndex].goal,
    });

    return chartData;
  };

  isAverageCounting = () => {
    const {sources, goalSourceFieldsMapping} = this.props;
    let fieldsMapping = {...goalSourceFieldsMapping};

    if(Object.keys(fieldsMapping).length === 0) return false;

    let primaryDataset = fieldsMapping[Object.keys(fieldsMapping).pop()];
    for(let type in primaryDataset){
      for(let subtype in primaryDataset[type]){
        let averageField = sources.filter(source =>  source.type == type && source.sub_type == subtype && Array.isArray(source.fields[primaryDataset[type][subtype]['field']]) );
        if(averageField.length) return true;
      }
    }

    return false;
  };

  isDataEmpty = (rawData) => {
    if(rawData.length < 1){
      this.setState({...defaultData});
      return true
    }
    return false
  };

  prepareTimePeriodChart = () => {
    const { patchGoalLocal, patchChartsLocal, goal, timespan, campaign } = this.props;

    let chart = { 'dataset0': {
                    [timespan]: {
                      'Days from the beginning of the campaign': daysDifference(new Date(campaign.start_time)).daysDiff
                    }}
                };

    let goalReplace = {
      ...goal,
      goal: daysDifference(new Date(campaign.start_time), new Date(campaign.finish_time)).daysDiff,
      dataset_naming: { 'dataset0': 'Days' }
    };

    patchGoalLocal(goalReplace);
    patchChartsLocal(goalReplace, chart);
  };

  getTitleComponent = (chartWidth) => {
    const {total, goal} = this.state;
    const goalStyle = {
      fontSize: 14,
    };
    const totalLabel = () => <tspan dy={'.2em'} x={chartWidth/2}>{total}</tspan>;
    const goalLabel = () => goal > 0 ? <tspan x={chartWidth/2} dy={'1.2em'} style={goalStyle}>{`/ ${goal}`}</tspan> : null;
    return <text>{totalLabel()}{goalLabel()}</text>
  };

  getSubTitleComponent = (chartWidth) => {
    const {title, total, goal} = this.state;
    const percentageStyle = {
      fontSize: 14,
    };
    const percentage = () => goal > 0 ? <tspan x={chartWidth/2} dy={'-4.2em'} style={percentageStyle}>{Math.round((total/goal)*100)} %</tspan> : null;
    const subTitle = () => <tspan dy={goal ? '1.2em' : '.5em'} x={chartWidth/2}>{title}</tspan>;
    return <text>{subTitle()}{percentage()}</text>
  };

  render() {
    const {title, data, total, goal} = this.state;
    const {isLoading} = this.props;

    return (
        <>
          <Loader visible={isLoading} text={'Chart is loading...'} />
          <div style={{visibility: (isLoading ? 'hidden' : 'visible')}}>
              <ChartDonut
                  height={190}
                  width={300}
                  radius={70}
                  innerRadius={95}
                  padding={0}
                  colorScale={colors}
                  constrainToVisibleArea={true}
                  data={data}
                  labels={({ datum }) => `${datum.x}: ${datum.y}`}
                  subTitle={title}
                  subTitleComponent={this.getSubTitleComponent(300)}
                  title={(goal > 0 ? total + '\n/' + goal : `${total}`)}
                  titleComponent={this.getTitleComponent(300)}
                  endAngle={(goal > 0 ? parseInt((total/goal)*360) : 360)}
              />
          </div>
        </>
    )
  }
}

const {fetchChart, fetchChartSources, patchGoalLocal, patchChartsLocal} = chartsActions;

export default connect(
    state => ({
      isFetchingChart: state.CampaignCharts.isFetchingChart,
      charts: state.CampaignCharts.charts,
      sources: state.Sources.sources,
      fromDate: state.Campaigns.fromDate,
      toDate: state.Campaigns.toDate,
      campaign: state.Campaigns.campaign
    }),
    { fetchChart, fetchChartSources, patchGoalLocal, patchChartsLocal }
)(DoughnutChart);