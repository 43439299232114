import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import ReportsFetch from "../../helpers/reportFetch";
import LeadsFetch from "../../helpers/leadsFetch";
import AdvertiserApi from "../../helpers/advertiserApi";


export function* fetchGroups() {
  yield takeEvery(actions.FETCH_GROUPS, function*() {

    const apiResponse = yield AdvertiserApi.get('groups', null)
      .then(response => (response))
      .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_GROUPS,
        groups: apiResponse.data.data
      });
      return;
    }
  
    yield put({
      type: actions.FETCH_GROUPS_ERROR
    });
    yield put({
      type: 'PUSH_ERROR',
      response: apiResponse,
    });
  });
}

export function* createGroup() {
  yield takeEvery(actions.CREATE_GROUP, function*(payload) {
    const {group} = payload;
    
    const apiResponse = yield AdvertiserApi.post('groups', {
      name: group.name
    })
      .then(response => (response))
      .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.GROUP_CREATED,
        createdGroup: apiResponse.data.data
      });
  
      yield put({
        type: actions.FETCH_GROUPS
      });
      return;
    }
    
    yield put({
      type: actions.CREATE_GROUP
    });
    yield put({
      type: 'PUSH_ERROR',
      response: apiResponse,
    });
  });
}

export function* assignGroupUser() {
  yield takeEvery(actions.ASSIGN_GROUP_USER, function*(payload) {
    const {user, groupAndLevels} = payload;
    
    const apiResponse = yield AdvertiserApi.PATCH(`users/${user}`, {
      groups: groupAndLevels
    })
      .then(response => (response))
      .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.GROUP_USER_ASSIGNED,
      });
      yield put({
        type: actions.FETCH_GROUPS
      });
      return;
    }
    
    yield put({
      type: actions.ASSIGN_GROUP_USER_ERROR
    });
    yield put({
      type: 'PUSH_ERROR',
      response: apiResponse,
    });
  });
}

export function* fetchReportsPerms() {
  yield takeEvery(actions.FETCH_REPORTS_PERMS, function*() {
    
    const apiResponse = yield ReportsFetch.get('secured-modules', null)
      .then(response => (response))
      .catch(error => ({error}))

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_REPORTS_PERMS,
        perms: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_REPORTS_PERMS_ERROR
    });
    yield put({
      type: 'PUSH_ERROR',
      response: apiResponse,
    });
  });
}

export function* addReportsPerms() {
  yield takeEvery(actions.ADD_REPORTS_PERMS, function*(payload) {
    const {groupId} = payload;
    const apiResponse = yield ReportsFetch.post('secured-modules/1/assign', {
      group: groupId
    })
      .then(response => (response))
      .catch(error => ({error}))

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.REPORTS_PERMS_ADDED
      });
      yield put({
        type: actions.FETCH_REPORTS_PERMS
      });
      return;
    }
    
    yield put({
      type: actions.ADD_REPORTS_PERMS_ERROR
    });
    yield put({
      type: 'PUSH_ERROR',
      response: apiResponse,
    });
  });
}

export function* removeReportsPerms() {
  yield takeEvery(actions.REMOVE_REPORTS_PERMS, function*(payload) {
    const {groupId} = payload;
    const apiResponse = yield ReportsFetch.delete('secured-modules/1/assign', {
      group: groupId
    })
      .then(response => (response))
      .catch(error => ({error}))

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.REPORTS_PERMS_REMOVED
      });
      yield put({
        type: actions.FETCH_REPORTS_PERMS
      });
      return;
    }
    
    yield put({
      type: actions.REMOVE_REPORTS_PERMS_ERROR
    });
    yield put({
      type: 'PUSH_ERROR',
      response: apiResponse,
    });
  });
}

export function* fetchLeadsPerms() {
  yield takeEvery(actions.FETCH_LEADS_PERMS, function*() {
    
    const apiResponse = yield LeadsFetch.get('secured-modules', null)
      .then(response => (response))
      .catch(error => ({error}))

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_LEADS_PERMS,
        perms: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_LEADS_PERMS_ERROR
    });
    yield put({
      type: 'PUSH_ERROR',
      response: apiResponse,
    });
  });
}

export function* addLeadsPerms() {
  yield takeEvery(actions.ADD_LEADS_PERMS, function*(payload) {
    const {groupId} = payload;
    const apiResponse = yield LeadsFetch.post('secured-modules/1/assign', {
      group: groupId
    })
      .then(response => (response))
      .catch(error => ({error}))

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.FETCH_LEADS_PERMS
      });
      return;
    }
    
    yield put({
      type: actions.ADD_LEADS_PERMS_ERROR
    });
    yield put({
      type: 'PUSH_ERROR',
      response: apiResponse,
    });
  });
}

export function* removeLeadsPerms() {
  yield takeEvery(actions.REMOVE_LEADS_PERMS, function*(payload) {
    const {groupId} = payload;
    const apiResponse = yield LeadsFetch.delete('secured-modules/1/assign', {
      group: groupId
    })
      .then(response => (response))
      .catch(error => ({error}))

    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.FETCH_LEADS_PERMS
      });
      return;
    }
    
    yield put({
      type: actions.REMOVE_LEADS_PERMS_ERROR
    });
    yield put({
      type: 'PUSH_ERROR',
      response: apiResponse,
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchGroups),
    fork(createGroup),
    fork(assignGroupUser),
    fork(fetchReportsPerms),
    fork(addReportsPerms),
    fork(removeReportsPerms),
    fork(fetchLeadsPerms),
    fork(addLeadsPerms),
    fork(removeLeadsPerms),
  ]);
}
