import { jwtConfig } from '../settings';
import React, { Component } from 'react';
import { connect } from 'react-redux';

const customHeader = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined,
});

const base = (method, url, data = null, replaceHeader = null) => {
  const header = replaceHeader == null ? customHeader() : replaceHeader;
  const advertiserId = localStorage.getItem('advertiser_id');
  
  const init = {
    method,
    headers: header,
  };
  
  if(data !== null) init.body = JSON.stringify(data);
  
  return fetch(`${jwtConfig.fetchUrl}api/${advertiserId}/${url}`, init)
    .then(r =>  r.json().then(data => ({status: r.status, data})))
    .then(obj => {
      if(obj.status == 401)
      {
        alert('Your session has been invalidated. Please log in again.');
        window.location.href = '/logout';
      }
      else
      {
        return (obj);
      }
    })
    .catch(error => {

      return ({ error: error });
    });
};

const AdvertiserApi = {};
['get', 'post', 'put', 'PATCH', 'delete'].forEach(method => {
  AdvertiserApi[method] = base.bind(null, method);
});

export default AdvertiserApi;
