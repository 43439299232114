import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius } from '../../settings/style-util';

const ReportWrapper = styled.div`
  width: 100%;
  min-height: 150px;
  height: 100%;
  border-radius: 8px;
  border: 4px dashed #e4e4e4;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: all 0.4s;
  
  .innerButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #d4d4d4;
    text-align: center;
    width: 100%;
    left: 0;
    font-size: 30px;
    transition: all 0.4s;
    -webkit-backface-visibility: hidden;-moz-backface-visibility: hidden;-ms-backface-visibility: hidden;backface-visibility: hidden;
    
    i {
      font-size: 30px;
      vertical-align: middle;
      margin-right: 10px;
      padding-bottom: 5px;
    }
  }
  
  &:hover {
    border-color: #2264f8;
    
    .innerButton {
    font-size: 36px;
    color: #2264f8;
    }
  }
`;

export default ReportWrapper;
