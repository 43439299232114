import actions from './actions';

const initState = {
    isFetchingBusinessAccounts: false,
    businessAccounts: [],
    isFetchingLocations: false,
    isFetchingLocationsPreviews: false,
    locations: [],
    locationsPreviews: [],
    location: null,
    locationsEmpty: false,
    isCreatingLocationImport: false,
    isFetchingLocationImports: false,
    imports: [],
    
    searchPhrase: '',
    totalLocations: 0,
    perPage: 50,
    page: 1,
};

export default function googleBusinessLocationsReducer(state = initState, action) {
    switch (action.type) {
        
        case 'SET_ADVERTISER':
            return {
                ...initState
            };
            
        case actions.SET_GOOGLE_LOCATION:
            return {
                ...state,
                location: action.location
            };
            
            //BUSINESS ACCOUNTS
        case actions.FETCH_BUSINESS_ACCOUNTS:
            return {
                ...state,
                isFetchingBusinessAccounts: true
            };
            
        case actions.FETCH_BUSINESS_ACCOUNTS_ERROR:
            return {
                ...state,
                isFetchingBusinessAccounts: false
            };
    
        case actions.BUSINESS_ACCOUNTS_RECEIVED:
            return {
                ...state,
                isFetchingBusinessAccounts: false,
                businessAccounts: action.businessAccounts
            };
            
            // LOCATIONS
        case actions.SET_LOCATIONS_SEARCH_PHRASE:
            return {
                ...state,
                searchPhrase: action.searchPhrase,
            };
            
        case actions.FETCH_GOOGLE_LOCATIONS:
            return {
                ...state,
                isFetchingLocations: true,
                locationsEmpty: false,
            };
    
        case actions.FETCH_GOOGLE_LOCATIONS_ERROR:
            return {
                ...state,
                isFetchingLocations: false,
                locationsEmpty: true,
            };
    
        case actions.RECEIVE_GOOGLE_LOCATIONS:
            let locationsEmpty = false;
            if(action.locations.length < 1)
                locationsEmpty = true;
        
            return {
                ...state,
                isFetchingLocations: false,
                locations: action.locations,
                page: action.page,
                totalLocations: action.totalLocations,
                locationsEmpty: locationsEmpty
            };
    
        case actions.FETCH_GOOGLE_LOCATION:
            return {
                ...state,
                isFetchingLocations: true,
            };
    
        case actions.FETCH_GOOGLE_LOCATION_ERROR:
            return {
                ...state,
                isFetchingLocations: false
            };
    
        case actions.RECEIVE_GOOGLE_LOCATION:
            return {
                ...state,
                isFetchingLocations: false,
                location: action.location
            };
    
        // LOCATIONS PREVIEWS
        case actions.FETCH_GOOGLE_LOCATIONS_PREVIEWS:
            return {
                ...state,
                isFetchingLocationsPreviews: true
            };
    
        case actions.FETCH_GOOGLE_LOCATIONS_PREVIEWS_ERROR:
            return {
                ...state,
                isFetchingLocationsPreviews: false
            };
    
        case actions.RECEIVE_GOOGLE_LOCATIONS_PREVIEWS:
            return {
                ...state,
                isFetchingLocationsPreviews: false,
                locationsPreviews: action.locationsPreviews
            };
    
        case actions.DELETE_GOOGLE_LOCATION:
            return {
                ...state,
                isFetchingLocations: true
            };
    
        case actions.DELETE_GOOGLE_LOCATION_ERROR:
            return {
                ...state,
                isFetchingLocations: false,
            };
    
        case actions.GOOGLE_LOCATION_DELETED:
            let deletedId = action.id;
            let postDeleteLocations = [...state.locations];
            
            for(let i = 0; i < postDeleteLocations.length; i++)
            {
                if(postDeleteLocations[i].id == deletedId)
                {
                    postDeleteLocations.splice(i, 1);
                    break;
                }
            }
            
            return {
                ...state,
                isFetchingLocations: false,
                locations: postDeleteLocations
            };
    
            
            // IMPORTS
    
        case actions.FETCH_LOCATIONS_IMPORT:
            return {
                ...state,
                isFetchingLocationImports: true
            };
    
        case actions.FETCH_LOCATIONS_IMPORT_ERROR:
            return {
                ...state,
                isFetchingLocationImports: false
            };
    
        case actions.LOCATIONS_IMPORT_RECEIVED:
            return {
                ...state,
                isFetchingLocationImports: false,
                imports: action.imports
            };
            
        case actions.CREATE_LOCATIONS_IMPORT:
            return {
                ...state,
                isCreatingLocationImport: true
            };
    
        case actions.CREATE_LOCATIONS_IMPORT_ERROR:
            return {
                ...state,
                isCreatingLocationImport: false
            };
    
        case actions.LOCATIONS_IMPORT_CREATED:
            return {
                ...state,
                isCreatingLocationImport: false
            };
    
        case actions.DELETE_LOCATIONS_IMPORT:
            return {
                ...state,
                isFetchingLocationImports: true
            };
    
        case actions.DELETE_LOCATIONS_IMPORT_ERROR:
            return {
                ...state,
                isFetchingLocationImports: false
            };
    
        case actions.LOCATIONS_IMPORT_DELETED:
            return {
                ...state,
                isFetchingLocationImports: false
            };

            
        default:
            return state;
    }
}
