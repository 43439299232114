import React, { Component } from "react";
import { connect } from "react-redux";
import {Layout, Spin} from "antd";
import appActions from "../../redux/app/actions";
import statusActions from "../../redux/status/actions";
import advertiserActions from "../../redux/advertiser/actions";
import TopbarUser from "./topbarUser";
import TopbarMenu from "./topbarMenu";
import TopbarWrapper from "./topbar.style";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import Logo from '../../components/utility/logo';
import NotificationCenter from './notificationCenter';

const { Header } = Layout;
const { toggleCollapsed } = appActions;
const { fetchStatuses } = statusActions;
const { fetchAdvertisers } = advertiserActions;
const customizedTheme = themes[themeConfig.theme];

class Topbar extends Component {
  
  componentDidMount()
  {
    const {fetchStatuses, user} = this.props;
    if(!user.permissions)
      return;
    
    fetchStatuses();
    
  }
  
  render() {
    const { toggleCollapsed, haveSidebar, url, advertisers, isFetchingAdvertisers } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };
  
    const currentId = localStorage.getItem('advertiser_id');
    let currentAdvertiser = advertisers.find(adr => {
      return adr.advertiser_id == currentId;
    });
    
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed " + (haveSidebar ? '' : 'withBorder') : "isomorphicTopbar " + (haveSidebar ? '' : 'withBorder')
          }
        >
          <div className={"isoTopLogo " + (haveSidebar ? '' : 'withoutSidebar')}>
            <Logo collapsed={collapsed} haveSidebar={haveSidebar} />
          </div>
          <div className="isoLeft">
            <button
              className={
                collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
              }
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
            />
            
            <TopbarMenu url={url} />
            
          </div>
          
          <NotificationCenter />

          <ul className="isoRight">
            <li style={{pointerEvents: 'none', textAlign: 'right'}}>
              {currentAdvertiser && !isFetchingAdvertisers
                ? <span>Advertiser: <br /><strong>{currentAdvertiser.advertiser_name}</strong></span>
                : <Spin />
              }
            </li>
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    user: state.Auth.user,
    advertisers: state.Advertisers.advertisers,
    isFetchingAdvertisers: state.Advertisers.isFetching,
  }),
  {
    toggleCollapsed,
    fetchStatuses
  }
)(Topbar);
