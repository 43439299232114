import actions from './actions';

const initState = {
  accountId: null,
  pages: [],
  pageId: null,
  pageToken: null,
  forms: [],
  formId: null,
  qualifiers: [],
  disclaimers: [],
  testLeads: [],
  testLead: null,
  
  isFetchingPages: false,
  isFetchingForms: false,
  isFetchingLeads: false,
};

export default function facebookFormsReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
  
    case actions.CLEAR_FACEBOOK_LEAD_FORM:
      return {
        ...state,
        accountId: action.accountId,
        pageId: initState.pageId,
        pageToken: initState.pageToken,
        forms: initState.forms,
        formId: initState.formId,
        testLead: initState.testLead,
        isFetchingPages: true,
      };
  
    // PAGES
    case actions.FETCH_FACEBOOK_PAGES:
      return {
        ...state,
        accountId: action.accountId,
        pageId: initState.pageId,
        pageToken: initState.pageToken,
        forms: initState.forms,
        formId: initState.formId,
        testLead: initState.testLead,
        isFetchingPages: true,
      };
  
    case actions.FETCH_FACEBOOK_PAGES_ERROR:
      return {
        ...state,
        isFetchingPages: false,
        accountId: null,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_FACEBOOK_PAGES:
      return {
        ...state,
        isFetchingPages: false,
        pages: action.pages,
      };
      
    // FORMS
    case actions.FETCH_FACEBOOK_FORMS:
      return {
        ...state,
        pageId: action.pageId,
        pageToken: action.pageToken,
        forms: initState.forms,
        formId: initState.formId,
        testLead: initState.testLead,
        isFetchingForms: true,
      };
  
    case actions.FETCH_FACEBOOK_FORMS_ERROR:
      return {
        ...state,
        pageId: initState.pageId,
        pageToken: initState.pageToken,
        isFetchingForms: false,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_FACEBOOK_FORMS:
      return {
        ...state,
        isFetchingForms: false,
        forms: action.forms,
      };
  
    // TEST LEADS
    case actions.FETCH_FACEBOOK_TEST_LEADS:
      return {
        ...state,
        formId: action.formId,
        qualifiers: action.qualifiers,
        disclaimers: action.disclaimers,
        isFetchingLeads: true,
      };
  
    case actions.FETCH_FACEBOOK_TEST_LEADS_ERROR:
      return {
        ...state,
        formId: initState.formId,
        isFetchingLeads: false,
      };
  
    case actions.RECEIVE_FACEBOOK_TEST_LEADS:
      return {
        ...state,
        isFetchingLeads: false,
        testLeads: action.testLeads,
      };
      
    case actions.SET_FACEBOOK_TEST_LEAD:
      return {
        ...state,
        testLead: action.lead,
      };
    
    default:
      return state;
  }
}
