import {all, takeEvery, put, fork, select} from 'redux-saga/effects';
import actions from './actions';
import LeadsFetch from "../../helpers/leadsFetch";
import moment from "moment";
import notifications from "../../components/feedback/notification";

export function* fetchProspectsWithDateFields() {
  yield takeEvery(actions.FETCH_PROSPECTS_WITH_DATE_FIELDS, function*() {
    const apiResponse = yield LeadsFetch.get('prospects-fields/2', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.PROSPECTS_WITH_DATE_FIELDS_RECEIVED,
        prospectsWithDates: apiResponse.data
      });
      return;
    }
    
  });
}

export function* fetchRecentCalendarEvents() {
  yield takeEvery(actions.FETCH_RECENT_CALENDAR_EVENTS, function*(payload) {
    const {startDate, endDate} = payload;
    let q = '?';
    if(startDate)
      q += `date_field_since=${startDate}`;
    if(endDate)
      q += `&date_field_until=${endDate}`;

    const apiResponse = yield LeadsFetch.get(`prospects-fields/2${q}`, null)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECENT_CALENDAR_EVENTS_RECEIVED,
        recentEvents: apiResponse.data
      });
      return;
    }

    yield put({
      type: actions.FETCH_RECENT_CALENDAR_EVENTS_ERROR
    });
  });
}

export function* updateCalendarEvent() {
  yield takeEvery(actions.UPDATE_CALENDAR_EVENT, function*(payload) {
    const {startDate, endDate, event} = payload;

    const apiResponse = yield LeadsFetch.PATCH('daily-tasks', event)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200){
      yield put({
        type: actions.CALENDAR_EVENT_UPDATED,
        event: apiResponse.data.data
      });
      yield put({
        type: actions.FETCH_DAILY_TASKS,
        startDate,
        endDate
      });
      notifications['success']({
        message: 'Calendar',
        description: 'Succeed moving on '+moment(apiResponse.data.data.value).format('MMMM Do, H:mm')
      });
      return;
    }

    yield put({
      type: actions.UPDATE_CALENDAR_EVENT_ERROR
    });
  });
}

export function* fetchDailyTasks() {
  yield takeEvery(actions.FETCH_DAILY_TASKS, function*(payload) {
    const {startDate, endDate} = payload;
    let q = '?';
    if(startDate)
      q += `since=${startDate}`;
    if(endDate)
      q += `&until=${endDate}`;

    const apiResponse = yield LeadsFetch.get(`daily-tasks${q}`, null)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.DAILY_TASKS_RECEIVED,
        dailyTasks: apiResponse.data.data
      });
      return;
    }

    yield put({
      type: actions.FETCH_DAILY_TASKS_ERROR
    });
  });
}

export function* addDailyTask() {
  yield takeEvery(actions.ADD_DAILY_TASK, function*(payload) {
    const {event} = payload;
    const params = {
      prospect_has_custom_field: event.id
    };

    const apiResponse = yield LeadsFetch.post(`daily-tasks`, params)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.DAILY_TASK_ADDED,
        task: apiResponse.data.data
      });
      return;
    }

    yield put({
      type: actions.ADD_DAILY_TASK_ERROR
    });
  });
}

export function* removeDailyTask() {
  yield takeEvery(actions.REMOVE_DAILY_TASK, function*(payload) {
    const {task} = payload;

    const apiResponse = yield LeadsFetch.delete(`daily-tasks/${task.id}`, null)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.DAILY_TASK_REMOVED,
        task
      });
      return;
    }

    yield put({
      type: actions.REMOVE_DAILY_TASK_ERROR
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchProspectsWithDateFields),
    fork(fetchRecentCalendarEvents),
    fork(updateCalendarEvent),
    fork(fetchDailyTasks),
    fork(addDailyTask),
    fork(removeDailyTask),
  ]);
}
