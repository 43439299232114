import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import DeliveryFetch from "../../../helpers/deliveryFetch";
import notifications from "../../../components/feedback/notification";

export function* fetchContactSentMessages() {
    yield takeEvery(actions.FETCH_CONTACT_SENT_MESSAGES, function*(payload) {
        
        let {messagesCampaign} = payload.filters;
        let query = "?page=1";
        if(messagesCampaign)
        {
            query += '&messages_campaign='+messagesCampaign.id;
        }
        
        const resp = yield DeliveryFetch.get('contactsentmessages' + query, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            yield put({
                type: actions.RECEIVE_CONTACT_SENT_MESSAGES,
                contactSentMessages: resp.data.data.contactSentMessages,
                total_count: resp.data.data.total_count,
                sent_count: resp.data.data.sent_count,
                open_count: resp.data.data.open_count,
            });
            return;
        }
        
        // yield put({
        //   type: actions.CREATE_MESSAGE_CAMPAIGN_ERROR,
        //   errorMessage: resp.error
        // });
    });
}

export default function* rootSaga() {
    yield all([
        fork(fetchContactSentMessages),
    ]);
}
