import actions from './actions';

const initState = {
    isFetchingCampaigns: false,
    locationCampaigns: [],
    campaign: null,
    isCreatingCampaign: false,
    shouldUpdate: false
};

export default function googleBusinessCampaignsReducer(state = initState, action) {
    switch (action.type) {
        
        case 'SET_ADVERTISER':
            return {
                ...initState
            };
    
        case actions.CLEAR_LOCATION_CAMPAIGN:
            return {
                ...state,
                campaign: initState.campaign
            };
        
        case actions.FETCH_LOCATION_CAMPAIGNS:
            return {
                ...state,
                isFetchingCampaigns: true,
            };
        
        case actions.FETCH_LOCATION_CAMPAIGNS_ERROR:
            return {
                ...state,
                isFetchingCampaigns: false,
            };
        
        case actions.RECEIVE_LOCATION_CAMPAIGNS:
            return {
                ...state,
                isFetchingCampaigns: false,
                locationCampaigns: action.locationCampaigns
            };
        
        case actions.CREATE_LOCATION_CAMPAIGN:
        case actions.DEPLOY_LOCATION_CAMPAIGN:
        case actions.UPDATE_LOCATION_CAMPAIGN:
            return {
                ...state,
                isCreatingCampaign: true,
            };
        
        case actions.CREATE_LOCATION_CAMPAIGN_ERROR:
        case actions.DEPLOY_LOCATION_CAMPAIGN_ERROR:
        case actions.UPDATE_LOCATION_CAMPAIGN_ERROR:
            return {
                ...state,
                isCreatingCampaign: false,
            };
        
        case actions.LOCATION_CAMPAIGN_CREATED:
        case actions.LOCATION_CAMPAIGN_DEPLOYED:
        case actions.LOCATION_CAMPAIGN_UPDATED:
            return {
                ...state,
                isCreatingCampaign: false,
                campaign: action.campaign
            };
        
        case actions.SET_LOCATION_CAMPAIGN:
            return {
                ...state,
                campaign: action.campaign
            };
        
        case actions.DELETE_LOCATION_CAMPAIGN:
            return {
                ...state,
                isFetchingCampaigns: true
            };
        
        case actions.DELETE_LOCATION_CAMPAIGN_ERROR:
            return {
                ...state,
                isFetchingCampaigns: false
            };
            
        case actions.LOCATION_CAMPAIGN_DELETED:
            return {
                ...state,
                isFetchingCampaigns: false
            };
    
        case actions.PAUSE_LOCATION_CAMPAIGN:
            return {
                ...state,
                isCreatingCampaign: true
            };
    
        case actions.PAUSE_LOCATION_CAMPAIGN_ERROR:
            return {
                ...state,
                isCreatingCampaign: false
            };
    
        case actions.LOCATION_CAMPAIGN_PAUSED:
            return {
                ...state,
                isCreatingCampaign: false,
                campaign: action.campaign
            };
        
        default:
            return state;
    }
}
