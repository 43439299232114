import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import LeadsFetch from "../../helpers/leadsFetch";
import ReportFetch from "../../helpers/reportFetch";


export function* createLead() {
  yield takeEvery(actions.CREATE_LEAD, function*(payload) {
    const {newLead} = payload;
    
    // let campaign = {
    //   name: campaignValues.title,
    //   client: campaignValues.client,
    //   margin: campaignValues.margin,
    //   startDate: startDate,
    //   endDate: endDate,
    // };
    
    const resp = yield LeadsFetch.post('leads', newLead)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.LEAD_CREATED,
        createdLead: resp.data
      });
      return;
    }
    
    yield put({
      type: actions.CREATE_LEAD_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* fetchCampaignIdentifiers() {
  yield takeEvery(actions.FETCH_LEAD_TO_CAMPAIGN_IDENTIFIERS, function*(payload) {
    const {identifiers} = payload;

    const apiResponse = yield ReportFetch.get(`identifier/campaign?` + identifiers.map(i => `unique[]=${i}`).join('&'))
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_LEAD_TO_CAMPAIGN_IDENTIFIERS,
        campaigns: apiResponse.data.data
      });
      return;
    }

    yield put({
      type: actions.FETCH_LEAD_TO_CAMPAIGN_IDENTIFIERS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* fetchAdIdentifiers() {
  yield takeEvery(actions.FETCH_LEAD_TO_AD_IDENTIFIERS, function*(payload) {
    const {identifiers} = payload;

    const apiResponse = yield ReportFetch.get(`identifier/source?` + identifiers.map(i => `unique[]=${i}`).join('&'))
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_LEAD_TO_AD_IDENTIFIERS,
        ads: apiResponse.data.data
      });
      return;
    }

    yield put({
      type: actions.FETCH_LEAD_TO_AD_IDENTIFIERS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(createLead),
    fork(fetchCampaignIdentifiers),
    fork(fetchAdIdentifiers),
  ]);
}
