import actions from './actions';

const notes = [];


const colors = ['#42a5f5', '#7ED321', '#de1b1b', '#511E78', '#ff9009'];
const initState = {
  notes,
  colors,
  fetchingNotes: true,
  postingNote: false,
  seectedColor: 0
};

export default function noteReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.CHANGE_NOTE:
      return {
        ...state,
        selectedId: action.selectedId,
        seectedColor: action.seectedColor
      };
    case actions.ADD_NOTE:
      return {
        ...state,
        newNote: action.newNote,
        postingNote: true,
      };
    case actions.RECEIVE_NOTE:
      return {
        ...state,
        notes: action.notes,
        postingNote: false,
        selectedId: action.newNote.id
      };
    case actions.RECEIVE_NOTES:
      return {
        ...state,
        notes: action.notes,
        selectedId: action.notes.length > 0 ? action.notes[0].id : state.selectedId,
        seectedColor: action.notes.length > 0 ? action.notes[0].color : state.seectedColor,
        fetchingNotes: false
      };
    case actions.EDIT_NOTE:
      return {
        ...state,
        notes: action.notes
      };
    case actions.DELETE_NOTE:
      return {
        ...state,
        notes: action.notes,
        selectedId: action.selectedId
      };
    case actions.CHANGE_COLOR:
      return {
        ...state,
        notes: action.notes,
        seectedColor: action.seectedColor
      };
    default:
      return state;
  }
}
