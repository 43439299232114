const actions = {
  FETCH_GROUPS: 'FETCH_GROUPS',
  FETCH_GROUPS_ERROR: 'FETCH_GROUPS_ERROR',
  RECEIVE_GROUPS: 'RECEIVE_GROUPS',
  
  CREATE_GROUP: 'CREATE_GROUP',
  CREATE_GROUP_ERROR: 'CREATE_GROUP_ERROR',
  GROUP_CREATED: 'GROUP_CREATED',
  
  ASSIGN_GROUP_USER: 'ASSIGN_GROUP_USER',
  ASSIGN_GROUP_USER_ERROR: 'ASSIGN_GROUP_USER_ERROR',
  GROUP_USER_ASSIGNED: 'GROUP_USER_ASSIGNED',
  
  FETCH_REPORTS_PERMS: 'FETCH_REPORTS_PERMS',
  FETCH_REPORTS_PERMS_ERROR: 'FETCH_REPORTS_PERMS_ERROR',
  RECEIVE_REPORTS_PERMS: 'RECEIVE_REPORTS_PERMS',
  
  ADD_REPORTS_PERMS: 'ADD_REPORTS_PERMS',
  ADD_REPORTS_PERMS_ERROR: 'ADD_REPORTS_PERMS_ERROR',
  REPORTS_PERMS_ADDED: 'REPORTS_PERMS_ADDED',
  
  REMOVE_REPORTS_PERMS: 'REMOVE_REPORTS_PERMS',
  REMOVE_REPORTS_PERMS_ERROR: 'REMOVE_REPORTS_PERMS_ERROR',
  REPORTS_PERMS_REMOVED: 'REPORTS_PERMS_REMOVED',
  
  FETCH_LEADS_PERMS: 'FETCH_LEADS_PERMS',
  FETCH_LEADS_PERMS_ERROR: 'FETCH_LEADS_PERMS_ERROR',
  RECEIVE_LEADS_PERMS: 'RECEIVE_LEADS_PERMS',
  
  ADD_LEADS_PERMS: 'ADD_LEADS_PERMS',
  ADD_LEADS_PERMS_ERROR: 'ADD_LEADS_PERMS_ERROR',
  
  REMOVE_LEADS_PERMS: 'REMOVE_LEADS_PERMS',
  REMOVE_LEADS_PERMS_ERROR: 'REMOVE_LEADS_PERMS_ERROR',
  
  fetchGroups: () => ({
    type: actions.FETCH_GROUPS
  }),
  
  createGroup: (group) => ({
    type: actions.CREATE_GROUP,
    group
  }),
  
  fetchReportsPerms: () => ({
    type: actions.FETCH_REPORTS_PERMS
  }),
  
  addReportsPerms: (groupId) => ({
    type: actions.ADD_REPORTS_PERMS,
    groupId
  }),
  
  removeReportsPerms: (groupId) => ({
    type: actions.REMOVE_REPORTS_PERMS,
    groupId
  }),
  
  fetchLeadsPerms: () => ({
    type: actions.FETCH_LEADS_PERMS
  }),
  
  addLeadsPerms: (groupId) => ({
    type: actions.ADD_LEADS_PERMS,
    groupId
  }),
  
  removeLeadsPerms: (groupId) => ({
    type: actions.REMOVE_LEADS_PERMS,
    groupId
  }),
  
  assignGroupUser: (user, groupAndLevels) => ({
    type: actions.ASSIGN_GROUP_USER,
    user,
    groupAndLevels
  }),
  
};
export default actions;
