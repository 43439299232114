const actions = {
    
    SET_LOCATION_CAMPAIGN: 'SET_LOCATION_CAMPAIGN',
    
    FETCH_REVIEWS: 'FETCH_REVIEWS',
    FETCH_REVIEWS_ERROR: 'FETCH_REVIEWS_ERROR',
    REVIEWS_RECEIVED: 'REVIEWS_RECEIVED',
    
    UPDATE_REVIEW: 'UPDATE_REVIEW',
    UPDATE_REVIEW_ERROR: 'UPDATE_REVIEW_ERROR',
    REVIEW_UPDATED: 'REVIEW_UPDATED',
    
    REPLY_REVIEW: 'REPLY_REVIEW',
    REPLY_REVIEW_ERROR: 'REPLY_REVIEW_ERROR',
    REVIEW_REPLIED: 'REVIEW_REPLIED',
    
    DELETE_REVIEW_REPLY: 'DELETE_REVIEW_REPLY',
    DELETE_REVIEW_REPLY_ERROR: 'DELETE_REVIEW_REPLY_ERROR',
    REVIEW_REPLY_DELETED: 'REVIEW_REPLY_DELETED',
    
    fetchReviews: (filters, page = 1) => ({
        type: actions.FETCH_REVIEWS,
        filters,
        page
    }),
    
    updateReview: (id, status) => ({
        type: actions.UPDATE_REVIEW,
        id,
        status
    }),
    
    reviewReply: (id, reply_comment) => ({
        type: actions.REPLY_REVIEW,
        id,
        reply_comment
    }),
    
    deleteReply: (id) => ({
        type: actions.DELETE_REVIEW_REPLY,
        id
    }),
    
};
export default actions;
