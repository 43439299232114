const actions = {
  FETCH_CAMPAIGNS: 'FETCH_CAMPAIGNS',
  FETCH_CAMPAIGNS_ERROR: 'FETCH_CAMPAIGNS_ERROR',
  RECEIVE_CAMPAIGNS: 'RECEIVE_CAMPAIGNS',
  
  FETCH_CAMPAIGN: 'FETCH_CAMPAIGN',
  FETCH_CAMPAIGN_ERROR: 'FETCH_CAMPAIGN_ERROR',
  RECEIVE_CAMPAIGN: 'RECEIVE_CAMPAIGN',
  
  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  CREATE_CAMPAIGN_ERROR: 'CREATE_CAMPAIGN_ERROR',
  CAMPAIGN_CREATED: 'CAMPAIGN_CREATED',

  ASYNC_CREATE_CAMPAIGN: 'ASYNC_CREATE_CAMPAIGN',

  PATCH_CAMPAIGN: 'PATCH_CAMPAIGN',
  PATCH_CAMPAIGN_ERROR: 'PATCH_CAMPAIGN_ERROR',
  CAMPAIGN_PATCHED: 'CAMPAIGN_PATCHED',
  
  DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',
  DELETE_CAMPAIGN_ERROR: 'DELETE_CAMPAIGN_ERROR',
  CAMPAIGN_DELETED: 'CAMPAIGN_DELETED',
  
  PATCH_CAMPAIGN_SETTINGS: 'PATCH_CAMPAIGN_SETTINGS',
  PATCH_CAMPAIGN_SETTINGS_ERROR: 'PATCH_CAMPAIGN_SETTINGS_ERROR',
  CAMPAIGN_SETTINGS_PATCHED: 'CAMPAIGN_SETTINGS_PATCHED',
  
  CLONE_CAMPAIGN: 'CLONE_CAMPAIGN',
  CLONE_CAMPAIGN_ERROR: 'CLONE_CAMPAIGN_ERROR',
  CAMPAIGN_CLONED: 'CAMPAIGN_CLONED',
  
  NOTIFY_CAMPAIGN_DEPARMENTS: 'NOTIFY_CAMPAIGN_DEPARMENTS',
  NOTIFY_CAMPAIGN_DEPARMENTS_ERROR: 'NOTIFY_CAMPAIGN_DEPARMENTS_ERROR',
  CAMPAIGN_DEPARMENTS_NOTIFIED: 'CAMPAIGN_DEPARMENTS_NOTIFIED',
  
  SET_DATERANGE: 'SET_DATERANGE',
  
  setDateRange: (fromDate, toDate, userDateProvided = false) => ({
    type: actions.SET_DATERANGE,
    fromDate,
    toDate,
    userDateProvided
  }),
  
  fetchCampaigns: () => ({
    type: actions.FETCH_CAMPAIGNS
  }),
  
  fetchCampaign: (campaignId) => ({
    type: actions.FETCH_CAMPAIGN,
    campaignId
  }),
  
  createCampaign: (campaignValues) => ({
    type: actions.CREATE_CAMPAIGN,
    campaignValues
  }),

  cloneCampaign: (campaignId) => ({
    type: actions.CLONE_CAMPAIGN,
    campaignId
  }),
  
  patchCampaign: (campaign, campaignValues) => ({
    type: actions.PATCH_CAMPAIGN,
    campaignValues,
    campaign
  }),
  
  deleteCampaign: (campaignId) => ({
    type: actions.DELETE_CAMPAIGN,
    campaignId
  }),
  
  patchCampaignSettings: (campaign, settings) => ({
    type: actions.PATCH_CAMPAIGN_SETTINGS,
    settings,
    campaign
  }),
  
  notifyCampaignDepartments: (campaign) => ({
    type: actions.NOTIFY_CAMPAIGN_DEPARMENTS,
    campaign
  }),
  
};
export default actions;
