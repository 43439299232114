import {all, takeEvery, put, fork, select} from 'redux-saga/effects';
import actions from './actions';
import LeadsFetch from "../../helpers/leadsFetch";



export function* fetchLeadReports() {
  yield takeEvery(actions.FETCH_LEAD_REPORTS, function*() {
    const apiResponse = yield LeadsFetch.get('reports', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.LEAD_REPORTS_RECEIVED,
        leadReports: apiResponse.data.data
      });
      return;
    }
    
  });
}

export function* createLeadReport() {
  yield takeEvery(actions.CREATE_LEAD_REPORT, function*(payload) {
    const {name, serializedFilters} = payload;
    
    let report = {
      name: name,
      json_content: JSON.stringify([]),
    };
    
    const resp = yield LeadsFetch.post('reports', report)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.LEAD_REPORT_CREATED,
      });
      yield put({
        type: actions.FETCH_LEAD_REPORTS,
      });
      return;
    }
    
    yield put({
      type: actions.CREATE_LEAD_REPORT_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* updateLeadReport() {
  yield takeEvery(actions.UPDATE_LEAD_REPORT, function*(payload) {
    const {id, name, serializedFilters} = payload;
    
    let report = {
      name: name,
      json_content: serializedFilters,
    };
    
    const resp = yield LeadsFetch.PATCH('reports/' + id, report)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.LEAD_REPORT_UPDATED,
      });
      yield put({
        type: actions.FETCH_LEAD_REPORTS,
      });
      return;
    }
    
    yield put({
      type: actions.UPDATE_LEAD_REPORT_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* deleteLeadReport() {
  yield takeEvery(actions.DELETE_LEAD_REPORT, function*(payload) {
    const {reportId} = payload;
    
    const resp = yield LeadsFetch.delete('reports/' + reportId, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.LEAD_REPORT_DELETED,
      });
      yield put({
        type: actions.FETCH_LEAD_REPORTS,
      });
      return;
    }
    
    yield put({
      type: actions.DELETE_LEAD_REPORT_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* copyLeadReport() {
  yield takeEvery(actions.COPY_LEAD_REPORT, function*(payload) {
    const {reportId} = payload;

    const resp = yield LeadsFetch.put('reports/' + reportId)
        .then(response => (response))
        .catch(error => ({error}));

    if(resp.status === 200)
    {
      yield put({
        type: actions.LEAD_REPORT_COPIED,
      });
      yield put({
        type: actions.FETCH_LEAD_REPORTS,
      });
      return;
    }

    yield put({
      type: actions.COPY_LEAD_REPORT_ERROR,
      errorMessage: resp.error
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchLeadReports),
    fork(createLeadReport),
    fork(updateLeadReport),
    fork(deleteLeadReport),
    fork(copyLeadReport),
  ]);
}
