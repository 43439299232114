import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import SuperFetch from "../../helpers/superFetch";
import AdvertiserApi from "../../helpers/advertiserApi";
import notifications from '../../components/feedback/notification';


export function* fetchUsers() {
  yield takeEvery('FETCH_USERS', function*() {
    const apiResponse = yield AdvertiserApi.get('users', null)
      .then(response => (response))
      .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_USERS,
        users: apiResponse.data.data
      });
    }
  });
}

export function* searchUsers() {
  yield takeEvery(actions.SEARCH_USERS, function*(payload) {
    const {email} = payload;
    const apiResponse = yield AdvertiserApi.get('search-users?email=' + email, null)
      .then(response => (response))
      .catch(error => ({error}))

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_USER_RESULTS,
        foundUser: apiResponse.data.data
      });
      return;
    }
  
    yield put({
      type: actions.RECEIVE_USER_RESULTS,
      foundUser: false
    });
  });
}

export function* inviteUser() {
  yield takeEvery(actions.INVITE_USER, function*(payload) {
    const {email, groupId, level} = payload;
    const apiResponse = yield AdvertiserApi.post('invite', {
      email,
      group: groupId,
      level
    })
      .then(response => (response))
      .catch(error => ({error}))

    if(apiResponse.status === 200)
    {
      notifications['success']({
        message: 'User invited!',
        description: 'We sent invitation link to user email. Please wait for acceptation.'
      });
      yield put({
        type: actions.USER_INVITED
      });
      return;
    }
    
    yield put({
      type: actions.INVITE_USER_ERROR
    });
    notifications['error']({
      message: 'User not invited',
      description: 'There was a problem with user invitation. Please try again.'
    });
  });
}

export function* uninviteUser() {
  yield takeEvery(actions.UNINVITE_USER, function*(payload) {
    const {id} = payload;
    const apiResponse = yield AdvertiserApi.delete('uninvite', {
      id
    })
      .then(response => (response))
      .catch(error => ({error}))

    if(apiResponse.status === 200)
    {
      notifications['info']({
        message: 'User removed',
        description: 'We have removed user from your advertiser account.'
      });
      yield put({
        type: actions.USER_UNINVITED
      });
      yield put({
        type: actions.FETCH_USERS
      });
      return;
    }
    
    yield put({
      type: actions.UNINVITE_USER_ERROR
    });
    notifications['error']({
      message: 'User not removed',
      description: 'There was a problem with removing user. Please try again.'
    });
  });
}

export function* promoteUser() {
  yield takeEvery(actions.PROMOTE_USER, function*(payload) {
    const {id, role} = payload;
    const apiResponse = yield AdvertiserApi.post('promote-user', {
      id,
      role
    })
      .then(response => (response))
      .catch(error => ({error}))

    if(apiResponse.status === 200)
    {
      notifications['success']({
        message: 'User promoted!',
        description: 'We have promoted user to new role.'
      });
      yield put({
        type: actions.USER_PROMOTED
      });
      yield put({
        type: actions.FETCH_USERS
      });
      return;
    }
  
    notifications['error']({
      message: 'User not promoted',
      description: apiResponse.data.message
    });
    
    yield put({
      type: actions.PROMOTE_USER_ERROR
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchUsers),
    fork(searchUsers),
    fork(inviteUser),
    fork(uninviteUser),
    fork(promoteUser),
  ]);
}
