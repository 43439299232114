import actions from './actions';

const initState = {
  offerTemplate: null,
  offerTemplates: [],
  isFetching: false
};


export default function offerTemplateReducer(state = initState, action) {
  switch (action.type) {

    case actions.FETCH_OFFER_TEMPLATE:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.FETCH_OFFER_TEMPLATE_ERROR:
      return {
        ...state,
        isFetching: false,
      };
  
    case actions.RECEIVE_OFFER_TEMPLATE:
      return {
        ...state,
        isFetching: false,
        offerTemplate: action.offerTemplate
      };
  
    case actions.CREATE_OFFER_TEMPLATE:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.CREATE_OFFER_TEMPLATE_ERROR:
      return {
        ...state,
        isFetching: false,
      };
  
    case actions.OFFER_TEMPLATE_CREATED:
      return {
        ...state,
        isFetching: false,
        offerTemplate: action.offerTemplate
      };
  
    case actions.SEND_OFFER_TEMPLATE:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.SEND_OFFER_TEMPLATE_ERROR:
      return {
        ...state,
        isFetching: false,
      };
  
    case actions.OFFER_TEMPLATE_SENT:
      return {
        ...state,
        isFetching: false
      };
  
    
    default:
      return state;
  }
}
