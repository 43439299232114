const actions = {
    
    SET_LOCATION_CAMPAIGN: 'SET_LOCATION_CAMPAIGN',
    
    FETCH_LOCATION_VERSIONS: 'FETCH_LOCATION_VERSIONS',
    FETCH_LOCATION_VERSIONS_ERROR: 'FETCH_LOCATION_VERSIONS_ERROR',
    LOCATION_VERSIONS_RECEIVED: 'LOCATION_VERSIONS_RECEIVED',
    
    CREATE_LOCATION_VERSIONS: 'CREATE_LOCATION_VERSIONS',
    CREATE_LOCATION_VERSIONS_ERROR: 'CREATE_LOCATION_VERSIONS_ERROR',
    LOCATION_VERSIONS_CREATED: 'LOCATION_VERSIONS_CREATED',
    
    DELETE_LOCATION_VERSION: 'DELETE_LOCATION_VERSION',
    DELETE_LOCATION_VERSION_ERROR: 'DELETE_LOCATION_VERSION_ERROR',
    LOCATION_VERSION_DELETED: 'LOCATION_VERSION_DELETED',
    
    fetchVersions: (locations) => ({
        type: actions.FETCH_LOCATION_VERSIONS,
        locations
    }),
    
    createVersions: (locations, version) => ({
        type: actions.CREATE_LOCATION_VERSIONS,
        locations,
        version
    }),
    
    deleteVersion: (id) => ({
        type: actions.DELETE_LOCATION_VERSION,
        id
    }),
    
};
export default actions;
