import moment from "moment";
import actions from './actions';
import {defaultGoal, defaultDatasetProperties} from './../../containers/Reports/Campaign/charts/charts'

const initState = {
  goal: {},
  goals: [],
  goalTemplates: [],
  charts: {},
  queueGoalPatch: [],
  isFetchingGoalTemplates: false,
  isFetchingChart: [],
  isFetchingGoals: false,
};


export default function campaignChartsReducer(state = initState, action) {
  switch (action.type) {

    case actions.FETCH_GOALS:
      return {
        ...state,
        isFetchingGoals: true
      };

    case actions.RECEIVE_GOALS:
      let receivedGoals = [...state.goals, ...action.goals].map(goal => {
        const {source_fields_mapping, dataset_properties} = goal;
        let props = {};
        Object.keys(source_fields_mapping).forEach(datasetIndex => {
          props = {
            ...props,
            ...(dataset_properties[datasetIndex] ?
                {[datasetIndex]: {...defaultDatasetProperties, ...dataset_properties[datasetIndex]}} :
                {[datasetIndex]: defaultDatasetProperties})
          }
        });
        return {...goal, dataset_properties: props}
      });
      return {
        ...state,
        goals: receivedGoals,
        isFetchingGoals: false
      };

    case actions.ADD_GOAL:
      return {
        ...state,
        goals: [
          ...state.goals,
          {
            ...defaultGoal,
            name: 'New chart',
            time_identifier: (new Date).getTime(),
            ...action.properties
          }
        ]
      };

    case actions.REMOVE_GOAL:
      let removeGoal = state.goals.filter(goal => (goal.time_identifier !== undefined) ? goal.time_identifier !== action.goal.time_identifier : true );
      return {
        ...state,
        goals: removeGoal
      };

    case actions.RECEIVE_REMOVE_GOAL:
      let removedGoal = state.goals.filter(goal => goal.id !== action.goal.id );
      return {
        ...state,
        goals: removedGoal
      };

    case actions.RECEIVE_PATCH_GOAL:
      let patchGoal = state.goals.findIndex(findGoal => findGoal.id === action.prevGoal.id);
      state.goals[patchGoal] = action.goal;
      return {
        ...state,
        goals: [
          ...state.goals
        ]
      };

    case actions.INITIAL_UPDATE_GOAL:
      let updateGoal = state.goals.findIndex(findGoal => findGoal.id === action.goal.id);
      state.goals[updateGoal] = action.goal;
      let replaceQueue = state.queueGoalPatch.filter(goal => goal.id !== action.goal.id);
      replaceQueue.push(action.goal);
      return {
        ...state,
        goals: [
            ...state.goals
        ],
        queueGoalPatch: [
            ...replaceQueue
        ]
      };

    case actions.RECEIVE_PATCH_MULTIPLE_GOAL:
      return {
        ...state,
        queueGoalPatch: []
      };

    case actions.FETCH_GOAL_TEMPLATES:
      return {
        ...state,
        isFetchingGoalTemplates: true
      };

    case actions.FETCH_GOAL_TEMPLATES_ERROR:
      return {
        ...state,
        isFetchingGoalTemplates: false,
        goalTemplates: initState.goalTemplates
      };

    case actions.RECEIVE_GOAL_TEMPLATES:
      return {
        ...state,
        isFetchingGoalTemplates: false,
        goalTemplates: action.goalTemplates
      };

    case actions.FETCH_SOURCES_CHARTS:
    case actions.FETCH_CHARTS:
      if(state.isFetchingChart.includes(action.goalId)) return state;
      return {
        ...state,
        isFetchingChart: [
            ...state.isFetchingChart,
            action.goalId
        ]
      };

    case actions.RECEIVE_SOURCES_CHARTS:
    case actions.RECEIVE_CHARTS:
      const {goalId} = action;
      let fetch = state.isFetchingChart.filter(goal => goal != goalId);
      return {
        ...state,
        isFetchingChart: fetch,
        charts: {
          ...state.charts,
          [goalId]: action.charts
        }
      };

    case actions.RECEIVE_CREATED_CHART:
      const {prevGoal, goal} = action;
      let refreshGoal = state.goals.findIndex(findGoal => findGoal.time_identifier === prevGoal.time_identifier);
      state.goals[refreshGoal] = goal;
      return {
        ...state,
        goals: [
            ...state.goals
        ]
      };

    case actions.PATCH_GOAL_LOCAL:
      return {
        ...state,
        goals: [
            ...state.goals.filter(goal=>goal.id !== action.goal.id),
            action.goal
        ]
      };

    case actions.PATCH_CHARTS_LOCAL:
      let chartsReduce = Object.assign({}, state.charts);
      delete chartsReduce[action.goal.id];
      return {
        ...state,
        charts: Object.assign([],
            chartsReduce,
            {[action.goal.id]: action.chart}
            )
      };

    case actions.CLEAR_STATE:
      return {
        ...initState,
        goalTemplates: state.goalTemplates
      };

    default:
      return state;
  }
}
