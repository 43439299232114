import React, { Component } from 'react';
import {connect} from "react-redux";
import { Chart } from 'frappe-charts/dist/frappe-charts.esm.js'
import 'frappe-charts/dist/frappe-charts.min.css'
import chartsActions from "../../../../redux/campaignCharts/actions";
import Loader from "../../../../components/uielements/loader";
import './../../../App/global.css';
import {StyledChartsWrapper} from "./charts.style";
import {X_AXIS_FORMAT_SHORTEN, X_AXIS_FORMAT_HIDING, getXaxisFormatId} from "./charts";

const basicData = {
  labels: [
    '12am-3am',
    '3am-6am',
    '6am-9am',
    '9am-12pm',
    '12pm-3pm',
    '3pm-6pm',
    '6pm-9pm',
    '9pm-12am'
  ],
  
  datasets: [
    {
      title: 'Some Data',
      color: 'light-blue',
      values: [25, 40, 30, 35, 8, 52, 17, -4]
    },
    {
      title: 'Another Set',
      color: 'violet',
      values: [25, 50, -10, 15, 18, 32, 27, 14]
    },
    {
      title: 'Yet Another',
      color: 'blue',
      values: [15, 20, -3, -15, 58, 12, -17, 37]
    }
  ]
};

const basicConfig = {
  height: 350,
  format_tooltip_x: d => (d + '').toUpperCase(),
  format_tooltip_y: d => d + ' pts'
};

const defaultData = {
  labels: [],
  datasets: [
    {
      title: 'Some Data',
      values: []
    },
  ],
};

const defaultAxisOpts = {
  yAxisMode: 'span',
  xAxisMode: 'tick',
  xIsSeries: 0
};

const colors = [
  '#3498DB', '#16A085', '#2ECC71', '#F1C40F', '#F39C12',
  '#D35400', '#BDC3C7', '#7F8C8D', '#34495E', '#E74C3C',
  '#8E44AD', '#C0392B', '#9B59B6', '#85C1E9', '#73C6B6',
  '#F8C471', '#EDBB99', '#E5E7E9', '#85929E', '#7FB3D5',
  '#F9E79F', '#4A235A'
];

class LineChart extends Component
{

  constructor(props) {
    super(props);

    this.state = {
      data: {...defaultData},
      axisOptions: {...defaultAxisOpts},
    };
  }

  getAxisOptions = () => {
    const {xaxis_format} = this.props;
    switch (xaxis_format) {
      case getXaxisFormatId(X_AXIS_FORMAT_HIDING): return 1;
      case getXaxisFormatId(X_AXIS_FORMAT_SHORTEN):
      default: return 0;
    }
  };

  barChart = {
    header: 'Line Chart',
    title: '',
    parent: '#' + this.props.chartIndex,
    parentId: this.props.chartIndex,
    type: 'line',
    data: basicData,
    show_dots: 0,
    colors: colors,
    tooltipOptions: {
      formatTooltipX: d => (d + '').toUpperCase(),
      formatTooltipY: d => {},
    },
    heatline: 1,
    axisOptions: {
      ...defaultAxisOpts,
      xIsSeries: this.getAxisOptions()
    },
    region_fill: 1,
    ...basicConfig
  };
  
  componentDidMount()
  {
    const {goalId, fromDate, toDate, fetchChart} = this.props;

    if(goalId)
      fetchChart(goalId,fromDate,toDate);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot)
  {
    const {goalId, goalSourceFieldsMapping, goalDatasetNaming, type, timespan, val_goal, gain, published, xaxis_format, charts, isFetchingChart, fromDate, toDate, fetchChart, shouldExport} = this.props;

    if(prevProps.fromDate != fromDate || prevProps.toDate != toDate){
      fetchChart(goalId,fromDate,toDate);
    }

    if( prevProps.goalId !== goalId ||
        prevProps.goalSourceFieldsMapping !== goalSourceFieldsMapping ||
        prevProps.goalDatasetNaming !== goalDatasetNaming ||
        prevProps.type !== type ||
        prevProps.timespan !== timespan ||
        prevProps.val_goal !== val_goal ||
        prevProps.gain !== gain ||
        prevProps.published !== published ||
        prevProps.xaxis_format !== xaxis_format){
      fetchChart(goalId,fromDate,toDate);
    }

    if(goalId)
      if(charts !== prevProps.charts  && !isFetchingChart.includes(goalId))
      {
        this.redrawChart();
      }

    if(shouldExport === true && this.chartRef){
      this.chartRef.export();
    }
  }
  
  redrawChart = () => {
    const {data} = this.state;
    let {axisOptions} = this.state;
    const {goalId, charts} = this.props;

    if(charts.hasOwnProperty(goalId)){
      let newAxisOptions = {...defaultAxisOpts, xIsSeries: this.getAxisOptions()};
      let newData = this.parseFieldsAndDataset(charts[goalId]);

      data.labels = newData.labels;
      data.datasets = newData.datasets;
      data.yMarkers = newData.yMarkers;
      axisOptions = newAxisOptions;
    }

    this.setState({data, axisOptions}, () => {
      this.chartRef = new Chart(this.barChart.parent, {...this.barChart, data, axisOptions});
    })

  };

  parseFieldsAndDataset = (rawData) => {
    const {goalDatasetNaming, timespan, val_goal, datasetProperties} = this.props;

    if(this.isDataEmpty(rawData)) return {...defaultData};

    let datasets = [];
    let yMarkers = [];

    for(let dataset_index in rawData){
      datasets.push({
        name: `${goalDatasetNaming[dataset_index]}`,
        values: Object.values(rawData[dataset_index][timespan || 'days']).map(field_value => Number.isInteger(field_value) ? field_value : field_value.toFixed(2)),
        chartType: "line"
      })
    }

    for(let dataset_index in rawData){
      if(datasetProperties[dataset_index].goal){
        yMarkers.push({
          label: `${goalDatasetNaming[dataset_index]} goal`,
          value: parseInt(datasetProperties[dataset_index].goal),
          options: { labelPos: 'left' }
        })
      }
    }

    let newState = {
      ...this.state,
      data: {
        labels: Object.keys(rawData[Object.keys(rawData).shift()][timespan || 'days']),
        datasets: datasets,
        yMarkers,
      }
    };

    this.setState(newState);

    return newState.data;
  };

  isDataEmpty = (rawData) => {
    if(rawData.length < 1){
      this.setState({data: {...defaultData}});
      return true
    }
    return false
  };
  
  render() {
    const {isLoading} = this.props;

    return (
        <StyledChartsWrapper>
          <Loader visible={isLoading} text={'Chart is loading...'} />
          <div style={{minHeight:'355px', visibility: (isLoading ? 'hidden' : 'visible') }} id={this.barChart.parentId}/>
        </StyledChartsWrapper>
    )
  }
}

const {fetchChart} = chartsActions;

export default connect(
    state => ({
      isFetchingChart: state.CampaignCharts.isFetchingChart,
      charts: state.CampaignCharts.charts,
      fromDate: state.Campaigns.fromDate,
      toDate: state.Campaigns.toDate,
    }),
    { fetchChart },
    null, {forwardRef: true}
)(LineChart);