import actions from './actions';

const initState = { advertisers: [], advertiserModal: false, isCreating: false, isChanging: false, createdAdvertiser: null, isFetching: false};

export default function advertiserReducer(state = initState, action) {
  switch (action.type) {
    
    case actions.TOGGLE_ADVERTISER_MODAL:
      return {
        ...state,
        advertiserModal: action.boole
      };
      
    case actions.FETCH_ADVERTISERS:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.FETCH_ADVERTISERS_ERROR:
      return {
        ...state,
        isFetching: false
      };
    
    case actions.RECEIVE_ADVERTISERS:
      return {
        ...state,
        isFetching: false,
        advertisers: action.advertisers
      };
  
    case actions.CREATE_ADVERTISER:
      return {
        ...state,
        isCreating: true
      };
  
    case actions.CREATE_ADVERTISER_ERROR:
      return {
        ...state,
        isCreating: false
      };
  
    case actions.ADVERTISER_CREATED:
      return {
        ...state,
        isCreating: false,
        createdAdvertiser: action.createdAdvertiser
      };
      
    case actions.CHANGE_ADVERTISER:
      return {
        ...state,
        isChanging: true
      };
      
    case actions.ADVERTISER_SET:
      return {
        ...state,
        isChanging: false
      };
      
    default:
      return state;
  }
}
