import {all} from 'redux-saga/effects';
import advertisersSaga from './advertiser/saga';
import agreeSaga from './agree/saga';
import analysisSaga from './analysis/saga';
import authSagas from './auth/saga';
import calendarSaga from './calendar/saga';
import campaignSagas from './campaign/saga';
import clientSagas from './client/saga';
import csvTemplateSagas from './csvtemplates/saga';
import facebookSagas from './facebook/saga';
import adFormSagas from './adform/saga';
import facebookFormsSaga from './facebookForms/saga';
import googleSagas from './google/saga';
import groupsSaga from './groups/saga';
import customFieldSagas from './L_customfields/saga';
import leadSagas from './lead/saga';
import leadSourceSaga from './leadSource/saga';
import noteSagas from './notes/saga';
import prospectSagas from './prospect/saga';
import reportSagas from './reports/saga';
import sourceSagas from './source/saga';
import statusSagas from './status/saga';
import userSagas from './users/saga';
import offerTemplateSaga from './offerTemplates/saga';
import mailTemplatesSaga from './mailing/template/saga';
import messageCampaignSaga from './mailing/messageCampaign/saga';
import contactListSaga from './mailing/contactList/saga';
import verifiedEmailsSaga from './mailing/verifiedEmails/saga';
import contactsSaga from './mailing/contact/saga';
import googleLocationsSaga from './googleBusiness/locations/saga';
import doubleclickSaga from './doubleclick/saga';
import campaignChartsSaga from './campaignCharts/saga';
import googleLocationsCampaignsSaga from './googleBusiness/campaigns/saga';
import googleLocationsReviewsSaga from './googleBusiness/reviews/saga';
import googleLocationsVersionsSaga from './googleBusiness/versionedLocations/saga';
import locationGroupsSaga from './googleBusiness/locationGroups/saga';
import adPreviewSaga from './adPreview/saga';
import smsNamesSaga from './mailing/smsNames/saga';
import opposSaga from './opportunities/saga';
import leadsImportSaga from './leadsImport/saga';
import primaryAdAccountSaga from './primaryAdAccount/saga'
import contactSentMessagesSaga from './mailing/contactSentMessage/saga'
import activitySaga from './activity/saga';
import singleMessageSaga from './mailing/singleMessage/saga';

export default function* rootSaga(getState)
{
  yield all([
    authSagas(),
    statusSagas(),
    noteSagas(),
    prospectSagas(),
    campaignSagas(),
    sourceSagas(),
    leadSourceSaga(),
    clientSagas(),
    facebookSagas(),
    adFormSagas(),
    googleSagas(),
    csvTemplateSagas(),
    reportSagas(),
    userSagas(),
    customFieldSagas(),
    leadSagas(),
    analysisSaga(),
    calendarSaga(),
    advertisersSaga(),
    groupsSaga(),
    facebookFormsSaga(),
    agreeSaga(),
    offerTemplateSaga(),
    mailTemplatesSaga(),
    messageCampaignSaga(),
    contactListSaga(),
    verifiedEmailsSaga(),
    contactsSaga(),
    googleLocationsSaga(),
    doubleclickSaga(),
    campaignChartsSaga(),
    googleLocationsCampaignsSaga(),
    googleLocationsReviewsSaga(),
    googleLocationsVersionsSaga(),
    locationGroupsSaga(),
    adPreviewSaga(),
    smsNamesSaga(),
    opposSaga(),
    leadsImportSaga(),
    primaryAdAccountSaga(),
    contactSentMessagesSaga(),
    activitySaga(),
    singleMessageSaga(),
  ]);
}
