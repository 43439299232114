import { all, takeEvery, put, fork } from 'redux-saga/effects';
import notifications from "../../components/feedback/notification";
import actions from './actions';
import ReportFetch from "../../helpers/reportFetch";


export function* fetchCampaigns() {
  yield takeEvery('FETCH_CAMPAIGNS', function*() {
    const apiResponse = yield ReportFetch.get('campaigns', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_CAMPAIGNS,
        campaigns: apiResponse.data
      });
      return;
    }
  
    yield put({
      type: actions.FETCH_CAMPAIGNS_ERROR,
      errorMessage: apiResponse.message
    });
  });
}

export function* fetchCampaign() {
  yield takeEvery('FETCH_CAMPAIGN', function*(payload) {
    const {campaignId} = payload;
    const apiResponse = yield ReportFetch.get('campaigns/'+campaignId, null)
      .then(response => (response))
      .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_CAMPAIGN,
        campaign: apiResponse.data
      });
      return;
    }

    alert(apiResponse.message);
    yield put({
      type: actions.FETCH_CAMPAIGN_ERROR,
      errorMessage: apiResponse.message
    });
  });
}

export function* deleteCampaign() {
  yield takeEvery('DELETE_CAMPAIGN', function*(payload) {

    const {campaignId} = payload;
    const resp = yield ReportFetch.delete('campaigns/' + campaignId, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.CAMPAIGN_DELETED,
        campaignId
      });
      yield put({
        type: actions.FETCH_CAMPAIGNS
      });
      return;
    }
    

    yield put({
      type: actions.DELETE_CAMPAIGN_ERROR,
      errorMessage: resp.message
    });
  });
}


export function* createCampaign() {
  yield takeEvery('CREATE_CAMPAIGN', function*(payload) {
    const {campaignValues} = payload;
    
    let startDate = campaignValues.start_finish[0].utc().format('YYYY-MM-DD');
    let endDate = campaignValues.start_finish[1].utc().format('YYYY-MM-DD');
    
    let campaign = {
      name: campaignValues.title,
      unique: campaignValues.unique,
      group_ids: campaignValues.group_ids,
      budget: campaignValues.budget,
      margin: campaignValues.margin,
      startDate: startDate,
      endDate: endDate,
    };
    
    const resp = yield ReportFetch.post('campaigns', campaign)
      .then(response => (response))
      .catch(error => ({error}));

    if(resp.status === 200)
    {

      yield put({
        type: actions.CAMPAIGN_CREATED,
        createdCampaign: resp.data
      });
      yield put({
        type: actions.FETCH_CAMPAIGNS
      });
      return;
    }

    let errors = [];
    resp.data.data.map((error) => {
      notifications['error']({
        message: 'Campaign creation error!',
        description: error.message
      });
      if(error.hasOwnProperty('error_code')){
        switch (error.error_code) {
          case 'NOT_UNIQUE_IDENTIFIER':{
            errors.push('unique');
            break;
          }
        }
      }
    });

    yield put({
      type: actions.CREATE_CAMPAIGN_ERROR,
      errors
    });
  });
}

export function* asyncCreateCampaign() {
  yield takeEvery(actions.ASYNC_CREATE_CAMPAIGN, function*(payload) {
    const {campaignValues, resolve, reject} = payload;

    let startDate = campaignValues.start_finish[0].utc().format('YYYY-MM-DD');
    let endDate = campaignValues.start_finish[1].utc().format('YYYY-MM-DD');

    let campaign = {
      name: campaignValues.title,
      unique: campaignValues.unique,
      group_ids: campaignValues.group_ids,
      budget: campaignValues.budget,
      margin: campaignValues.margin,
      startDate: startDate,
      endDate: endDate,
    };

    const resp = yield ReportFetch.post('campaigns', campaign)
        .then(response => (response))
        .catch(error => ({error}));

    if(resp.status === 200)
    {
      return resolve(resp.data);
    }

    yield put({
      type: actions.CREATE_CAMPAIGN_ERROR
    });
    return reject(resp.data);
  });
}

export function* patchCampaign() {
  yield takeEvery('PATCH_CAMPAIGN', function*(payload) {
    const {campaign, campaignValues} = payload;
    
    const resp = yield ReportFetch.PATCH('campaigns/' + campaign.id, campaignValues)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.RECEIVE_CAMPAIGN,
        campaign: resp.data
      });
  
      // yield put({
      //   type: actions.CAMPAIGN_PATCHED
      // });
      return;
    }

    let errors = [];
    resp.data.data.map((error) => {
      notifications['error']({
        message: 'Campaign updating error!',
        description: error.message
      });
      if(error.hasOwnProperty('error_code')){
        switch (error.error_code) {
          case 'NOT_UNIQUE_IDENTIFIER':{
            errors.push('unique');
            break;
          }
        }
      }
    });

    yield put({
      type: actions.PATCH_CAMPAIGN_ERROR,
      errors,
      campaign
    });
  });
}

export function* patchCampaignSettings() {
  yield takeEvery(actions.PATCH_CAMPAIGN_SETTINGS, function*(payload) {
    const {campaign, settings} = payload;
    
    const resp = yield ReportFetch.PATCH('campaigns/' + campaign.id + '/settings', settings)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.CAMPAIGN_SETTINGS_PATCHED,
        settings: resp.data.data
      });
      return;
    }
    
    yield put({
      type: actions.PATCH_CAMPAIGN_SETTINGS_ERROR,
      errorMessage: resp.message
    });
  });
}

export function* cloneCampaign() {
  yield takeEvery(actions.CLONE_CAMPAIGN, function*(payload) {
    const {campaignId} = payload;
    
    const resp = yield ReportFetch.post('campaigns/' + campaignId + '/clone', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.CAMPAIGN_CLONED,
        clonedCampaign: resp.data.data
      });
      yield put({
        type: actions.FETCH_CAMPAIGNS
      });
      return;
    }

    yield put({
      type: actions.CLONE_CAMPAIGN_ERROR,
      errorMessage: resp.message
    });
  });
}

export function* notifyCampaignDepartments() {
  yield takeEvery(actions.NOTIFY_CAMPAIGN_DEPARMENTS, function*(payload) {
    const {campaign} = payload;
    const apiResponse = yield ReportFetch.post(`campaigns/${campaign.id}/notify`, null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.CAMPAIGN_DEPARMENTS_NOTIFIED
      });
      notifications['success']({
        message: 'Notified!',
        description: 'All department members were notified about published campaign.'
      });
      return;
    }
  
    notifications['error']({
      message: 'Server error!',
      description: 'We could not notify members about campaign.'
    });
    yield put({
      type: actions.NOTIFY_CAMPAIGN_DEPARMENTS_ERROR
    });
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchCampaigns),
    fork(deleteCampaign),
    fork(createCampaign),
    fork(asyncCreateCampaign),
    fork(cloneCampaign),
    fork(fetchCampaign),
    fork(patchCampaign),
    fork(patchCampaignSettings),
    fork(notifyCampaignDepartments),
  ]);
}
