import actions from './actions';

const initState = {
  csvTemplates: [],
  isFetching: false,
  isCreatingCsvTemplate: false,
  isDeletingCsvTemplate: false,
};

export default function csvTemplatesReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.FETCH_CSV_TEMPLATES:
      return {
        ...state,
        isFetching: true,
      };
    
    case actions.FETCH_CSV_TEMPLATES_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage
      };
    
    case actions.RECEIVE_CSV_TEMPLATES:
      return {
        ...state,
        isFetching: false,
        csvTemplates: action.csvTemplates
      };

    case actions.CREATE_CSV_TEMPLATE:
      return {
        ...state,
        isCreatingCsvTemplate: true
      };

    case actions.CSV_TEMPLATE_CREATED:
    case actions.CREATE_CSV_TEMPLATE_ERROR:
      return {
        ...state,
        isCreatingCsvTemplate: false
      };

    case actions.DELETE_CSV_TEMPLATE:
      return {
        ...state,
        isDeletingCsvTemplate: true
      };

    case actions.CSV_TEMPLATE_DELETED:
    case actions.DELETE_CSV_TEMPLATE_ERROR:
      return {
        ...state,
        isDeletingCsvTemplate: false
      };

    default:
      return state;
  }
}
