import actions from './actions';

const initState = {
  isFetching: false,
  verifiedEmails: []
};

export default function verifiedEmailsReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
  
    case actions.FETCH_VERIFIED_EMAILS:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.FETCH_VERIFIED_EMAILS_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.RECEIVE_VERIFIED_EMAILS:
      return {
        ...state,
        isFetching: false,
        verifiedEmails: action.verifiedEmails
      };
    
    default:
      return state;
  }
}
