import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import ReportFetch from "../../helpers/reportFetch";


export function* fetchClients() {
  yield takeEvery('FETCH_CLIENTS', function*() {
    const apiResponse = yield ReportFetch.get('clients', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_CLIENTS,
        clients: apiResponse.data
      });
      return;
    }
  
    yield put({
      type: actions.FETCH_CLIENTS_ERROR,
      errorMessage: apiResponse.message
    });
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchClients),
    // fork(fetchProspectDataRequest),
  ]);
}
