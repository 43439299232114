import React, {Component} from "react";
import message from "./feedback/message";
import packageJson from '../../package.json';

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);
  
  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    
    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

export default class CacheBuster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...')
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function(names) {
            for (let name of names) caches.delete(name);
          });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
      }
    };
  }
  
  componentDidMount() {
    this.checkForUpdates();
    
    // setInterval(this.checkForUpdates, 60000);
    this.checkForUpdates();
  }
  
  checkForUpdates = () => {
    fetch('/appversion.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        // const currentVersion = "15.2.18";
        const currentVersion = packageJson.version;
      
        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          // console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          message.info(
            <p style={{display: 'inline-block', fontSize: 13, marginBottom: 0}}>New version available. Please reload application.&nbsp;
              <a href={"#"} onClick={() => {
                caches.keys().then(function(cacheNames) {
                  return Promise.all(
                    cacheNames.filter(function(cacheName) {
                      return true;
                    }).map(function(cacheName) {
                      return caches.delete(cacheName);
                    })
                  )
                    .then(() => {
                      window.location.reload(true);
                    });
                })
                // caches.delete().then(s => {
                //   window.location.reload(true)
                // });
              }}>reload</a>
            </p>, 0);
          // this.setState({ loading: false, isLatestVersion: false });
        } else {
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  };
  
  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}
