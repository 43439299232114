const actions = {

  FETCH_SMS_NAMES: 'FETCH_SMS_NAMES',
  FETCH_SMS_NAMES_ERROR: 'FETCH_SMS_NAMES_ERROR',
  RECEIVE_SMS_NAMES: 'RECEIVE_SMS_NAMES',

  fetchSmsNames: () => ({
    type: actions.FETCH_SMS_NAMES
  }),
  
};
export default actions;
