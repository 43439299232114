import actions from './actions';

const initState = {
  account: '',
  accounts: [],
  adAccount: '',
  adAccounts: [],
  campaign: null,
  campaigns: [],
  adGroups: [],
  isFetchingAccounts: false,
  isFetchingAdAccounts: false,
  isFetchingCampaigns: false,
  isFetchingAdGroups: false,
};

export default function googleReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
      
    case actions.FETCH_GOOGLE_ACCOUNTS:
      return {
        ...initState,
        isFetchingAccounts: true,
      };
  
    case actions.FETCH_GOOGLE_ACCOUNTS_ERROR:
      return {
        ...state,
        isFetchingAccounts: false,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_GOOGLE_ACCOUNTS:
      return {
        ...state,
        isFetchingAccounts: false,
        accounts: action.accounts,
      };
  
    case actions.FETCH_GOOGLE_ADACCOUNTS:
      return {
        ...state,
        isFetchingAdAccounts: true,
        account: action.account,
        campaign: initState.campaign,
        campaigns: initState.campaigns,
      };
  
    case actions.FETCH_GOOGLE_ADACCOUNTS_ERROR:
      return {
        ...state,
        isFetchingAdAccounts: false,
        account: null,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_GOOGLE_ADACCOUNTS:
      return {
        ...state,
        isFetchingAdAccounts: false,
        adAccounts: action.adAccounts
      };
    
    
    case actions.FETCH_GOOGLE_CAMPAIGNS:
      return {
        ...state,
        isFetchingCampaigns: true,
        adAccount: action.adAccount,
        campaign: null
      };
    
    case actions.FETCH_GOOGLE_CAMPAIGNS_ERROR:
      return {
        ...state,
        isFetchingCampaigns: false,
        campaign: null,
        adAccount: null,
        errorMessage: action.errorMessage
      };
    
    case actions.RECEIVE_GOOGLE_CAMPAIGNS:
      return {
        ...state,
        isFetchingCampaigns: false,
        campaigns: action.campaigns,
        campaign: null
      };
    
    case actions.SET_GOOGLE_CAMPAIGN:
      return {
        ...state,
        campaign: action.campaign
      };
    
    case actions.FETCH_GOOGLE_ADGROUPS:
      return {
        ...state,
        isFetchingAdGroups: true,
        campaign: action.campaign
      };
    
    case actions.FETCH_GOOGLE_ADGROUPS_ERROR:
      return {
        ...state,
        isFetchingAdGroups: false,
        campaign: null,
        errorMessage: action.errorMessage
      };
    
    case actions.RECEIVE_GOOGLE_ADGROUPS:
      return {
        ...state,
        isFetchingAdGroups: false,
        adGroups: action.adGroups
      };
    
    
    default:
      return state;
  }
}
