import moment from "moment";
import {getUrlAdIds} from "./parseAdId";

export const buildFilterQuery = (filters) =>
{
  let query = '';
  query = addLeadSourceQuery(filters, query);
  query = addCustomFieldQuery(filters, query);
  query = addAssignedUserQuery(filters, query);
  query = addStatusesQuery(filters, query);
  query = addBasicFieldQuery(filters, query);
  query = addAgreesQuery(filters, query);
  return query;
};

export const buildSearchQuery = (searchPhrase) =>
{
  return searchPhrase ? '&searchPhrase=' + encodeURIComponent(searchPhrase) : '';
};

export const buildDateRangeQuery = (startDate, endDate) =>
{
  let query = '';
  query += startDate ? '&fromDate=' + Math.round((startDate.toDate().getTime() / 1000)) : '';
  query += endDate ? '&toDate=' + Math.round((endDate.toDate().getTime() / 1000)) : '';
  return query;
};

export const getDefaultLogic = (filterType, customField) =>
{
  let logic = null;
  if(filterType == 'customField')
  {
    switch(customField.type){
      case 0:
        logic = 'isset';
        break;
        
      case 1:
        logic = 'equals';
        break;
        
      case 2:
        logic = 'greater';
        break;
        
      case 3:
        logic = 'Tak';
        break;
        
      case 4:
        logic = 'equals';
        break;
    }
  }
  
  if(filterType == 'status')
  {
    logic = 'latest';
  }
  
  if(filterType == 'basicField')
  {
    logic = 'equals';
  }
  return logic;
};

export const indexOfCustomFilter = (customField, customFilters) => {
  for(let i = 0; i < customFilters.length; i++)
  {
    if(customFilters[i].id === customField.id)
      return i;
  }
  return -1;
};

export const findPossibleFilters = (prospects) => {
  let possibleCauses = [];
  let possibleSources = [];
  
  prospects.map(prospect => {
    
    if (!possibleCauses.includes(prospect.cause))
    {
      possibleCauses.push(prospect.cause);
    }
    
    let sourceExist = false;
    for(let i = 0; i < possibleSources.length; i++)
    {
      if (possibleSources[i].id === prospect.original_source.id){
        sourceExist = true;
        break;
      }
    }
    
    if(!sourceExist && prospect.original_source !== undefined)
      possibleSources.push(prospect.original_source);
  });
  
  return {
    possibleSources,
    possibleCauses
  };
};

// const onSearch = () => {
//   let { searchText } = this.state;
//   console.log(this.state.prospects);
//   if(searchText === '')
//   {
//     this.setState({
//       dataList: this.state.prospects,
//       filterDropdownVisible: false,
//       filtered: false
//     });
//     return;
//   }
//
//   searchText = searchText.toUpperCase();
//   const dataList = this.state.dataList
//     .filter(data => {
//       if(data['email'].toUpperCase().includes(searchText))
//       {
//         return true;
//       }
//       return false;
//     });
//   this.setState({
//     dataList,
//     filterDropdownVisible: false,
//     filtered: false
//   });
// };

export const isApplyForFilters = (prospect, filters, allCustomFilters, searchPhrase = '') => {
  
  let prospectDate = new Date(prospect.last_activity_time);
  const {latest_custom_fields, latest_status, latest_assigned_user, prospect_has_basic_fields} = prospect;
  
  if(searchPhrase != '')
  {
    let foundEmailOrPhone = false;
    if(prospect.email && prospect.email.toUpperCase().includes(searchPhrase.toUpperCase()))
      foundEmailOrPhone = true;
    
    if(!foundEmailOrPhone)
    {
      for(let i = 0; i < prospect_has_basic_fields.length; i++)
      {
        if(prospect_has_basic_fields[i].value.includes(searchPhrase.toUpperCase()))
        {
          foundEmailOrPhone = true;
          break;
        }
      }
    }
    
    if(!foundEmailOrPhone)
      return false;
  }
  
  if(filters.startDate != null)
  {
    if(typeof filters.startDate == "string") filters.startDate = moment(filters.startDate);
    if(prospectDate.getTime() <= filters.startDate.toDate().getTime()) return false;
  }
  
  if(filters.endDate != null)
  {
    if(typeof filters.endDate == "string") filters.endDate = moment(filters.endDate);
    if(prospectDate.getTime() >= filters.endDate.toDate().getTime()) return false;
  }
  
  if(filters.original_source !== '')
  {
    let foundSource = false;
    for(let li = 0; li < prospect.leads.length; li++)
    {
      if(prospect.leads[li].lead_source.id == filters.original_source)
      {
        foundSource = true;
        break;
      }
    }
    
    if(!foundSource)
      return false;
    // if(prospect.original_source.id !== filters.original_source)
    // {
    //   return false;
    // }
  }
  
  if(filters.cause !== '')
  {
    if(prospect.cause !== filters.cause)
    {
      return false;
    }
  }
  
  let customFilters = allCustomFilters.filter(fil => {
    return fil.filterType == 'customField';
  });
  
  let statusFilters = allCustomFilters.filter(fil => {
    return fil.filterType == 'status';
  });
  
  let agreeFilters = allCustomFilters.filter(fil => {
    return fil.filterType == 'agree';
  });
  
  let userFilters = allCustomFilters.filter(fil => {
    return fil.filterType == 'assignedUser';
  });
  
  if(customFilters.length > 0)
  {
    if(latest_custom_fields.length > 0)
    {
      for(let i = 0; i < customFilters.length; i++)
      {
        let isValid = false;
        let customFilter = customFilters[i];
        
        if(customFilter.type == '1')
        {
          latest_custom_fields.forEach(latest_custom_field => {
            if(latest_custom_field.custom_field.id == customFilter.id)
            {
              if(latest_custom_field.value.includes(customFilter.customValue))
              {
                isValid = true;
              }
            }
          });
        }
        
        if(customFilter.type == '2')
        {
          // DATES
          latest_custom_fields.forEach(latest_custom_field => {
            if(latest_custom_field.custom_field.id == customFilter.id)
            {
              let prospectDate = moment(latest_custom_field.value);
              
              let {customValue} = customFilter;
              if(typeof customValue == "string") customValue = moment(customValue);
              
              if(customFilter.filterLogic == 'greater')
              {
                customValue.hours(0).minutes(0).seconds(0).milliseconds(0);
                if(prospectDate.toDate().getTime() >= customFilter.customValue.toDate().getTime())
                {
                  isValid = true;
                }
              }
              
              if(customFilter.filterLogic == 'lesser')
              {
                customValue.hours(23).minutes(59).seconds(59).milliseconds(999);
                if(prospectDate.toDate().getTime() <= customFilter.customValue.toDate().getTime())
                {
                  isValid = true;
                }
              }
            }
          });
        }
        
        if(customFilter.type == '3')
        {
          latest_custom_fields.forEach(latest_custom_field => {
            if(latest_custom_field.custom_field.id == customFilter.id)
            {
              if(latest_custom_field.value.includes(customFilter.filterLogic))
              {
                isValid = true;
              }
            }
          });
        }
        
        if(customFilter.type == '4')
        {
          latest_custom_fields.forEach(latest_custom_field => {
            if(latest_custom_field.custom_field.id == customFilter.id)
            {
              if(latest_custom_field.value.includes(customFilter.customValue))
              {
                isValid = true;
              }
            }
          });
        }
        
        if(!isValid) return false;
      }
    }
    else
    {
      return false;
    }
  }
  
  if(statusFilters.length > 0)
  {
    if(latest_status != false)
    {
      let isValid = false;
      for(let i = 0; i < statusFilters.length; i++)
      {
        if(latest_status.status.id == statusFilters[i].id)
        {
          isValid = true;
          break;
        }
      }
      
      if(!isValid)
        return false;
    }
    else
    {
      return false;
    }
  }
  
  if(userFilters.length > 0)
  {
    if(latest_assigned_user != false)
    {
      let isValid = false;
      for(let i = 0; i < userFilters.length; i++)
      {
        if(latest_assigned_user.user_id == userFilters[i].id)
        {
          isValid = true;
          break;
        }
      }
      
      if(!isValid)
        return false;
    }
    else
    {
      return false;
    }
  }
  
  return true;
};

export const addLeadSourceQuery = (filters, query) => {
  let leadSources = [];
  filters.forEach(filter => {
    if(filter.filterType == 'source')
      leadSources.push(filter.id);
  });
  // if(leadSources.length > 0)
  // {
  //   query = query + '&leadSources=' + JSON.stringify(leadSources);
  // }
  query += (leadSources.length > 0) ? '&leadSources=' + JSON.stringify(leadSources) : '';
  return query;
};

export const addCustomFieldQuery = (filters, query) => {
  let customFields = [];
  filters.forEach(filter => {
    
    if(filter.filterType == 'customField')
      customFields.push({
        id: filter.id,
        type: filter.type,
        logic: filter.filterLogic,
        value: filter.customValue
      });
  });
  // if(Object.keys(customFields).length > 0)
  // {
  //   query = query + '&customFields=' + JSON.stringify(customFields);
  // }
  query += (Object.keys(customFields).length > 0) ? '&customFields=' + JSON.stringify(customFields) : '';
  return query;
};

export const addAssignedUserQuery = (filters, query) => {
  let users = [];
  filters.forEach(filter => {
    if(filter.filterType == 'assignedUser')
      users.push(filter.id);
  });
  // if(users.length > 0)
  // {
  //   query = query + '&assignedUsers=' + JSON.stringify(users);
  // }
  query += (users.length > 0) ? '&assignedUsers=' + JSON.stringify(users) : '';
  return query;
};

export const addStatusesQuery = (filters, query) => {
  let statuses = [];
  filters.forEach(filter => {
    if(filter.filterType == 'status')
      statuses.push({statusId: filter.id, logic: filter.filterLogic});
  });
  // if(statuses.length > 0)
  // {
  //   query = query + '&statuses=' + JSON.stringify(statuses);
  // }
  query += (statuses.length > 0) ? '&statuses=' + JSON.stringify(statuses) : '';
  return query;
};

export const addAgreesQuery = (filters, query) => {
  let agrees = [];
  filters.forEach(filter => {
    if(filter.filterType == 'agree')
      agrees.push(filter.id);
  });
  // if(statuses.length > 0)
  // {
  //   query = query + '&statuses=' + JSON.stringify(statuses);
  // }
  query += (agrees.length > 0) ? '&agrees=' + JSON.stringify(agrees) : '';
  return query;
};

export const addBasicFieldQuery = (filters, query) => {
  let basicFields = {};
  filters.forEach(filter => {
    if(filter.filterType == 'basicField')
      basicFields[filter.id] = filter.customValue;
  });
  // if(Object.keys(basicFields).length > 0)
  // {
  //   query = query + '&basicFields=' + JSON.stringify(basicFields);
  // }
  query += (Object.keys(basicFields).length > 0) ? '&basicFields=' + JSON.stringify(basicFields) : '';
  return query;
};

export const buildSegmentationDataQuery = (segmentation) => {
  return (segmentation.length || !(segmentation instanceof Array)) ? '&segmentData=' + JSON.stringify(segmentation) : ''
};

export const buildAdIdQuery = () => {
  const filteringAdIdsWithUrl = getUrlAdIds();
  if(filteringAdIdsWithUrl.length < 1)
    return "";
  
  return "&fbAdIds=" +JSON.stringify(filteringAdIdsWithUrl);
};
