import React from "react";
import { polyfill } from 'es6-promise';
import { Provider } from "react-redux";
import { store, history } from "./redux/store";
import PublicRoutes from "./router";
import { ThemeProvider } from "styled-components";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import config, {
  getCurrentLanguage
} from "./containers/LanguageSwitcher/config";
import { themeConfig } from "./settings";
import DashAppHolder from "./dashAppStyle";
import Boot from "./redux/boot";
import CacheBuster from "./components/cacheBuster";
import ApiErrorHandler from './components/apiErrorHandler';
import LogRocket from 'logrocket';
import packageJson from '../package.json';

polyfill();

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // log rocket disabled on production
}
else
{
  LogRocket.init('zlacgn/bitetrack-app-prod');
  
  try {
    const userss = JSON.parse(localStorage.getItem('user'));
    LogRocket.identify(userss.id, {
      email: userss.email,
      appVersion: packageJson.version,
      advertiserId: localStorage.getItem('advertiser_id'),
    });
    
  }
  catch(e) {
  
  }
}

const currentAppLocale =
  AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];

const DashApp = () => (
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        // You can decide how and when you want to force reload
        refreshCacheAndReload();
      }
      
      return (
        <ConfigProvider locale={currentAppLocale.antd}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <ThemeProvider theme={themes[themeConfig.theme]}>
              <DashAppHolder>
                <Provider store={store}>
                  <ApiErrorHandler>
                    <PublicRoutes history={history} />
                  </ApiErrorHandler>
                </Provider>
              </DashAppHolder>
            </ThemeProvider>
          </IntlProvider>
        </ConfigProvider>
      );
    }}
  </CacheBuster>
);
Boot()
  .then(() => DashApp())
  .catch(error => console.error(error));

export default DashApp;
export { AppLocale };
