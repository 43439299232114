import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
import notifications from '../../components/feedback/notification';
import { push } from 'connected-react-router';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard')),
  },
  {
    path: 'admin',
    component: asyncComponent(() => import('../administer')),
  },
  {
    path: 'blankPage',
    component: asyncComponent(() => import('../blankPage')),
  },
  {
    path: 'new-prospect/:edit_email/:edit_source/:edit_id',
    permission: 'LeadsProspects',
    component: asyncComponent(() => import('../Leads/Prospect/create')),
  },
  {
    path: 'new-prospect',
    permission: 'LeadsProspects',
    component: asyncComponent(() => import('../Leads/Prospect/create')),
  },
  {
    path: 'import',
    permission: 'LeadsProspects',
    component: asyncComponent(() => import('../Leads/Import/SheetImport')),
  },
  {
    path: 'prospects/filter-by-adid/:ad_ids',
    permission: 'LeadsProspects',
    component: asyncComponent(() => import('../Leads/Prospect/List')),
  },
  {
    path: 'prospects/:prospect_id',
    permission: 'LeadsProspects',
    component: asyncComponent(() => import('../Leads/Prospect/single')),
  },
  {
    path: 'prospects',
    permission: 'LeadsProspects',
    component: asyncComponent(() => import('../Leads/Prospect/List')),
  },
  {
    path: 'analysis/:report_id',
    permission: 'LeadsProspects',
    component: asyncComponent(() => import('../Leads/Analysis/report')),
  },
  {
    path: 'analysis',
    permission: 'LeadsProspects',
    component: asyncComponent(() => import('../Leads/Analysis/')),
  },
  {
    path: 'offers/templates/:template_id',
    permission: 'LeadsProspects',
    component: asyncComponent(() => import('../Leads/OfferTemplates/edit')),
  },
  {
    path: 'offers/templates',
    permission: 'LeadsProspects',
    component: asyncComponent(() => import('../Leads/OfferTemplates/')),
  },
  {
    path: 'schedule',
    permission: 'LeadsProspects',
    component: asyncComponent(() => import('../Leads/Calendar/calendar')),
  },
  {
    path: 'lead-sources',
    permission: 'LeadsProspects',
    component: asyncComponent(() => import('../Leads/LeadSource/')),
  },
  {
    path: 'reports/campaigns',
    component: asyncComponent(() => import('../Reports/Campaign')),
  },
  {
    path: 'reports',
    component: asyncComponent(() => import('../Reports/Campaign')),
  },
  {
    path: 'authCheck',
    component: asyncComponent(() => import('../AuthCheck')),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style, user } = this.props;
    const {permissions} = user;

    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, permission, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}

              render={(props) => {
                if(permission)
                {
                  if(!permissions || !permissions[permission])
                  {
                    notifications['warning']({
                      message: 'Not allowed',
                      description: 'You have no permission to that resource.'
                    });
                    return <Redirect to={'/'}/>
                  }
                }

                return <otherProps.component {...props} />;
              }}
              
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
