import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import LeadsFetch from "../../helpers/leadsFetch";
import notifications from "../../components/feedback/notification";

export function* fetchActivityRequest() {
  yield takeEvery(actions.FETCH_ACTIVITY, function*(payload) {
    const {activityType, filter} = payload;

    let params = {...filter};
    if(activityType)
      params.type = activityType;

    const apiResponse = yield LeadsFetch.get(`activity?${Object.entries(params).map(
        ([key, value]) => Array.isArray(value) 
            ? value.map(val => `${key}[]=${val}` ).join('&')
            : `${key}=${value}`
    ).join('&')}`, null)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.ACTIVITY_RECEIVED,
        activity: apiResponse.data.data
      });
      return;
    }

    yield put({
      type: actions.FETCH_ACTIVITY_ERROR
    });
  });
}

export function* createActivityRequest() {
  yield takeEvery(actions.CREATE_ACTIVITY, function*(payload) {
    const {activity} = payload;

    const apiResponse = yield LeadsFetch.post('activity', activity)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.ACTIVITY_CREATED,
        activity: apiResponse.data
      });
      return;
    }

    yield put({
      type: actions.CREATE_ACTIVITY_ERROR
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchActivityRequest),
    fork(createActivityRequest),
  ]);
}
