import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import notifications from '../../components/feedback/notification';
import LeadsFetch from "../../helpers/leadsFetch";

export function* fetchPages() {
  yield takeEvery(actions.FETCH_FACEBOOK_PAGES, function*(payload) {
    
    const {accountId} = payload;
    
    const apiResponse = yield LeadsFetch.get(`facebook/${accountId}/pages`, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_FACEBOOK_PAGES,
        pages: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_FACEBOOK_PAGES_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* fetchForms() {
  yield takeEvery(actions.FETCH_FACEBOOK_FORMS, function*(payload) {
    const {pageId, pageToken} = payload;
    const accountId = yield select((state) => state.FacebookForms.accountId);
    
    let query = `page_id=${pageId}&`;
    query += `page_token=${pageToken}&`;
    
    const apiResponse = yield LeadsFetch.get(`facebook/${accountId}/forms?${query}`, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_FACEBOOK_FORMS,
        forms: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_FACEBOOK_FORMS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* fetchTestLeads() {
  yield takeEvery(actions.FETCH_FACEBOOK_TEST_LEADS, function*(payload) {
    const {formId} = payload;
    const accountId = yield select((state) => state.FacebookForms.accountId);
    const pageToken = yield select((state) => state.FacebookForms.pageToken);
    
    let query = `form_id=${formId}&`;
    query += `page_token=${pageToken}&`;
    
    const apiResponse = yield LeadsFetch.get(`facebook/${accountId}/test-leads?${query}`, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_FACEBOOK_TEST_LEADS,
        testLeads: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_FACEBOOK_TEST_LEADS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchPages),
    fork(fetchForms),
    fork(fetchTestLeads),
  ]);
}
