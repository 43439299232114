import actions from './actions';

const initState = {
  isFetching: false,
  templates: [],
  template: null,
};

export default function mailTemplateReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.CREATE_MAIL_TEMPLATE:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.CREATE_MAIL_TEMPLATE_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.MAIL_TEMPLATE_CREATED:
      return {
        ...state,
        isFetching: false,
        template: action.template
      };
  
    case actions.UPDATE_MAIL_TEMPLATE:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.UPDATE_MAIL_TEMPLATE_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.MAIL_TEMPLATE_UPDATED:
      return {
        ...state,
        isFetching: false,
        template: action.template
      };
  
    case actions.FETCH_MAIL_TEMPLATES:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.RECEIVE_MAIL_TEMPLATES:
      return {
        ...state,
        isFetching: false,
        templates: action.templates
      };
  
    case actions.FETCH_MAIL_TEMPLATE:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.FETCH_MAIL_TEMPLATE_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.RECEIVE_MAIL_TEMPLATE:
      return {
        ...state,
        isFetching: false,
        template: action.template
      };
  
    case actions.SEND_TEST_EMAIL_TEMPLATE:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.SEND_TEST_EMAIL_TEMPLATE_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.TEST_EMAIL_TEMPLATE_SENT:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.DELETE_MAIL_TEMPLATE:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.DELETE_MAIL_TEMPLATE_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.MAIL_TEMPLATE_DELETED:
      return {
        ...state,
        isFetching: false
      };
      
      case actions.CLEAR_MAIL_TEMPLATE:
      return {
        ...state,
        template: initState.template
      };
  
    case actions.CLONE_MAIL_TEMPLATE:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.CLONE_MAIL_TEMPLATE_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.CLONE_MAIL_TEMPLATE_DONE:
      return {
        ...state,
        isFetching: false
      };
    
    default:
      return state;
  }
}
