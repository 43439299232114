import Auth from './auth/reducer';
import Agree from './agree/reducer';
import App from './app/reducer';
import Prospect from './prospect/reducer';
import Status from './status/reducer';
import Notes from './notes/reducer';
import LeadSource from './leadSource/reducer';
import Campaigns from './campaign/reducer';
import Sources from './source/reducer';
import Clients from './client/reducer';
import Facebook from './facebook/reducer';
import AdForm from './adform/reducer';
import Google from './google/reducer';
import CsvTemplate from './csvtemplates/reducer';
import Report from './reports/reducer';
import User from './users/reducer';
import CustomField from './L_customfields/reducer';
import ProspectFilter from './prospectFilter/reducer';
import Lead from './lead/reducer';
import Analysis from './analysis/reducer';
import Calendar from './calendar/reducer';
import Error from './error/reducer';
import Groups from './groups/reducer';
import Advertisers from './advertiser/reducer';
import FacebookForms from './facebookForms/reducer';
import OfferTemplates from './offerTemplates/reducer';
import MailTemplates from './mailing/template/reducer';
import MessageCampaigns from './mailing/messageCampaign/reducer';
import ContactLists from './mailing/contactList/reducer';
import VerifiedEmails from './mailing/verifiedEmails/reducer';
import Contacts from './mailing/contact/reducer';
import GoogleLocations from './googleBusiness/locations/reducer';
import DoubleClick from './doubleclick/reducer';
import CampaignCharts from './campaignCharts/reducer';
import LocationCampaigns from './googleBusiness/campaigns/reducer';
import LocationReviews from './googleBusiness/reviews/reducer';
import LocationVersions from './googleBusiness/versionedLocations/reducer';
import LocationGroups from './googleBusiness/locationGroups/reducer';
import AdPreview from "./adPreview/reducer";
import SmsNames from "./mailing/smsNames/reducer";
import Oppos from "./opportunities/reducer";
import LeadsImport from "./leadsImport/reducer";
import PrimaryAdAccount from "./primaryAdAccount/reducer";
import ContactSentMessages from "./mailing/contactSentMessage/reducer";
import Activity from "./activity/reducer";
import SingleMessage from "./mailing/singleMessage/reducer";

export default {
    Auth,
    Agree,
    App,
    Notes,
    Prospect,
    LeadSource,
    Status,
    Campaigns,
    Sources,
    Clients,
    Facebook,
    AdForm,
    Google,
    CsvTemplate,
    Report,
    User,
    CustomField,
    Lead,
    ProspectFilter,
    Analysis,
    Calendar,
    Error,
    Groups,
    Advertisers,
    FacebookForms,
    OfferTemplates,
    MailTemplates,
    MessageCampaigns,
    ContactLists,
    VerifiedEmails,
    Contacts,
    GoogleLocations,
    DoubleClick,
    CampaignCharts,
    LocationCampaigns,
    LocationReviews,
    LocationVersions,
    LocationGroups,
    AdPreview,
    SmsNames,
    Oppos,
    LeadsImport,
    PrimaryAdAccount,
    ContactSentMessages,
    Activity,
    SingleMessage,
};
