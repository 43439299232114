const actions = {
  
  // CREATE_CONTACT_LIST: 'CREATE_CONTACT_LIST',
  // CREATE_CONTACT_LIST_ERROR: 'CREATE_CONTACT_LIST_ERROR',
  // CONTACT_LIST_CREATED: 'CONTACT_LIST_CREATED',
  //
  // UPDATE_CONTACT_LIST: 'UPDATE_CONTACT_LIST',
  // UPDATE_CONTACT_LIST_ERROR: 'UPDATE_CONTACT_LIST_ERROR',
  // CONTACT_LIST_UPDATED: 'CONTACT_LIST_UPDATED',
  //
  // FETCH_CONTACT_LISTS: 'FETCH_CONTACT_LISTS',
  // RECEIVE_CONTACT_LISTS: 'RECEIVE_CONTACT_LISTS',
  
  FETCH_VERIFIED_EMAILS: 'FETCH_VERIFIED_EMAILS',
  FETCH_VERIFIED_EMAILS_ERROR: 'FETCH_VERIFIED_EMAILS_ERROR',
  RECEIVE_VERIFIED_EMAILS: 'RECEIVE_VERIFIED_EMAILS',
  
  // SEND_TEST_CONTACT_LIST: 'SEND_TEST_CONTACT_LIST',
  // SEND_TEST_CONTACT_LIST_ERROR: 'SEND_TEST_CONTACT_LIST_ERROR',
  // TEST_CONTACT_LIST_SENT: 'TEST_CONTACT_LIST_SENT',
  //
  // DELETE_CONTACT_LIST: 'DELETE_CONTACT_LIST',
  // DELETE_CONTACT_LIST_ERROR: 'DELETE_CONTACT_LIST_ERROR',
  // CONTACT_LIST_DELETED: 'CONTACT_LIST_DELETED',
  //
  // CLEAR_CONTACT_LIST: 'CLEAR_CONTACT_LIST',

  fetchVerifiedEmails: () => ({
    type: actions.FETCH_VERIFIED_EMAILS
  }),
  
  // clearContactList: () => ({
  //   type: actions.CLEAR_CONTACT_LIST
  // }),
  //
  // fetchContactList: (contactListId) => ({
  //   type: actions.FETCH_CONTACT_LIST,
  //   contactListId
  // }),
  //
  // deleteContactList: (contactListId) => ({
  //   type: actions.DELETE_CONTACT_LIST,
  //   contactListId
  // }),
  //
  // createContactList: (contactList) => ({
  //   type: actions.CREATE_CONTACT_LIST,
  //   contactList
  // }),
  //
  // updateContactList: (contactList, contactListId) => ({
  //   type: actions.UPDATE_CONTACT_LIST,
  //   contactList,
  //   contactListId
  // }),
  
};
export default actions;
