import actions from './actions';

const initState = { customFields: [], isFetching: false, clearForm: false, updatingSettings: false, loaded: false};

export default function customFieldReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.FETCH_CUSTOM_FIELDS:
      return {
        ...state,
        isFetching: true,
        loaded: false,
      };
    
    case actions.RECEIVE_CUSTOM_FIELDS:
      return {
        ...state,
        isFetching: false,
        customFields: action.customFields,
        loaded: true
      };
  
    case actions.CREATE_CUSTOM_FIELD:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.CREATE_CUSTOM_FIELD_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.CUSTOM_FIELD_CREATED:
      return {
        ...state,
        isFetching: false,
        clearForm: !state.clearForm
      };
  
    case actions.DELETE_CUSTOM_FIELD:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.DELETE_CUSTOM_FIELD_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.CUSTOM_FIELD_DELETED:
      return {
        ...state,
        isFetching: false
      };

    case actions.UPDATE_CUSTOM_FIELD_SETTINGS:
      return {
        ...state,
        updatingSettings: true
      };

    case actions.UPDATE_CUSTOM_FIELD_SETTINGS_ERROR:
      return {
        ...state,
        updatingSettings: false
      };

    case actions.CUSTOM_FIELD_SETTINGS_UPDATED:
      return {
        ...state,
        updatingSettings: false
      };

    default:
      return state;
  }
}
