import {all, takeEvery, put, fork} from 'redux-saga/effects';
import actions from './actions';
import LeadsFetch from "../../helpers/leadsFetch";


export function* fetchLeadSources() {
  yield takeEvery(actions.FETCH_LEAD_SOURCES, function*() {
    const apiResponse = yield LeadsFetch.get('sources')
      .then(response => (response))
      .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_LEAD_SOURCES,
        leadSources: apiResponse.data.data
      });
      return;
    }
  
    yield put({
      type: actions.FETCH_ERROR_LEAD_SOURCES,
      errorMessage: apiResponse.data.error.message
    });
  });
}

export function* createLeadSource() {
  yield takeEvery(actions.CREATE_LEAD_SOURCE, function*(payload) {
    const {name, sourceType} = payload.values;
    const apiResponse = yield LeadsFetch.post('sources', {
      name,
      type: sourceType
    })
      .then(response => (response))
      .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.LEAD_SOURCE_CREATED
      });
      yield put({
        type: actions.FETCH_LEAD_SOURCES
      });
      return;
    }
  
    yield put({
      type: actions.CREATE_LEAD_SOURCE_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* patchLeadSource() {
  yield takeEvery(actions.PATCH_LEAD_SOURCE, function*(payload) {
    const {additional_mapping} = payload;
    const {id, name, assign_user, sourceType} = payload.values;
    const apiResponse = yield LeadsFetch.PATCH('sources/' + id, {
      name,
      type: sourceType,
      assign_user,
      additional_mapping
    })
      .then(response => (response))
      .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.LEAD_SOURCE_PATCHED
      });
      yield put({
        type: actions.FETCH_LEAD_SOURCES
      });
    }
    else
    {
      yield put({
        type: actions.PATCH_LEAD_SOURCE_ERROR,
        errorMessage: apiResponse.data.message
      });
    }
  });
}

export function* deleteLeadSource() {
  yield takeEvery(actions.DELETE_LEAD_SOURCE, function*(payload) {
    const {id} = payload;
    const apiResponse = yield LeadsFetch.delete('sources/' + id)
      .then(response => (response))
      .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.LEAD_SOURCE_DELETED
      });
      yield put({
        type: actions.FETCH_LEAD_SOURCES
      });
    }
    else
    {
      yield put({
        type: actions.DELETE_LEAD_SOURCE_ERROR,
        errorMessage: apiResponse.data.message
      });
    }
  });
}

export function* createReceipt() {
  yield takeEvery(actions.CREATE_LEAD_SOURCE_RECEIPT, function*(payload) {
    const {from_name, content, from_email_id} = payload.values;
    const apiResponse = yield LeadsFetch.post(`sources/${payload.sourceId}/receipts`, {
      from_name, content, from_email_id
    })
        .then(response => (response))
        .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.LEAD_SOURCE_RECEIPT_CREATED
      });
      yield put({
        type: actions.FETCH_LEAD_SOURCES
      });
      return;
    }
    
    yield put({
      type: actions.CREATE_LEAD_SOURCE_RECEIPT_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* patchReceipt() {
  yield takeEvery(actions.PATCH_LEAD_SOURCE_RECEIPT, function*(payload) {
    const {from_name, content, from_email_id} = payload.values;
    const apiResponse = yield LeadsFetch.PATCH(`sources/${payload.sourceId}/receipts/${payload.receiptId}`, {
      from_name, content, from_email_id
    })
        .then(response => (response))
        .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.LEAD_SOURCE_RECEIPT_PATCHED
      });
      yield put({
        type: actions.FETCH_LEAD_SOURCES
      });
      return;
    }
    
    yield put({
      type: actions.PATCH_LEAD_SOURCE_RECEIPT_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* deleteReceipt() {
  yield takeEvery(actions.DELETE_LEAD_SOURCE_RECEIPT, function*(payload) {
    const apiResponse = yield LeadsFetch.delete(`sources/${payload.sourceId}/receipts`, null)
        .then(response => (response))
        .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.LEAD_SOURCE_RECEIPT_DELETED
      });
      yield put({
        type: actions.FETCH_LEAD_SOURCES
      });
      return;
    }
    
    yield put({
      type: actions.DELETE_LEAD_SOURCE_RECEIPT_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchLeadSources),
    fork(createLeadSource),
    fork(patchLeadSource),
    fork(deleteLeadSource),
    fork(createReceipt),
    fork(patchReceipt),
    fork(deleteReceipt),
  ]);
}
