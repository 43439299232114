import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import DeliveryFetch from "../../../helpers/deliveryFetch";
import notifications from "../../../components/feedback/notification";

export function* fetchTemplates() {
  yield takeEvery(actions.FETCH_CONTACT_LISTS, function*() {
    
    const resp = yield DeliveryFetch.get('contactslists', null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.RECEIVE_CONTACT_LISTS,
        contactLists: resp.data.data
      });
      // return;
    }
    
    // yield put({
    //   type: actions.CREATE_CONTACT_LIST_ERROR,
    //   errorMessage: resp.error
    // });
  });
}

export function* fetchTemplate() {
  yield takeEvery(actions.FETCH_CONTACT_LIST, function*(payload) {
    const {contactListId} = payload;
    
    const resp = yield DeliveryFetch.get('contactslists/' + contactListId, null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      let contactList = resp.data.data;
      contactList.contacts = resp.data.contacts;
      yield put({
        type: actions.RECEIVE_CONTACT_LIST,
        contactList: contactList,
        count: resp.data.total_contacts,
      });
  
      yield put({
        type: 'FETCH_CONTACTS'
      });
      
      return;
    }
    
    yield put({
      type: actions.FETCH_CONTACT_LIST_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* createTemplate() {
  yield takeEvery(actions.CREATE_CONTACT_LIST, function*(payload) {
    const {contactList} = payload;
    
    const resp = yield DeliveryFetch.post('contactslists', contactList)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      notifications['success']({
        message: 'OK!',
        description: 'Audience created.'
      });
      yield put({
        type: actions.CONTACT_LIST_CREATED,
        contactList: resp.data.data
      });
      yield put({
        type: actions.FETCH_CONTACT_LISTS,
      });
      return;
    }
  
    notifications['error']({
      message: 'Server error!',
      description: resp.message
    });
    yield put({
      type: actions.CREATE_CONTACT_LIST_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* updateTemplate() {
  yield takeEvery(actions.UPDATE_CONTACT_LIST, function*(payload) {
    const {contactList, contactListId} = payload;
    
    const resp = yield DeliveryFetch.PATCH('contactslists/' + contactListId, contactList)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      notifications['success']({
        message: 'OK!',
        description: 'Audience updated.'
      });
      yield put({
        type: actions.CONTACT_LIST_UPDATED,
        contactList: resp.data.data
      });
      return;
    }
    
    notifications['error']({
      message: 'Server error!',
      description: resp.message
    });
    yield put({
      type: actions.UPDATE_CONTACT_LIST_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* deleteTemplate() {
  yield takeEvery(actions.DELETE_CONTACT_LIST, function*(payload) {
    const {contactListId} = payload;
    
    const resp = yield DeliveryFetch.delete('contactslists/' + contactListId, null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.FETCH_CONTACT_LISTS,
      });
      return;
    }
    
    yield put({
      type: actions.DELETE_CONTACT_LIST_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* importContactsToList() {
  yield takeEvery(actions.IMPORT_CONTACTS_TO_LIST, function*(payload) {
    const {contactsArray, contactListId, routerHistory} = payload;
    
    const resp = yield DeliveryFetch.post('contact-list-request/' + contactListId, {contacts: contactsArray})
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      notifications['success']({
        message: 'Import started!',
        description: 'Wait until contacts are imported.'
      });
      yield put({
        type: actions.CONTACTS_IMPORTED_TO_LIST
      });
      routerHistory.push('/delivery/audiences')
      yield put({
        type: actions.FETCH_CONTACT_LISTS
      });
      return;
    }
    
    notifications['error']({
      message: 'Server error!',
      description: resp.message
    });
    yield put({
      type: actions.IMPORT_CONTACTS_TO_LIST_ERROR,
      errorMessage: resp.error
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(createTemplate),
    fork(fetchTemplates),
    fork(fetchTemplate),
    fork(updateTemplate),
    fork(deleteTemplate),
    fork(importContactsToList),
  ]);
}
