import actions from './actions';

const initState = { clients: [], isFetching: false, };

// const FindCampaignIndex = (campaignId, campaigns) => {
//   return campaigns.findIndex(campaign => {
//     return campaign.id === campaignId;
//   });
// };
//
// const FindCampaign = (campaignId, campaigns) => {
//   return campaigns.find(campaign => {
//     return campaign.id === campaignId;
//   });
// };

export default function campaignReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
  
    case actions.FETCH_CLIENTS:
      return {
        ...state,
        isFetching: true
      };
      
    case actions.FETCH_CLIENTS_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_CLIENTS:
      return {
        ...state,
        isFetching: false,
        clients: action.clients
      };
    
    default:
      return state;
  }
}
