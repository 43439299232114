const actions = {
  
  CREATE_LEAD: 'CREATE_LEAD',
  CREATE_LEAD_ERROR: 'CREATE_LEAD_ERROR',
  LEAD_CREATED: 'LEAD_CREATED',
  
  // CLEAR_LEAD_FORM: 'CLEAR_LEAD_FORM',
  LEAD_FORM_CLEARED: 'LEAD_FORM_CLEARED',
  
  SET_LEAD_FORM_EMAIL: 'SET_LEAD_FORM_EMAIL',
  SET_LEAD_FORM_LEAD_SOURCE: 'SET_LEAD_FORM_LEAD_SOURCE',
  SET_LEAD_FORM_CAUSE: 'SET_LEAD_FORM_CAUSE',
  
  ADD_LEAD_FORM_BASIC_FIELD: 'ADD_LEAD_FORM_BASIC_FIELD',
  SET_LEAD_FORM_BASIC_FIELD: 'SET_LEAD_FORM_BASIC_FIELD',
  REMOVE_LEAD_FORM_BASIC_FIELD: 'REMOVE_LEAD_FORM_BASIC_FIELD',
  
  ADD_LEAD_FORM_CUSTOM_FIELD: 'ADD_LEAD_FORM_CUSTOM_FIELD',
  SET_LEAD_FORM_CUSTOM_FIELD: 'SET_LEAD_FORM_CUSTOM_FIELD',
  REMOVE_LEAD_FORM_CUSTOM_FIELD: 'REMOVE_LEAD_FORM_CUSTOM_FIELD',
  
  ADD_LEAD_FORM_AGREE: 'ADD_LEAD_FORM_AGREE',
  REMOVE_LEAD_FORM_AGREE: 'REMOVE_LEAD_FORM_AGREE',

  FETCH_LEAD_TO_CAMPAIGN_IDENTIFIERS: 'FETCH_LEAD_TO_CAMPAIGN_IDENTIFIERS',
  FETCH_LEAD_TO_CAMPAIGN_IDENTIFIERS_ERROR: 'FETCH_LEAD_TO_CAMPAIGN_IDENTIFIERS_ERROR',
  RECEIVE_LEAD_TO_CAMPAIGN_IDENTIFIERS: 'RECEIVE_LEAD_TO_CAMPAIGN_IDENTIFIERS',

  FETCH_LEAD_TO_AD_IDENTIFIERS: 'FETCH_LEAD_TO_AD_IDENTIFIERS',
  FETCH_LEAD_TO_AD_IDENTIFIERS_ERROR: 'FETCH_LEAD_TO_AD_IDENTIFIERS_ERROR',
  RECEIVE_LEAD_TO_AD_IDENTIFIERS: 'RECEIVE_LEAD_TO_AD_IDENTIFIERS',

  createLead: (newLead) => ({
    type: actions.CREATE_LEAD,
    newLead
  }),
  
  clearLeadForm: () => ({
    type: actions.LEAD_FORM_CLEARED
  }),
  
  setFormEmail: (email) => ({
    type: actions.SET_LEAD_FORM_EMAIL,
    email
  }),
  
  setFormLeadSource: (leadSourceId) => ({
    type: actions.SET_LEAD_FORM_LEAD_SOURCE,
    leadSourceId
  }),
  
  setFormCause: (cause) => ({
    type: actions.SET_LEAD_FORM_CAUSE,
    cause
  }),
  
  addFormBasicField: () => ({
    type: actions.ADD_LEAD_FORM_BASIC_FIELD
  }),
  
  setFormBasicField: (fieldIndex, field) => ({
    type: actions.SET_LEAD_FORM_BASIC_FIELD,
    fieldIndex,
    field
  }),
  
  removeFormBasicField: (fieldIndex) => ({
    type: actions.REMOVE_LEAD_FORM_BASIC_FIELD,
    fieldIndex
  }),
  
  addFormCustomField: () => ({
    type: actions.ADD_LEAD_FORM_CUSTOM_FIELD
  }),
  
  setFormCustomField: (fieldIndex, field) => ({
    type: actions.SET_LEAD_FORM_CUSTOM_FIELD,
    fieldIndex,
    field
  }),
  
  removeFormCustomField: (fieldIndex) => ({
    type: actions.REMOVE_LEAD_FORM_CUSTOM_FIELD,
    fieldIndex
  }),
  
  addFormAgree: (agreeId) => ({
    type: actions.ADD_LEAD_FORM_AGREE,
    agreeId
  }),
  
  removeFormAgree: (agreeId) => ({
    type: actions.REMOVE_LEAD_FORM_AGREE,
    agreeId
  }),

  fetchLeadToCampaignIdentifiers: (identifiers) => ({
    type: actions.FETCH_LEAD_TO_CAMPAIGN_IDENTIFIERS,
    identifiers
  }),

  fetchLeadToAdIdentifiers: (identifiers) => ({
    type: actions.FETCH_LEAD_TO_AD_IDENTIFIERS,
    identifiers
  }),

};
export default actions;
