import React, { Component } from 'react';
import {connect} from "react-redux";
import { Chart } from 'frappe-charts/dist/frappe-charts.esm.js'
import 'frappe-charts/dist/frappe-charts.min.css'
import chartsActions from "../../../../redux/campaignCharts/actions";
import Loader from "../../../../components/uielements/loader";
import './../../../App/global.css';

const basicData = {
  labels: [
    '12am-3am',
    '3am-6am',
    '6am-9am',
    '9am-12pm',
    '12pm-3pm',
    '3pm-6pm',
    '6pm-9pm',
    '9pm-12am'
  ],
  
  datasets: [
    {
      title: 'Some Data',
      color: 'light-blue',
      values: [25, 40, 30, 35, 8, 52, 17, -4]
    },
    {
      title: 'Another Set',
      color: 'violet',
      values: [25, 50, -10, 15, 18, 32, 27, 14]
    },
    {
      title: 'Yet Another',
      color: 'blue',
      values: [15, 20, -3, -15, 58, 12, -17, 37]
    }
  ]
};

const basicConfig = {
  height: 350,
  format_tooltip_x: d => (d + '').toUpperCase(),
  format_tooltip_y: d => {}
};

const colors = [
  '#3498DB', '#16A085', '#2ECC71', '#F1C40F', '#F39C12',
  '#D35400', '#BDC3C7', '#7F8C8D', '#34495E', '#E74C3C',
  '#8E44AD', '#C0392B', '#9B59B6', '#85C1E9', '#73C6B6',
  '#F8C471', '#EDBB99', '#E5E7E9', '#85929E', '#7FB3D5',
  '#F9E79F', '#4A235A'
];

const defaultData = {
  labels: [],
  datasets: [
    {
      title: 'Some Data',
      values: []
    },
  ],
};

class PieChart extends Component
{

  constructor(props) {
    super(props);

    this.state = {
      data: {...defaultData},
      tooltipOptions:{
        ...basicConfig
      }
    };

    this.pieChart = {
      header: 'Pie Chart',
      title: '',
      parent: '#' + this.props.chartIndex,
      parentId: this.props.chartIndex,
      type: 'pie',
      data: basicData,
      show_dots: 0,
      colors: colors,
      tooltipOptions: {
        ...this.state.tooltipOptions
      },
      heatline: 1,
      axisOptions: {
        yAxisMode: 'span',
        xAxisMode: 'tick',
        xIsSeries: 1,
      },
      region_fill: 1,
      ...basicConfig
    };
  }
  
  componentDidMount()
  {
    const {goalId, fromDate, toDate, fetchChartSources} = this.props;

    if(goalId)
      fetchChartSources(goalId,fromDate,toDate);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot)
  {
    const {goalId, goalSourceFieldsMapping, goalDatasetNaming, type, timespan, val_goal, gain, published, charts, isFetchingChart, fromDate, toDate, fetchChartSources, shouldExport } = this.props;

    if(prevProps.fromDate != fromDate || prevProps.toDate != toDate){
      fetchChartSources(goalId,fromDate,toDate);
    }

    if( prevProps.goalId !== goalId ||
        prevProps.goalSourceFieldsMapping !== goalSourceFieldsMapping ||
        prevProps.goalDatasetNaming !== goalDatasetNaming ||
        prevProps.type !== type ||
        prevProps.timespan !== timespan ||
        prevProps.val_goal !== val_goal ||
        prevProps.gain !== gain ||
        prevProps.published !== published){
      fetchChartSources(goalId,fromDate,toDate);
    }

    if(goalId)
      if(charts !== prevProps.charts  && !isFetchingChart.includes(goalId))
      {
        this.redrawChart();
      }

    if(shouldExport === true && this.chartRef){
      this.chartRef.export();
    }
  }
  
  redrawChart = () => {
    const {data} = this.state;
    const {goalId, charts} = this.props;

    if(charts.hasOwnProperty(goalId)){
      let newData = this.parseFieldsAndDataset(charts[goalId]);

      data.labels = newData.labels;
      data.datasets = newData.datasets;
    }

    this.setState({data}, () => {
      this.chartRef = new Chart(this.pieChart.parent, {...this.pieChart, data});
    })

  };

  parseFieldsAndDataset = (rawData) => {
    const {goalDatasetNaming, timespan, datasetProperties} = this.props;

    if(this.isDataEmpty(rawData)) return {...defaultData};

    let datasetName = '';
    let datasetCollection = [];
    let labelsCollection = [];

    let primaryDatasetName = rawData[Object.keys(rawData).shift()];
    let datasetValues = Object.values(primaryDatasetName[timespan || 'sources']);
    let compareValue = datasetValues.reduce((a, b) => a+b, 0);
    let val_goal = parseInt(datasetProperties[Object.keys(rawData).shift()].goal);

    if(compareValue <= val_goal || val_goal == 0){
      labelsCollection = Object.keys(primaryDatasetName[timespan || 'sources']);
      datasetCollection = datasetValues;
      datasetName = goalDatasetNaming[primaryDatasetName]
    } else {
      labelsCollection = ['In the goal range', 'Above'];
      datasetCollection = [val_goal, compareValue - val_goal];
      datasetName = 'Goal reached'
    }

    let newState = {
      ...this.state,
      data: {
        labels: labelsCollection,
        datasets: [{
          name: `${datasetName}`,
          values: datasetCollection,
          chartType: "pie"
        }]
      }
    };

    this.setState(newState);

    return newState.data;
  };

  isDataEmpty = (rawData) => {
    if(rawData.length < 1){
      this.setState({data: {...defaultData}});
      return true
    }
    return false
  };
  
  render() {
    const {isLoading} = this.props;

    return (
        <>
          <Loader visible={isLoading} text={'Chart is loading...'} />
          <div className={'campaignPieChart'} style={{minHeight:'355px', visibility: (isLoading ? 'hidden' : 'visible') }} id={this.pieChart.parentId}/>
        </>
    )
  }
}

const {fetchChartSources} = chartsActions;

export default connect(
    state => ({
      isFetchingChart: state.CampaignCharts.isFetchingChart,
      charts: state.CampaignCharts.charts,
      fromDate: state.Campaigns.fromDate,
      toDate: state.Campaigns.toDate,
    }),
    { fetchChartSources }
)(PieChart);