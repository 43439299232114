const datePickerConfigs = {
  id: 'SingleDatePicker',
  title: 'Select Date',
  options: [
    {
      id: 'disabled',
      title: 'Disabled',
      trueValue: true,
      falseValue: false,
      value: false
    },
    {
      id: 'showDefaultInputIcon',
      title: 'Show Defaut Icon',
      trueValue: true,
      falseValue: false,
      value: true
    },
    {
      id: 'isRTL',
      title: 'Enable RTL',
      trueValue: true,
      falseValue: false,
      value: false
    },
    {
      id: 'numberOfMonths',
      title: 'Single Month Enable',
      trueValue: 1,
      falseValue: 2,
      value: 2
    },
    {
      id: 'enableOutsideDays',
      title: 'Enable Outside Days',
      trueValue: true,
      falseValue: false,
      value: true
    },
    {
      id: 'keepOpenOnDateSelect',
      title: 'Keep Open On Date Select',
      trueValue: true,
      falseValue: false,
      value: false
    },
    {
      id: 'placeholder',
      title: 'Custom Placeholder',
      trueValue: 'From Date',
      falseValue: undefined,
      value: 'From Date'
    }
  ]
};

export const datePickerOptions = {
  isRangePicker: false,
  configsValue: datePickerConfigs,
  focusedInput: 'startDate',
  startDate: null,
  startDateId: 'your_unique_id',
  endDate: null,
  endDateId: 'your_unique_id',
};
