import React, {Component} from "react";
import {CoverLoader} from './styles/loader.style';


class RawSpin extends Component {
  render() {
    let {text} = this.props;
    return(
      <div className={'contai'}>
        {text ? <h2>{text}</h2> : null}
        <div className="cm-spinner" />
      </div>
    );
  }
}
class Loader extends Component
{
  render() {
    const {visible, type, text} = this.props;
    
    if(!visible)
      return(null);
    
    if(type && type === 'inline')
      return null;
    
    return(
      <CoverLoader><RawSpin text={text} /></CoverLoader>
    );
  }
}

export {RawSpin};
export default Loader;
