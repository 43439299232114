import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import {push} from 'connected-react-router';
import GoogleBusinessFetch from "../../../helpers/gooleBusinessFetch";
import notifications from "../../../components/feedback/notification";

export function* fetchLocationCampaigns() {
    yield takeEvery(actions.FETCH_LOCATION_CAMPAIGNS, function*() {
        
        const resp = yield GoogleBusinessFetch.get('campaigns', null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            yield put({
                type: actions.RECEIVE_LOCATION_CAMPAIGNS,
                locationCampaigns: resp.data.data
            });
            return;
            // yield put({
            //     type: actions.FETCH_GOOGLE_LOCATIONS
            // });
        }
        
        yield put({
            type: actions.FETCH_LOCATION_CAMPAIGNS_ERROR,
            errorMessage: resp.error
        });
    });
}

export function* createLocationCampaign() {
    yield takeEvery(actions.CREATE_LOCATION_CAMPAIGN, function*(payload) {
        const {values} = payload;
        
        const resp = yield GoogleBusinessFetch.post('campaigns', values)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            notifications['success']({
                message: 'OK!',
                description: 'Campaign created.'
            });
            
            yield put({
                type: actions.LOCATION_CAMPAIGN_CREATED,
                campaign: resp.data.data
            });
            yield put({
                type: actions.FETCH_LOCATION_CAMPAIGNS
            });
            return;
        }
        
        yield put({
            type: actions.CREATE_LOCATION_CAMPAIGN_ERROR,
            errorMessage: resp.error
        });
    });
}

export function* deployLocationCampaign() {
    yield takeEvery(actions.DEPLOY_LOCATION_CAMPAIGN, function*(payload) {
        const {id} = payload;
        
        const resp = yield GoogleBusinessFetch.post('campaigns/'+id+'/deploy', null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            notifications['success']({
                message: 'Deploy started!',
                description: 'Campaign deployment started. We will notify you when it\'s finished',
                duration: 2
            });
            
            yield put({
                type: actions.LOCATION_CAMPAIGN_DEPLOYED,
                campaign: resp.data.data
            });
            yield put({
                type: actions.FETCH_LOCATION_CAMPAIGNS
            });
            return;
        }
        
        yield put({
            type: actions.DEPLOY_LOCATION_CAMPAIGN_ERROR,
            errorMessage: resp.error
        });
    });
}

export function* pauseLocationCampaign() {
    yield takeEvery(actions.PAUSE_LOCATION_CAMPAIGN, function*(payload) {
        const {id} = payload;
        
        const resp = yield GoogleBusinessFetch.post('campaigns/'+id+'/pause', null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            notifications['warning']({
                message: 'Campaign paused!',
                description: 'We stopped deploying campaign. You can continue by deploying it.',
                duration: 2
            });
            
            yield put({
                type: actions.LOCATION_CAMPAIGN_PAUSED,
                campaign: resp.data.data
            });
            yield put({
                type: actions.FETCH_LOCATION_CAMPAIGNS
            });
            return;
        }
        
        yield put({
            type: actions.PAUSE_LOCATION_CAMPAIGN_ERROR,
            errorMessage: resp.error
        });
    });
}

export function* updateLocationCampaign() {
    yield takeEvery(actions.UPDATE_LOCATION_CAMPAIGN, function*(payload) {
        const {values, id} = payload;
        
        const resp = yield GoogleBusinessFetch.PATCH('campaigns/'+id, values)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            notifications['success']({
                message: 'OK!',
                description: 'Campaign saved.',
                duration: 1.2
            });
            
            yield put({
                type: actions.LOCATION_CAMPAIGN_UPDATED,
                campaign: resp.data.data
            });
            yield put({
                type: actions.FETCH_LOCATION_CAMPAIGNS
            });
            return;
        }
        
        yield put({
            type: actions.UPDATE_LOCATION_CAMPAIGN_ERROR,
            errorMessage: resp.error
        });
    });
}

export function* deleteLocationCampaign() {
    yield takeEvery(actions.DELETE_LOCATION_CAMPAIGN, function*(payload) {
        const {id} = payload;
        
        const resp = yield GoogleBusinessFetch.delete('campaigns/'+id, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            notifications['success']({
                message: 'OK!',
                description: 'Campaign flagged for removal. We will delete it after removing from all locations.',
                duration: 5
            });
            
            yield put({
                type: actions.LOCATION_CAMPAIGN_DELETED,
            });
            yield put({
                type: actions.FETCH_LOCATION_CAMPAIGNS
            });
            yield put(push('/google-business/location-campaigns'));
            return;
        }
        
        yield put({
            type: actions.DELETE_LOCATION_CAMPAIGN_ERROR,
            errorMessage: resp.error
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(fetchLocationCampaigns),
        fork(createLocationCampaign),
        fork(updateLocationCampaign),
        fork(deleteLocationCampaign),
        fork(deployLocationCampaign),
        fork(pauseLocationCampaign),
    ]);
}
