import styled from 'styled-components';
import { palette } from 'styled-theme';
import { borderRadius, transition } from '../../../../settings/style-util';
import WithDirection from '../../../../settings/withDirection';

const CampaignChartsWrapper = styled.div`
    padding-top: 10px;

    .isoExampleWrapper {
        margin-top: 0px !important;
    }
    
    .ant-card-body {
        padding: 5px;
    }
    
    .ant-card-head {
        min-height: 34px;
    }
    
    .ant-card-head-title {
        padding: 5px 0;
    }
    
    .ant-card-extra div > *:not(.chart-settings-active) {
        display: none;
    }
    
    .chart-card-active {
        
        .ant-card-extra div > * {
            display: inline-block;
        }        
    }
    
    .chart-card {
        position: relative;
    }
    
    .chart-settings-nav {
        position: absolute;
        top: -5px;
        right: 0;
        padding: 5px 10px;
        z-index: 1;
        
        button {
            background: #fff;
        }
    }
    
    
    .chart-settings {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 5px 10px;
        z-index: 1;
        display: none;
    }
    
    .chart-settings-active {
        display: initial;
    }
`;

const PercentageChart = styled.div`
    height: 100%;
    
    .frappe-chart {
        height: 100px;
    }
    
    .chart-legend {
        display: initial !important;
    }
`;

const StyledChartsWrapper = styled.div`
    .frappe-chart {
    
        .x {
        
            text {
                transition: all 300ms ease-in-out;
            }
            
        }
    
    }
`;

const CollapseWrapper = WithDirection(PercentageChart);
export {CampaignChartsWrapper, PercentageChart, StyledChartsWrapper};
