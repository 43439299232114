import React, { Component } from "react";
import {Col, Row} from "antd";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {SingleDatePicker} from "react-dates";
import {connect} from "react-redux";
import campaignActions from "../../../redux/campaign/actions";
import sourceActions from '../../../redux/source/actions';
// import csvActions from "../../../redux/csvtemplates/actions";
import {ReactDatesWrapper} from "../../ReactDates/reactDates.style";
import {datePickerOptions} from "../../Leads/Prospect/Filtering/datePickerOptions";
import basicStyle from "../../../settings/basicStyle";
import moment from "moment";
import Button, {ButtonGroup} from '../../../components/uielements/button';
import {SourceHeaderWrapper} from "../Source/typeTable.style";


class DateRangeFilter extends Component {
    
    state = {
        dateFromFocused: false,
        dateToFocused: false,
    };
    
    renderFromDatePicker = () => {
        const {configsValue} = datePickerOptions;
        const {dateFromFocused} = this.state;
        const {fromDate, toDate, setDateRange} = this.props;
        
        let options = {
            date: fromDate,
            onDateChange: dateFromResult => {
                dateFromResult.hours(0).minutes(0).seconds(0).milliseconds(0);
                setDateRange(dateFromResult, toDate, true)
            },
            focused: dateFromFocused,
            onFocusChange: ({ focused }) =>
                this.setState({ dateFromFocused: focused }),
        };
        const renderOptions = configsValue.options;
        
        renderOptions.forEach(option => {
            options[option.id] = option.value;
        });
        
        if (this.props.view === 'MobileView') {
            options.numberOfMonths = 1;
        }
        
        return (
            <div className="isoReactDate">
                <SingleDatePicker {...options} displayFormat={'DD-MM-YYYY'} isOutsideRange={() => false} />
            </div>
        );
    };
    
    renderToDatePicker = () => {
        const {configsValue} = datePickerOptions;
        const {dateToFocused} = this.state;
        const {fromDate, toDate, setDateRange} = this.props;
        
        let options = {
            date: toDate,
            onDateChange: dateFromResult => {
                dateFromResult.hours(23).minutes(59).seconds(59).milliseconds(0);
                setDateRange(fromDate, dateFromResult, true)
            },
            focused: dateToFocused,
            onFocusChange: ({ focused }) =>
                this.setState({ dateToFocused: focused }),
        };
        const renderOptions = configsValue.options;
        
        renderOptions.forEach(option => {
            options[option.id] = option.value;
        });
        
        if (this.props.view === 'MobileView') {
            options.numberOfMonths = 1;
        }
        
        return (
            <div className="isoReactDate">
                <SingleDatePicker {...options} displayFormat={'DD-MM-YYYY'} isOutsideRange={() => false} />
            </div>
        );
    };
    
    thisMonth = () => {
        const {campaign, fetchCampaignSources, setDateRange} = this.props;
        const {start_time, finish_time} = campaign;
        let startMoment = moment(start_time, 'YYYY-MM-DD');
        let endMoment = moment(finish_time, 'YYYY-MM-DD');
        
        if(endMoment > moment())
            endMoment = moment();
    
        let firstDayMoment = moment().startOf('month');
        if(endMoment > firstDayMoment)
            startMoment = firstDayMoment;
        
        setDateRange(startMoment, endMoment, true);
        fetchCampaignSources(campaign.id);
    };
    
    previousMonth = () => {
        const {campaign, fetchCampaignSources, setDateRange} = this.props;
        const {start_time, finish_time} = campaign;
        let startMoment = moment(start_time, 'YYYY-MM-DD');
        let endMoment = moment(finish_time, 'YYYY-MM-DD');
        
        let previousEndMoment = moment().subtract(1,'months').endOf('month');
        
        if(endMoment > previousEndMoment)
            endMoment = previousEndMoment;
        
        let firstDayMoment = moment(previousEndMoment).startOf('month');
        if(endMoment > firstDayMoment)
            startMoment = firstDayMoment;
        
        setDateRange(startMoment, endMoment, true);
        fetchCampaignSources(campaign.id);
    };
    
    wholePeriod = () => {
        const {campaign, fetchCampaignSources, setDateRange} = this.props;
        const {start_time, finish_time} = campaign;
        let startMoment = moment(start_time, 'YYYY-MM-DD');
        let endMoment = moment(finish_time, 'YYYY-MM-DD');
        setDateRange(startMoment, endMoment, true);
        fetchCampaignSources(campaign.id);
    };
    
    render() {
        const { rowStyle, colStyle, gutter } = basicStyle;
        const {campaign, fetchCampaignSources} = this.props;
        
        return (
            <div>
                <Row>
        
                    <Col sm={10}>
                        <ReactDatesWrapper>
                            {this.renderFromDatePicker()}
                        </ReactDatesWrapper>
                    </Col>
        
                    <Col sm={10} >
                        <ReactDatesWrapper>
                            {this.renderToDatePicker()}
                        </ReactDatesWrapper>
                    </Col>
        
                    <Col sm={4}>
                        <Button style={{width: '100%'}} onClick={() => fetchCampaignSources(campaign.id)}>Apply</Button>
                    </Col>
    
                </Row>
                
                <Row>
        
                    <Col sm={12}>
                    </Col>
        
                    <Col sm={12} style={{textAlign: 'right', paddingTop: 10}}>
                        <SourceHeaderWrapper style={{marginTop: 0}}>
                        <ButtonGroup>
                            <Button onClick={this.wholePeriod} type="primary" size="small" ghost={true} style={{fontSize: 12, lineHeight: '16px', height: 24}}>whole period</Button>
                            <Button onClick={this.previousMonth} type="primary" size="small" ghost={true} style={{fontSize: 12, lineHeight: '16px', height: 24}}>previous month</Button>
                            <Button onClick={this.thisMonth} type="primary" size="small" ghost={true} style={{fontSize: 12, lineHeight: '16px', height: 24}}>this month</Button>
                        </ButtonGroup>
                        </SourceHeaderWrapper>
                        {/*<a href="javascript;" style={{paddingLeft: 10}}>whole period</a>*/}
                        {/*<a href="javascript;" style={{paddingLeft: 10}}>previous month</a>*/}
                        {/*<a href="javascript;" style={{paddingLeft: 10}}>this month</a>*/}
                    </Col>
    
                </Row>
            </div>
        );
    }
}

const {setDateRange} = campaignActions;
const {fetchCampaignSources} = sourceActions;

export default connect(
    state => ({
        fromDate: state.Campaigns.fromDate,
        toDate: state.Campaigns.toDate,
    }),
    { setDateRange, fetchCampaignSources }
)(DateRangeFilter);
