import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import ReportFetch from "../../helpers/reportFetch";

export function* createReport() {
  yield takeEvery(actions.CREATE_REPORT, function*(payload) {
    const {reportData} = payload;
    let campaign = yield select((state) => state.Campaigns.campaign);

    const apiResponse = yield ReportFetch.post(campaign.id + '/report/create', reportData)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({type: actions.REPORT_CREATED});
      yield put({ type: 'FETCH_SOURCES', campaignId: campaign.id });
      return;
    }
    
    yield put({
      type: actions.CREATE_REPORT_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* deleteReport() {
  yield takeEvery(actions.DELETE_REPORT, function*(payload) {
    const {reportId} = payload;
    let campaign = yield select((state) => state.Campaigns.campaign);
    
    const apiResponse = yield ReportFetch.delete('report/' + reportId)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({type: actions.REPORT_DELETED});
      yield put({ type: 'FETCH_SOURCES', campaignId: campaign.id });
      return;
    }
    
    yield put({
      type: actions.DELETE_REPORT_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(createReport),
    fork(deleteReport),
  ]);
}
