import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import DeliveryFetch from "../../../helpers/deliveryFetch";
import notifications from "../../../components/feedback/notification";

export function* fetchMessageCampaigns() {
  yield takeEvery(actions.FETCH_MESSAGE_CAMPAIGNS, function*() {
    
    const resp = yield DeliveryFetch.get('messagescampaigns', null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.RECEIVE_MESSAGE_CAMPAIGNS,
        campaigns: resp.data.data
      });
      // return;
    }
    
    // yield put({
    //   type: actions.CREATE_MESSAGE_CAMPAIGN_ERROR,
    //   errorMessage: resp.error
    // });
  });
}

export function* fetchMessageCampaign() {
  yield takeEvery(actions.FETCH_MESSAGE_CAMPAIGN, function*(payload) {
    const {campaignId} = payload;
    
    const resp = yield DeliveryFetch.get('messagescampaigns/' + campaignId, null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: "CLEAR_CONTACTS",
      });
  
      yield put({
        type: "CLEAR_CONTACT_SENT_MESSAGES",
      });
      
      yield put({
        type: actions.RECEIVE_MESSAGE_CAMPAIGN,
        campaign: resp.data.data,
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_MESSAGE_CAMPAIGN_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* createMessageCampaign() {
  yield takeEvery(actions.CREATE_MESSAGE_CAMPAIGN, function*(payload) {
    const {campaign} = payload;
    
    const resp = yield DeliveryFetch.post('messagescampaigns', campaign)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      notifications['success']({
        message: 'OK!',
        description: 'Message Campaign created.'
      });
      yield put({
        type: actions.MESSAGE_CAMPAIGN_CREATED,
        campaign: resp.data.data
      });
      window.location.reload();
      return;
    }
  
    notifications['error']({
      message: 'Server error!',
      description: resp.message
    });
    yield put({
      type: actions.CREATE_MESSAGE_CAMPAIGN_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* updateMessageCampaign() {
  yield takeEvery(actions.UPDATE_MESSAGE_CAMPAIGN, function*(payload) {
    const {campaign, campaignId} = payload;
    
    const resp = yield DeliveryFetch.PATCH('messagescampaigns/' + campaignId, campaign)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      notifications['success']({
        message: 'OK!',
        description: 'Message campaign saved.'
      });
      window.location.reload();
      // yield put({
      //   type: actions.MESSAGE_CAMPAIGN_UPDATED,
      //   campaign: resp.data.data
      // });
      return;
    }
    
    notifications['error']({
      message: 'Server error!',
      description: resp.message
    });
    yield put({
      type: actions.UPDATE_MESSAGE_CAMPAIGN_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* sendTestMessageCampaign() {
  yield takeEvery(actions.SEND_TEST_MESSAGE_CAMPAIGN, function*(payload) {
    const {testEmail, campaignId} = payload;
    
    const resp = yield DeliveryFetch.post(`messagescampaigns/${campaignId}/send-test`, {testEmail})
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      notifications['success']({
        message: 'OK!',
        description: 'We sent test campaign to provided address.'
      });
      yield put({
        type: actions.TEST_MESSAGE_CAMPAIGN_SENT,
        campaign: resp.data.data
      });
      return;
    }
  
    notifications['error']({
      message: 'Server error!',
      description: resp.message
    });
    yield put({
      type: actions.SEND_TEST_MESSAGE_CAMPAIGN_ERROR,
      errorMessage: resp.data.message
    });
  });
}

export function* deleteMessageCampaign() {
  yield takeEvery(actions.DELETE_MESSAGE_CAMPAIGN, function*(payload) {
    const {campaignId} = payload;
    
    const resp = yield DeliveryFetch.delete('messagescampaigns/' + campaignId, null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.FETCH_MESSAGE_CAMPAIGNS,
      });
      return;
    }
    
    yield put({
      type: actions.DELETE_MESSAGE_CAMPAIGN_ERROR,
      errorMessage: resp.error
    });
  });
}

export function* sendMessageCampaign() {
  yield takeEvery(actions.SEND_MESSAGE_CAMPAIGN, function*(payload) {
    const {listId, campaignId} = payload;
    
    const resp = yield DeliveryFetch.post(`messagescampaigns/${campaignId}/send`, {lists: [listId]})
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      notifications['success']({
        message: 'Campaign started!',
        description: 'We started process of sending emails to all receivers.'
      });
      yield put({
        type: actions.FETCH_MESSAGE_CAMPAIGN,
        campaignId
      });
      return;
    }
    
    notifications['error']({
      message: 'Server error!',
      description: resp.data.message
    });
    yield put({
      type: actions.SEND_MESSAGE_CAMPAIGN_ERROR,
      errorMessage: resp.data.message
    });
  });
}

export function* patchScheduledCampaignMessages() {
  yield takeEvery(actions.PATCH_SCHEDULED_MESSAGE_CAMPAIGN_MESSAGES, function*(payload) {
    const {listId, campaignId} = payload;

    const resp = yield DeliveryFetch.PATCH(`messagescampaigns/${campaignId}/schedule-contact-messages`, {lists: [listId]})
        .then(response => (response))
        .catch(error => ({error}));

    if(resp.status === 200)
    {
      yield put({
        type: actions.FETCH_MESSAGE_CAMPAIGN,
        campaignId
      });
      return;
    }

    notifications['error']({
      message: 'Server error!',
      description: resp.data.message
    });
    yield put({
      type: actions.PATCH_SCHEDULED_MESSAGE_CAMPAIGN_MESSAGES_ERROR,
      errorMessage: resp.data.message
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(createMessageCampaign),
    fork(fetchMessageCampaigns),
    fork(fetchMessageCampaign),
    fork(sendTestMessageCampaign),
    fork(updateMessageCampaign),
    fork(deleteMessageCampaign),
    fork(sendMessageCampaign),
    fork(patchScheduledCampaignMessages),
  ]);
}
