import actions from './actions';
import notifications from '../../components/feedback/notification';

const initState = {
  leadReports: [],
  selectedReport: null,
  deletingReport: null,
  isFetching: false,
  isCreating: false,
  isCopying: false,
};


export default function analysisReducer(state = initState, action) {
  const {leadReports} = state;
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.FETCH_LEAD_REPORTS:
      return {
        ...state,
        isFetching: true,
        leadReports: initState.leadReports
      };

    case actions.FETCH_LEAD_REPORTS_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage
      };

    case actions.LEAD_REPORTS_RECEIVED:
      return {
        ...state,
        isFetching: false,
        leadReports: action.leadReports
      };
  
    case actions.SET_LEAD_REPORT:
      let selectedReport = leadReports.find(report => {
        return report.id == action.reportId;
      });
      return {
        ...state,
        selectedReport
      };
      
    case actions.CREATE_LEAD_REPORT:
      return {
        ...state,
        isCreating: true,
      };
    
    case actions.CREATE_LEAD_REPORT_ERROR:
      return {
        ...state,
        isCreating: false,
        errorMessage: action.errorMessage
      };
    
    case actions.LEAD_REPORT_CREATED:
      return {
        ...state,
        isCreating: false,
      };
  
    case actions.UPDATE_LEAD_REPORT:
      return {
        ...state,
        isCreating: true,
      };
  
    case actions.UPDATE_LEAD_REPORT_ERROR:
      return {
        ...state,
        isCreating: false,
        errorMessage: action.errorMessage
      };
  
    case actions.LEAD_REPORT_UPDATED:
      notifications['success']({
        message: 'Updated',
        description: 'Report was successfully updated.'
      });
      return {
        ...state,
        isCreating: false,
      };
  
    case actions.DELETE_LEAD_REPORT:
      return {
        ...state,
        isFetching: true,
      };
  
    case actions.DELETE_LEAD_REPORT_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage
      };
  
    case actions.LEAD_REPORT_DELETED:
      return {
        ...state,
        isFetching: false,
      };

    case actions.COPY_LEAD_REPORT:
      return {
        ...state,
        isCopying: true
      };

    case actions.LEAD_REPORT_COPIED:
    case actions.COPY_LEAD_REPORT_ERROR:
      return {
        ...state,
        isCopying: false
      };

    default:
      return state;
  }
}
