import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import activityActions, {ACTIVITY_TYPE} from './../../activity/actions';
import DeliveryFetch from "../../../helpers/deliveryFetch";
import notifications from "../../../components/feedback/notification";

export function* sendSingleSms() {
  yield takeEvery(actions.SEND_SINGLE_SMS, function*(payload) {
    const {smsName, message, prospect_id, phone, email} = payload;

    const resp = yield DeliveryFetch.post('sms-to-contact', {
      smsName,
      message,
      prospect_id,
      phone_number: phone,
      email
    })
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.SINGLE_SMS_SENT
      });
      yield put({
        type: activityActions.CREATE_ACTIVITY,
        activity: {
          type: ACTIVITY_TYPE.SINGLE_SMS_TO_PROSPECT,
          content: {
            smsName,
            message,
            phone,
            prospect_id
          }
        }
      });
      notifications['success']({
        message: 'Message sent successfully!'
      });
      return;
    }
    
    yield put({
      type: actions.SEND_SINGLE_SMS_ERROR
    });
    notifications['error']({
      message: 'Cannot sent Sms!',
      description: resp.data.message
    });
  });
}


export default function* rootSaga() {
  yield all([
    fork(sendSingleSms),
  ]);
}
