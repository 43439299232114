import React, { Component } from "react";
import Button from "../../../../components/uielements/button";
import AdvertiserForm from "./form";
import Popconfirm from '../../../../components/feedback/popconfirm';
import Modals from "../../../../components/feedback/modal";
import ModalStyle, { ModalContent } from "./form.style";
import {connect} from "react-redux";
import notifications from '../../../../components/feedback/notification';
import advertiserActions from '../../../../redux/advertiser/actions';
import Loader from '../../../../components/uielements/loader';

const Modal = ModalStyle(Modals);
const confirm = Modals.confirm;

class DepartmentFormModal extends Component {

  state = {
    visible: false
  };
  
  componentWillReceiveProps(nextProps, nextContext)
  {
    const {createdAdvertiser, showModal} = this.props;
    if(createdAdvertiser !== nextProps.createdAdvertiser)
    {
      showModal(false);
    }
  }
  
  handleCancel = () => {
    const { form } = this.formRef.props;
    const {showModal} = this.props;
    form.resetFields();
    this.setState({visible: false});
    showModal(false);
  };
  
  handleOpen = () => {
    this.setState({visible: true});
  };
  
  GetFooter = () => {
    const {isCreating, hideModal} = this.props;
    return [
      <Popconfirm
        title="Sure to discard changes?"
        okText="DISCARD"
        cancelText="No"
        placement={'top'}
        onConfirm={this.handleCancel}
      >
        <Button key="back" size="large" >
          Discard
        </Button>
      </Popconfirm>,
      <Popconfirm
        title="Are you sure you want to create new advertiser? It may result in additional billing to your account."
        okText="CREATE"
        cancelText="No"
        placement={'top'}
        onConfirm={this.handleCreate}
      >
        <Button
          key="submit"
          type="primary"
          size="large"
          loading={isCreating}
          htmlType="submit"
        >
          Create
        </Button>
      </Popconfirm>
    ]
  };
  
  handleCreate = () => {
    const { form } = this.formRef.props;
    const {createAdvertiser} = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
  
      createAdvertiser(values);
    });
  };
  
  saveFormRef = formRef => {
    this.formRef = formRef;
  };
  
  render() {
    const {campaign} = this.state;
    const {isCreating, isFetchingGroups, groups, fetchGroups, advertiserModal, showModal} = this.props;
    
    return(
      <Modal
        visible={advertiserModal}
        title="Advertiser creation"
        onOk={this.handleCreate}
        onCancel={() => showModal(false)}
        footer={this.GetFooter()}
      >
        <Loader visible={isCreating}/>
        <AdvertiserForm wrappedComponentRef={this.saveFormRef}/>
      </Modal>
    );
  }
}

const {createAdvertiser, showModal} = advertiserActions;
export default connect(
  state => ({
    isFetchingGroups: state.Groups.isFetching,
    groups: state.Groups.groups,
    advertiserModal: state.Advertisers.advertiserModal,
    isCreating: state.Advertisers.isCreating,
    createdAdvertiser: state.Advertisers.createdAdvertiser
    // user: state.auth.user,
  }),
  {
    createAdvertiser,
    showModal
  }
)(DepartmentFormModal);
