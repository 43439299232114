import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import DeliveryFetch from "../../../helpers/deliveryFetch";
import notifications from "../../../components/feedback/notification";

export function* fetchVerifiedEmails() {
  yield takeEvery(actions.FETCH_VERIFIED_EMAILS, function*() {
    
    const resp = yield DeliveryFetch.get('verified-emails', null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.RECEIVE_VERIFIED_EMAILS,
        verifiedEmails: resp.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_VERIFIED_EMAILS_ERROR,
      errorMessage: resp.error
    });
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchVerifiedEmails),
  ]);
}
