const actions = {
  FETCH_ADVERTISERS: 'FETCH_ADVERTISERS',
  FETCH_ADVERTISERS_ERROR: 'FETCH_ADVERTISERS_ERROR',
  RECEIVE_ADVERTISERS: 'RECEIVE_ADVERTISERS',
  
  CREATE_ADVERTISER: 'CREATE_ADVERTISER',
  CREATE_ADVERTISER_ERROR: 'CREATE_ADVERTISER_ERROR',
  ADVERTISER_CREATED: 'ADVERTISER_CREATED',
  
  CHANGE_ADVERTISER: 'CHANGE_ADVERTISER',
  ADVERTISER_SET: 'ADVERTISER_SET',
  TOGGLE_ADVERTISER_MODAL: 'TOGGLE_ADVERTISER_MODAL',
  
  fetchAdvertisers: () => ({
    type: actions.FETCH_ADVERTISERS
  }),
  
  createAdvertiser: (advertiser) => ({
    type: actions.CREATE_ADVERTISER,
    advertiser
  }),
  
  showModal: (boole) => ({
    type: actions.TOGGLE_ADVERTISER_MODAL,
    boole
  }),
  
  changeAdvertiser: (advertiser) => ({
    type: actions.CHANGE_ADVERTISER,
    advertiser
  })
  
};
export default actions;
