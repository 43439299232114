import actions from './actions';
import moment from "moment";
import {getDefaultLogic, indexOfCustomFilter, findPossibleFilters, isApplyForFilters } from '../../containers/Leads/Prospect/Filtering/functions';

const initState = {
  filteredProspects: [],
  needsUpdate: false,
  searchPhrase: '',
  filters: {
    startDate: null, //filterStartDate,
    endDate: null, //filterEndDate,
    original_source: '',
    cause: '',
    possibleSources: [],
    possibleCauses: [],
  },
  customFilters: [],
  statusFilters: [],
};

export default function prospectReducer(state = initState, action) {
  let {filters, customFilters, searchPhrase} = state;
  
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.APPLY_FILTERS:
      let dataList = [];
      action.prospects.forEach(prospect => {
        if(!isApplyForFilters(prospect, filters, customFilters, searchPhrase)) return;
        dataList.push(prospect);
      });
      return {
        ...state,
        filteredProspects: dataList
      };
  
    case actions.RESET_PROSPECT_FILTERS:
      return {
        ...state,
        needsUpdate: !state.needsUpdate,
        filters: {
          startDate: null,
          endDate: null,
          original_source: '',
          cause: '',
          possibleSources: [],
          possibleCauses: [],
        },
        customFilters: [],
        statusFilters: [],
        searchPhrase: '',
        filteredProspects: action.prospects
      };
  
    case actions.SET_FILTER:
      filters[action.filterName] = action.filterValue;
      return {
        ...state,
        filters,
      };
    
    case actions.FIND_FILTERS:
      let mainFilters = findPossibleFilters(action.prospects);
      filters.possibleCauses = mainFilters.possibleCauses;
      filters.possibleSources = mainFilters.possibleSources;

      return {
        ...state,
        filters,
        needsUpdate: !state.needsUpdate
      };
  
    case actions.ADD_PROSPECT_CUSTOM_FILTER:
      let foundStatus = false;
      if(action.filterType == 'status')
      {
        for(let i = 0; i < customFilters.length; i++)
        {
          if(action.customField.id == customFilters[i].id)
          {
            foundStatus = i;
            break;
          }
        }
      }
      
      if(foundStatus === false)
      {
        customFilters.push({...action.customField, filterType: action.filterType, filterLogic: getDefaultLogic(action.filterType, action.customField), customValue: action.customValue});
      }
      else
      {
        customFilters.splice(foundStatus, 1);
      }

      return {
        ...state,
        customFilters,
        needsUpdate: !state.needsUpdate,
      };
      
    case actions.SET_SEARCH_PHRASE:
      return {
        ...state,
        searchPhrase: action.searchPhrase,
      };
  
    case actions.CHANGE_PROSPECT_CUSTOM_FILTER_TYPE:
      customFilters[action.customFilterIndex] = {...action.newCustomField, filterType: action.newFilterType, filterLogic: getDefaultLogic(action.newFilterType, action.newCustomField), customValue: null};
      return {
        ...state,
        customFilters,
        needsUpdate: !state.needsUpdate,
      };
  
    case actions.CHANGE_PROSPECT_CUSTOM_FILTER_LOGIC:
      customFilters[action.customFilterIndex].filterLogic = action.newLogic;
      return {
        ...state,
        customFilters,
        needsUpdate: !state.needsUpdate,
      };
  
    case actions.CHANGE_PROSPECT_CUSTOM_FILTER:
      customFilters[action.customFilterIndex] = {
        ...action.newCustomField,
        filterType: customFilters[action.customFilterIndex].filterType,
        filterLogic: getDefaultLogic(customFilters[action.customFilterIndex].filterType, action.newCustomField),
        customValue: null
      };
      return {
        ...state,
        customFilters,
        needsUpdate: !state.needsUpdate,
      };
  
    case actions.REMOVE_PROSPECT_CUSTOM_FILTER:
      customFilters.splice(action.customFilterIndex, 1);
      return {
        ...state,
        customFilters,
        needsUpdate: !state.needsUpdate,
      };
  
    case actions.SET_PROSPECT_CUSTOM_FILTER_VALUE:
      customFilters[action.customFilterIndex].customValue = action.customValue;
      return {
        ...state,
        needsUpdate: !state.needsUpdate,
        customFilters
      };
    
    default:
      return state;
  }
}
