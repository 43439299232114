import ReportWrapper from './style';
import React, { Component } from 'react';
import Button from '../uielements/button';
import Popover from '../uielements/popover';
import ColorChooserDropdown from '../colorChooser.style';
import {Icon} from 'antd';

export default class extends Component {
  
  render() {
    const {clickHandler} = this.props;
    return (
      <ReportWrapper onClick={clickHandler}>
          <div className="innerButton">
            <Icon type={'plus-circle'} />
            add chart
          </div>
      </ReportWrapper>
    )
  }
}
