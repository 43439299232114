import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import {store} from './../../redux/store'
import actions from './actions';
import notifications from '../../components/feedback/notification';
import ReportFetch from "../../helpers/reportFetch";

export function* fetchAccount() {
  yield takeEvery(actions.FETCH_PRIMARY_AD_ACCOUNT, function*(payload) {
    const { platformTypeEnum } = payload;

    const apiResponse = yield ReportFetch.get(`primary-ad-account/${platformTypeEnum}`, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      switch (platformTypeEnum) {
        case 3:
          yield put({
            type: actions.RECEIVE_PRIMARY_FACEBOOK_ACCOUNT,
            account: apiResponse.data.data
          });
          break;
      }
      return;
    }
    
    yield put({
      type: actions.FETCH_PRIMARY_AD_ACCOUNT_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* patchAccount() {
  yield takeEvery(actions.PATCH_PRIMARY_AD_ACCOUNT, function*(payload) {
    const { primaryAdAccount } = payload;

    const apiResponse = yield ReportFetch.PATCH(`primary-ad-account`, primaryAdAccount)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      switch (primaryAdAccount.type) {
        case 3:
          yield put({
            type: actions.RECEIVE_PRIMARY_FACEBOOK_ACCOUNT,
            account: apiResponse.data.data
          });
          break;
      }
      yield put({
        type: actions.FETCH_PRIMARY_AD_ACCOUNT,
        platformTypeEnum: primaryAdAccount.type
      });
      return;
    }

    yield put({
      type: actions.FETCH_PRIMARY_AD_ACCOUNT_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* deleteAccount() {
  yield takeEvery(actions.DELETE_PRIMARY_AD_ACCOUNT, function*(payload) {
    const { platformTypeEnum } = payload;

    const apiResponse = yield ReportFetch.delete(`primary-ad-account/${platformTypeEnum}`, null)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      switch (platformTypeEnum) {
        case 3:
          yield put({
            type: actions.PRIMARY_FACEBOOK_ACCOUNT_DELETED,
          });
          break;
      }
      yield put({
        type: actions.FETCH_PRIMARY_AD_ACCOUNT,
        platformTypeEnum
      });
      return;
    }

    yield put({
      type: actions.DELETE_PRIMARY_AD_ACCOUNT_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchAccount),
    fork(patchAccount),
    fork(deleteAccount),
  ]);
}
