import {all, fork, put, takeEvery} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import {clearToken, getToken} from '../../helpers/utility';
import actions from './actions';
import SuperFetch from "../../helpers/superFetch";
import {jwtConfig} from '../../settings';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*(loginData) {

    const loginToken = btoa(loginData.username+':'+loginData.password);
    const apiResponse = yield SuperFetch.get('token', null, {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-AUTH-TOKEN': loginToken
        })
        .then(response => (response))
        .catch(error => ({error}))
    
    
    if (apiResponse.status === 200) {
      let {user} = apiResponse.data;
      user.role = apiResponse.data.role;
      
      if(user.groups.length < 1)
      {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: apiResponse.data.token,
          user: user,
          profile: apiResponse.data.user,
          welcomeMessage: true
        });
        return;
      }
      
      yield put({
        type: actions.LOGIN_REPORTS_PERMISSIONS,
        token: apiResponse.data.token,
        user: user,
        profile: apiResponse.data.user
      });
      
    } else {
      yield put({
        type: actions.LOGIN_ERROR,
        message: apiResponse.data.message
      });
    }
  });
}

export function* reauthorize() {
  yield takeEvery('REAUTHORIZE_REQUEST', function*() {

    const apiResponse = yield SuperFetch.get('api/token', null)
      .then(response => (response))
      .catch(error => ({error}))
      
    if (apiResponse.status === 200) {
      let {user} = apiResponse.data;
      user.role = apiResponse.data.role;
      
      if(user.groups.length < 1)
      {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: apiResponse.data.token,
          user: user,
          profile: apiResponse.data.user,
        });
        return;
      }
      
      yield put({
        type: actions.LOGIN_REPORTS_PERMISSIONS,
        token: apiResponse.data.token,
        user: user,
        profile: apiResponse.data.user
      });
      
    } else {
      yield put({
        type: actions.LOGIN_ERROR,
        message: apiResponse.data.message
      });
    }
  });
}

export function* obtainReportsPermission() {
  yield takeEvery('LOGIN_REPORTS_PERMISSIONS', function*(loginData) {
    
    const {user, token, profile} = loginData;

    let {id} = user.groups[0].group;
    const selectedAdvertiser = localStorage.getItem('advertiser_id');
    if(selectedAdvertiser != null)
    {
      let connectionInAdvertiser = user.groups.find(connection => {
        return connection.group.advertiser.id == selectedAdvertiser;
      });
  
      if(connectionInAdvertiser)
      {
        id = connectionInAdvertiser.group.id;
      }
    }
  
    const apiResponse = yield fetch(`${jwtConfig.reportsApiUrl}${id}/permissions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(r =>  r.json().then(data => ({status: r.status, data})))
      .then(obj => (obj))
      .catch(error => ({ error: error }))
  
    if (apiResponse.status === 200) {
      
      const {permissions} = apiResponse.data;
      let currentPermissions = {};
      
      Object.keys(permissions).forEach(key => {
        currentPermissions[key] = permissions[key];
      });
      
      user.permissions = currentPermissions;
    
      yield put({
        type: actions.LOGIN_LEADS_PERMISSIONS,
        token: token,
        user: user,
        profile: profile,
      });
    
    } else {
      yield put({
        type: actions.LOGIN_ERROR,
        message: apiResponse.data.message
      });
    }
    
  });
}

export function* obtainLeadsPermission() {
  yield takeEvery('LOGIN_LEADS_PERMISSIONS', function*(loginData) {
    
    const {user, token, profile} = loginData;
    let {id} = user.groups[0].group;
    const selectedAdvertiser = localStorage.getItem('advertiser_id');
    if(selectedAdvertiser != null)
    {
      let connectionInAdvertiser = user.groups.find(connection => {
        return connection.group.advertiser.id == selectedAdvertiser;
      });
  
      if(connectionInAdvertiser)
      {
        id = connectionInAdvertiser.group.id;
      }
    }
    
    const apiResponse = yield fetch(`${jwtConfig.leadsApiUrl}${id}/permissions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(r =>  r.json().then(data => ({status: r.status, data})))
      .then(obj => (obj))
      .catch(error => ({ error: error }))

    if (apiResponse.status === 200) {
      
      const {permissions} = apiResponse.data;
      let currentPermissions = user.permissions;
      
      Object.keys(permissions).forEach(key => {
        currentPermissions[key] = permissions[key];
      });
      
      user.permissions = currentPermissions;
  
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        user: user,
        profile: profile
      });
      
    } else {
      yield put({
        type: actions.LOGIN_ERROR,
        message: apiResponse.data.message
      });
    }
    
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    const {user} = payload;
    
    let advertiser;
    if(payload.user.groups.length < 1)
    {
      advertiser = {id:-1};
    }
    else
    {
      ({advertiser} = payload.user.groups[0].group);
    }
  
    const selectedAdvertiser = localStorage.getItem('advertiser_id');
    if(selectedAdvertiser != null)
    {
      let connectionInAdvertiser = user.groups.find(connection => {
        return connection.group.advertiser.id == selectedAdvertiser;
      });
      
      if(connectionInAdvertiser)
      {
        ({advertiser} = connectionInAdvertiser.group);
      }
    }
  
    // console.log("TUTAJ START");
    // console.log(payload);
    yield localStorage.setItem('id_token', payload.token);
    yield localStorage.setItem('user', JSON.stringify(payload.user));
    yield localStorage.setItem('advertiser_id', advertiser.id);
    yield put({
      type: 'ADVERTISER_SET'
    });
    yield put({
      type: 'FETCH_ADVERTISERS'
    });
    yield put({
      type: 'CLEAR_USERS'
    });
    yield put({
      type: actions.SET_ADVERTISER,
      advertiserId: advertiser.id
    });
    // yield put(push('/dashboard'));
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
    yield put(push('/'));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {

    const token = getToken().get('idToken');
    const user = getToken().get('user');
    
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
        user
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(obtainLeadsPermission),
    fork(obtainReportsPermission),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(reauthorize),
  ]);
}
