import React, { Component } from "react";
import Form from '../../../../components/uielements/form';
import Input, {InputSearch, InputGroup} from '../../../../components/uielements/input';
import InputNumber from '../../../../components/uielements/InputNumber';
import Select, { SelectOption } from '../../../../components/uielements/select';
import {advertiserRules} from "./rules";
import {DatePicker, Icon} from 'antd';
import Spin from '../../../../components/uielements/spin';
import Button from '../../../../components/uielements/button';
import moment from "moment";


const { RangePicker } = DatePicker;
const Option = SelectOption;
const FormItem = Form.Item;

class AdvertiserForm extends Component {
  
  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 }
    }
  };
  
  render() {
    const { getFieldDecorator } = this.props.form;
    const {layout} = this.props;
    
    let {formItemLayout} = this;

    return(
        <Form layout={layout ? layout : 'horizontal'}>
          <FormItem {...formItemLayout} label="Name" hasFeedback>
            {getFieldDecorator('name', {initialValue: '', ...advertiserRules.title})(<Input name="title" id="title" />)}
          </FormItem>
        </Form>
    );
  }
}

export default Form.create()(AdvertiserForm);
