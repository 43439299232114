import React, { Component } from 'react';
import { connect } from 'react-redux';
import notification from '../../components/notification';
import authActions from '../../redux/auth/actions';

class NotificationCenter extends Component {
  
  // state = {
  //   isLoggedIn
  // };
  
  componentDidMount(prevProps, prevState, snapshot)
  {
    const {welcomeMessage, user, resetLoginMessage} = this.props;
    if(welcomeMessage)
    {
      notification('success', user ? `Welcome back ${user.username}!` : 'Welcome back!');
      resetLoginMessage();
    }
  }
  
  render() {
    // const {isLoggedIn} = this.props;
    return (
      null
    );
  }
}

const {resetLoginMessage} = authActions;
export default connect(
  state => ({
  isLoggedIn: state.Auth.idToken !== null ? true : false,
  welcomeMessage: state.Auth.welcomeMessage,
  user: state.Auth.user,
  }),
  {resetLoginMessage}
  )(NotificationCenter);
