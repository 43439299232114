const actions = {
    
    templateModel: {
    
    },
    
    SET_GOOGLE_LOCATION: 'SET_GOOGLE_LOCATION',
    
    FETCH_BUSINESS_ACCOUNTS: 'FETCH_BUSINESS_ACCOUNTS',
    FETCH_BUSINESS_ACCOUNTS_ERROR: 'FETCH_BUSINESS_ACCOUNTS_ERROR',
    BUSINESS_ACCOUNTS_RECEIVED: 'BUSINESS_ACCOUNTS_RECEIVED',
    
    FETCH_GOOGLE_LOCATIONS_PREVIEWS: 'FETCH_GOOGLE_LOCATIONS_PREVIEWS',
    FETCH_GOOGLE_LOCATIONS_PREVIEWS_ERROR: 'FETCH_GOOGLE_LOCATIONS_PREVIEWS_ERROR',
    RECEIVE_GOOGLE_LOCATIONS_PREVIEWS: 'RECEIVE_GOOGLE_LOCATIONS_PREVIEWS',
    
    FETCH_GOOGLE_LOCATIONS: 'FETCH_GOOGLE_LOCATIONS',
    FETCH_GOOGLE_LOCATIONS_ERROR: 'FETCH_GOOGLE_LOCATIONS_ERROR',
    RECEIVE_GOOGLE_LOCATIONS: 'RECEIVE_GOOGLE_LOCATIONS',
    
    FETCH_GOOGLE_LOCATION: 'FETCH_GOOGLE_LOCATION',
    FETCH_GOOGLE_LOCATION_ERROR: 'FETCH_GOOGLE_LOCATION_ERROR',
    RECEIVE_GOOGLE_LOCATION: 'RECEIVE_GOOGLE_LOCATION',
    
    DELETE_GOOGLE_LOCATION: 'DELETE_GOOGLE_LOCATION',
    DELETE_GOOGLE_LOCATION_ERROR: 'DELETE_GOOGLE_LOCATION_ERROR',
    GOOGLE_LOCATION_DELETED: 'GOOGLE_LOCATION_DELETED',
    
    FETCH_LOCATIONS_IMPORT: 'FETCH_LOCATIONS_IMPORT',
    FETCH_LOCATIONS_IMPORT_ERROR: 'FETCH_LOCATIONS_IMPORT_ERROR',
    LOCATIONS_IMPORT_RECEIVED: 'LOCATIONS_IMPORT_RECEIVED',
    
    CREATE_LOCATIONS_IMPORT: 'CREATE_LOCATIONS_IMPORT',
    CREATE_LOCATIONS_IMPORT_ERROR: 'CREATE_LOCATIONS_IMPORT_ERROR',
    LOCATIONS_IMPORT_CREATED: 'LOCATIONS_IMPORT_CREATED',
    
    DELETE_LOCATIONS_IMPORT: 'DELETE_LOCATIONS_IMPORT',
    DELETE_LOCATIONS_IMPORT_ERROR: 'DELETE_LOCATIONS_IMPORT_ERROR',
    LOCATIONS_IMPORT_DELETED: 'LOCATIONS_IMPORT_DELETED',
    
    SET_LOCATIONS_SEARCH_PHRASE: 'SET_LOCATIONS_SEARCH_PHRASE',
    
    fetchBusinessAccounts: (googleAccountId) => ({
        type: actions.FETCH_BUSINESS_ACCOUNTS,
        googleAccountId,
    }),
    
    fetchLocationsPreviews: () => ({
        type: actions.FETCH_GOOGLE_LOCATIONS_PREVIEWS
    }),
    
    fetchLocations: (page = 1) => ({
        type: actions.FETCH_GOOGLE_LOCATIONS,
        page
    }),
    
    fetchLocation: (id) => ({
        type: actions.FETCH_GOOGLE_LOCATION,
        id
    }),
    
    setSearchPhrase: (searchPhrase) => ({
        type: actions.SET_LOCATIONS_SEARCH_PHRASE,
        searchPhrase
    }),
    
    setLocation: (location) => ({
        type: actions.SET_GOOGLE_LOCATION,
        location
    }),

    deleteLocation: (locationId) => ({
        type: actions.DELETE_GOOGLE_LOCATION,
        locationId
    }),
    
    fetchLocationsImport: () => ({
        type: actions.FETCH_LOCATIONS_IMPORT
    }),
    
    createLocationsImport: (googleAccountId, accountDisplayName, accountGoogleName) => ({
        type: actions.CREATE_LOCATIONS_IMPORT,
        googleAccountId,
        accountDisplayName,
        accountGoogleName
    }),
    
    deleteLocationsImport: (id) => ({
        type: actions.DELETE_LOCATIONS_IMPORT,
        id
    }),
    
};
export default actions;
