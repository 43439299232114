import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import ReportFetch from "../../helpers/reportFetch";

export function* fetchCsvTemplates() {
  yield takeEvery(actions.FETCH_CSV_TEMPLATES, function*() {
    const apiResponse = yield ReportFetch.get('csv-templates', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_CSV_TEMPLATES,
        csvTemplates: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_CSV_TEMPLATES_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* createCsvTemplates() {
  yield takeEvery(actions.CREATE_CSV_TEMPLATE, function*(payload) {
    const {template} = payload;

    const apiResponse = yield ReportFetch.post('csv-templates', template)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.CSV_TEMPLATE_CREATED
      });
      yield put({
        type: actions.FETCH_CSV_TEMPLATES
      });
      return;
    }

    yield put({
      type: actions.CREATE_CSV_TEMPLATE_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* deleteCsvTemplates() {
  yield takeEvery(actions.DELETE_CSV_TEMPLATE, function*(payload) {
    const {template} = payload;

    const apiResponse = yield ReportFetch.delete('csv-templates', template)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.CSV_TEMPLATE_DELETED
      });
      yield put({
        type: actions.FETCH_CSV_TEMPLATES
      });
      return;
    }

    yield put({
      type: actions.DELETE_CSV_TEMPLATE_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchCsvTemplates),
    fork(createCsvTemplates),
    fork(deleteCsvTemplates),
  ]);
}
