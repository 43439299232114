import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import {push} from 'connected-react-router';
import GoogleBusinessFetch from "../../../helpers/gooleBusinessFetch";
import notifications from "../../../components/feedback/notification";

export function* fetchReviews() {
    yield takeEvery(actions.FETCH_REVIEWS, function*(payload) {
        let {filters, page} = payload;
        if(!page)
            page = 1;
    
        let perPage = yield select((state) => state.LocationReviews.perPage);
        
        let location, status, rating, toDate, fromDate, comment;
        
        if(filters)
        {
            ({location,status,rating, toDate, fromDate, comment} = filters);
        }
        
        let query = '?test=false';
        if(location && location.length > 0)
        {
            location.forEach(loc => {
                query += '&location[]='+loc;
            });
        }
    
        if(status)
            query += '&status='+status;
        
        if(comment)
            query += '&comment='+comment;
    
        if(rating)
            query += '&rating='+rating;
    
        if(toDate)
            query += '&toDate='+toDate;
    
        if(fromDate)
            query += '&fromDate='+fromDate;
        
        query += `&per_page=${perPage}&page=${page}`;
        
        const resp = yield GoogleBusinessFetch.get('reviews'+query, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            yield put({
                type: actions.REVIEWS_RECEIVED,
                reviews: resp.data.data.reviews,
                totalReviews: resp.data.data.total_all,
                page
            });
            return;
        }
    
        notifications['error']({
            message: 'Cannot fetch reviews!',
            description: resp.data.message
        });
        
        yield put({
            type: actions.FETCH_REVIEWS_ERROR,
            errorMessage: resp.error
        });
    });
}


export function* updateReview() {
    yield takeEvery(actions.UPDATE_REVIEW, function*(payload) {
        const {id, status} = payload;
        
        const resp = yield GoogleBusinessFetch.PATCH('reviews/'+id, {status})
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            notifications['success']({
                message: 'Review updated.'
            });
            yield put({
                type: actions.REVIEW_UPDATED
            });
            return;
        }
    
        notifications['error']({
            message: 'Updating review failed!',
            description: resp.data.message
        });
        yield put({
            type: actions.UPDATE_REVIEW_ERROR,
            errorMessage: resp.error
        });
    });
}

export function* reviewReply() {
    yield takeEvery(actions.REPLY_REVIEW, function*(payload) {
        const {id, reply_comment} = payload;
        
        const resp = yield GoogleBusinessFetch.post(`reviews/${id}/reply`, {reply_comment})
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            notifications['success']({
                message: 'Reply posted.'
            });
            yield put({
                type: actions.REVIEW_REPLIED,
                review: resp.data.data
            });
            return;
        }
    
        notifications['error']({
            message: 'Replying update failed!',
            description: resp.data.message
        });
        yield put({
            type: actions.REPLY_REVIEW_ERROR,
            errorMessage: resp.data.message
        });
    });
}

export function* deleteReply()
{
    yield takeEvery(actions.DELETE_REVIEW_REPLY, function* (payload)
    {
        const {id} = payload;
        
        const resp = yield GoogleBusinessFetch.delete(`reviews/${id}/reply`, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if (resp.status === 200)
        {
            notifications['success']({
                message: 'Reply deleted.'
            });
            yield put({
                type: actions.REVIEW_REPLY_DELETED,
                review: resp.data.data
            });
            return;
        }
        
        notifications['error']({
            message: 'Deleting reply failed',
            description: resp.data.message
        });
        yield put({
            type: actions.DELETE_REVIEW_REPLY_ERROR,
            errorMessage: resp.error
        });
    });
}
export default function* rootSaga() {
    yield all([
        fork(fetchReviews),
        fork(updateReview),
        fork(reviewReply),
        fork(deleteReply),
    ]);
}
