import actions from "./actions";
import React, { Component } from 'react';
import events from "../../containers/Leads/Calendar/demoEvents";
import moment from "moment";

const initState = {
  events: [],
  view: "month",
  isFetching: false,
  prospectsWithDates: [],
  recentEvents: [],
  isFetchingRecent: false,
  dailyTasks: [],
  isFetchingTasks: false,
  updatingDailyTask: false,
};

const colors = {
  '8': '#3498DB',
  '9': '#16A085',
  '10': '#2ECC71',
  '11': '#F1C40F',
  '16': '#F39C12',
  
  '20': '#3498DB',
  '21': '#16A085',
  '22': '#2ECC71',
  '23': '#04ba00',
  '27': '#579407',
  // '#D35400', '#BDC3C7', '#7F8C8D', '#34495E', '#E74C3C',
  // '#8E44AD', '#C0392B', '#9B59B6', '#85C1E9', '#73C6B6',
  // '#F8C471', '#EDBB99', '#E5E7E9', '#85929E', '#7FB3D5',
  // '#F9E79F', '#4A235A'
};

export default function calendarReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
      
    case actions.CALENDAR_VIEW:
      console.log(action.view);
      return {
        
        ...state,
        view: state.view == 'month' ? 'agenda' : 'month'
      };
    case actions.CALENDAR_EVENTS:
      return {
        ...state,
        events: action.events
      };
      
    case actions.FETCH_PROSPECTS_WITH_DATE_FIELDS:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.FETCH_PROSPECTS_WITH_DATE_FIELDS_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.PROSPECTS_WITH_DATE_FIELDS_RECEIVED:
      const {prospectsWithDates} = action;
      let events = [];
      
      prospectsWithDates.forEach((prospect, index) => {
        const {latest_custom_fields, id, email, prospect_has_basic_fields} = prospect;
  
        let phoneNumber = 'missing id';
        if(!email && prospect_has_basic_fields)
        {
          for(let i = 0; i < prospect_has_basic_fields.length; i++)
          {
            const {basic_field} = prospect_has_basic_fields[i];
            if(basic_field && basic_field.name == 'phone_number')
            {
              phoneNumber = prospect_has_basic_fields[i].value;
            }
          }
        }
        
        latest_custom_fields.forEach(prospectField => {
          const {custom_field, value} = prospectField;
          let startDate = moment(value, "YYYY-MM-DD HH:mm:ss").toDate();
          let endDate = moment(value, "YYYY-MM-DD HH:mm:ss").toDate();
          events.push({
            id: prospectField.id,
            prospectId: id,
            hexColor: colors['' + custom_field.id + ''],
            allDay: false,
            title: custom_field.display_name + ": " + (email ? email : phoneNumber),//.substring(0, 15) + '...',
            desc: <div><strong>{custom_field.display_name}: </strong><br/>{email ? email : phoneNumber}</div>,
            start: startDate,
            end: endDate,
          });
        });
      });
      return {
        ...state,
        isFetching: false,
        // prospectsWithDates: action.prospectsWithDates
        events
      };

    case actions.FETCH_RECENT_CALENDAR_EVENTS:
      return {
        ...state,
        isFetchingRecent: true,
        recentEvents: []
      };

    case actions.FETCH_RECENT_CALENDAR_EVENTS_ERROR:
      return {
        ...state,
        isFetchingRecent: false
      };

    case actions.RECENT_CALENDAR_EVENTS_RECEIVED:
      return {
        ...state,
        isFetchingRecent: false,
        recentEvents: action.recentEvents
      };

    case actions.UPDATE_CALENDAR_EVENT:
      return {
        ...state,
        isFetchingRecent: true
      };

    case actions.UPDATE_CALENDAR_EVENT_ERROR:
      return {
        ...state,
        isFetchingRecent: false
      };

    case actions.CALENDAR_EVENT_UPDATED:
      const updateEvent = (customFields, event) => {
        return customFields.map(field => field.id === event.id ? event : field)
      };
      return {
        ...state,
        isFetchingRecent: false,
        recentEvents: [...state.recentEvents].map(recentEvent => ({
          ...recentEvent,
          latest_custom_fields: updateEvent(recentEvent.latest_custom_fields, action.event),
          prospect_has_custom_fields: updateEvent(recentEvent.prospect_has_custom_fields, action.event)
        }))
      };

    case actions.FETCH_DAILY_TASKS:
      return {
        ...state,
        isFetchingTasks: true,
        dailyTasks: []
      };

    case actions.FETCH_DAILY_TASKS_ERROR:
      return {
        ...state,
        isFetchingTasks: false
      };

    case actions.DAILY_TASKS_RECEIVED:
      return {
        ...state,
        isFetchingTasks: false,
        dailyTasks: action.dailyTasks
      };

    case actions.ADD_DAILY_TASK:
      return {
        ...state,
        updatingDailyTask: true
      };

    case actions.DAILY_TASK_ADDED:
      return {
        ...state,
        dailyTasks: [...state.dailyTasks, action.task],
        updatingDailyTask: false
      };

    case actions.ADD_DAILY_TASK_ERROR:
      return {
        ...state,
        updatingDailyTask: false
      };

    case actions.REMOVE_DAILY_TASK:
      return {
        ...state,
        updatingDailyTask: true
      };

    case actions.DAILY_TASK_REMOVED:
      return {
        ...state,
        dailyTasks: [...state.dailyTasks].filter(dailyTask => dailyTask.id !== action.task.id),
        updatingDailyTask: false
      };

    case actions.REMOVE_DAILY_TASK_ERROR:
      return {
        ...state,
        updatingDailyTask: false
      };

    default:
      return state;
  }
}
