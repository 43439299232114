const actions = {
    
    // CREATE_CONTACT_LIST: 'CREATE_CONTACT_LIST',
    // CREATE_CONTACT_LIST_ERROR: 'CREATE_CONTACT_LIST_ERROR',
    // CONTACT_LIST_CREATED: 'CONTACT_LIST_CREATED',
    //
    // UPDATE_CONTACT_LIST: 'UPDATE_CONTACT_LIST',
    // UPDATE_CONTACT_LIST_ERROR: 'UPDATE_CONTACT_LIST_ERROR',
    // CONTACT_LIST_UPDATED: 'CONTACT_LIST_UPDATED',
    //
    CLEAR_CONTACTS: 'CLEAR_CONTACTS',
    
    FETCH_CONTACTS: 'FETCH_CONTACTS',
    RECEIVE_CONTACTS: 'RECEIVE_CONTACTS',
    
    SET_CONTACTS_PAGE: 'SET_PAGE',
    
    FETCH_PROSPECT_CONTACT: 'FETCH_PROSPECT_CONTACT',
    FETCH_PROSPECT_CONTACT_ERROR: 'FETCH_PROSPECT_CONTACT_ERROR',
    RECEIVE_PROSPECT_CONTACT: 'RECEIVE_PROSPECT_CONTACT',
    
    FETCH_PROSPECT_CONTACT_DETAILS: 'FETCH_PROSPECT_CONTACT_DETAILS',
    FETCH_PROSPECT_CONTACT_DETAILS_ERROR: 'FETCH_PROSPECT_CONTACT_DETAILS_ERROR',
    RECEIVE_PROSPECT_CONTACT_DETAILS: 'RECEIVE_PROSPECT_CONTACT_DETAILS',
    
    FETCH_PROSPECT_CONTACT_CAMPAIGNS: 'FETCH_PROSPECT_CONTACT_CAMPAIGNS',
    FETCH_PROSPECT_CONTACT_CAMPAIGNS_ERROR: 'FETCH_PROSPECT_CONTACT_CAMPAIGNS_ERROR',
    RECEIVE_PROSPECT_CONTACT_CAMPAIGNS: 'RECEIVE_PROSPECT_CONTACT_CAMPAIGNS',
    
    FETCH_PROSPECT_CONTACT_MESSAGES: 'FETCH_PROSPECT_CONTACT_MESSAGES',
    FETCH_PROSPECT_CONTACT_MESSAGES_ERROR: 'FETCH_PROSPECT_CONTACT_MESSAGES_ERROR',
    RECEIVE_PROSPECT_CONTACT_MESSAGES: 'RECEIVE_PROSPECT_CONTACT_MESSAGES',
    
    // SEND_TEST_CONTACT_LIST: 'SEND_TEST_CONTACT_LIST',
    // SEND_TEST_CONTACT_LIST_ERROR: 'SEND_TEST_CONTACT_LIST_ERROR',
    // TEST_CONTACT_LIST_SENT: 'TEST_CONTACT_LIST_SENT',
    //
    // DELETE_CONTACT_LIST: 'DELETE_CONTACT_LIST',
    // DELETE_CONTACT_LIST_ERROR: 'DELETE_CONTACT_LIST_ERROR',
    // CONTACT_LIST_DELETED: 'CONTACT_LIST_DELETED',
    //
    // CLEAR_CONTACT_LIST: 'CLEAR_CONTACT_LIST',
    
    fetchContacts: () => ({
        type: actions.FETCH_CONTACTS
    }),
    
    setPage: (page) => ({
        type: actions.SET_CONTACTS_PAGE,
        page,
    }),
    
    fetchProspectContact: (prospectId) => ({
        type: actions.FETCH_PROSPECT_CONTACT,
        prospectId
    }),
    
    fetchProspectContactDetails: (contactId) => ({
        type: actions.FETCH_PROSPECT_CONTACT_DETAILS,
        contactId
    }),
    
    fetchProspectContactCampaigns: (contactSentMessageIds) => ({
        type: actions.FETCH_PROSPECT_CONTACT_CAMPAIGNS,
        contactSentMessageIds
    }),
    
    fetchContactSentMessages: (filters) => ({
        type: actions.FETCH_PROSPECT_CONTACT_MESSAGES,
        filters
    }),
    
    // clearContactList: () => ({
    //   type: actions.CLEAR_CONTACT_LIST
    // }),
    //
    // fetchContactList: (contactListId) => ({
    //   type: actions.FETCH_CONTACT_LIST,
    //   contactListId
    // }),
    //
    // deleteContactList: (contactListId) => ({
    //   type: actions.DELETE_CONTACT_LIST,
    //   contactListId
    // }),
    //
    // createContactList: (contactList) => ({
    //   type: actions.CREATE_CONTACT_LIST,
    //   contactList
    // }),
    //
    // updateContactList: (contactList, contactListId) => ({
    //   type: actions.UPDATE_CONTACT_LIST,
    //   contactList,
    //   contactListId
    // }),
    
};
export default actions;
