import actions from './actions';

const initState = { agrees: [], isFetching: false, clearForm: false};

export default function agreeReducer(state = initState, action) {
  switch (action.type) {
    
    case actions.FETCH_AGREES:
    case actions.CREATE_AGREE:
    case actions.DELETE_AGREE:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.FETCH_AGREES_ERROR:
    case actions.CREATE_AGREE_ERROR:
    case actions.DELETE_AGREE_ERROR:
      return {
        ...state,
        isFetching: false
      };
    
    case actions.RECEIVE_AGREES:
      return {
        ...state,
        isFetching: false,
        agrees: action.agrees
      };
      
    case actions.AGREE_CREATED:
    case actions.AGREE_DELETED:
      return {
        ...state,
        isFetching: false,
        clearForm: !state.clearForm
      };
  
    default:
      return state;
  }
}
