const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_REPORTS_PERMISSIONS: 'LOGIN_REPORTS_PERMISSIONS',
  LOGIN_LEADS_PERMISSIONS: 'LOGIN_LEADS_PERMISSIONS',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  SET_ADVERTISER: 'SET_ADVERTISER',
  RESET_LOGIN_MESSAGE: 'RESET_LOGIN_MESSAGE',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (username, password) => ({
    type: actions.LOGIN_REQUEST,
    username: username,
    password: password
  }),
  registerUser: (email, username, password) => ({
    type: actions.LOGIN_REQUEST,
    username,
    email,
    password,
  }),
  resetLoginMessage: () => ({
    type: actions.RESET_LOGIN_MESSAGE
  }),
  logout: () => ({
    type: actions.LOGOUT
  })
};
export default actions;
