import styled from "styled-components";

const LayoutContentWrapper = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  
  .wideModal {
    width: 80%!important;
  }

  @media only screen and (max-width: 767px) {
    padding: 50px 20px;
  }

  @media (max-width: 580px) {
    padding: 15px;
  }
  
  .ant-checkbox-wrapper {
      .ant-form-item-children-icon {
        display: none;
      }
  }
  
  .ant-pagination {
  padding-right: 20px;
  }
  
  .formRowsSeparate {
    border-bottom: 2px dashed #dddddd;
    margin-bottom: 20px;
  }
  
  .ratingFilterButtons {
    button {
    margin-right: 5px;
    }
  }
  
  .versionList {
    .deployedVersion {
      .ant-collapse-header
      {
        color: #ffffff;
        font-weight: bold;
        background-color: #2264f8;
      }
    }
    
    .systemVersion {
      .ant-collapse-header
      {
        color: #444444;
        font-weight: bold;
        background-color: #eeeeee;
      }
    }
    
    .deployingVersion {
      .ant-collapse-header
      {
        color: #ffffff;
        font-weight: bold;
        background-color: rgb(250, 173, 20);
      }
    }
    
    .errorVersion {
      .ant-collapse-header
      {
        color: #ffffff;
        font-weight: bold;
        background-color: #fe4f66;
      }
    }
  }
`;

export { LayoutContentWrapper };
