import {all, takeEvery, put, fork, select} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import notifications from "../../components/feedback/notification";
import actions from './actions';
import LeadsFetch from "../../helpers/leadsFetch";
import {buildFilterQuery, buildSearchQuery, buildDateRangeQuery, buildAdIdQuery} from '../../containers/Leads/Prospect/Filtering/functions';
import {createBrowserHistory} from "history";

export function* fetchProspectsRequest() {
  yield takeEvery('FETCH_PROSPECTS', function*() {
  
    let filters = yield select((state) => state.ProspectFilter.customFilters);
    let mainFilters = yield select((state) => state.ProspectFilter.filters);
    let searchPhrase = yield select((state) => state.ProspectFilter.searchPhrase);
    let page = yield select((state) => state.Prospect.page);
    let perPage = yield select((state) => state.Prospect.perPage);
    
    const {startDate, endDate} = mainFilters;
    
    let query = '?page=' + page;
    query += buildFilterQuery(filters, mainFilters);
    query += buildDateRangeQuery(startDate, endDate);
    query += buildSearchQuery(searchPhrase);
    query += buildAdIdQuery();
    
    const apiResponse = yield LeadsFetch.get('prospects' + query, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      const {total_all} = apiResponse.data;
      
      if(parseInt(page) > 0)
      {
        if(parseInt(perPage) * (parseInt(page)-1) > total_all)
          page = 1;
      }
      
      yield put({
        type: actions.RECEIVE_PROSPECTS,
        prospects: apiResponse.data.data,
        totalProspects: total_all,
        page: page,
        // totalFiltered: apiResponse.data.total,
      });
  
      // yield put({
      //   type: "FIND_FILTERS",
      //   prospects: apiResponse.data.data
      // });
    }
  });
}

export function* fetchProspectDataRequest() {
  yield takeEvery(actions.FETCH_PROSPECT_DATA, function*(payload) {

    const {prospectId} = payload;
    const apiResponse = yield LeadsFetch.get('prospects/'+prospectId, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_PROSPECT_DATA,
        prospect: apiResponse.data
      });
    }
    else
    {
      yield put({
        type: actions.FETCH_PROSPECT_ERROR,
        errorMessage: 'There was an error while obtaining prospect data.'
      });
    }
  });
}

export function* setProspectStatus() {
  yield takeEvery('SET_PROSPECT_STATUS', function*(payload) {
    let {prospectId, statusId} = payload;
  
    if(statusId === '') statusId = null;
    
    const apiResponse = yield LeadsFetch.post('prospects/' + prospectId + '/status', {status_id: statusId})
      .then(response => (response))
      .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.PROSPECT_STATUS_SET
      });
      yield put({
        type: actions.FETCH_PROSPECT_DATA,
        prospectId
      });
      return;
    }
  
    // console.log("Setting status error.");
    yield put({
      type: actions.SET_PROSPECT_STATUS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* assignProspectUser() {
  yield takeEvery('ASSIGN_PROSPECT_USER', function*(payload) {
    let {prospectId, userId} = payload;
    
    if(!userId || userId === '') {
      yield put({
        type: actions.ASSIGN_PROSPECT_USER_ERROR,
        errorMessage: 'User id cannot be empty.'
      });
      return;
    }
    
    const apiResponse = yield LeadsFetch.post('prospects/' + prospectId + '/assign', {user_id: userId})
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.PROSPECT_USER_ASSIGNED
      });
      yield put({
        type: actions.FETCH_PROSPECT_DATA,
        prospectId
      });
      return;
    }
    
    // console.log("Setting status error.");
    yield put({
      type: actions.ASSIGN_PROSPECT_USER_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* editProspect() {
  yield takeEvery('EDIT_PROSPECT', function*(payload) {
    let {prospectPath, editProspect} = payload;

    const apiResponse = yield LeadsFetch.PATCH('prospects/' + prospectPath.prospectId, editProspect)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      const history = createBrowserHistory({forceRefresh: true});
      yield put(history.push(`/leads/new-prospect/${editProspect.email || 'empty'}/${prospectPath.leadSource}/${prospectPath.prospectId}`));
      return;
    }

    yield put({
      type: actions.EDIT_PROSPECT_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}


export function* deleteProspectBasicField() {
  yield takeEvery(actions.DELETE_PROSPECT_BASIC_FIELD, function*(payload) {
    
    let {prospectHasBasicFieldId} = payload;
    let prospect = yield select((state) => state.Prospect.prospect);
    
    const apiResponse = yield LeadsFetch.delete(`prospects-basic-fields/${prospectHasBasicFieldId}`, null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.PROSPECT_BASIC_FIELD_DELETED
      });
      yield put({
        type: actions.FETCH_PROSPECT_DATA,
        prospectId: prospect.id
      });
      return;
    }
    
    yield put({
      type: actions.DELETE_PROSPECT_BASIC_FIELD_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* addProspectField() {
  yield takeEvery(actions.ADD_PROSPECT_CUSTOM_FIELD, function*(payload) {
    let {prospectId, fieldId, fieldValue, eventView} = payload;
    
    if(!fieldId || fieldId === '') {
      yield put({
        type: actions.ADD_PROSPECT_FIELD_ERROR,
        errorMessage: 'Field id cannot be empty.'
      });
      return;
    }
    
    const apiResponse = yield LeadsFetch.post('prospects/' + prospectId + '/custom-field', {
        field_id: fieldId,
        field_value: fieldValue,
      })
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.PROSPECT_FIELD_ADDED
      });
      
      if(eventView)
      {
        yield put({
          type: 'FETCH_PROSPECTS_WITH_DATE_FIELDS'
        });
      }
      else {
        yield put({
          type: actions.FETCH_PROSPECT_DATA,
          prospectId
        });
      }
      
      return;
    }
    
    // console.log("Setting status error.");
    yield put({
      type: actions.ADD_PROSPECT_FIELD_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* exportProspects() {
  yield takeEvery(actions.EXPORT_PROSPECTS, function*() {
  
    let filters = yield select((state) => state.ProspectFilter.customFilters);
    let mainFilters = yield select((state) => state.ProspectFilter.filters);
    let searchPhrase = yield select((state) => state.ProspectFilter.searchPhrase);
    let page = yield select((state) => state.Prospect.page);
    let perPage = yield select((state) => state.Prospect.perPage);
  
    const {startDate, endDate} = mainFilters;
  
    let query = '?page=' + page;
    query += buildFilterQuery(filters, mainFilters);
    query += buildDateRangeQuery(startDate, endDate);
    query += buildSearchQuery(searchPhrase);
    
    const apiResponse = yield LeadsFetch.post('export/prospects' + query)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.PROSPECTS_EXPORTED,
        csvString: apiResponse.data,
      });
      return;
    }
    
    // console.log("Setting status error.");
    // yield put({
    //   type: actions.ADD_PROSPECT_FIELD_ERROR,
    //   errorMessage: apiResponse.data.message
    // });
  });
}

export function* exportAthlonAccountTemplate() {
  yield takeEvery(actions.EXPORT_ATHLON_ACCOUNT_TEMPLATE, function*() {
  
    let filters = yield select((state) => state.ProspectFilter.customFilters);
    let mainFilters = yield select((state) => state.ProspectFilter.filters);
    let searchPhrase = yield select((state) => state.ProspectFilter.searchPhrase);
    let page = yield select((state) => state.Prospect.page);
    let perPage = yield select((state) => state.Prospect.perPage);
  
    const {startDate, endDate} = mainFilters;
  
    let query = '?page=' + page;
    query += buildFilterQuery(filters, mainFilters);
    query += buildDateRangeQuery(startDate, endDate);
    query += buildSearchQuery(searchPhrase);
    
    const apiResponse = yield LeadsFetch.post('export-accounts-template' + query)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.ATHLON_ACCOUNT_TEMPLATE_EXPORTED,
        csvString: apiResponse.data,
      });
      return;
    }
    
    // console.log("Setting status error.");
    // yield put({
    //   type: actions.ADD_PROSPECT_FIELD_ERROR,
    //   errorMessage: apiResponse.data.message
    // });
  });
}

export function* exportProspectsWithNotes() {
  yield takeEvery(actions.EXPORT_PROSPECTS_WITH_NOTES, function*() {
    
    let filters = yield select((state) => state.ProspectFilter.customFilters);
    let mainFilters = yield select((state) => state.ProspectFilter.filters);
    let searchPhrase = yield select((state) => state.ProspectFilter.searchPhrase);
    let page = yield select((state) => state.Prospect.page);
    let perPage = yield select((state) => state.Prospect.perPage);
    
    const {startDate, endDate} = mainFilters;
    
    let query = '?page=' + page;
    query += '&withNotes=true';
    query += buildFilterQuery(filters, mainFilters);
    query += buildDateRangeQuery(startDate, endDate);
    query += buildSearchQuery(searchPhrase);
    
    const apiResponse = yield LeadsFetch.post('export/prospects' + query)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.PROSPECTS_EXPORTED,
        csvString: apiResponse.data,
      });
      return;
    }
    
    // console.log("Setting status error.");
    // yield put({
    //   type: actions.ADD_PROSPECT_FIELD_ERROR,
    //   errorMessage: apiResponse.data.message
    // });
  });
}

export function* removeProspectAgree() {
  yield takeEvery(actions.PROSPECT_REMOVE_AGREE, function*(payload) {
    let {agreeId, prospectId} = payload;
    
    const apiResponse = yield LeadsFetch.delete('prospects/' + prospectId + '/agree', {agree_id: agreeId})
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.PROSPECT_AGREE_REMOVED
      });
      yield put({
        type: actions.FETCH_PROSPECT_DATA,
        prospectId
      });
      return;
    }
    
    yield put({
      type: actions.PROSPECT_REMOVE_AGREE_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* deleteProspect() {
  yield takeEvery(actions.DELETE_PROSPECT, function*(payload) {
    let {prospectId} = payload;
    
    const apiResponse = yield LeadsFetch.delete('prospects/' + prospectId)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.PROSPECT_DELETED
      });
      yield put({
        type: actions.FETCH_PROSPECTS
      });
      yield put(push('/leads/prospects'));
      return;
    }
    
    yield put({
      type: actions.DELETE_PROSPECT_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}


export function* afterPageSet() {
  yield takeEvery(actions.SET_PROSPECTS_PAGE, function*() {
  
    yield put({
      type: actions.FETCH_PROSPECTS
    });
    
  });
}

export function* createProspectsAudience() {
  yield takeEvery(actions.CREATE_PROSPECTS_AUDIENCE, function*() {
    
    let filters = yield select((state) => state.ProspectFilter.customFilters);
    let mainFilters = yield select((state) => state.ProspectFilter.filters);
    let searchPhrase = yield select((state) => state.ProspectFilter.searchPhrase);
    
    const {startDate, endDate} = mainFilters;
    
    let query = '?';
    query += buildFilterQuery(filters, mainFilters);
    query += buildDateRangeQuery(startDate, endDate);
    query += buildSearchQuery(searchPhrase);
    
    const apiResponse = yield LeadsFetch.get('prospects/create-audience' + query, null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      // console.log(apiResponse.data);
      notifications['success']({
        message: 'OK!',
        description: 'New Audience created!'
      });
      yield put({
        type: actions.PROSPECTS_AUDIENCE_CREATED,
      });
      return;
    }
  
    notifications['error']({
      message: 'Server error!',
      description: apiResponse.data.message
    });
    yield put({
      type: actions.CREATE_PROSPECTS_AUDIENCE_ERROR,
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchProspectsRequest),
    fork(setProspectStatus),
    fork(assignProspectUser),
    fork(editProspect),
    fork(fetchProspectDataRequest),
    fork(addProspectField),
    fork(exportProspects),
    fork(removeProspectAgree),
    fork(deleteProspect),
    fork(afterPageSet),
    fork(createProspectsAudience),
    fork(exportProspectsWithNotes),
    fork(exportAthlonAccountTemplate),
    fork(deleteProspectBasicField),
  ]);
}
