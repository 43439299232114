import {Col, Row} from "antd";
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
    {
        path: '',
        component: asyncComponent(() => import('../dashboard')),
    },
    // {
    //     path: 'campaigns/:campaignId/:sourceId',
    //     component: asyncComponent(() => import('./Source/single')),
    //     exact: true
    // },
    {
        path: 'templates/:template_id',
        component: asyncComponent(() => import('./Template/edit')),
        exact: true
    },
    {
        path: 'templates',
        component: asyncComponent(() => import('./Template/')),
    },
    {
        path: 'audiences/:contact_list_id',
        component: asyncComponent(() => import('./ContactList/edit')),
        exact: true
    },
    {
        path: 'audiences',
        component: asyncComponent(() => import('./ContactList/')),
    },
    {
        path: 'message-campaigns/:campaign_id',
        component: asyncComponent(() => import('./MessageCampaign/edit')),
        exact: true
    },
    {
        path: 'message-campaigns',
        component: asyncComponent(() => import('./MessageCampaign/')),
    },
];

export default class DeliveryRouter extends Component {
    
    render() {
        const { url, style } = this.props;
        return (
            <div style={style}>
                
                {routes.map(singleRoute => {
                    const { path, exact, ...otherProps } = singleRoute;
                    return (
                        <Route
                            exact={exact === false ? false : true}
                            key={singleRoute.path}
                            path={`${url}/${singleRoute.path}`}
                            {...otherProps}
                        />
                    );
                })}
            </div>
        );
    }
}
