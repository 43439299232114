import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import notifications from "../../components/feedback/notification";
import actions from './actions';
import LeadsFetch from "../../helpers/leadsFetch";


// export function* fetchCampaigns() {
//   yield takeEvery('FETCH_CAMPAIGNS', function*() {
//     const apiResponse = yield ReportFetch.get('campaigns', null)
//       .then(response => (response))
//       .catch(error => ({error}));
//
//     if(apiResponse.status === 200)
//     {
//       yield put({
//         type: actions.RECEIVE_CAMPAIGNS,
//         campaigns: apiResponse.data
//       });
//       return;
//     }
//
//     yield put({
//       type: actions.FETCH_CAMPAIGNS_ERROR,
//       errorMessage: apiResponse.message
//     });
//   });
// }

export function* fetchOfferTemplate() {
  yield takeEvery(actions.FETCH_OFFER_TEMPLATE, function*(payload) {
    const {offerTemplateId} = payload;
    const apiResponse = yield LeadsFetch.get('offer-templates/' + offerTemplateId, null)
      .then(response => (response))
      .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_OFFER_TEMPLATE,
        offerTemplate: apiResponse.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_OFFER_TEMPLATE_ERROR,
      errorMessage: apiResponse.message
    });
  });
}

export function* createOfferTemplate() {
  yield takeEvery(actions.CREATE_OFFER_TEMPLATE, function*(payload) {
    const {offerTemplate} = payload;

    const resp = yield LeadsFetch.post('offer-templates', offerTemplate)
      .then(response => (response))
      .catch(error => ({error}));

    if(resp.status === 200)
    {
      yield put({
        type: actions.OFFER_TEMPLATE_CREATED,
        offerTemplate: resp.data.data
      });
      return;
    }
    
    yield put({
      type: actions.CREATE_OFFER_TEMPLATE_ERROR,
      errorMessage: resp.message
    });
  });
}

export function* sendOfferTemplate() {
  yield takeEvery(actions.SEND_OFFER_TEMPLATE, function*(payload) {
    const {email} = payload;
    let offerTemplate = yield select((state) => state.OfferTemplates.offerTemplate);

    const resp = yield LeadsFetch.post('offer-template-test/' + offerTemplate.id, {test_email: email, magic_content: []})
      .then(response => (response))
      .catch(error => ({error}));

    if(resp.status === 200)
    {
      yield put({
        type: actions.OFFER_TEMPLATE_SENT
      });
      return;
    }
    
    yield put({
      type: actions.SEND_OFFER_TEMPLATE_ERROR,
      errorMessage: resp.message
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchOfferTemplate),
    fork(createOfferTemplate),
    fork(sendOfferTemplate)
  ]);
}
