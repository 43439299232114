import actions from './actions';

const initState = {
    isFetching: false,
    contactSentMessages: [],
    total_count: -1,
    sent_count: -1,
    open_count: -1,
};

export default function contactSentMessagesReducer(state = initState, action) {
    switch (action.type) {
        
        case 'SET_ADVERTISER':
            return {
                ...initState
            };
    
        case actions.CLEAR_CONTACT_SENT_MESSAGES:
            return {
                ...state,
                contactSentMessages: [],
                total_count: -1,
                sent_count: -1,
                open_count: -1,
                isFetching: false
            };
            
        case actions.FETCH_CONTACT_SENT_MESSAGES:
            return {
                ...state,
                isFetching: true,
                contactSentMessages: [],
                total_count: -1,
                sent_count: -1,
                open_count: -1,
            };
        
        case actions.RECEIVE_CONTACT_SENT_MESSAGES:
            return {
                ...state,
                isFetching: false,
                contactSentMessages: action.contactSentMessages,
                total_count: action.total_count,
                sent_count: action.sent_count,
                open_count: action.open_count,
            };
        
        default:
            return state;
    }
}
