import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import notifications from '../../components/feedback/notification';
import ReportFetch from "../../helpers/reportFetch";
import AdvertiserApi from "../../helpers/advertiserApi";

export function* fetchAccounts() {
  yield takeEvery(actions.FETCH_ADFORM_ACCOUNTS, function*() {
    
    const apiResponse = yield AdvertiserApi.get('adform-account', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_ADFORM_ACCOUNTS,
        accounts: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_ADFORM_ACCOUNTS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* removeAccount() {
  yield takeEvery(actions.REMOVE_ADFORM_ACCOUNT, function*(payload) {
    const {account} = payload;
  
    const accountUsageResponse = yield ReportFetch.get('adform/' + account + '/sources', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(accountUsageResponse.status !== 200)
    {
      notifications['error']({
        message: 'Account not deleted',
        description: 'We could not delete your account.'
      });
      yield put({
        type: actions.REMOVE_ADFORM_ACCOUNT_ERROR,
        errorMessage: ''
      });
      return;
    }
  
    if(accountUsageResponse.data.data > 0)
    {
      notifications['error']({
        message: 'Account not deleted',
        description: 'There are ' + accountUsageResponse.data.data + ' campaign sources that use this account. Delete sources first.'
      });
      yield put({
        type: actions.REMOVE_ADFORM_ACCOUNT_ERROR
      });
      return;
    }
  
    const apiResponse = yield AdvertiserApi.delete('adform-account/' + account, null)
      .then(response => (response))
      .catch(error => ({error}));
  
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.FETCH_ADFORM_ACCOUNTS
      });
      return;
    }
    
    yield put({
      type: actions.REMOVE_ADFORM_ACCOUNT_ERROR,
      errorMessage: ''
    });
  });
}


export function* fetchCampaigns() {
  yield takeEvery('FETCH_ADFORM_CAMPAIGNS', function*(payload) {
    const {account} = payload;
    
    const apiResponse = yield ReportFetch.get(`adform/${account}/sources-campaigns`, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_ADFORM_CAMPAIGNS,
        campaigns: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_ADFORM_CAMPAIGNS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchAccounts),
    fork(fetchCampaigns),
    fork(removeAccount),
  ]);
}
