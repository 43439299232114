import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import {push} from 'connected-react-router';
import GoogleBusinessFetch from "../../../helpers/gooleBusinessFetch";
import notifications from "../../../components/feedback/notification";

export function* fetchBusinessAccounts() {
    yield takeEvery(actions.FETCH_BUSINESS_ACCOUNTS, function*(payload) {
        const {googleAccountId} = payload;
        
        const resp = yield GoogleBusinessFetch.get('accounts?googleAccountId='+googleAccountId, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            yield put({
                type: actions.BUSINESS_ACCOUNTS_RECEIVED,
                businessAccounts: resp.data.data
            });
            return;
        }
    
        notifications['error']({
            message: 'Cannot fetch google business account!',
            description: resp.data.message
        });
        
        yield put({
            type: actions.FETCH_BUSINESS_ACCOUNTS_ERROR,
            errorMessage: resp.data.message
        });
    });
}


export function* fetchLocationsImport() {
    yield takeEvery(actions.FETCH_LOCATIONS_IMPORT, function*() {
        
        const resp = yield GoogleBusinessFetch.get('import-locations', null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            yield put({
                type: actions.LOCATIONS_IMPORT_RECEIVED,
                imports: resp.data.data
            });
            return;
        }
    
        notifications['error']({
            message: 'Error fetching imports!',
            description: resp.data.message
        });
        yield put({
            type: actions.FETCH_LOCATIONS_IMPORT_ERROR,
            errorMessage: resp.data.message
        });
    });
}

export function* createLocationsImport() {
    yield takeEvery(actions.CREATE_LOCATIONS_IMPORT, function*(payload) {
        const {googleAccountId, accountDisplayName, accountGoogleName} = payload;
        
        const resp = yield GoogleBusinessFetch.post('import-locations', {
            googleAccountId,
            account_name: accountDisplayName,
            name: accountGoogleName
        })
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            notifications['success']({
                message: 'Import started!',
                description: 'We created import for account "'+accountDisplayName+'".'
            });
            yield put({
                type: actions.LOCATIONS_IMPORT_CREATED
            });
            
            yield put({
                type: actions.FETCH_LOCATIONS_IMPORT
            });
            return;
        }

        notifications['error']({
            message: 'Failed creating import!',
            description: resp.data.message
        });
        
        yield put({
            type: actions.CREATE_LOCATIONS_IMPORT_ERROR,
            errorMessage: resp.data.message
        });
    });
}

export function* deleteLocationsImport() {
    yield takeEvery(actions.DELETE_LOCATIONS_IMPORT, function*(payload) {
        const {id} = payload;
        
        const resp = yield GoogleBusinessFetch.delete('import-locations/'+id, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            notifications['success']({
                message: 'Import deleted!',
            });
            yield put({
                type: actions.LOCATIONS_IMPORT_DELETED
            });
            yield put({
                type: actions.FETCH_LOCATIONS_IMPORT
            });
            return;
        }
    
        notifications['error']({
            message: 'Deletion failed!',
            description: resp.data.message
        });
        
        yield put({
            type: actions.DELETE_LOCATIONS_IMPORT_ERROR,
            errorMessage: resp.data.message
        });
    });
}

export function* fetchLocationsPreviews() {
    yield takeEvery(actions.FETCH_GOOGLE_LOCATIONS_PREVIEWS, function*() {
        
        const resp = yield GoogleBusinessFetch.get('all-locations', null)
            .then(response => (response))
            .catch(error => ({error}));
        
        
        if(resp.status === 200)
        {
            yield put({
                type: actions.RECEIVE_GOOGLE_LOCATIONS_PREVIEWS,
                locationsPreviews: resp.data.data
            });
            return;
        }
        
        notifications['error']({
            message: 'Fetching locations previews failed!',
            description: resp.data.message
        });
        
        yield put({
            type: actions.FETCH_GOOGLE_LOCATIONS_PREVIEWS_ERROR,
            errorMessage: resp.data.message
        });
    });
}

export function* fetchLocations() {
    yield takeEvery(actions.FETCH_GOOGLE_LOCATIONS, function*(payload) {
        let {page} = payload;
        if(!page)
            page = 1;
        
        let phrase = yield select((state) => state.GoogleLocations.searchPhrase);
        let perPage = yield select((state) => state.GoogleLocations.perPage);
        
        let addSearch = '';
        if(phrase)
            addSearch = '&searchPhrase='+phrase;
        
        const resp = yield GoogleBusinessFetch.get(`locations?page=${page}&per_page=${perPage}${addSearch}`, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        
        if(resp.status === 200)
        {
            yield put({
                type: actions.RECEIVE_GOOGLE_LOCATIONS,
                locations: resp.data.data.locations,
                totalLocations: resp.data.data.total_all,
                page
            });
            return;
        }
    
        notifications['error']({
            message: 'Fetching locations failed!',
            description: resp.data.message
        });
        
        yield put({
          type: actions.FETCH_GOOGLE_LOCATIONS_ERROR,
          errorMessage: resp.data.message
        });
    });
}

export function* fetchLocation() {
    yield takeEvery(actions.FETCH_GOOGLE_LOCATION, function*(payload) {
        let {id} = payload;
        
        const resp = yield GoogleBusinessFetch.get(`locations/${id}`, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        
        if(resp.status === 200)
        {
            yield put({
                type: actions.RECEIVE_GOOGLE_LOCATION,
                location: resp.data.data,
            });
            return;
        }
        
        notifications['error']({
            message: 'Fetching location failed!',
            description: resp.data.message
        });
        
        yield put({
            type: actions.FETCH_GOOGLE_LOCATION_ERROR,
            errorMessage: resp.data.message
        });
    });
}

export function* deleteLocation() {
    yield takeEvery(actions.DELETE_GOOGLE_LOCATION, function*(payload) {
        const {locationId} = payload;
        
        const resp = yield GoogleBusinessFetch.delete('locations/'+locationId, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            notifications['success']({
                message: 'Location deleted!',
            });
            yield put({
                type: actions.GOOGLE_LOCATION_DELETED,
                id: locationId
            });
            yield put(push('/google-business/locations'));
            return;
        }
        
        notifications['error']({
            message: 'Deletion failed!',
            description: resp.data.message
        });
        
        yield put({
            type: actions.DELETE_GOOGLE_LOCATION_ERROR,
            errorMessage: resp.data.message
        });
    });
}



export default function* rootSaga() {
    yield all([
        fork(fetchLocations),
        fork(fetchBusinessAccounts),
        fork(fetchLocationsImport),
        fork(createLocationsImport),
        fork(deleteLocationsImport),
        fork(deleteLocation),
        fork(fetchLocationsPreviews),
        fork(fetchLocation),
    ]);
}
