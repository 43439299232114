const actions = {
    
    SET_LOCATION_GROUP: 'SET_LOCATION_GROUP',
    
    FETCH_LOCATION_GROUPS: 'FETCH_LOCATION_GROUPS',
    FETCH_LOCATION_GROUPS_ERROR: 'FETCH_LOCATION_GROUPS_ERROR',
    RECEIVE_LOCATION_GROUPS: 'RECEIVE_LOCATION_GROUPS',
    
    CREATE_LOCATION_GROUP: 'CREATE_LOCATION_GROUP',
    CREATE_LOCATION_GROUP_ERROR: 'CREATE_LOCATION_GROUP_ERROR',
    LOCATION_GROUP_CREATED: 'LOCATION_GROUP_CREATED',
    
    UPDATE_LOCATION_GROUP: 'UPDATE_LOCATION_GROUP',
    UPDATE_LOCATION_GROUP_ERROR: 'UPDATE_LOCATION_GROUP_ERROR',
    LOCATION_GROUP_UPDATED: 'LOCATION_GROUP_UPDATED',
    
    DELETE_LOCATION_GROUP: 'DELETE_LOCATION_GROUP',
    DELETE_LOCATION_GROUP_ERROR: 'DELETE_LOCATION_GROUP_ERROR',
    LOCATION_GROUP_DELETED: 'LOCATION_GROUP_DELETED',
    
    setLocationGroup: (locationGroup) => ({
        type: actions.SET_LOCATION_GROUP,
        locationGroup
    }),
    
    fetchLocationGroups: () => ({
        type: actions.FETCH_LOCATION_GROUPS
    }),
    
    createLocationGroup: (values) => ({
        type: actions.CREATE_LOCATION_GROUP,
        values
    }),
    
    updateLocationGroup: (id, values) => ({
        type: actions.UPDATE_LOCATION_GROUP,
        values,
        id
    }),
    
    deleteLocationGroup: (id) => ({
        type: actions.DELETE_LOCATION_GROUP,
        id
    }),
    
};
export default actions;
