import actions from './actions';

const initState = { groups: [], createdGroup: null, reportsPerms: [], leadsPerms: [], isFetching: false, isFetchingPerms: false};

export default function groupsReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.FETCH_GROUPS:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.FETCH_GROUPS_ERROR:
      return {
        ...state,
        isFetching: false
      };
    
    case actions.RECEIVE_GROUPS:
      return {
        ...state,
        isFetching: false,
        groups: action.groups
      };
  
    case actions.CREATE_GROUP:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.CREATE_GROUP_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.GROUP_CREATED:
      return {
        ...state,
        isFetching: false,
        createdGroup: action.createdGroup
      };
  
    case actions.ASSIGN_GROUP_USER:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.ASSIGN_GROUP_USER_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.GROUP_USER_ASSIGNED:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.FETCH_REPORTS_PERMS:
      return {
        ...state,
        isFetchingPerms: true
      };
  
    case actions.FETCH_REPORTS_PERMS_ERROR:
      return {
        ...state,
        isFetchingPerms: false
      };
  
    case actions.RECEIVE_REPORTS_PERMS:
      return {
        ...state,
        isFetchingPerms: false,
        reportsPerms: action.perms
      };
  
    case actions.ADD_REPORTS_PERMS:
      return {
        ...state,
        isFetchingPerms: true
      };
  
    case actions.ADD_REPORTS_PERMS_ERROR:
      return {
        ...state,
        isFetchingPerms: false
      };
  
    case actions.REMOVE_REPORTS_PERMS:
      return {
        ...state,
        isFetchingPerms: true
      };
  
    case actions.REMOVE_REPORTS_PERMS_ERROR:
      return {
        ...state,
        isFetchingPerms: false
      };
  
    case actions.FETCH_LEADS_PERMS:
      return {
        ...state,
        isFetchingPerms: true
      };
  
    case actions.FETCH_LEADS_PERMS_ERROR:
      return {
        ...state,
        isFetchingPerms: false
      };
  
    case actions.RECEIVE_LEADS_PERMS:
      return {
        ...state,
        isFetchingPerms: false,
        leadsPerms: action.perms
      };
  
    case actions.ADD_LEADS_PERMS:
      return {
        ...state,
        isFetchingPerms: true
      };
  
    case actions.ADD_LEADS_PERMS_ERROR:
      return {
        ...state,
        isFetchingPerms: false
      };
  
    case actions.REMOVE_LEADS_PERMS:
      return {
        ...state,
        isFetchingPerms: true
      };
  
    case actions.REMOVE_LEADS_PERMS_ERROR:
      return {
        ...state,
        isFetchingPerms: false
      };
      
    default:
      return state;
  }
}
