import packageJson from '../../package.json';

export default {
  apiUrl: process.env.REACT_APP_AUTH_URL
};

const siteConfig = {
  siteName: 'Bitetrack',
  siteIcon: 'ion-flash',
  footerText: 'Bitetrack. Marketing automation.',
  appVersion: packageJson.version
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};
const language = 'english';

const jwtConfig = {
  leadsApiUrl: process.env.REACT_APP_LEADS_URL,
  reportsApiUrl: process.env.REACT_APP_REPORTS_URL,
  fetchUrl: process.env.REACT_APP_AUTH_URL,
  deliveryApiUrl: process.env.REACT_APP_DELIVERY_URL,
  googleBussinesApi: process.env.REACT_APP_GOOGLE_BUSSINES_API_URL,
};

export { siteConfig, language, themeConfig, jwtConfig };
