import moment from "moment";
import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import notifications from "../../components/feedback/notification";
import actions from './actions';
import ReportFetch from "../../helpers/reportFetch";
import {createBrowserHistory} from "history";

export const getSourceQueue = (state) => state.Sources.queuedSources;

export function* fetchSources() {
  yield takeEvery('FETCH_SOURCES', function*(payload) {
    const {campaignId} = payload;
    let query = "?";
    
    if(campaignId)
    {
      query += "campaign=" + campaignId
    }
    
    const apiResponse = yield ReportFetch.get('sources' + query, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_SOURCES,
        sources: apiResponse.data
      });
      
      for(let i = 0; i < apiResponse.data.length; i++)
      {
        yield put({
          type: actions.FETCH_SOURCE_REPORTS,
          sourceId: apiResponse.data[i].id
        });
      }
      return;
    }

    yield put({
      type: actions.FETCH_SOURCES_ERROR,
      errorMessage: apiResponse.message
    });
  });
}

export function* fetchSourceReports() {
  yield takeEvery(actions.FETCH_SOURCE_REPORTS, function*(payload) {
    const {sourceId} = payload;
    let query = "?";
    
    if(sourceId)
    {
      query += "source=" + sourceId;
    }
    
    // let fromDate = moment('2019-11-01 00:00:00');
    let fromDate = yield select((state) => state.Campaigns.fromDate);
    let fromDateTimestamp = Math.round((fromDate.toDate().getTime() / 1000));
    query += "&fromDate=" + fromDateTimestamp;
  
    // let toDate = moment('2019-11-02 23:59:59');
    let toDate = yield select((state) => state.Campaigns.toDate);
    let toDateTimestamp = Math.round((toDate.toDate().getTime() / 1000));
    query += "&toDate=" + toDateTimestamp;
    
    const apiResponse = yield ReportFetch.get('reports' + query, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_SOURCE_REPORTS,
        sourceId,
        reports: apiResponse.data.data
      });
      return;
    }
    
    console.error(apiResponse);
    yield put({
      type: actions.FETCH_SOURCE_REPORTS_ERROR,
      errorMessage: apiResponse.message
    });
  });
}

export function* checkIfSourceExist() {
  yield takeEvery('SET_SOURCE', function*(payload) {
    const {sourceId} = payload;
    let sources = yield select((state) => state.Sources.sources);
  
    let source = sources.find(source => {
      return source.id == sourceId;
    });
    
    if(source)
      return;
  
    let campaign = yield select((state) => state.Campaigns.campaign);
    
    yield put(push('../' + campaign.id));
    
  });
}

export function* patchSource() {
  yield takeEvery(actions.PATCH_SOURCE, function*(payload) {
    const {source, sourceValues} = payload;
    let campaign = yield select((state) => state.Campaigns.campaign);
  
    if(campaign == null)
    {
      yield put({
        type: actions.PATCH_SOURCE_ERROR,
        errorMessage: 'No campaign selected'
      });
      return;
    }
    
    const resp = yield ReportFetch.PATCH('sources/' + source.id, sourceValues)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.SOURCE_PATCHED,
      });
  
      yield put({ type: actions.FETCH_SOURCES, campaignId: campaign.id });
      return;
    }
    
    yield put({
      type: actions.PATCH_CAMPAIGN_ERROR,
      errorMessage: resp.message
    });
  });
}

export function* deleteSource() {
  yield takeEvery(actions.DELETE_SOURCE, function*(payload) {
    const {source} = payload;
    
    const resp = yield ReportFetch.delete('sources/' + source.id, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.SOURCE_DELETED,
      });
  
      let campaign = yield select((state) => state.Campaigns.campaign);
      yield put(push('../' + campaign.id));
      yield put({ type: 'FETCH_CAMPAIGN', campaignId: campaign.id });
      
      return;
    }

    yield put({
      type: actions.DELETE_SOURCE_ERROR,
      errorMessage: resp.data.message
    });
  });
}

export function* processQueuedSources() {
  yield takeEvery('PROCESS_QUEUED_SOURCES', function*(payload) {
    const {campaign} = payload;
    let query = "?";
  
    if(campaign)
    {
      query += "campaign=" + campaign.id
    }
    
    let queuedSources = yield select(getSourceQueue);
    let createBody = {
      newSources: queuedSources
    };
    
    
    const apiResponse = yield ReportFetch.post('sources' + query, createBody)
      .then(response => (response))
      .catch(error => ({error}));
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.QUEUED_SOURCES_PROCESSED
      });
      yield put({ type: actions.FETCH_SOURCES, campaignId: campaign.id });
      yield put({ type: 'FETCH_CAMPAIGN', campaignId: campaign.id });
      return;
    }

    if(apiResponse.data.hasOwnProperty('status') && apiResponse.data.status === 'ERROR'){
      notifications['error']({
        message: 'Sources creation error!',
        description: apiResponse.data.data.message
      });

      switch (apiResponse.data.data.error) {
        case 'NOT_UNIQUE_IDENTIFIER':
          yield put({
            type: actions.PROCESS_QUEUED_SOURCES_ERROR,
            duplicates: apiResponse.data.data.duplicates
          });
          break;
      }
      return;
    }

    yield put({
      type: actions.PROCESS_QUEUED_SOURCES_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* updateSourceSubType() {
  yield takeEvery('REQUEST_SOURCE_SUBTYPE_UPDATE', function*(payload) {
    const {sourceTypeEnum, sourceSubTypeId} = payload;
    let campaign = yield select((state) => state.Campaigns.campaign);
    let user = yield select((state) => state.Auth.user);
    
    if(campaign == null)
    {
      yield put({
        type: actions.REQUEST_SOURCE_SUBTYPE_UPDATE_ERROR,
        errorMessage: 'No campaign selected'
      });
      return;
    }
  
    let url = '';
    switch(sourceTypeEnum)
    {
      case 'TYPE_GOOGLE_ADS':
        url = `update-data/adwords/${campaign.id}/${sourceSubTypeId}`;
        break;
        
      case 'TYPE_FACEBOOK_INSIGHTS':
        url = `update-data/facebook/${campaign.id}/${sourceSubTypeId}`;
        break;
  
      case 'TYPE_AD_FORM':
        url = `update-data/adform/${campaign.id}/${sourceSubTypeId}`;
        break;

      case 'TYPE_DOUBLE_CLICK':
        url = `update-data/doubleclick/${campaign.id}/${sourceSubTypeId}`;
        break;
    }
    
    const apiResponse = yield ReportFetch.get(url, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.SOURCE_SUBTYPE_UPDATED,
      });
      
      if(user.role === 'ROLE_SUPER_ADMIN')
      {
        yield put({ type: actions.FETCH_SOURCES, campaignId: campaign.id });
        return;
      }
  
      // for user im downloading whole campaign again,
      // to show update progress bar
      // TODO Make endpoint for fetching JUST campaign_rate_limits
      switch(sourceTypeEnum)
      {
        case 'TYPE_AD_FORM':
        case 'TYPE_DOUBLE_CLICK':
          notifications['info']({
            message: 'Update request received!',
            description: 'New data should be available in minutes.'
          });
          break;
      }
      yield put({ type: "FETCH_CAMPAIGN", campaignId: campaign.id });
      return;
    }
    
    console.error(apiResponse.data);
    yield put({
      type: actions.REQUEST_SOURCE_SUBTYPE_UPDATE_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* redirectSourcesOnFilteredLeads() {
  yield takeEvery('REDIRECT_SOURCE_AD_IDS', function*(payload) {
    const {sourceIds} = payload;
    const query = '?query='+JSON.stringify({
      sourceIds
    });
    const apiResponse = yield ReportFetch.get('sources-ad-ids' + query)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      const data = apiResponse.data.data;

      if(!data.hasOwnProperty('ad_ids'))
        return;

      if(!data.ad_ids.length){
        notifications['info']({
          message: 'Searching Leads',
          description: 'Not enough data to get Leads.'
        });
        return;
      }

      const history = createBrowserHistory({forceRefresh: true});
      yield put(history.push(`/leads/prospects/filter-by-adid/` + data.ad_ids.join(',') ));
      return;
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchSources),
    fork(fetchSourceReports),
    fork(checkIfSourceExist),
    fork(updateSourceSubType),
    fork(patchSource),
    fork(deleteSource),
    fork(processQueuedSources),
    fork(redirectSourcesOnFilteredLeads),
  ]);
}
