import moment from "moment";
import actions from './actions';

const initState = {
  campaign: null,
  campaigns: [],
  deletingCampaign: null,
  createdCampaign: null,
  clonedCampaign: null,
  isCreating: false,
  isFetching: false,
  isFetchingSingle: false,
  isFetchingSettings: false,
  isNotifyingDepartments: false,
  settingsUpdate: 0,
  fromDate: moment(),
  toDate: moment().subtract(30, 'days'),
  userDateProvided: false,
  createCampaignErrors: [],
};

const FindCampaignIndex = (campaignId, campaigns) => {
  return campaigns.findIndex(campaign => {
    return campaign.id === campaignId;
  });
};

const FindCampaign = (campaignId, campaigns) => {
  return campaigns.find(campaign => {
    return campaign.id === campaignId;
  });
};

export default function campaignReducer(state = initState, action) {
  let {campaigns, campaign} = state;
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
  
    case actions.FETCH_CAMPAIGNS:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.FETCH_CAMPAIGN:
      return {
        ...state,
        isFetchingSingle: true,
        userDateProvided: false
      };
      
    case actions.FETCH_CAMPAIGNS_ERROR:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_CAMPAIGNS:
      return {
        ...state,
        isFetching: false,
        campaigns: action.campaigns
      };
  
    case actions.RECEIVE_CAMPAIGN:
      return {
        ...state,
        isFetchingSingle: false,
        campaign: action.campaign,
        clonedCampaign: null
      };

    case actions.ASYNC_CREATE_CAMPAIGN:
    case actions.CREATE_CAMPAIGN:
      // let {newCampaign} = action;
      return {
        ...state,
        isCreating: true
      };
      
    case actions.PATCH_CAMPAIGN:
      // let {newCampaign} = action;
      return {
        ...state,
        isFetchingSingle: true,
        campaign: null,
      };
  
    case actions.PATCH_CAMPAIGN_ERROR:
      // let {newCampaign} = action;
      return {
        ...state,
        isFetchingSingle: false,
        campaign: action.campaign,
        createCampaignErrors: action.errors
      };
  
    // case actions.CAMPAIGN_PATCHED:
    //   // let {newCampaign} = action;
    //   return {
    //     ...state
    //   };
    
    case actions.CAMPAIGN_CREATED:
      let {createdCampaign} = action;
      // campaigns.unshift(createdCampaign);
      return {
        ...state,
        isCreating: false,
        createdCampaign,
        campaigns
      };

    case actions.CREATE_CAMPAIGN_ERROR:
      return {
        ...state,
        isCreating: false,
        createCampaignErrors: action.errors,
      };
  
    case actions.DELETE_CAMPAIGN:

      let deletingCampaign = FindCampaign(action.campaignId, campaigns);
      return {
        ...state,
        deletingCampaign,
      };
      
    case actions.CAMPAIGN_DELETED:
      let index = FindCampaignIndex(action.campaignId, campaigns);
      campaigns.splice(index, 1);
      return {
        ...state,
        campaigns
      };
  
    case actions.PATCH_CAMPAIGN_SETTINGS:
      return {
        ...state,
        isFetchingSettings: true,
      };
  
    case actions.PATCH_CAMPAIGN_SETTINGS_ERROR:
      return {
        ...state,
        isFetchingSettings: false,
      };
  
    case actions.CAMPAIGN_SETTINGS_PATCHED:
      campaign.settings = action.settings;
      return {
        ...state,
        isFetchingSettings: false,
        campaign,
        settingsUpdate: state.settingsUpdate + 1,
      };
  
    case actions.CLONE_CAMPAIGN:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.CLONE_CAMPAIGN_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.CAMPAIGN_CLONED:
      let {clonedCampaign} = action;
      return {
        ...state,
        isFetching: false,
        clonedCampaign
      };
  
    case actions.NOTIFY_CAMPAIGN_DEPARMENTS:
      return {
        ...state,
        isNotifyingDepartments: true
      };
  
    case actions.NOTIFY_CAMPAIGN_DEPARMENTS_ERROR:
      return {
        ...state,
        isNotifyingDepartments: false
      };
  
    case actions.CAMPAIGN_DEPARMENTS_NOTIFIED:
      return {
        ...state,
        isNotifyingDepartments: false
      };
      
    case actions.SET_DATERANGE:
      let {fromDate, toDate, userDateProvided} = action;
      return {
        ...state,
        fromDate,
        toDate,
        userDateProvided
      };
    
    default:
      return state;
  }
}
