import actions from './actions';

const initState = {
  ads: [],
  isFetchingAds: false,

  modal: {},
  isModalOpen: false,
  isModalFetching: false,
};


export default function adsPreviewReducers(state = initState, action) {
  switch (action.type) {

    case actions.FETCH_ADS:
      return {
        ...state,
        isFetchingAds: true,
      };
  
    case actions.FETCH_ADS_ERROR:
      return {
        ...state,
        isFetchingAds: false,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_ADS:
      return {
        ...state,
        isFetchingAds: false,
        ads: action.ads
      };

    case actions.FETCH_AD_FACEBOOK:
      return {
        ...state,
        isModalFetching: true,
      };

    case actions.FETCH_AD_FACEBOOK_ERROR:
      return {
        ...state,
        isModalFetching: false,
        errorMessage: action.errorMessage
      };

    case actions.RECEIVE_AD_FACEBOOK:
      return {
        ...state,
        isModalFetching: false,
        modal: [action.ad]
      };

    case actions.SET_AD_PREVIEW_MODAL:
      return {
        ...state,
        modal: action.modal
      };

    case actions.SET_AD_PREVIEW_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: action.lead.id
      };

    case actions.SET_AD_PREVIEW_MODAL_CLOSE:
      return {
        ...state,
        isModalOpen: false
      };

    case actions.SET_AD_PREVIEW_MODAL_FETCHING:
      return {
        ...state,
        isModalFetching: true
      };

    case actions.UNSET_AD_PREVIEW_MODAL_FETCHING:
      return {
        ...state,
        isModalFetching: false
      };
      
    default:
      return state;
  }
}
