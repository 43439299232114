import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import DeliveryFetch from "../../../helpers/deliveryFetch";
import notifications from "../../../components/feedback/notification";

export function* fetchSmsNames() {
  yield takeEvery(actions.FETCH_SMS_NAMES, function*() {
    
    const resp = yield DeliveryFetch.get('sms-names', null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(resp.status === 200)
    {
      yield put({
        type: actions.RECEIVE_SMS_NAMES,
        smsNames: resp.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_SMS_NAMES_ERROR,
      errorMessage: resp.error
    });
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchSmsNames),
  ]);
}
