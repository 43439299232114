import React from 'react';
import {Link} from "react-router-dom";
import ImageCellView from './imageCell';
import DeleteCell from './deleteCell';
import EditableCell from './editableCell';
import FilterDropdown from './filterDropdown';

const DateCell = (data, withTime = true) => {
  return withTime
    ? <p>{data.toLocaleDateString() + ' ' + data.toLocaleTimeString()}</p>
    : <p>{data.toLocaleDateString()}</p>
};
const ImageCell = src => <ImageCellView src={src} />;
const LinkCell = (link, href, disabled = false) => <Link style={{fontWeight: 'bold', fontSize: 14, pointerEvents: disabled ? 'none' : 'all'}} to={href ? href : '#'}>{link}</Link>;
const TextCell = text => <p>{text}</p>;
const BoldTextCell = text => <p style={{fontWeight: 'bold'}}>{text}</p>;

export {
  DateCell,
  ImageCell,
  LinkCell,
  TextCell,
  EditableCell,
  DeleteCell,
  FilterDropdown
};
