import actions from './actions';

const initState = {
    isFetching: false,
    contacts: [],
    currentProspectContact: null,
    contactCampaigns: [],
    contactMessages: [],
    page: 1,
    total_contacts: -1,
    validated_contacts: -1,
    error_contacts: -1,
};

export default function contactReducer(state = initState, action) {
    switch (action.type) {
        
        case 'SET_ADVERTISER':
            return {
                ...initState
            };
        
        case 'FETCH_PROSPECT_DATA':
            return {
                ...state,
                currentProspectContact: initState.currentProspectContact,
                contactCampaigns: initState.contactCampaigns,
                contactMessages: initState.contactMessages,
            };
    
        case actions.CLEAR_CONTACTS:
            return {
                ...state,
                total_contacts: -1,
                contacts: [],
                validated_contacts: -1,
                error_contacts: -1,
            };
            
        case actions.FETCH_CONTACTS:
            return {
                ...state,
                isFetching: true,
                total_contacts: -1,
                contacts: [],
                validated_contacts: -1,
                error_contacts: -1,
            };
    
        case actions.RECEIVE_CONTACTS:
            return {
                ...state,
                isFetching: false,
                page: action.page,
                contacts: action.contacts,
                total_contacts: action.total_contacts,
                validated_contacts: action.validated_contacts,
                error_contacts: action.error_contacts,
            };
    
        case actions.SET_CONTACTS_PAGE:
            return {
                ...state,
                page: action.page
            };
            
        case actions.FETCH_PROSPECT_CONTACT:
            return {
                ...state,
                isFetching: true
            };
        
        case actions.FETCH_PROSPECT_CONTACT_ERROR:
            return {
                ...state,
                isFetching: false
            };
        
        case actions.RECEIVE_PROSPECT_CONTACT:
            return {
                ...state,
                isFetching: false,
                currentProspectContact: action.currentProspectContact
            };
    
        case actions.FETCH_PROSPECT_CONTACT_DETAILS:
            return {
                ...state,
                isFetching: true
            };
    
        case actions.FETCH_PROSPECT_CONTACT_DETAILS_ERROR:
            return {
                ...state,
                isFetching: false
            };
    
        case actions.RECEIVE_PROSPECT_CONTACT_DETAILS:
            return {
                ...state,
                isFetching: false,
                currentProspectContact: action.currentProspectContact
            };
    
        case actions.FETCH_PROSPECT_CONTACT_CAMPAIGNS:
            return {
                ...state,
                isFetching: true
            };
    
        case actions.FETCH_PROSPECT_CONTACT_CAMPAIGNS_ERROR:
            return {
                ...state,
                isFetching: false
            };
    
        case actions.RECEIVE_PROSPECT_CONTACT_CAMPAIGNS:
            return {
                ...state,
                isFetching: false,
                contactCampaigns: action.contactCampaigns
            };
    
        case actions.FETCH_PROSPECT_CONTACT_MESSAGES:
            return {
                ...state,
                isFetching: true
            };
    
        case actions.FETCH_PROSPECT_CONTACT_MESSAGES_ERROR:
            return {
                ...state,
                isFetching: false
            };
    
        case actions.RECEIVE_PROSPECT_CONTACT_MESSAGES:
            return {
                ...state,
                isFetching: false,
                contactMessages: action.contactMessages
            };
        
        default:
            return state;
    }
}
