import React from 'react';
import { Link } from 'react-router-dom';
import { siteConfig } from '../../settings';
import pageLogo from '../../image/logo.png';
import pageDarkLogo from '../../image/logoDark.png';
import favicon from '../../image/favicon.png';

export default ({ collapsed, haveSidebar }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/dashboard">
              <img src={favicon} style={{width: 40}} alt="Bitetrack - Marketing Automation"/>
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/dashboard" style={{textAlign: 'left', paddingLeft: 20}}><img src={haveSidebar ? pageLogo : pageDarkLogo} style={{width: 150}} alt="Bitetrack - Marketing Automation"/></Link>
        </h3>
      )}
    </div>
  );
};
