const opposAction = {
    CHANGE_OPPO: 'CHANGE_OPPO',
    FETCH_OPPOS: 'FETCH_OPPOS',
    RECEIVE_OPPOS: 'RECEIVE_OPPOS',
    ADD_OPPO: 'ADD_OPPO',
    RECEIVE_OPPO: 'RECEIVE_OPPO',
    EDIT_OPPO: 'EDIT_OPPO',
    SAVE_OPPO: 'SAVE_OPPO',
    DELETE_OPPO: 'DELETE_OPPO',
    
    fetchOppos: prospectId => {
        return (dispatch) => {
            dispatch({
                type: opposAction.FETCH_OPPOS,
                prospectId
            });
        };
    },
    
    changeOppo: id => {
        return (dispatch, getState) => {
            dispatch({
                type: opposAction.CHANGE_OPPO,
                selectedId: id
            });
        };
    },
    
    addOppo: (prospectId) => {
        return (dispatch, getState) => {
            const newNote = {
                note: 'New Opportunity',
                prospect: prospectId
            };
            const notes = getState().Oppos.notes;
            dispatch({
                type: opposAction.ADD_OPPO,
                newNote,
                oldNotes: notes,
                prospectId
            });
        };
    },
    
    editOppo: (id, newNote) => {
        return (dispatch, getState) => {
            const oldNotes = getState().Oppos.notes;
            const notes = [];
            oldNotes.forEach(note => {
                if (note.id !== id) {
                    notes.push(note);
                } else {
                    note.note = newNote;
                    notes.push(note);
                }
            });
            dispatch({
                type: opposAction.EDIT_OPPO,
                notes
            });
        };
    },
    
    saveOppo: (id, newText) => {
        return (dispatch, getState) => {
            dispatch({
                type: opposAction.SAVE_OPPO,
                newText,
                id
            });
        };
    },
    
    deleteOppo: id => {
        return (dispatch, getState) => {
            const oldNotes = getState().Oppos.notes;
            const notes = [];
            oldNotes.forEach(note => {
                if (note.id !== id) {
                    notes.push(note);
                }
            });
            let selectedId = getState().Oppos.selectedId;
            if (selectedId === id) {
                if (notes.length === 0) {
                    selectedId = undefined;
                } else {
                    selectedId = notes[0].id;
                }
            }
            dispatch({
                type: opposAction.DELETE_OPPO,
                notes,
                deleteId: id,
                selectedId
            });
        };
    }
};
export default opposAction;
