const actions = {
  
  templateModel: {
  
  },
  
  CREATE_MESSAGE_CAMPAIGN: 'CREATE_MESSAGE_CAMPAIGN',
  CREATE_MESSAGE_CAMPAIGN_ERROR: 'CREATE_MESSAGE_CAMPAIGN_ERROR',
  MESSAGE_CAMPAIGN_CREATED: 'MESSAGE_CAMPAIGN_CREATED',
  
  UPDATE_MESSAGE_CAMPAIGN: 'UPDATE_MESSAGE_CAMPAIGN',
  UPDATE_MESSAGE_CAMPAIGN_ERROR: 'UPDATE_MESSAGE_CAMPAIGN_ERROR',
  MESSAGE_CAMPAIGN_UPDATED: 'MESSAGE_CAMPAIGN_UPDATED',
  
  FETCH_MESSAGE_CAMPAIGNS: 'FETCH_MESSAGE_CAMPAIGNS',
  RECEIVE_MESSAGE_CAMPAIGNS: 'RECEIVE_MESSAGE_CAMPAIGNS',
  
  FETCH_MESSAGE_CAMPAIGN: 'FETCH_MESSAGE_CAMPAIGN',
  FETCH_MESSAGE_CAMPAIGN_ERROR: 'FETCH_MESSAGE_CAMPAIGN_ERROR',
  RECEIVE_MESSAGE_CAMPAIGN: 'RECEIVE_MESSAGE_CAMPAIGN',
  
  SEND_TEST_MESSAGE_CAMPAIGN: 'SEND_TEST_MESSAGE_CAMPAIGN',
  SEND_TEST_MESSAGE_CAMPAIGN_ERROR: 'SEND_TEST_MESSAGE_CAMPAIGN_ERROR',
  TEST_MESSAGE_CAMPAIGN_SENT: 'TEST_MESSAGE_CAMPAIGN_SENT',
  
  DELETE_MESSAGE_CAMPAIGN: 'DELETE_MESSAGE_CAMPAIGN',
  DELETE_MESSAGE_CAMPAIGN_ERROR: 'DELETE_MESSAGE_CAMPAIGN_ERROR',
  MESSAGE_CAMPAIGN_DELETED: 'MESSAGE_CAMPAIGN_DELETED',
  
  CLEAR_MESSAGE_CAMPAIGN: 'CLEAR_MESSAGE_CAMPAIGN',
  
  SEND_MESSAGE_CAMPAIGN: 'SEND_MESSAGE_CAMPAIGN',
  SEND_MESSAGE_CAMPAIGN_ERROR: 'SEND_MESSAGE_CAMPAIGN_ERROR',
  MESSAGE_CAMPAIGN_SENT: 'MESSAGE_CAMPAIGN_SENT',

  PATCH_SCHEDULED_MESSAGE_CAMPAIGN_MESSAGES: 'PATCH_SCHEDULED_MESSAGE_CAMPAIGN_MESSAGES',
  PATCH_SCHEDULED_MESSAGE_CAMPAIGN_MESSAGES_ERROR: 'PATCH_SCHEDULED_MESSAGE_CAMPAIGN_MESSAGES_ERROR',
  SCHEDULED_MESSAGE_CAMPAIGN_MESSAGES_PATCHED: 'SCHEDULED_MESSAGE_CAMPAIGN_MESSAGES_PATCHED',

  fetchMessageCampaigns: () => ({
    type: actions.FETCH_MESSAGE_CAMPAIGNS
  }),
  
  fetchMessageCampaign: (campaignId) => ({
    type: actions.FETCH_MESSAGE_CAMPAIGN,
    campaignId
  }),
  
  deleteMessageCampaign: (campaignId) => ({
    type: actions.DELETE_MESSAGE_CAMPAIGN,
    campaignId
  }),
  
  createMessageCampaign: (campaign) => ({
    type: actions.CREATE_MESSAGE_CAMPAIGN,
    campaign
  }),
  
  updateMessageCampaign: (campaign, campaignId) => ({
    type: actions.UPDATE_MESSAGE_CAMPAIGN,
    campaign,
    campaignId
  }),
  
  sendTestMessageCampaign: (campaignId, testEmail) => ({
    type: actions.SEND_TEST_MESSAGE_CAMPAIGN,
    testEmail,
    campaignId
  }),
  
  sendCampaign: (campaignId, listId) => ({
    type: actions.SEND_MESSAGE_CAMPAIGN,
    listId,
    campaignId
  }),

  patchScheduledCampaignMessages: (campaignId, listId) => ({
    type: actions.PATCH_SCHEDULED_MESSAGE_CAMPAIGN_MESSAGES,
    campaignId,
    listId
  }),
  
  clearCampaign: () => ({
    type: actions.CLEAR_MESSAGE_CAMPAIGN
  }),
  
};
export default actions;
