import actions from './actions';

export const DEFAULT_PRIMARY_AD_ACCOUNT = {
  id: null,
  type: null,
  platform_account: '',
  ad_account: '',
};

const initState = {
  facebookAccount: DEFAULT_PRIMARY_AD_ACCOUNT,

  isFetchingAccount: false,
};

export default function primaryAdAccountReducer(state = initState, action) {
  switch (action.type) {
  
    case actions.FETCH_PRIMARY_AD_ACCOUNT:
      return {
        ...initState,
        isFetchingAccount: true,
      };
  
    case actions.FETCH_PRIMARY_AD_ACCOUNT_ERROR:
      return {
        ...state,
        isFetchingAccount: false,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_PRIMARY_FACEBOOK_ACCOUNT:
      return {
        ...state,
        isFetchingAccount: false,
        facebookAccount: action.account || initState.facebookAccount,
      };

    case actions.DELETE_PRIMARY_AD_ACCOUNT:
      return {
        ...state,
        isFetchingAccount: true,
      };

    case actions.DELETE_PRIMARY_AD_ACCOUNT_ERROR:
      return {
        ...state,
        isFetchingAccount: false,
      };

    case actions.PRIMARY_FACEBOOK_ACCOUNT_DELETED:
      return {
        ...state,
        facebookAccount: initState.facebookAccount,
        isFetchingAccount: false,
      };

    default:
      return state;
  }
}
