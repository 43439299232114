import actions from './actions';

const initState = {
  sources: [],
  source: null,
  isFetching: false,
  isFetchingSingle: false,
  incompleteSources: [],
  queuedSources: [],
  queuedSourcesErrors: {
    duplicates: []
  },
  queueSize: 0,
  queueUpdate: false,
  isProcessing: false,
  
  updatingSourceSubType: null,
};

const indexOfQueuedSource = (source, sourceType, queuedSources) => {
  for(let i = 0; i < queuedSources.length; i++)
  {
    if(queuedSources[i].id === source.id
      && queuedSources[i].sourceType === sourceType)
      return i;
  }
  return -1;
};

const findSourceIndex = (sourceId, sources) =>
{
  for(let i = 0; i < sources.length; i++)
  {
    if(sources[i].id === sourceId)
      return i;
  }
  return -1;
};

export default function sourceReducers(state = initState, action) {
  let {sources, queuedSources, queuedSourcesErrors} = state;
  
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.FETCH_SOURCES:
      return {
        ...state,
        isFetching: true,
        sources: initState.sources
      };
      
    case actions.FETCH_SOURCES_ERROR:
      return {
        ...state,
        isFetching: false,
        updatingSourceSubType: initState.updatingSourceSubType,
        errorMessage: action.errorMessage
      };
    
    case actions.RECEIVE_SOURCES:
      action.sources.forEach(source => {
        source.ready = false;
      });
      
      return {
        ...state,
        isFetching: false,
        updatingSourceSubType: initState.updatingSourceSubType,
        sources: action.sources
      };
  
    case actions.FETCH_SOURCE_REPORTS:
      return {
        ...state,
        incompleteSources: [...state.incompleteSources, action.sourceId]
      };
  
    case actions.FETCH_SOURCE_REPORTS_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_SOURCE_REPORTS:
      let sourceIndex = findSourceIndex(action.sourceId, sources);
      if(sourceIndex === -1)
      {
        return {
          ...state,
        };
      }

      let newSources = [...sources];
      let newSource = {...newSources[sourceIndex]};
      newSource.reports = action.reports;
      newSource.ready = true;
      newSources[sourceIndex] = newSource;
      
      // let newIncompletedSources = [...state.incompleteSources];
      // let completedIndex = newIncompletedSources.indexOf(action.sourceId);
      // if (completedIndex !== -1) {
      //   newIncompletedSources.splice(completedIndex, 1);
      // }
      return {
        ...state,
        isFetching: false,
        sources: newSources,
      };
  
    case actions.SET_SOURCE:
      let source = sources.find(source => {
        return source.id == action.sourceId;
      });
      return {
        ...state,
        source
      };
  
    case actions.PATCH_SOURCE:
      return {
        ...state,
        isFetchingSingle: true,
        // source: null,
      };
  
    case actions.PATCH_CAMPAIGN_ERROR:
      return {
        ...state,
        isFetchingSingle: false
      };
      
    case actions.SOURCE_PATCHED:
      return {
        ...state,
        isFetchingSingle: false
      };
  
    case actions.DELETE_SOURCE:
      return {
        ...state,
        isFetchingSingle: true,
      };
  
    case actions.DELETE_SOURCE_ERROR:
      return {
        ...state,
        isFetchingSingle: false
      };
  
    case actions.SOURCE_DELETED:
      return {
        ...state,
        isFetchingSingle: false,
        source: null,
      };
  
    case actions.QUEUE_SOURCE:
      if(indexOfQueuedSource(action.queuedSource, action.sourceType, queuedSources) !== -1)
        return state;
  
      queuedSources.push(action.queuedSource);
      return {
        ...state,
        queuedSources,
        queueSize: state.queueSize + 1
      };
  
    case actions.DEQUEUE_SOURCE:
      const queuedSourceIndex = indexOfQueuedSource(action.queuedSource, action.sourceType, queuedSources);
      if(queuedSourceIndex === -1)
        return state;
    
      queuedSources.splice(queuedSourceIndex, 1);
      return {
        ...state,
        queuedSources,
        queueSize: state.queueSize - 1
      };
      
    case actions.SET_QUEUED_MARGIN:
      const {margin} = action;
      const marginForIndex = indexOfQueuedSource(action.queuedSource, action.sourceType, queuedSources);
      if(marginForIndex === -1)
        return state;
      
      queuedSources[marginForIndex].margin = margin;
      return {
        ...state,
        queueUpdate: !state.queueUpdate,
        queuedSources
      };

    case actions.SET_QUEUED_UNIQUE:
      const {unique} = action;
      const iSource = indexOfQueuedSource(action.queuedSource, action.sourceType, queuedSources);
      if(iSource === -1)
        return state;

      queuedSources[iSource].unique = unique;
      return {
        ...state,
        queueUpdate: !state.queueUpdate,
        queuedSources,
        queuedSourcesErrors: {
          ...state.queuedSourcesErrors,
          duplicates: queuedSourcesErrors.duplicates.filter(sourceId => sourceId !== action.queuedSource.id)
        }
      };
  
    case actions.PROCESS_QUEUED_SOURCES:
      return {
        ...state,
        isProcessing: true
      };
  
    case actions.PROCESS_QUEUED_SOURCES_ERROR:
      return {
        ...state,
        isProcessing: false,
        queuedSourcesErrors: {
          ...state.queuedSourcesErrors,
          duplicates: action.duplicates
        },
      };
  
    case actions.QUEUED_SOURCES_PROCESSED:
      return {
        ...state,
        isProcessing: false,
        queuedSources: [],
        queueSize: 0,
        queueUpdate: false,
      };
  
    case actions.REQUEST_SOURCE_SUBTYPE_UPDATE:
      return {
        ...state,
        updatingSourceSubType: action.sourceSubTypeEnum
      };
  
    case actions.REQUEST_SOURCE_SUBTYPE_UPDATE_ERROR:
      return {
        ...state,
        updatingSourceSubType: initState.updatingSourceSubType,
        errorMessage: action.errorMessage
      };
  
    case actions.SOURCE_SUBTYPE_UPDATED:
      return {
        ...state,
        updatingSourceSubType: initState.updatingSourceSubType
      };
    
    default:
      return state;
  }
}
