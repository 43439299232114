const actions = {
  FIND_FILTERS: 'FIND_FILTERS',
  APPLY_FILTERS: 'APPLY_FILTERS',
  SET_FILTER: 'SET_FILTER',
  
  ADD_PROSPECT_CUSTOM_FILTER: 'ADD_PROSPECT_CUSTOM_FILTER',
  CHANGE_PROSPECT_CUSTOM_FILTER: 'CHANGE_PROSPECT_CUSTOM_FILTER',
  CHANGE_PROSPECT_CUSTOM_FILTER_TYPE: 'CHANGE_PROSPECT_CUSTOM_FILTER_TYPE',
  CHANGE_PROSPECT_CUSTOM_FILTER_LOGIC: 'CHANGE_PROSPECT_CUSTOM_FILTER_LOGIC',
  REMOVE_PROSPECT_CUSTOM_FILTER: 'REMOVE_PROSPECT_CUSTOM_FILTER',
  SET_PROSPECT_CUSTOM_FILTER_VALUE: 'SET_PROSPECT_CUSTOM_FILTER_VALUE',
  SET_SEARCH_PHRASE: 'SET_SEARCH_PHRASE',
  RESET_PROSPECT_FILTERS: 'RESET_PROSPECT_FILTERS',

  findPossibleFilters: (prospects) => ({
    type: actions.FETCH_PROSPECTS,
    prospects
  }),
  
  applyFilters: (prospects) => ({
    type: "SET_PROSPECTS_PAGE",
    page: 1
  }),
  
  setFilter: (filterName, filterValue) => ({
    type: actions.SET_FILTER,
    filterName,
    filterValue,
  }),
  
  setSearchPhrase: (searchPhrase) => ({
    type: actions.SET_SEARCH_PHRASE,
    searchPhrase,
  }),
  
  addCustomFilter: (filterType, customField, customValue = null) => ({
    type: actions.ADD_PROSPECT_CUSTOM_FILTER,
    filterType,
    customField,
    customValue,
  }),
  
  changeCustomFilterField: (customFilterIndex, newCustomField) => ({
    type: actions.CHANGE_PROSPECT_CUSTOM_FILTER,
    customFilterIndex,
    newCustomField
  }),
  
  changeCustomFilterLogic: (customFilterIndex, newLogic) => ({
    type: actions.CHANGE_PROSPECT_CUSTOM_FILTER_LOGIC,
    customFilterIndex,
    newLogic
  }),
  
  changeCustomFilterType: (customFilterIndex, newFilterType, newCustomField) => ({
    type: actions.CHANGE_PROSPECT_CUSTOM_FILTER_TYPE,
    customFilterIndex,
    newCustomField,
    newFilterType
  }),
  
  removeCustomFilter: (customFilterIndex) => ({
    type: actions.REMOVE_PROSPECT_CUSTOM_FILTER,
    customFilterIndex,
  }),
  
  resetFilters: (prospects) => ({
    type: actions.RESET_PROSPECT_FILTERS,
    prospects
  }),
  
  setCustomFilterValue: (customFilterIndex, customValue) => ({
    type: actions.SET_PROSPECT_CUSTOM_FILTER_VALUE,
    customFilterIndex,
    customValue,
  }),
  
};
export default actions;
