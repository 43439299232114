import actions from './actions';

const initState = {
  creatingLead: false,
  createdLead: null,
  message: '',
  
  leadSourceId: '',
  cause: '',
  email: '',
  basicFields: [],
  customFields: [],
  agrees: [],

  leadsCampaignIdentifiers: [],
  leadsAdIdentifiers: [],
};

export default function leadReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.CREATE_LEAD:
      return {
        ...state,
        creatingLead: true
      };
  
    case actions.CREATE_LEAD_ERROR:
      return {
        ...state,
        creatingLead: false
      };
  
    case actions.LEAD_CREATED:
      return {
        ...state,
        creatingLead: false,
        createdLead: action.createdLead
      };
  
    case actions.LEAD_FORM_CLEARED:
      return initState;
      
    case actions.SET_LEAD_FORM_EMAIL:
      return {
        ...state,
        email: action.email
      };
  
    case actions.SET_LEAD_FORM_LEAD_SOURCE:
      return {
        ...state,
        leadSourceId: action.leadSourceId
      };
  
    case actions.SET_LEAD_FORM_CAUSE:
      return {
        ...state,
        cause: action.cause
      };
  
    case actions.ADD_LEAD_FORM_BASIC_FIELD:
      let addedBasicFields = [...state.basicFields];
      addedBasicFields.push({name: '', value: ''});
      return {
        ...state,
        basicFields: addedBasicFields,
      };
  
    case actions.SET_LEAD_FORM_BASIC_FIELD:
      let basicFields = [...state.basicFields];
      basicFields[action.fieldIndex] = action.field;
      return {
        ...state,
        basicFields
      };
  
    case actions.REMOVE_LEAD_FORM_BASIC_FIELD:
      let removedBasicFields = [...state.basicFields];
      removedBasicFields.splice(action.fieldIndex, 1);
      return {
        ...state,
        basicFields: removedBasicFields
      };
  
    case actions.ADD_LEAD_FORM_CUSTOM_FIELD:
      let addedCustomFields = [...state.customFields];
      addedCustomFields.push({id: '', value: ''});

      return {
        ...state,
        customFields: addedCustomFields,
      };
  
    case actions.SET_LEAD_FORM_CUSTOM_FIELD:
      let customFields = [...state.customFields];
      customFields[action.fieldIndex] = action.field;
      return {
        ...state,
        customFields
      };
  
    case actions.REMOVE_LEAD_FORM_CUSTOM_FIELD:
      let removedCustomFields = [...state.customFields];
      removedCustomFields.splice(action.fieldIndex, 1);
      return {
        ...state,
        customFields: removedCustomFields
      };
  
    case actions.ADD_LEAD_FORM_AGREE:
      let addedAgrees = [...state.agrees];
      addedAgrees.push(action.agreeId);
      return {
        ...state,
        agrees: addedAgrees
      };
  
    case actions.REMOVE_LEAD_FORM_AGREE:
      let removedAgrees = [...state.agrees];
      removedAgrees.splice(removedAgrees.indexOf(action.agreeId), 1);
      return {
        ...state,
        agrees: removedAgrees
      };

    case actions.RECEIVE_LEAD_TO_CAMPAIGN_IDENTIFIERS:
      const {campaigns} = action;
      return {
        ...state,
        leadsCampaignIdentifiers: campaigns
      };

    case actions.RECEIVE_LEAD_TO_AD_IDENTIFIERS:
      const {ads} = action;
      return {
        ...state,
        leadsAdIdentifiers: ads
      };
    
    default:
      return state;
  }
}
