const actions = {
  FETCH_LEAD_REPORTS: 'FETCH_LEAD_REPORTS',
  FETCH_LEAD_REPORTS_ERROR: 'FETCH_LEAD_REPORTS_ERROR',
  LEAD_REPORTS_RECEIVED: 'LEAD_REPORTS_RECEIVED',
  
  SET_LEAD_REPORT: 'SET_LEAD_REPORT',
  
  CREATE_LEAD_REPORT: 'CREATE_LEAD_REPORT',
  CREATE_LEAD_REPORT_ERROR: 'CREATE_LEAD_REPORT_ERROR',
  LEAD_REPORT_CREATED: 'LEAD_REPORT_CREATED',
  
  UPDATE_LEAD_REPORT: 'UPDATE_LEAD_REPORT',
  UPDATE_LEAD_REPORT_ERROR: 'UPDATE_LEAD_REPORT_ERROR',
  LEAD_REPORT_UPDATED: 'LEAD_REPORT_UPDATED',
  
  DELETE_LEAD_REPORT: 'DELETE_LEAD_REPORT',
  DELETE_LEAD_REPORT_ERROR: 'DELETE_LEAD_REPORT_ERROR',
  LEAD_REPORT_DELETED: 'LEAD_REPORT_DELETED',

  COPY_LEAD_REPORT: 'COPY_LEAD_REPORT',
  COPY_LEAD_REPORT_ERROR: 'COPY_LEAD_REPORT_ERROR',
  LEAD_REPORT_COPIED: 'LEAD_REPORT_COPIED',

  fetchLeadReports: (sourceId) => ({
    type: actions.FETCH_LEAD_REPORTS,
    sourceId
  }),
  
  setLeadReport: (reportId) => ({
    type: actions.SET_LEAD_REPORT,
    reportId
  }),
  
  createLeadReport: (name) => ({
    type: actions.CREATE_LEAD_REPORT,
    name
  }),
  
  updateLeadReport: (id, name, serializedFilters) => ({
    type: actions.UPDATE_LEAD_REPORT,
    id,
    name,
    serializedFilters,
  }),
  
  deleteLeadReport: (reportId) => ({
    type: actions.DELETE_LEAD_REPORT,
    reportId
  }),

  copyLeadReport: (reportId) => ({
    type: actions.COPY_LEAD_REPORT,
    reportId
  }),
  
};
export default actions;
