import { all, takeEvery, put, fork } from 'redux-saga/effects';
import notifications from "../../components/feedback/notification";
import actions from './actions';
import LeadsFetch from "../../helpers/leadsFetch";

export function* fetchStatuses() {
  yield takeEvery('FETCH_STATUSES', function*() {
    const apiResponse = yield LeadsFetch.get('status', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_STATUSES,
        statuses: apiResponse.data.data
      });
      return;
    }
  
    yield put({
      type: 'PUSH_ERROR',
      response: apiResponse,
    });

  });
}

export function* createStatus() {
  yield takeEvery(actions.CREATE_STATUS, function*(payload) {
    const {status} = payload;
    const apiResponse = yield LeadsFetch.post('status', status)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.STATUS_CREATED
      });
      yield put({
        type: actions.FETCH_STATUSES
      });
      notifications['success']({
        message: 'OK!',
        description: 'New status created.'
      });
      return;
    }
    
    notifications['error']({
      message: 'Server error!',
      description: apiResponse.data.message
    });
    
    yield put({
      type: actions.CREATE_STATUS_ERROR
    });
  });
}

export function* deleteStatus() {
  yield takeEvery(actions.DELETE_STATUS, function*(payload) {
    const {statusId} = payload;
    const apiResponse = yield LeadsFetch.delete('status/' + statusId, null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.FETCH_STATUSES
      });
      return;
    }
    
    notifications['error']({
      message: 'Not allowed!',
      description: apiResponse.data.message
    });
    
    yield put({
      type: actions.DELETE_STATUS_ERROR
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchStatuses),
    fork(createStatus),
    fork(deleteStatus),
  ]);
}
