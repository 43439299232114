import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('./Users/')),
  },
  // {
  //   path: 'advertiser',
  //   component: asyncComponent(() => import('./Settings/')),
  // },
  {
    path: 'facebook-accounts',
    component: asyncComponent(() => import('./Facebook/')),
  },
  {
    path: 'adform-accounts',
    component: asyncComponent(() => import('./AdForm/')),
  },
  {
    path: 'google-accounts',
    component: asyncComponent(() => import('./Google/')),
  },
  {
    path: 'doubleclick-accounts',
    component: asyncComponent(() => import('./DoubleClick/')),
  },
  {
    path: 'users',
    component: asyncComponent(() => import('./Users/')),
  },
  {
    path: 'departments',
    component: asyncComponent(() => import('./Departments/')),
  },
  {
    path: 'custom-fields',
    component: asyncComponent(() => import('./Leads/CustomFields/')),
  },
  {
    path: 'agrees',
    component: asyncComponent(() => import('./Leads/Agrees/')),
  },
  {
    path: 'statuses',
    component: asyncComponent(() => import('./Leads/Status/')),
  },
  {
    path: 'sms-names',
    component: asyncComponent(() => import('./Delivery/SmsNames/')),
  },
  // {
  //   path: 'campaigns',
  //   component: asyncComponent(() => import('../Reports/Campaign/')),
  // },
];

class AdvertiserRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AdvertiserRouter;
