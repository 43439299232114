const actions = {
  FETCH_REPORTS: 'FETCH_REPORTS',
  FETCH_REPORTS_ERROR: 'FETCH_REPORTS_ERROR',
  RECEIVE_REPORTS: 'RECEIVE_REPORTS',
  
  CREATE_REPORT: 'CREATE_REPORT',
  CREATE_REPORT_ERROR: 'CREATE_REPORT_ERROR',
  REPORT_CREATED: 'REPORT_CREATED',
  
  DELETE_REPORT: 'DELETE_REPORT',
  DELETE_REPORT_ERROR: 'DELETE_REPORT_ERROR',
  REPORT_DELETED: 'REPORT_DELETED',

  SET_MODAL: 'SET_MODAL',
  SET_MODAL_OPEN: 'IS_MODAL_OPEN',
  SET_MODAL_CLOSE: 'IS_MODAL_CLOSE',

  SET_MODAL_FETCHING: 'SET_MODAL_FETCHING',
  UNSET_MODAL_FETCHING: 'UNSET_MODAL_FETCHING',

  fetchReports: (sourceId) => ({
    type: actions.FETCH_REPORTS,
    sourceId
  }),
  
  createReport: (reportData) => ({
    type: actions.CREATE_REPORT,
    reportData
  }),
  
  deleteReport: (reportId) => ({
    type: actions.DELETE_REPORT,
    reportId
  }),

  setModal: (modalContent) => ({
    type: actions.SET_MODAL,
    modal: modalContent
  }),

  setModalOpen: () => ({
    type: actions.SET_MODAL_OPEN,
  }),

  setModalClose: () => ({
    type: actions.SET_MODAL_CLOSE,
  }),

  setModalFetching: () => ({
    type: actions.SET_MODAL_FETCHING
  }),

  unsetModalFetching: () => ({
    type: actions.UNSET_MODAL_FETCHING
  }),
};
export default actions;
