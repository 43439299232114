import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import {push} from 'connected-react-router';
import GoogleBusinessFetch from "../../../helpers/gooleBusinessFetch";
import notifications from "../../../components/feedback/notification";

export function* fetchVersions() {
    yield takeEvery(actions.FETCH_LOCATION_VERSIONS, function*(payload) {
        const {locations} = payload;
        
        let query = '?test=false';
        if(locations && locations.length > 0)
        {
            locations.forEach(loc => {
                query += '&locations[]='+loc;
            });
        }
        
        const resp = yield GoogleBusinessFetch.get('versioned-locations'+query, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            yield put({
                type: actions.LOCATION_VERSIONS_RECEIVED,
                versions: resp.data.data
            });
            return;
        }
    
        notifications['error']({
            message: 'Failed to fetch versions!',
            description: resp.data.message
        });
        yield put({
            type: actions.FETCH_LOCATION_VERSIONS_ERROR,
            errorMessage: resp.error
        });
    });
}


export function* createVersions() {
    yield takeEvery(actions.CREATE_LOCATION_VERSIONS, function*(payload) {
        const {locations, version} = payload;
    
        let query = '?test=false';
        if(locations && locations.length > 0)
        {
            locations.forEach(loc => {
                query += '&locations[]='+loc;
            });
        }
        
        const resp = yield GoogleBusinessFetch.post('versioned-locations'+query, {
            ...version
        })
            .then(response => (response))
            .catch(error => ({error}));
        
        if(resp.status === 200)
        {
            notifications['success']({
                message: 'Versions created.',
                description: `Deploying ${resp.data.data} changes.`
            });
            yield put({
                type: actions.LOCATION_VERSIONS_CREATED
            });
            
            yield put({
                type: actions.FETCH_LOCATION_VERSIONS,
                locations
            });
            return;
        }
        
        notifications['error']({
            message: 'Creating versions failed!',
            description: resp.data.message
        });
        yield put({
            type: actions.CREATE_LOCATION_VERSIONS_ERROR,
            errorMessage: resp.error
        });
    });
}

export function* deleteVersion()
{
    yield takeEvery(actions.DELETE_LOCATION_VERSION, function* (payload)
    {
        const {id} = payload;
        
        const resp = yield GoogleBusinessFetch.delete(`versioned-locations/${id}`, null)
            .then(response => (response))
            .catch(error => ({error}));
        
        if (resp.status === 200)
        {
            notifications['success']({
                message: 'Version deleted.'
            });
            yield put({
                type: actions.LOCATION_VERSION_DELETED,
                id
            });
            return;
        }
        
        notifications['error']({
            message: 'Deleting failed!',
            description: resp.data.message
        });
        yield put({
            type: actions.DELETE_LOCATION_VERSION_ERROR,
            errorMessage: resp.error
        });
    });
}
export default function* rootSaga() {
    yield all([
        fork(fetchVersions),
        fork(createVersions),
        fork(deleteVersion)
    ]);
}
