import actions from './actions';

const initState = {
    isFetching: false,
    isCreating: false,
    updating: -1,
    deleting: -1,
    locationGroups: [],
    locationGroup: null
};

export default function locationGroupsReducer(state = initState, action) {
    switch (action.type) {
        
        case 'SET_ADVERTISER':
            return {
                ...initState
            };
            
        case actions.SET_LOCATION_GROUP:
            return {
                ...state,
                locationGroup: action.locationGroup
            };
            
        // FETCHING
        
        case actions.FETCH_LOCATION_GROUPS:
            return {
                ...state,
                isFetching: true
            };
        
        case actions.FETCH_LOCATION_GROUPS_ERROR:
            return {
                ...state,
                isFetching: false
            };
        
        case actions.RECEIVE_LOCATION_GROUPS:
            return {
                ...state,
                isFetching: false,
                locationGroups: action.locationGroups
            };
    
        // CREATING
        case actions.CREATE_LOCATION_GROUP:
            return {
                ...state,
                isCreating: true
            };
    
        case actions.CREATE_LOCATION_GROUP_ERROR:
            return {
                ...state,
                isCreating: false
            };
    
        case actions.LOCATION_GROUP_CREATED:
            let newGroups = [...state.locationGroups];
            newGroups.unshift(action.locationGroup);
            return {
                ...state,
                isCreating: false,
                locationGroups: newGroups,
                locationGroup: action.locationGroup
            };
            
        // UPDATING
        case actions.UPDATE_LOCATION_GROUP:
            return {
                ...state,
                updating: action.id,
                locationGroup: null
            };
    
        case actions.UPDATE_LOCATION_GROUP_ERROR:
            return {
                ...state,
                updating: -1
            };
    
        case actions.LOCATION_GROUP_UPDATED:
            let updatedGroups = [...state.locationGroups];
            for(let i = 0; i < updatedGroups.length; i++)
            {
                if(updatedGroups[i].id == action.updatedGroup.id)
                {
                    updatedGroups[i] = action.updatedGroup;
                    break;
                }
            }

            return {
                ...state,
                updating: -1,
                locationGroups: updatedGroups,
                locationGroup: action.updatedGroup
            };
    
        // DELETING
        case actions.DELETE_LOCATION_GROUP:
            return {
                ...state,
                deleting: action.id
            };
    
        case actions.DELETE_LOCATION_GROUP_ERROR:
            return {
                ...state,
                deleting: -1
            };
    
        case actions.LOCATION_GROUP_DELETED:
            let reducedGroups = [...state.locationGroups];
            for(let i = 0; i < reducedGroups.length; i++)
            {
                if(reducedGroups[i].id == action.deletedGroupId)
                {
                    reducedGroups.splice(i, 1);
                    break;
                }
            }
        
            return {
                ...state,
                deleting: -1,
                locationGroups: reducedGroups
            };

        
        default:
            return state;
    }
}
