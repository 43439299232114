const actions = {
    
    SET_LOCATION_CAMPAIGN: 'SET_LOCATION_CAMPAIGN',
    CLEAR_LOCATION_CAMPAIGN: 'CLEAR_LOCATION_CAMPAIGN',
    
    FETCH_LOCATION_CAMPAIGNS: 'FETCH_LOCATION_CAMPAIGNS',
    FETCH_LOCATION_CAMPAIGNS_ERROR: 'FETCH_LOCATION_CAMPAIGNS_ERROR',
    RECEIVE_LOCATION_CAMPAIGNS: 'RECEIVE_LOCATION_CAMPAIGNS',
    
    CREATE_LOCATION_CAMPAIGN: 'CREATE_LOCATION_CAMPAIGN',
    CREATE_LOCATION_CAMPAIGN_ERROR: 'CREATE_LOCATION_CAMPAIGN_ERROR',
    LOCATION_CAMPAIGN_CREATED: 'LOCATION_CAMPAIGN_CREATED',
    
    UPDATE_LOCATION_CAMPAIGN: 'UPDATE_LOCATION_CAMPAIGN',
    UPDATE_LOCATION_CAMPAIGN_ERROR: 'UPDATE_LOCATION_CAMPAIGN_ERROR',
    LOCATION_CAMPAIGN_UPDATED: 'LOCATION_CAMPAIGN_UPDATED',
    
    DELETE_LOCATION_CAMPAIGN: 'DELETE_LOCATION_CAMPAIGN',
    DELETE_LOCATION_CAMPAIGN_ERROR: 'DELETE_LOCATION_CAMPAIGN_ERROR',
    LOCATION_CAMPAIGN_DELETED: 'LOCATION_CAMPAIGN_DELETED',
    
    DEPLOY_LOCATION_CAMPAIGN: 'DEPLOY_LOCATION_CAMPAIGN',
    DEPLOY_LOCATION_CAMPAIGN_ERROR: 'DEPLOY_LOCATION_CAMPAIGN_ERROR',
    LOCATION_CAMPAIGN_DEPLOYED: 'LOCATION_CAMPAIGN_DEPLOYED',
    
    PAUSE_LOCATION_CAMPAIGN: 'PAUSE_LOCATION_CAMPAIGN',
    PAUSE_LOCATION_CAMPAIGN_ERROR: 'PAUSE_LOCATION_CAMPAIGN_ERROR',
    LOCATION_CAMPAIGN_PAUSED: 'LOCATION_CAMPAIGN_PAUSED',
    
    fetchLocationCampaigns: () => ({
        type: actions.FETCH_LOCATION_CAMPAIGNS
    }),
    
    clearCampaign: () => ({
        type: actions.CLEAR_LOCATION_CAMPAIGN
    }),
    
    createLocationCampaign: (values) => ({
        type: actions.CREATE_LOCATION_CAMPAIGN,
        values
    }),
    
    deployLocationCampaign: (id) => ({
        type: actions.DEPLOY_LOCATION_CAMPAIGN,
        id
    }),
    
    pauseLocationCampaign: (id) => ({
        type: actions.PAUSE_LOCATION_CAMPAIGN,
        id
    }),
    
    updateLocationCampaign: (id, values) => ({
        type: actions.UPDATE_LOCATION_CAMPAIGN,
        id,
        values
    }),
    
    deleteLocationCampaign: (id) => ({
        type: actions.DELETE_LOCATION_CAMPAIGN,
        id
    }),
    
    setCampaign: (campaign) => ({
        type: actions.SET_LOCATION_CAMPAIGN,
        campaign
    }),
    
};
export default actions;
