const actions = {
  FETCH_CUSTOM_FIELDS: 'FETCH_CUSTOM_FIELDS',
  RECEIVE_CUSTOM_FIELDS: 'RECEIVE_CUSTOM_FIELDS',
  
  CREATE_CUSTOM_FIELD: 'CREATE_CUSTOM_FIELD',
  CREATE_CUSTOM_FIELD_ERROR: 'CREATE_CUSTOM_FIELD_ERROR',
  CUSTOM_FIELD_CREATED: 'CUSTOM_FIELD_CREATED',
  
  CLEAR_CUSTOM_FIELD_FORM: 'CLEAR_CUSTOM_FIELD_FORM',
  
  DELETE_CUSTOM_FIELD: 'DELETE_CUSTOM_FIELD',
  DELETE_CUSTOM_FIELD_ERROR: 'DELETE_CUSTOM_FIELD_ERROR',
  CUSTOM_FIELD_DELETED: 'CUSTOM_FIELD_DELETED',

  UPDATE_CUSTOM_FIELD_SETTINGS: 'UPDATE_CUSTOM_FIELD_SETTINGS',
  UPDATE_CUSTOM_FIELD_SETTINGS_ERROR: 'UPDATE_CUSTOM_FIELD_SETTINGS_ERROR',
  CUSTOM_FIELD_SETTINGS_UPDATED: 'CUSTOM_FIELD_SETTINGS_UPDATED',

  fetchCustomFields: () => ({
    type: actions.FETCH_CUSTOM_FIELDS
  }),
  
  createCustomField: (customField) => ({
    type: actions.CREATE_CUSTOM_FIELD,
    customField
  }),
  
  deleteCustomField: (customFieldId) => ({
    type: actions.DELETE_CUSTOM_FIELD,
    customFieldId
  }),

  updateCustomFieldSettings: (customFieldId, customFieldSettings) => ({
    type: actions.UPDATE_CUSTOM_FIELD_SETTINGS,
    customFieldId,
    customFieldSettings
  }),

};
export default actions;
