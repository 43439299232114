const actions = {
  
  FETCH_DOUBLECLICK_ACCOUNTS: 'FETCH_DOUBLECLICK_ACCOUNTS',
  FETCH_DOUBLECLICK_ACCOUNTS_ERROR: 'FETCH_DOUBLECLICK_ACCOUNTS_ERROR',
  RECEIVE_DOUBLECLICK_ACCOUNTS: 'RECEIVE_DOUBLECLICK_ACCOUNTS',
  
  FETCH_DOUBLECLICK_ADACCOUNTS: 'FETCH_DOUBLECLICK_ADACCOUNTS',
  FETCH_DOUBLECLICK_ADACCOUNTS_ERROR: 'FETCH_DOUBLECLICK_ADACCOUNTS_ERROR',
  RECEIVE_DOUBLECLICK_ADACCOUNTS: 'RECEIVE_DOUBLECLICK_ADACCOUNTS',
  
  FETCH_DOUBLECLICK_CAMPAIGNS: 'FETCH_DOUBLECLICK_CAMPAIGNS',
  FETCH_DOUBLECLICK_CAMPAIGNS_ERROR: 'FETCH_DOUBLECLICK_CAMPAIGNS_ERROR',
  RECEIVE_DOUBLECLICK_CAMPAIGNS: 'RECEIVE_DOUBLECLICK_CAMPAIGNS',
  
  SET_DOUBLECLICK_CAMPAIGN: 'SET_DOUBLECLICK_CAMPAIGN',
  
  FETCH_DOUBLECLICK_INSERTION_ORDERS: 'FETCH_DOUBLECLICK_INSERTION_ORDERS',
  FETCH_DOUBLECLICK_INSERTION_ORDERS_ERROR: 'FETCH_DOUBLECLICK_INSERTION_ORDERS_ERROR',
  RECEIVE_DOUBLECLICK_INSERTION_ORDERS: 'RECEIVE_DOUBLECLICK_INSERTION_ORDERS',
  
  REMOVE_DOUBLECLICK_ACCOUNT: 'REMOVE_DOUBLECLICK_ACCOUNT',
  REMOVE_DOUBLECLICK_ACCOUNT_ERROR: 'REMOVE_DOUBLECLICK_ACCOUNT_ERROR',
  DOUBLECLICK_ACCOUNT_REMOVED: 'DOUBLECLICK_ACCOUNT_REMOVED',

  FETCH_DOUBLECLICK_PARTNERS: 'FETCH_DOUBLECLICK_PARTNERS',
  FETCH_DOUBLECLICK_PARTNERS_ERROR: 'FETCH_DOUBLECLICK_PARTNERS_ERROR',
  RECEIVE_DOUBLECLICK_PARTNERS: 'RECEIVE_DOUBLECLICK_PARTNERS',

  ADD_DOUBLECLICK_PARTNER: 'ADD_DOUBLECLICK_PARTNER',
  ADD_DOUBLECLICK_PARTNER_ERROR: 'ADD_DOUBLECLICK_PARTNER_ERROR',
  ADD_DOUBLECLICK_PARTNER_CONFIRM: 'ADD_DOUBLECLICK_PARTNER_CONFIRM',
  ADD_DOUBLECLICK_PARTNER_CONFIRM_ERROR: 'ADD_DOUBLECLICK_PARTNER_CONFIRM_ERROR',

  fetchAccounts: () => ({
    type: actions.FETCH_DOUBLECLICK_ACCOUNTS,
  }),
  
  fetchAdAccounts: (account, bidManagerPartnerId) => ({
    type: actions.FETCH_DOUBLECLICK_ADACCOUNTS,
    account,
    bidManagerPartnerId
  }),
  
  fetchCampaigns: (adAccount) => ({
    type: actions.FETCH_DOUBLECLICK_CAMPAIGNS,
    adAccount
  }),
  
  setCampaign: (campaign) => ({
    type: actions.SET_DOUBLECLICK_CAMPAIGN,
    campaign
  }),
  
  fetchInsertionOrders: (campaign) => ({
    type: actions.FETCH_DOUBLECLICK_INSERTION_ORDERS,
    campaign
  }),
  
  removeAccount: (account) => ({
    type: actions.REMOVE_DOUBLECLICK_ACCOUNT,
    account
  }),

  fetchPartners: () => ({
    type: actions.FETCH_DOUBLECLICK_PARTNERS
  }),

  addPartner: (partner) => ({
    type: actions.ADD_DOUBLECLICK_PARTNER,
    partner
  }),

  addPartnerError: () => ({
    type:actions.ADD_DOUBLECLICK_PARTNER_CONFIRM_ERROR
  }),
  
  // fetchCampaignData: (prospectId) => ({
  //   type: actions.FETCH_PROSPECT_DATA,
  //   prospectId: prospectId
  // }),
  //
  // setCampaign: (prospect, status) => ({
  //   type: actions.SET_CAMPAIGN,
  //   status,
  //   prospect
  // }),
  
};
export default actions;
