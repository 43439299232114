const actions = {
  
  FETCH_LEAD_SOURCES: 'FETCH_LEAD_SOURCES',
  RECEIVE_LEAD_SOURCES: 'RECEIVE_LEAD_SOURCES',
  FETCH_ERROR_LEAD_SOURCES: 'FETCH_ERROR_LEAD_SOURCES',
  FETCH_LEAD_SOURCE_FIELDS: 'FETCH_LEAD_SOURCE_FIELDS',
  
  CREATE_LEAD_SOURCE: 'CREATE_LEAD_SOURCE',
  CREATE_LEAD_SOURCE_ERROR: 'CREATE_LEAD_SOURCE_ERROR',
  LEAD_SOURCE_CREATED: 'LEAD_SOURCE_CREATED',
  
  PATCH_LEAD_SOURCE: 'PATCH_LEAD_SOURCE',
  PATCH_LEAD_SOURCE_ERROR: 'PATCH_LEAD_SOURCE_ERROR',
  LEAD_SOURCE_PATCHED: 'LEAD_SOURCE_PATCHED',
  
  DELETE_LEAD_SOURCE: 'DELETE_LEAD_SOURCE',
  DELETE_LEAD_SOURCE_ERROR: 'DELETE_LEAD_SOURCE_ERROR',
  LEAD_SOURCE_DELETED: 'LEAD_SOURCE_DELETED',
  
  CREATE_LEAD_SOURCE_RECEIPT: 'CREATE_LEAD_SOURCE_RECEIPT',
  CREATE_LEAD_SOURCE_RECEIPT_ERROR: 'CREATE_LEAD_SOURCE_RECEIPT_ERROR',
  LEAD_SOURCE_RECEIPT_CREATED: 'LEAD_SOURCE_CREATED',
  
  PATCH_LEAD_SOURCE_RECEIPT: 'PATCH_LEAD_SOURCE_RECEIPT',
  PATCH_LEAD_SOURCE_RECEIPT_ERROR: 'PATCH_LEAD_SOURCE_RECEIPT_ERROR',
  LEAD_SOURCE_RECEIPT_PATCHED: 'LEAD_SOURCE_RECEIPT_PATCHED',
  
  DELETE_LEAD_SOURCE_RECEIPT: 'DELETE_LEAD_SOURCE_RECEIPT',
  DELETE_LEAD_SOURCE_RECEIPT_ERROR: 'DELETE_LEAD_SOURCE_RECEIPT_ERROR',
  LEAD_SOURCE_RECEIPT_DELETED: 'LEAD_SOURCE_RECEIPT_DELETED',
  
  fetchLeadSources: () => ({
    type: actions.FETCH_LEAD_SOURCES
  }),
  
  createLeadSource: (values) => ({
    type: actions.CREATE_LEAD_SOURCE,
    values
  }),
  
  patchLeadSource: (values, additional_mapping = {}) => ({
    type: actions.PATCH_LEAD_SOURCE,
    values,
    additional_mapping
  }),
  
  deleteLeadSource: (id) => ({
    type: actions.DELETE_LEAD_SOURCE,
    id
  }),
  
  fetchCustomFieldsForSource: (leadSourceId) => ({
    type: actions.FETCH_LEAD_SOURCE_FIELDS
  }),
  
  createReceipt: (sourceId, values) => ({
    type: actions.CREATE_LEAD_SOURCE_RECEIPT,
    sourceId,
    values
  }),
  
  patchReceipt: (sourceId, receiptId, values) => ({
    type: actions.PATCH_LEAD_SOURCE_RECEIPT,
    sourceId, receiptId, values
  }),
  
  deleteReceipt: (sourceId, receiptId) => ({
    type: actions.DELETE_LEAD_SOURCE_RECEIPT,
    sourceId, receiptId
  }),
  
};
export default actions;
