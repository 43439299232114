import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Layout, Result} from 'antd';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
import { siteConfig } from '../../settings';
import themes from '../../settings/themes';
import { themeConfig } from '../../settings';
import AppHolder from './commonStyle';
import './global.css';

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;
export class App extends Component {
  render() {
    const { url } = this.props.match;
    const { height, auth, advertisers } = this.props;
    const {user} = auth;
    
    const currentAdvertiser =  localStorage.getItem('advertiser_id');
    let suspended = false;
      advertisers.forEach(adv => {
         if(adv.advertiser_id == currentAdvertiser && adv.suspended)
             suspended = true;
      });
    
    const appHeight = window.innerHeight;
    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={windowSize =>
                  this.props.toggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  )
                }
              />
            </Debounce>
            <Topbar url={url} />
            <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
              <Sidebar url={url} />
              <Layout
                className="isoContentMainLayout"
                style={{
                  height: height
                }}
              >
                <Content
                  className="isomorphicContent"
                  style={{
                    padding: '70px 0 0',
                    flexShrink: '0',
                    background: '#f1f3f6',
                    position: 'relative'
                  }}
                >
                    {suspended ? <div style={{padding: 100, backgroundColor: '#ffffff', borderRadius: 10, width: 600, position: 'relative', left: 0, right: 0, margin: 'auto', marginTop: 100}}>
                        <Result
                            title={'Konto wstrzymane'}
                            status={'info'}
                            style={{padding: 0}}
                            extra={
                                <div>Spokojnie! Twój system ciągle pracuje i zbiera informacje. Zawieszony został tylko dostęp do konta, ponieważ Twoja wersja próbna wygasła.<br/>&nbsp;<br/>
                                    Jeśli chcesz kontynuować skontaktuj się z nami w celu przedłużenia pakietu: <a href={'mailto:hello@bitetrack.io'}>hello@bitetrack.io</a></div>
                            }
                        />
                    </div> : <AppRouter url={url} user={user} />}
                  
                </Content>
                <Footer
                  style={{
                    background: '#ffffff',
                    textAlign: 'center',
                    borderTop: '1px solid #ededed'
                  }}
                >
                  {siteConfig.footerText} <span style={{color: '#dddddd'}}> (v{siteConfig.appVersion})</span>
                </Footer>
              </Layout>
            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

export default connect(
  state => ({
    auth: state.Auth,
    height: state.App.height,
    advertisers: state.Advertisers.advertisers,
  }),
  { logout, toggleAll }
)(App);
