import React, { Component } from 'react';
import { connect } from 'react-redux';
import clone from 'clone';
import { Link } from 'react-router-dom';
import {Icon, Layout} from 'antd';
import options from './options';
import Scrollbars from '../../../components/utility/customScrollBar.js';
import Menu from '../../../components/uielements/menu';
import IntlMessages from '../../../components/utility/intlMessages';
import SidebarWrapper from './sidebar.style';
import appActions from '../../../redux/app/actions';
import Logo from '../../../components/utility/logo';
import themes from '../../../settings/themes';
import { themeConfig } from '../../../settings';
import folderIcon from '../../../image/icons/folder.svg'
import privateFolderIcon from '../../../image/icons/folderPrivate.svg'
import Spin from "../../../components/uielements/spin";

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed
} = appActions;
const stripTrailingSlash = str => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }
  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === 'MobileView') {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = key => {
    const map = {
      sub3: ['sub2']
    };
    return map[key] || [];
  };
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, label, leftIcon, customIcon, children, opacity } = singleOption;
    const url = stripTrailingSlash(this.props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              <i className={leftIcon} />
              <span className="nav-text">
                {label}
              </span>
            </span>
          }
        >
          {children.map(child => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  <IntlMessages id={child.label} />
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key} style={{opacity: opacity ? opacity : 1 }}>
        <Link style={{overflow: 'hidden'}} to={`${url}/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            {leftIcon ? <Icon type={leftIcon} /> : null}
            <span className="nav-text">
              {customIcon ? <img src={customIcon} style={{ width: 12, marginRight: 10 }} /> : null}
              {label}
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };
  
  getProperMenu = () => {
    const {url} = this.props;
    switch(url)
    {
      case '/leads':
        return options.leadsMenu;
      
      case '/reports':
        return options.reportsMenu;
    }
    return options.leadsMenu;
  };
  
  getCampaignsMenu = () => {
    const {campaigns} = this.props;
    let reportsMenu = [];
    campaigns.forEach(campaign => {
      const {published} = campaign;
      reportsMenu.push({
        key: 'campaigns/' + campaign.id,
        label: campaign.name,
        customIcon: published ? folderIcon : privateFolderIcon
        // leftIcon: published ? 'eye' : 'lock'
      });
    });
    return reportsMenu;
  };
  
  render() {
    const { app, toggleOpenDrawer, height, visible, isFetchingCampaigns} = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? 'vertical' : 'inline';
    const onMouseEnter = event => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const customizedTheme = themes[themeConfig.theme];
    const styling = {
      backgroundColor: customizedTheme.backgroundColor
    };
    const submenuStyle = {
      backgroundColor: 'rgba(0,0,0,0.3)',
      color: customizedTheme.textColor
    };
    const submenuColor = {
      color: customizedTheme.textColor
    };
    const menuItems = this.getCampaignsMenu();
    const url = stripTrailingSlash(this.props.url);
    
    if(!visible) return null;
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          {/*<Logo collapsed={collapsed} />*/}
          <Scrollbars style={{ height: height - 70, marginTop: 70 }}>
            
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={  app.current }
              onOpenChange={this.onOpenChange}
            >
              {/*<Menu.Item key={'advertiser'}>*/}
              {/*  <Link to={`${url}/advertiser`}>*/}
              {/*    <span className="isoMenuHolder" style={submenuColor}>*/}
              {/*      <i className={'ion-android-home bigIcon'} style={{marginRight: 10}} />*/}
              {/*      <span className="nav-text">*/}
              {/*        Advertiser*/}
              {/*      </span>*/}
              {/*    </span>*/}
              {/*  </Link>*/}
              {/*</Menu.Item>*/}
              <Menu.Item key={'users'}>
                <Link to={`${url}/users`}>
                  <span className="isoMenuHolder" style={submenuColor}>
                    <i className={'ion-android-person bigIcon'} style={{marginRight: 10}} />
                    <span className="nav-text">
                      Users
                    </span>
                  </span>
                </Link>
              </Menu.Item>
  
              <Menu.Item key={'departments'}>
                <Link to={`${url}/departments`}>
                  <span className="isoMenuHolder" style={submenuColor}>
                    <i className={'ion-android-contacts bigIcon'} style={{marginRight: 10}} />
                    <span className="nav-text">
                      Departments
                    </span>
                  </span>
                </Link>
              </Menu.Item>
              
              <Menu.Item key={'google-accounts'}>
                <Link to={`${url}/google-accounts`}>
                  <span className="isoMenuHolder" style={submenuColor}>
                    <i className={'ion-social-google bigIcon'} style={{marginRight: 10}} />
                    <span className="nav-text">
                      Google Accounts
                    </span>
                  </span>
                </Link>
              </Menu.Item>
              
              <Menu.Item key={'facebook-accounts'} >
                <Link to={`${url}/facebook-accounts`}>
                  <span className="isoMenuHolder" style={submenuColor}>
                    <i className={'ion-social-facebook bigIcon'} style={{marginRight: 10}} />
                    <span className="nav-text">
                      Facebook Accounts
                    </span>
                  </span>
                </Link>
              </Menu.Item>
  
              <Menu.Item key={'doubleclick-accounts'} >
                <Link to={`${url}/doubleclick-accounts`}>
                  <span className="isoMenuHolder" style={submenuColor}>
                    <i className={'ion-at bigIcon'} style={{marginRight: 10}} />
                    <span className="nav-text">
                      DoubleClick Accounts
                    </span>
                  </span>
                </Link>
              </Menu.Item>
  
              <Menu.Item key={'adform-accounts'} >
                <Link to={`${url}/adform-accounts`}>
                  <span className="isoMenuHolder" style={submenuColor}>
                    <i className={'ion-aperture bigIcon'} style={{marginRight: 10}} />
                    <span className="nav-text">
                      AdForm Accounts
                    </span>
                  </span>
                </Link>
              </Menu.Item>

              <SubMenu title={
                <span className="isoMenuHolder" style={submenuColor}>
                    {/*<i className={'ion-social-google bigIcon'} style={{marginRight: 10}} />*/}
                     <Icon type="usergroup-add" />
                    <span className="nav-text">
                      Leads Settings
                    </span>
                  </span>
              }>
  
                <Menu.Item key={'agrees'} >
                  <Link to={`${url}/agrees`}>
                    <span className="isoMenuHolder" style={submenuColor}>
                      <span className="nav-text">
                        Agrees
                      </span>
                    </span>
                  </Link>
                </Menu.Item>
  
                <Menu.Item key={'custom-fields'} >
                  <Link to={`${url}/custom-fields`}>
                    <span className="isoMenuHolder" style={submenuColor}>
                      <span className="nav-text">
                        Custom Fields
                      </span>
                    </span>
                  </Link>
                </Menu.Item>
  
                <Menu.Item key={'statuses'} >
                  <Link to={`${url}/statuses`}>
                    <span className="isoMenuHolder" style={submenuColor}>
                      <span className="nav-text">
                        Statuses
                      </span>
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu title={
                <span className="isoMenuHolder" style={submenuColor}>
                  <Icon type="usergroup-add" />
                    <span className="nav-text">
                      Delivery Settings
                    </span>
                  </span>
              }>

                <Menu.Item key={'sms-names'} >
                  <Link to={`${url}/sms-names`}>
                    <span className="isoMenuHolder" style={submenuColor}>
                      <span className="nav-text">
                        Sms Names
                      </span>
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>

            </Menu>

          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  state => ({
    app: state.App,
    height: state.App.height,
    campaigns: state.Campaigns.campaigns,
    isFetchingCampaigns: state.Campaigns.isFetching,
    campaign: state.Campaigns.campaign,
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Sidebar);
