import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import LeadsFetch from "../../helpers/leadsFetch";
import notifications from "../../components/feedback/notification";


export function* fetchCustomFields() {
  yield takeEvery(actions.FETCH_CUSTOM_FIELDS, function*() {
    const apiResponse = yield LeadsFetch.get('custom-fields', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      // console.log(apiResponse);
      yield put({
        type: actions.RECEIVE_CUSTOM_FIELDS,
        customFields: apiResponse.data.data
      });
    }
  });
}

export function* createCustomField() {
  yield takeEvery(actions.CREATE_CUSTOM_FIELD, function*(payload) {
    const {customField} = payload;
    const apiResponse = yield LeadsFetch.post('custom-fields', customField)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(apiResponse.status === 201)
    {
      yield put({
        type: actions.CUSTOM_FIELD_CREATED
      });
      yield put({
        type: actions.FETCH_CUSTOM_FIELDS
      });
      notifications['success']({
        message: 'Ok!',
        description: 'New custom field created.'
      });
      return;
    }
  
    notifications['error']({
      message: 'Server error!',
      description: 'We could not create custom field.'
    });
  
    yield put({
      type: actions.CREATE_CUSTOM_FIELD_ERROR
    });
  });
}

export function* deleteCustomField() {
  yield takeEvery(actions.DELETE_CUSTOM_FIELD, function*(payload) {
    const {customFieldId} = payload;
    const apiResponse = yield LeadsFetch.delete('custom-fields/' + customFieldId, null)
        .then(response => (response))
        .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.FETCH_CUSTOM_FIELDS
      });
      return;
    }
    
    notifications['error']({
      message: 'Not allowed!',
      description: apiResponse.data.message
    });
    
    yield put({
      type: actions.DELETE_CUSTOM_FIELD_ERROR
    });
  });
}

export function* updateCustomFieldSettings() {
  yield takeEvery(actions.UPDATE_CUSTOM_FIELD_SETTINGS, function*(payload) {
    const {customFieldId, customFieldSettings} = payload;
    const apiResponse = yield LeadsFetch.PATCH('custom-field-settings/' + customFieldId, customFieldSettings)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      notifications['success']({
        message: 'Settings applied!',
        description: 'Custom field settings have been successfully changed'
      });
      yield put({
        type: actions.FETCH_CUSTOM_FIELDS
      });
      yield put({
        type: actions.CUSTOM_FIELD_SETTINGS_UPDATED
      });
      return;
    }

    notifications['error']({
      message: 'Settings error!',
      description: apiResponse.data.message
    });

    yield put({
      type: actions.UPDATE_CUSTOM_FIELD_SETTINGS_ERROR
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchCustomFields),
    fork(createCustomField),
    fork(deleteCustomField),
    fork(updateCustomFieldSettings),
  ]);
}
