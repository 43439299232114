import React from 'react';
import { matchPath } from 'react-router';

export const getUrlAdIds = () => {
    let ad_ids = [];
    
    let pathMatch = matchPath(window.location.pathname, {
        path: window.location.pathname.split('/',4).join('/')+"/:ad_ids"
    });
    
    if(pathMatch)
    {
        const { params } = pathMatch;
    
        if(params && params.hasOwnProperty('ad_ids'))
            ad_ids = params.ad_ids.split(',');
    }
    

    return ad_ids;
};
