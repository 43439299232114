import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius } from '../../../settings/style-util';

const ChartWrapper = styled.div`
  height: 100%;
  .chart-legend {
  display: none;
  }
  
  .isoComponentTitle{
    margin-bottom: 15px;
    font-size: 15px;
    &:before {
    height: 20px;
    }
  }
  
  .ant-card {
  height: 100%;
  }
  
  .ant-card-actions > li > span {
    width: 95%;
    text-align: left;
  }
  
  .legend {
    &:after {
      clear: both;
      content: '';
      display: block;
    }
  
    .legend-item {
      
        display: inline-block;
        padding-right: 30px;
        width: 33.33%;
        float: left;
        font-size: 12px;
        margin-bottom: 10px;
        font-weight: 600;
        
        @media(max-width: 1350px)
        {
          width: 50%;
        }
        
        span {
          float: right;
          font-weight: 400;
        }
    
        div {
          //display: inline-block;
          //margin-right: 5px;
          //width: 10px;
          //height: 10px;
          //border-radius: 4px;
          height: 5px; width: 100%;
        }
    }
  }
`;

export default ChartWrapper;
