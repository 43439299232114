import actions from './actions';
import {clearToken } from '../../helpers/utility';

const initState = {
  idToken: null,
  user: {
    isManager: () => {
        return false;
    },
    isAdmin: () => {
      return false;
    },
    isUser: () => {
      return false;
    },
    isLevel: (level) => {
      return level == 0;
    },
    getGroup: () => {
      return null;
    }
  },
  loginErrorCounter: 0,
  loginError: '',
  loggingIn: false,
  welcomeMessage: false,
  advertiserId: null
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token,
        user: {
          ...action.user,
          isManager: () => {
            if(action.user.role)
            {
              return action.user.role[localStorage.getItem('advertiser_id')] == 'ROLE_SUPER_ADMIN'
              || action.user.role[localStorage.getItem('advertiser_id')] == 'ROLE_MANAGER';
            }
            return false;
          },
          isUser: () => {
            if(action.user.role)
            {
              return action.user.role[localStorage.getItem('advertiser_id')] == 'ROLE_SUPER_ADMIN'
                  || action.user.role[localStorage.getItem('advertiser_id')] == 'ROLE_MANAGER'
                  || action.user.role[localStorage.getItem('advertiser_id')] == 'ROLE_USER';
            }
            return false;
          },
          isAdmin: () => {
            if(action.user.role)
            {
              return action.user.role[localStorage.getItem('advertiser_id')] == 'ROLE_SUPER_ADMIN';
            }
            return false;
          },
          isLevel: (level) => {
            let advertiser = localStorage.getItem('advertiser_id');
            let groupInAdvertiser = action.user.groups.find(grp => {
              return advertiser == grp.group.advertiser.id;
            });
            if(groupInAdvertiser)
            {
              return groupInAdvertiser.level == level;
            }
            return false;
          },
          getGroup: () => {
            let advertiser = localStorage.getItem('advertiser_id');
            let otherUserConnection = action.user.groups.find(connection => {
              return connection.group.advertiser.id == advertiser;
            });
            if(otherUserConnection)
              return otherUserConnection.group;
            
            return null;
          },
        },
        // welcomeMessage: action.welcomeMessage ? action.welcomeMessage : false,
        loginErrorCounter: 0,
        loggingIn: false
      };
      
    case actions.LOGIN_ERROR:
      return {
        ...state,
        loggingIn: false,
        loginError: action.message,
        loginErrorCounter: state.loginErrorCounter+1
      };
      
    case actions.LOGIN_REPORTS_PERMISSIONS:
      return {
        ...state,
        loggingIn: true,
      };
      
    case actions.LOGIN_LEADS_PERMISSIONS:
      return {
        ...state,
        loggingIn: true,
      };
      
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        welcomeMessage: true,
      };
  
    case actions.RESET_LOGIN_MESSAGE:
      return {
        ...state,
        welcomeMessage: false,
      };
      
    case actions.SET_ADVERTISER:
      return {
        ...state,
        advertiserId: action.advertiserId,
      };
      
    case actions.LOGOUT:
      clearToken();
      return initState;
      
    default:
      return state;
  }
}
