import actions from './actions';

const initState = {
  isSendingSingleSms: false,
};

export default function smsNamesReducer(state = initState, action) {
  switch (action.type) {

    case actions.SEND_SINGLE_SMS:
      return {
        ...state,
        isSendingSingleSms: true
      };

    case actions.SEND_SINGLE_SMS_ERROR:
      return {
        ...state,
        isSendingSingleSms: false
      };

    case actions.SINGLE_SMS_SENT:
      return {
        ...state,
        isSendingSingleSms: false
      };
    
    default:
      return state;
  }
}
