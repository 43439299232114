import actions from './actions';

const initState = {
  account: '',
  accounts: [],
  // adAccount: '',
  // adAccounts: [],
  campaign: null,
  campaigns: [],
  // adSets: [],
  isFetchingAccounts: false,
  // isFetchingAdAccounts: false,
  isFetchingCampaigns: false,
  // isFetchingAdsets: false,
};

export default function adFormReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
  
    case actions.FETCH_ADFORM_ACCOUNTS:
      return {
        ...initState,
        isFetchingAccounts: true,
      };
  
    case actions.FETCH_ADFORM_ACCOUNTS_ERROR:
      return {
        ...state,
        isFetchingAccounts: false,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_ADFORM_ACCOUNTS:
      return {
        ...state,
        isFetchingAccounts: false,
        accounts: action.accounts,
      };
  
    case actions.FETCH_ADFORM_ADACCOUNTS:
      return {
        ...state,
        account: action.account,
        adAccount: '',
        isFetchingAdAccounts: true,
      };
  
    case actions.FETCH_ADFORM_ADACCOUNTS_ERROR:
      return {
        ...state,
        isFetchingAdAccounts: false,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_ADFORM_ADACCOUNTS:
      return {
        ...state,
        isFetchingAdAccounts: false,
        adAccounts: action.adAccounts,
      };
    
    case actions.FETCH_ADFORM_CAMPAIGNS:
      return {
        ...state,
        isFetchingCampaigns: true,
        account: action.account,
        campaign: null
      };
      
    case actions.FETCH_ADFORM_CAMPAIGNS_ERROR:
      return {
        ...state,
        isFetchingCampaigns: false,
        campaign: null,
        account: '',
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_ADFORM_CAMPAIGNS:
      return {
        ...state,
        isFetchingCampaigns: false,
        campaigns: action.campaigns,
        campaign: null
      };
  
    case actions.SET_ADFORM_CAMPAIGN:
      return {
        ...state,
        campaign: action.campaign
      };
  
    case actions.FETCH_ADFORM_ADSETS:
      return {
        ...state,
        isFetchingAdsets: true,
        campaign: action.campaign
      };
  
    case actions.FETCH_ADFORM_ADSETS_ERROR:
      return {
        ...state,
        isFetchingAdsets: false,
        campaign: null,
        errorMessage: action.errorMessage
      };
  
    case actions.RECEIVE_ADFORM_ADSETS:
      return {
        ...state,
        isFetchingAdsets: false,
        adSets: action.adSets
      };
  
    case actions.REMOVE_ADFORM_ACCOUNT:
      return {
        ...state,
        isFetchingAccounts: true,
      };
  
    case actions.REMOVE_ADFORM_ACCOUNT_ERROR:
      return {
        ...state,
        isFetchingAccounts: false,
        errorMessage: action.errorMessage
      };
  
    case actions.ADFORM_ACCOUNT_REMOVED:
      return {
        ...state,
        isFetchingAccounts: false,
      };
      
    
    default:
      return state;
  }
}
