import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import notifications from '../../components/feedback/notification';
import ReportFetch from "../../helpers/reportFetch";
import AdvertiserApi from "../../helpers/advertiserApi";

export function* fetchAccounts() {
  yield takeEvery(actions.FETCH_DOUBLECLICK_ACCOUNTS, function*() {
    
    const apiResponse = yield AdvertiserApi.get('doubleclick/accounts', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_DOUBLECLICK_ACCOUNTS,
        accounts: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_DOUBLECLICK_ACCOUNTS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* removeAccount() {
  yield takeEvery(actions.REMOVE_DOUBLECLICK_ACCOUNT, function*(payload) {
    const {account} = payload;
  
    const accountUsageResponse = yield ReportFetch.get('doubleclick/' + account + '/sources', null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(accountUsageResponse.status !== 200)
    {
      notifications['error']({
        message: 'Account not deleted',
        description: 'We could not delete your account.'
      });
      yield put({
        type: actions.REMOVE_DOUBLECLICK_ACCOUNT_ERROR,
        errorMessage: ''
      });
      return;
    }

    if(accountUsageResponse.data.data > 0)
    {
      notifications['error']({
        message: 'Account not deleted',
        description: 'There are ' + accountUsageResponse.data.data + ' campaign sources that use this account. Delete sources first.'
      });
      yield put({
        type: actions.REMOVE_DOUBLECLICK_ACCOUNT_ERROR
      });
      return;
    }

    const apiResponse = yield AdvertiserApi.delete('doubleclick/accounts/' + account, null)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.FETCH_DOUBLECLICK_ACCOUNTS
      });
      return;
    }

    yield put({
      type: actions.REMOVE_DOUBLECLICK_ACCOUNT_ERROR,
      errorMessage: ''
    });
  });
}

export function* fetchAdAccounts() {
  yield takeEvery(actions.FETCH_DOUBLECLICK_ADACCOUNTS, function*(payload) {
    const {account, bidManagerPartnerId} = payload;

    if(!bidManagerPartnerId || !account) return;

    const apiResponse = yield ReportFetch.get(`doubleclick/${account}/advertisers/${bidManagerPartnerId}`, null)
      .then(response => (response))
      .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_DOUBLECLICK_ADACCOUNTS,
        adAccounts: apiResponse.data.data
      });
      return;
    }

    yield put({
      type: actions.FETCH_DOUBLECLICK_ADACCOUNTS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* fetchCampaigns() {
  yield takeEvery(actions.FETCH_DOUBLECLICK_CAMPAIGNS, function*(payload) {
    let account = yield select((state) => state.DoubleClick.account);
    const {adAccount} = payload;
    
    const apiResponse = yield ReportFetch.get(`doubleclick/${account}/${adAccount}/campaigns`, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_DOUBLECLICK_CAMPAIGNS,
        campaigns: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_DOUBLECLICK_CAMPAIGNS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* fetchInsertionOrders() {
  yield takeEvery(actions.FETCH_DOUBLECLICK_INSERTION_ORDERS, function*(payload) {
    let account = yield select((state) => state.DoubleClick.account);
    let adAccount = yield select((state) => state.DoubleClick.adAccount);
    const {campaign} = payload;
    
    const apiResponse = yield ReportFetch.get(`doubleclick/${account}/${adAccount}/insertion-orders/${campaign.campaignId}`, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_DOUBLECLICK_INSERTION_ORDERS,
        insertionOrders: apiResponse.data.data
      });
      return;
    }
    
    yield put({
      type: actions.FETCH_DOUBLECLICK_INSERTION_ORDERS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* fetchPartnersHistory() {
  yield takeEvery(actions.FETCH_DOUBLECLICK_PARTNERS, function*(payload) {
    let {account} = yield select((state) => state.DoubleClick);

    const apiResponse = yield ReportFetch.get(`doubleclick/${account}/partner`, null)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_DOUBLECLICK_PARTNERS,
        partners: apiResponse.data.data
      });
      return;
    }

    yield put({
      type: actions.FETCH_DOUBLECLICK_PARTNERS_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* addPartnerHistory() {
  yield takeEvery(actions.ADD_DOUBLECLICK_PARTNER, function*(payload) {
    let {account} = yield select((state) => state.DoubleClick);
    const {partner} = payload;

    const apiResponse = yield ReportFetch.post(`doubleclick/${account}/partner/${partner}`, null)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.ADD_DOUBLECLICK_PARTNER_CONFIRM,
        partner: apiResponse.data.data.id
      });
      return;
    }

    yield put({
      type: actions.ADD_DOUBLECLICK_PARTNER_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchAccounts),
    fork(fetchAdAccounts),
    fork(fetchCampaigns),
    fork(fetchInsertionOrders),
    fork(removeAccount),
    fork(fetchPartnersHistory),
    fork(addPartnerHistory)
  ]);
}
