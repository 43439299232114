import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import ReportFetch from "../../helpers/reportFetch";
import Campaigns from "../campaign/reducer";
import {store} from "../store";
import chartsActions from "../campaignCharts/actions";


export function* fetchGoals() {
  yield takeEvery('FETCH_GOALS', function*(payload) {
    const {campaign} = payload;

    const apiResponse = yield ReportFetch.get('chart/campaign/' + campaign.id, null)
      .then(response => (response))
      .catch(error => ({error}));
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_GOALS,
        goals: apiResponse.data.data
      });
      return;
    }
  
    yield put({
      type: actions.FETCH_GOALS_ERROR,
      errorMessage: apiResponse.message
    });
  });
}

export function* deleteGoal() {
  yield takeEvery('REMOVE_GOAL', function*(payload) {
    const {campaign, goal} = payload;

    if(!goal.id) return;

    const apiResponse = yield ReportFetch.delete(`chart/${campaign.id}/goals`, goal)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_REMOVE_GOAL,
        goal
      });
      return;
    }

    yield put({
      type: actions.REMOVE_GOAL_ERROR,
      errorMessage: apiResponse.message
    });
  });
}

export function* patchGoal() {
  yield takeEvery('PATCH_GOAL', function*(payload) {
    const {campaign, goal} = payload;

    const apiResponse = yield ReportFetch.PATCH(`chart/${campaign.id}/goals`, goal)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_PATCH_GOAL,
        prevGoal: goal,
        goal: apiResponse.data.data
      });
      return;
    }

    yield put({
      type: actions.PATCH_GOAL_ERROR,
      errorMessage: apiResponse.message
    });
  });
}

export function* patchMultipleGoal() {
  yield takeEvery('PATCH_MULTIPLE_GOAL', function*(payload) {
    let campaign = yield select((state) => state.Campaigns.campaign);
    let goals = yield select((state) => state.CampaignCharts.queueGoalPatch);

    const apiResponse = yield ReportFetch.PATCH(`chart/${campaign.id}/goals-multiple`, {goals})
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_PATCH_MULTIPLE_GOAL
      });
      return;
    }

    yield put({
      type: actions.PATCH_MULTIPLE_GOAL_ERROR,
      errorMessage: apiResponse.message
    });
  });
}

export function* fetchChart() {
  yield takeEvery('FETCH_CHARTS', function*(payload) {
    const {goalId, fromDate, toDate} = payload;

    const apiResponse = yield ReportFetch.get(`chart/${goalId}/${fromDate.format('YYYY-MM-DD')}/${toDate.format('YYYY-MM-DD')}`, null)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_CHARTS,
        charts: apiResponse.data.data,
        goalId
      });
      return;
    }

    yield put({
      type: actions.FETCH_CHARTS_ERROR,
      errorMessage: apiResponse.message
    });
  });
}

export function* fetchChartSources() {
  yield takeEvery('FETCH_SOURCES_CHARTS', function*(payload) {
    const {goalId, fromDate, toDate} = payload;

    const apiResponse = yield ReportFetch.get(`chart-sources/${goalId}/${fromDate.format('YYYY-MM-DD')}/${toDate.format('YYYY-MM-DD')}`, null)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_SOURCES_CHARTS,
        charts: apiResponse.data.data.dataset,
        goalId
      });
      return;
    }

    yield put({
      type: actions.FETCH_SOURCES_CHARTS_ERROR,
      errorMessage: apiResponse.message
    });
  });
}

export function* addCreatedChart() {
  yield takeEvery('ADD_CREATED_CHART', function*(payload) {
    const {campaign, goal} = payload;

    const apiResponse = yield ReportFetch.post(`chart/${campaign.id}/goals`, goal)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_CREATED_CHART,
        prevGoal: goal,
        goal: apiResponse.data.data
      });
      return;
    }

    yield put({
      type: actions.ADD_CREATED_CHART_ERROR,
      errorMessage: apiResponse.message
    });
  });
}

export function* fetchGoalTemplates() {
  yield takeEvery('FETCH_GOAL_TEMPLATES', function*(payload) {
    let campaign = yield select((state) => state.Campaigns.campaign);

    const apiResponse = yield ReportFetch.get(`chart/${campaign.id}/templates`, null)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVE_GOAL_TEMPLATES,
        goalTemplates: apiResponse.data.data
      });
      return;
    }

    yield put({
      type: actions.FETCH_GOAL_TEMPLATES_ERROR,
      errorMessage: apiResponse.message
    });
  });
}

export function* addGoalTemplate() {
  yield takeEvery('ADD_GOAL_TEMPLATE', function*(payload) {
    let campaign = yield select((state) => state.Campaigns.campaign);
    const { goalTemplate } = payload;

    const apiResponse = yield ReportFetch.post(`chart/${campaign.id}/template`, goalTemplate)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield store.dispatch(chartsActions.fetchGoalTemplates());
      yield put({
        type: actions.RECEIVE_ADD_GOAL_TEMPLATE
      });
      return;
    }

    yield put({
      type: actions.FETCH_GOAL_TEMPLATES_ERROR,
      errorMessage: apiResponse.message
    });
  });
}

export function* removeGoalTemplate() {
  yield takeEvery('REMOVE_GOAL_TEMPLATE', function*(payload) {
    let campaign = yield select((state) => state.Campaigns.campaign);
    const { goalTemplate } = payload;

    const apiResponse = yield ReportFetch.delete(`chart/${campaign.id}/template`, goalTemplate)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield store.dispatch(chartsActions.fetchGoalTemplates());
      yield put({
        type: actions.RECEIVE_REMOVE_GOAL_TEMPLATE
      });
      return;
    }

    yield put({
      type: actions.REMOVE_GOAL_TEMPLATE_ERROR,
      errorMessage: apiResponse.message
    });
  });
}


export function* selectGoalTemplate() {
  yield takeEvery('SELECT_GOAL_TEMPLATE', function*(payload) {
    let campaign = yield select((state) => state.Campaigns.campaign);
    const { goalTemplate } = payload;

    const apiResponse = yield ReportFetch.post(`chart/${campaign.id}/template/${goalTemplate.id}`, null)
        .then(response => (response))
        .catch(error => ({error}));

    if(apiResponse.status === 200)
    {
      yield store.dispatch(chartsActions.clearCharts());
      yield store.dispatch(chartsActions.fetchGoals(campaign));
      yield put({
        type: actions.RECEIVE_SELECT_GOAL_TEMPLATE
      });
      return;
    }

    yield put({
      type: actions.SELECT_GOAL_TEMPLATE_ERROR,
      errorMessage: apiResponse.message
    });
  });
}



export default function* rootSaga() {
  yield all([
    fork(fetchGoals),
    fork(deleteGoal),
    fork(patchGoal),
    fork(patchMultipleGoal),
    fork(fetchGoalTemplates),
    fork(addGoalTemplate),
    fork(removeGoalTemplate),
    fork(selectGoalTemplate),
    fork(fetchChart),
    fork(fetchChartSources),
    fork(addCreatedChart)
  ]);
}
