const actions = {
    
    CLEAR_CONTACT_SENT_MESSAGES: 'CLEAR_CONTACT_SENT_MESSAGES',
    FETCH_CONTACT_SENT_MESSAGES: 'FETCH_CONTACT_SENT_MESSAGES',
    RECEIVE_CONTACT_SENT_MESSAGES: 'RECEIVE_CONTACT_SENT_MESSAGES',

    // filters: contact, messagesCampaign
    fetchContactSentMessages: (filters) => ({
        type: actions.FETCH_CONTACT_SENT_MESSAGES,
        filters
    }),
    
};
export default actions;
