const actions = {
  
  FETCH_PRIMARY_AD_ACCOUNT: 'FETCH_PRIMARY_AD_ACCOUNT',
  FETCH_PRIMARY_AD_ACCOUNT_ERROR: 'FETCH_PRIMARY_AD_ACCOUNT_ERROR',
  RECEIVE_PRIMARY_FACEBOOK_ACCOUNT: 'RECEIVE_PRIMARY_FACEBOOK_ACCOUNT',

  PATCH_PRIMARY_AD_ACCOUNT: 'PATCH_PRIMARY_AD_ACCOUNT',
  PATCH_PRIMARY_AD_ACCOUNT_ERROR: 'PATCH_PRIMARY_AD_ACCOUNT_ERROR',
  PRIMARY_AD_ACCOUNT_PATCHED: 'PRIMARY_AD_ACCOUNT_PATCHED',

  DELETE_PRIMARY_AD_ACCOUNT: 'DELETE_PRIMARY_AD_ACCOUNT',
  DELETE_PRIMARY_AD_ACCOUNT_ERROR: 'DELETE_PRIMARY_AD_ACCOUNT_ERROR',
  PRIMARY_FACEBOOK_ACCOUNT_DELETED: 'PRIMARY_FACEBOOK_ACCOUNT_DELETED',

  fetchPrimaryAdAccount: (platformTypeEnum) => ({
    type: actions.FETCH_PRIMARY_AD_ACCOUNT,
    platformTypeEnum
  }),

  patchPrimaryAdAccount: (primaryAdAccount) => ({
    type: actions.PATCH_PRIMARY_AD_ACCOUNT,
    primaryAdAccount
  }),

  deletePrimaryAdAccount: (platformTypeEnum) => ({
    type: actions.DELETE_PRIMARY_AD_ACCOUNT,
    platformTypeEnum
  }),

};
export default actions;
