import actions from './actions';

const initState = {
  selectedLeads: [],
  importLeads: [],
  importLeadsExtendedMap: {},

  importLeadsRequest:{
    lead_source: null,
    allow_create_prospect: false,
    cause: '',
    import_leads: [],
    additional_logic: []
  },

  importLeadsPreview:{},

  isImportRequested: false,
};

export default function leadsImportReducer(state = initState, action) {
  switch (action.type) {

    case actions.SET_IMPORT_REQUEST_LEAD_SOURCE:
      return {
        ...state,
        importLeadsRequest: {
          ...state.importLeadsRequest,
          lead_source: action.leadSourceId
        }
      };

    case actions.SET_IMPORT_REQUEST_LEAD_CAUSE:
      return {
        ...state,
        importLeadsRequest: {
          ...state.importLeadsRequest,
          cause: action.leadCause
        }
      };

    case actions.SET_IMPORT_LEADS_EXTENDED_MAP:
      return {
        ...state,
        importLeadsExtendedMap: {...state.importLeadsExtendedMap, [action.columnName]: action.mapValue}
      };

    case actions.DELETE_IMPORT_LEADS_EXTENDED_MAP:
      const {columnName} = action;
      let removeExtendedMap = {...state.importLeadsExtendedMap};
      if(removeExtendedMap.hasOwnProperty(columnName))
        delete removeExtendedMap[columnName];
      return {
        ...state,
        importLeadsExtendedMap: removeExtendedMap
      };

    case actions.SET_IMPORT_REQUEST_ALLOW_CREATE_PROSPECT_OPTION:
      return {
        ...state,
        importLeadsRequest: {
          ...state.importLeadsRequest,
          allow_create_prospect: action.allowCreate
        }
      };

    case actions.SET_IMPORT_REQUEST_LEADS:
      return {
        ...state,
        importLeadsRequest: {
          ...state.importLeadsRequest,
          import_leads: action.parsedLeads
        }
      };

    case actions.ADD_IMPORT_REQUEST_ADDITIONAL_LOGIC:
      let updateLogic = state.importLeadsRequest.additional_logic.filter(logic => logic.logicId !== action.leadLogic.logicId);
      return {
        ...state,
        importLeadsRequest: {
          ...state.importLeadsRequest,
          additional_logic: [...updateLogic, action.leadLogic]
        }
      };

    case actions.CHANGE_SELECTED_LEADS:
      return {
        ...state,
        selectedLeads: action.selectedLeads
      };

    case actions.SET_IMPORT_LEADS:
      return {
        ...state,
        importLeads: action.importLeads
      };

    case actions.CREATE_IMPORT_LEADS_REQUEST:
      return {
        ...state,
        isImportRequested: true,
        importLeadsPreview: initState.importLeadsPreview
      };

    case actions.RECEIVED_IMPORT_LEADS_PREVIEW:
      return {
        ...state,
        importLeadsPreview: action.response
      };

    case actions.RECEIVED_IMPORT_LEADS_REQUEST:
      return {
        ...state,
        isImportRequested: false
      };

    default:
      return state;
  }
}
