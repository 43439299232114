import socialNetworkIcon from '../../../image/icons/socialNetwork.svg'
import searchNetworkIcon from '../../../image/icons/searchNetwork.png'
import displayNetworkIcon from '../../../image/icons/displayNetwork.png'
import videoNetworkIcon from '../../../image/icons/videoNetwork.png'

export function getSubTypeName(subTypeEnum) {
  switch(subTypeEnum)
  {
    case 'SEARCH_STANDARD':
    case 'SEARCH_DYNAMIC_ADS':
      return "Search Network";
    
    case 'DISPLAY_STANDARD':
    case 'DISPLAY_ENGAGEMENT':
      return "Display Network";
    
    case 'VIDEO_TRUE_VIEW_IN_STREAM':
    case 'VIDEO_TRUE_VIEW_IN_DISPLAY':
    case 'VIDEO_BUMPER':
    case 'VIDEO_NON_SKIPPABLE_IN_STREAM':
      return "Video Network";

    case 'UNIVERSAL_APP_CAMPAIGN':
      return 'App Network';

    case 'REACH':
    case 'OUTCOME_AWARENESS':
      return "Social Network / Reach";
  
    case 'LINK_CLICKS':
    case 'OUTCOME_TRAFFIC':
      return "Social Network / Link Clicks";
  
    case 'POST_ENGAGEMENT':
    case 'OUTCOME_ENGAGEMENT':
      return "Social Network / Engagement";
  
    case 'VIDEO_VIEWS':
      return "Social Network / Video";
  
    case 'CONVERSIONS':
      return "Social Network / Conversion";
  
    case 'LEAD_GENERATION':
    case 'OUTCOME_LEADS':
      return "Social Network / Lead Generation";

    case 'APP_INSTALLS':
      return "Social Network /  App Installs";
    case 'BRAND_AWARENESS':
      return "Social Network / Brand awareness";
    case 'LOCAL_AWARENESS':
      return "Social Network / Local brand awareness";
    case 'EVENT_RESPONSES':
      return "Social Network / Event responses";
    case 'MESSAGES':
      return "Social Network / Messages";
    case 'PRODUCT_CATALOG_SALES':
      return "Social Network / Product catalog sales";
    case 'STORE_VISITS':
      return "Social Network / Store visits";
    case 'PAGE_LIKES':
      return "Social Network / Page likes";

    case 'Display':
      return 'AdForm / Display';
    case 'Email':
      return 'AdForm / Email';
    case 'Affiliate':
      return 'AdForm / Affiliate';
    case 'Mobile':
      return 'AdForm / Mobile';
    case 'SocialMedia':
      return 'AdForm / Social Media';
    case 'Mixed':
      return 'AdForm / Mixed';
    case 'SearchNonApi':
      return 'AdForm / Search';
    case 'Rtb':
      return 'AdForm / RTB';
    case 'Print':
      return 'AdForm / Print';


    case 'PERFORMANCE_GOAL_TYPE_VIEWABILITY':
      return "Display Network / Percent Viewability";
    case 'PERFORMANCE_GOAL_TYPE_CPM':
      return "Display Network / Cost per Impression";
    case 'PERFORMANCE_GOAL_TYPE_CPC':
      return "Display Network / Cost per Click";
    case 'PERFORMANCE_GOAL_TYPE_CPA':
      return "Display Network / Cost per Action";
    case 'PERFORMANCE_GOAL_TYPE_CTR':
      return "Display Network / Click-Through Rate";
    case 'PERFORMANCE_GOAL_TYPE_CPIAVC':
      return "Display Network / Cost per Impressions Audible and Visible at Completion";
  }
}

export function getTypeEnum(source) {
  const {type} = source;
  switch(type)
  {
    case 1: return 'TYPE_CSV';
    case 2: return 'TYPE_GOOGLE_ADS';
    case 3: return 'TYPE_FACEBOOK_INSIGHTS';
    case 6: return 'TYPE_AD_FORM';
    case 4: return 'TYPE_DOUBLE_CLICK';
    default: return 'UNKNOWN';
  }
}

export function getSubTypeIcon(subTypeEnum) {
  switch(subTypeEnum)
  {
    case 'SEARCH_STANDARD':
    case 'SEARCH_DYNAMIC_ADS':
      return searchNetworkIcon;
    
    case 'DISPLAY_STANDARD':
    case 'DISPLAY_ENGAGEMENT':
    case 'PERFORMANCE_GOAL_TYPE_VIEWABILITY':
    case 'PERFORMANCE_GOAL_TYPE_CPM':
    case 'PERFORMANCE_GOAL_TYPE_CPC':
    case 'PERFORMANCE_GOAL_TYPE_CPA':
    case 'PERFORMANCE_GOAL_TYPE_CTR':
    case 'PERFORMANCE_GOAL_TYPE_CPIAVC':
    case 'UNIVERSAL_APP_CAMPAIGN':
    case 'PERCENT_VIEWABILITY':
    case 'CPM':
    case 'CPC':
    case 'CPA':
    case 'CTR':
    case 'CPIAVC':
      return displayNetworkIcon;
    
    case 'VIDEO_TRUE_VIEW_IN_STREAM':
    case 'VIDEO_TRUE_VIEW_IN_DISPLAY':
    case 'VIDEO_BUMPER':
    case 'VIDEO_NON_SKIPPABLE_IN_STREAM':
      return videoNetworkIcon;
    
    case 'REACH':
    case 'LINK_CLICKS':
    case 'POST_ENGAGEMENT':
    case 'VIDEO_VIEWS':
    case 'CONVERSIONS':
    case 'LEAD_GENERATION':
    case 'APP_INSTALLS':
    case 'BRAND_AWARENESS':
    case 'LOCAL_AWARENESS':
    case 'EVENT_RESPONSES':
    case 'MESSAGES':
    case 'PRODUCT_CATALOG_SALES':
    case 'STORE_VISITS':
    case 'PAGE_LIKES':
    case 'OUTCOME_AWARENESS':
    case 'OUTCOME_ENGAGEMENT':
    case 'OUTCOME_TRAFFIC':
    case 'OUTCOME_LEADS':
      return socialNetworkIcon;
  
    case 'Display':
    case 'Email':
    case 'Affiliate':
    case 'Mobile':
    case 'SocialMedia':
    case 'Mixed':
    case 'SearchNonApi':
    case 'Rtb':
    case 'Print':
      return displayNetworkIcon;
  }
}

export function getTypeId(subTypeEnum) {
  switch(subTypeEnum)
  {
    case 'SEARCH_STANDARD':
    case 'SEARCH_DYNAMIC_ADS':
    case 'DISPLAY_STANDARD':
    case 'DISPLAY_ENGAGEMENT':
    case 'VIDEO_TRUE_VIEW_IN_STREAM':
    case 'VIDEO_TRUE_VIEW_IN_DISPLAY':
    case 'VIDEO_BUMPER':
    case 'VIDEO_NON_SKIPPABLE_IN_STREAM':
    case 'UNIVERSAL_APP_CAMPAIGN':
      return 2;
    
    case 'REACH':
    case 'LINK_CLICKS':
    case 'POST_ENGAGEMENT':
    case 'VIDEO_VIEWS':
    case 'CONVERSIONS':
    case 'LEAD_GENERATION':
    case 'APP_INSTALLS':
    case 'BRAND_AWARENESS':
    case 'LOCAL_AWARENESS':
    case 'EVENT_RESPONSES':
    case 'MESSAGES':
    case 'PRODUCT_CATALOG_SALES':
    case 'STORE_VISITS':
    case 'PAGE_LIKES':
    case 'OUTCOME_AWARENESS':
    case 'OUTCOME_ENGAGEMENT':
    case 'OUTCOME_TRAFFIC':
    case 'OUTCOME_LEADS':
      return 3;
  
    case 'Display':
    case 'Email':
    case 'Affiliate':
    case 'Mobile':
    case 'SocialMedia':
    case 'Mixed':
    case 'SearchNonApi':
    case 'Rtb':
    case 'Print':
      return 6;

    case 'PERFORMANCE_GOAL_TYPE_VIEWABILITY':
    case 'PERFORMANCE_GOAL_TYPE_CPM':
    case 'PERFORMANCE_GOAL_TYPE_CPC':
    case 'PERFORMANCE_GOAL_TYPE_CPA':
    case 'PERFORMANCE_GOAL_TYPE_CTR':
    case 'PERFORMANCE_GOAL_TYPE_CPIAVC':
      return 4;
      
    default:
      return 1;
  }
}

export function getSubTypeId(subTypeEnum) {
  switch(subTypeEnum)
  {
    case 'SEARCH_STANDARD':
      return 1;
    case 'SEARCH_DYNAMIC_ADS':
      return 2;
    case 'DISPLAY_STANDARD':
      return 3;
    case 'DISPLAY_ENGAGEMENT':
      return 7;
    case 'VIDEO_TRUE_VIEW_IN_STREAM':
      return 8;
    case 'VIDEO_TRUE_VIEW_IN_DISPLAY':
      return 9;
    case 'VIDEO_BUMPER':
      return 10;
    case 'VIDEO_NON_SKIPPABLE_IN_STREAM':
      return 11;
    case 'UNIVERSAL_APP_CAMPAIGN':
      return 101;
    
    case 'REACH':
      return 1;
    case 'LINK_CLICKS':
      return 2;
    case 'POST_ENGAGEMENT':
      return 3;
    case 'VIDEO_VIEWS':
      return 4;
    case 'CONVERSIONS':
      return 5;
    case 'LEAD_GENERATION':
      return 6;
    case 'APP_INSTALLS':
      return 7;
    case 'BRAND_AWARENESS':
      return 8;
    case 'LOCAL_AWARENESS':
      return 9;
    case 'EVENT_RESPONSES':
      return 10;
    case 'MESSAGES':
      return 11;
    case 'PRODUCT_CATALOG_SALES':
      return 12;
    case 'STORE_VISITS':
      return 13;
    case 'PAGE_LIKES':
      return 14;
    case 'OUTCOME_AWARENESS':
      return 15;
    case 'OUTCOME_ENGAGEMENT':
      return 16;
    case 'OUTCOME_TRAFFIC':
      return 17;
    case 'OUTCOME_LEADS':
      return 18;
  
    case 'Display':
      return 1;
    case 'Email':
      return 2;
    case 'Affiliate':
      return 3;
    case 'Mobile':
      return 4;
    case 'SocialMedia':
      return 5;
    case 'Mixed':
      return 6;
    case 'SearchNonApi':
      return 7;
    case 'Rtb':
      return 8;
    case 'Print':
      return 9;

    case 'PERFORMANCE_GOAL_TYPE_VIEWABILITY':
      return 1;
    case 'PERFORMANCE_GOAL_TYPE_CPM':
      return 2;
    case 'PERFORMANCE_GOAL_TYPE_CPC':
      return 3;
    case 'PERFORMANCE_GOAL_TYPE_CPA':
      return 4;
    case 'PERFORMANCE_GOAL_TYPE_CTR':
      return 5;
    case 'PERFORMANCE_GOAL_TYPE_CPIAVC':
      return 6;
      
    default:
      return -1;
  }
}

export function getSubTypeEnum(source) {
  const {type} = source;
  switch(type)
  {
    case 1: return 'NONE';
    case 2: return getGoogleSubTypeEnum(source);
    case 3: return getFacebookSubTypeEnum(source);
    case 6: return getAdformSubTypeEnum(source);
    case 4: return getDoubleClickSubTypeEnum(source);
    default: return 'UNKNOWN';
  }
}

export function getGoogleSubTypeEnum(source) {
  const {sub_type} = source;
  switch(sub_type)
  {
    case 1: return 'SEARCH_STANDARD';
    case 2: return 'SEARCH_DYNAMIC_ADS';
    case 3: return 'DISPLAY_STANDARD';
    case 4: return 'SHOPPING_PRODUCT_ADS';
    case 5: return 'SHOPPING_SHOWCASE_ADS';
    case 6: return 'SHOPPING_GOAL_OPTIMIZED_ADS';
    case 7: return 'DISPLAY_ENGAGEMENT';
    case 8: return 'VIDEO_TRUE_VIEW_IN_STREAM';
    case 9: return 'VIDEO_TRUE_VIEW_IN_DISPLAY';
    case 10: return 'VIDEO_BUMPER';
    case 11: return 'VIDEO_NON_SKIPPABLE_IN_STREAM';
    case 12: return 'HOTEL_ADS';
    case 101: return 'UNIVERSAL_APP_CAMPAIGN';
    default: return 'UNKNOWN';
  }
}

export function getFacebookSubTypeEnum(source) {
  const {sub_type} = source;
  switch(sub_type)
  {
    case 1: return 'REACH';
    case 2: return 'LINK_CLICKS';
    case 3: return 'POST_ENGAGEMENT';
    case 4: return 'VIDEO_VIEWS';
    case 5: return 'CONVERSIONS';
    case 6: return 'LEAD_GENERATION';
    case 7: return 'APP_INSTALLS';
    case 8: return 'BRAND_AWARENESS';
    case 9: return 'LOCAL_AWARENESS';
    case 10: return 'EVENT_RESPONSES';
    case 11: return 'MESSAGES';
    case 12: return 'PRODUCT_CATALOG_SALES';
    case 13: return 'STORE_VISITS';
    case 14: return 'PAGE_LIKES';
    case 15: return 'OUTCOME_AWARENESS';
    case 16: return 'OUTCOME_ENGAGEMENT';
    case 17: return 'OUTCOME_TRAFFIC';
    case 18: return 'OUTCOME_LEADS';
    default: return 'UNKNOWN';
  }
}

export function getAdformSubTypeEnum(source)
{
  const {sub_type} = source;
  switch (sub_type)
  {
    case 1:
      return 'DISPLAY';
    case 2:
      return 'EMAIL';
    case 3:
      return 'AFFILIATE';
    case 4:
      return 'MOBILE';
    case 5:
      return 'SOCIAL_MEDIA';
    case 6:
      return 'MIXED';
    case 7:
      return 'SEARCH';
    case 8:
      return 'RTB';
    case 9:
      return 'PRINT';
  }
}

export function getDoubleClickSubTypeEnum(source) {
  const {sub_type} = source;
  switch(sub_type)
  {
    case 1: return 'PERFORMANCE_GOAL_TYPE_VIEWABILITY';
    case 2: return 'PERFORMANCE_GOAL_TYPE_CPM';
    case 3: return 'PERFORMANCE_GOAL_TYPE_CPC';
    case 4: return 'PERFORMANCE_GOAL_TYPE_CPA';
    case 5: return 'PERFORMANCE_GOAL_TYPE_CTR';
    case 6: return 'PERFORMANCE_GOAL_TYPE_CPIAVC';
    default: return 'UNKNOWN';
  }
}

