import styled from 'styled-components';

const CoverLoader = styled.div`

  position: absolute;
  left: 0;
  right: 0;
  background-color: rgba(255,255,255,1);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9;
  text-align: center;
  
  .contai {
    left: 0; right: 0; margin: auto;
    top: 50%; transform: translateY(-50%);
        display: inline-block;
        position: relative;
        
        h2 {
            font-weight: 400;
    margin-bottom: 30px;
        }
  }
  
  .cm-spinner {
    height: 100px;
    width: 100px;
    display: inline-block;
    border: 3px solid transparent;
    border-radius: 50%;
    border-top: 4px solid #2655f9;
    -webkit-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
    position: relative;
  }

.cm-spinner::before,
.cm-spinner::after {
  content: "";
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: 6px;
  right: 6px;
  border-radius: 50%;
  border: 4px solid transparent;
}

.cm-spinner::before {
  border-top-color: #04efeb;
  -webkit-animation: 3s spin linear infinite;
  animation: 3s spin linear infinite;
}

.cm-spinner::after {
  border-top-color: #ff5e48;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
         transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

`;

export {CoverLoader};
