import actions from './actions';

const initState = {
  isFetching: false,
  campaigns: [],
  campaign: null,
};

export default function messageCampaignReducer(state = initState, action) {
  switch (action.type) {
  
    case 'SET_ADVERTISER':
      return {
        ...initState
      };
    
    case actions.CREATE_MESSAGE_CAMPAIGN:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.CREATE_MESSAGE_CAMPAIGN_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.MESSAGE_CAMPAIGN_CREATED:
      return {
        ...state,
        isFetching: false,
        campaign: action.campaign
      };
  
    case actions.UPDATE_MESSAGE_CAMPAIGN:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.UPDATE_MESSAGE_CAMPAIGN_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.MESSAGE_CAMPAIGN_UPDATED:
      return {
        ...state,
        isFetching: false,
        campaign: action.campaign
      };
  
    case actions.FETCH_MESSAGE_CAMPAIGNS:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.RECEIVE_MESSAGE_CAMPAIGNS:
      return {
        ...state,
        isFetching: false,
        campaigns: action.campaigns
      };
  
    case actions.FETCH_MESSAGE_CAMPAIGN:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.FETCH_MESSAGE_CAMPAIGN_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.RECEIVE_MESSAGE_CAMPAIGN:
      return {
        ...state,
        isFetching: false,
        campaign: action.campaign
      };
  
    case actions.SEND_TEST_MESSAGE_CAMPAIGN:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.SEND_TEST_MESSAGE_CAMPAIGN_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.TEST_MESSAGE_CAMPAIGN_SENT:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.DELETE_MESSAGE_CAMPAIGN:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.DELETE_MESSAGE_CAMPAIGN_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.MESSAGE_CAMPAIGN_DELETED:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.CLEAR_MESSAGE_CAMPAIGN:
      return {
        ...state,
        campaign: initState.campaign
      };
  
    case actions.SEND_MESSAGE_CAMPAIGN:
      return {
        ...state,
        isFetching: true
      };
  
    case actions.SEND_MESSAGE_CAMPAIGN_ERROR:
      return {
        ...state,
        isFetching: false
      };
  
    case actions.MESSAGE_CAMPAIGN_SENT:
      return {
        ...state,
        isFetching: false
      };

    case actions.PATCH_SCHEDULED_MESSAGE_CAMPAIGN_MESSAGES:
      return {
        ...state,
        isFetching: true
      };

    case actions.PATCH_SCHEDULED_MESSAGE_CAMPAIGN_MESSAGES_ERROR:
      return {
        ...state,
        isFetching: false
      };

    case actions.SCHEDULED_MESSAGE_CAMPAIGN_MESSAGES_PATCHED:
      return {
        ...state,
        isFetching: false
      };

    default:
      return state;
  }
}
