const actions = {
  FETCH_STATUSES: 'FETCH_STATUSES',
  RECEIVE_STATUSES: 'RECEIVE_STATUSES',
  
  CREATE_STATUS: 'CREATE_STATUS',
  CREATE_STATUS_ERROR: 'CREATE_STATUS_ERROR',
  STATUS_CREATED: 'STATUS_CREATED',
  
  DELETE_STATUS: 'DELETE_STATUS',
  DELETE_STATUS_ERROR: 'DELETE_STATUS_ERROR',
  STATUS_DELETED: 'STATUS_DELETED',
  
  fetchStatuses: () => ({
    type: actions.FETCH_STATUSES
  }),
  
  createStatus: (status) => ({
    type: actions.CREATE_STATUS,
    status
  }),
  
  deleteStatus: (statusId) => ({
    type: actions.DELETE_STATUS,
    statusId
  }),
};
export default actions;
