import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import userpic from '../../image/user1.png';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import {Icon} from "antd";
import Button, { ButtonGroup } from '../../components/uielements/button';
import advertiserActions from '../../redux/advertiser/actions';
import {Link} from "react-router-dom";
import Menu from "../../components/uielements/menu";
import DepartmentFormModal from '../Advertiser/Settings/form/formModal';
const {SubMenu} = Menu;

const { logout } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
      modalVisible: false,
      user: ''
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  
  componentDidMount()
  {
    const {advertisers, isFetchingAdvertisers, fetchAdvertisers} = this.props;
    if(advertisers.length < 1 && !isFetchingAdvertisers)
    {
      fetchAdvertisers();
    }
  }
  
  componentWillReceiveProps(nextProps)
  {
    if(nextProps.user !== this.state.user)
    {
      const {user} = nextProps;
      this.setState({user});
    }
  }
  
  render() {
    const {user} = this.props;
    const {advertisers, isFetchingAdvertisers, fetchAdvertisers, changeAdvertiser, showModal} = this.props;
    const {modalVisible} = this.state;
    const currentId = localStorage.getItem('advertiser_id');
    let currentAdv = advertisers.find(adr => {
      return adr.advertiser_id == currentId;
    });
    
    let diffAdvertisers = [];
    advertisers.forEach(adv => {
      for(let i = 0; i < diffAdvertisers.length; i++)
      {
        if(diffAdvertisers[i].advertiser_id == adv.advertiser_id)
          return;
      }
      
      diffAdvertisers.push(adv);
    });
    
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <DepartmentFormModal />
        <Menu  mode="vertical">
          <Menu.Item key="userName">
            <span className="isoDropdownLink" style={{pointerEvents: 'none'}}>
              Hello, <strong style={{paddingLeft: 5}}> {user ? user.username : '...'}</strong>!
                  {/*<Icon type={'user'} style={{paddingRight: 10}}/>*/}
            </span>
          </Menu.Item>
          {user.isManager() && advertisers.length > 0 ?
            <Menu.Item key="settings">
              <Link to={'/advertiser/users'} ><Icon type={'setting'} />Advertiser Settings</Link>
            </Menu.Item>
            : null
          }
          
          
          {diffAdvertisers.length > 0 ?
            <SubMenu
              title={
                <span className="submenu-title-wrapper">
              <Icon type="bar-chart" />
              Change Advertiser
            </span>
              }
            >
              <Menu.ItemGroup>
                {diffAdvertisers.map(adv => {
                  return <Menu.Item
                      className={'vertiser' + adv.advertiser_id}
                    onClick={() => {
                      changeAdvertiser(adv.advertiser_id);
                      this.setState({ visible: false });
                    }}
                    key={adv.advertiser_id}>{adv.advertiser_name}
                  </Menu.Item>;
                })}
              </Menu.ItemGroup>
            </SubMenu>
            
            :
            
            null
          }
          
          {user.isAdmin() ?
            <Menu.Item key="createNew" onClick={() => showModal(true)}>
              <Icon type={'plus'} />Create Advertiser
            </Menu.Item>
            : null
          }
          
          <Menu.Item key="logout" onClick={this.props.logout}>
            <Icon type={'logout'} />Logout
          </Menu.Item>
        </Menu>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        {/*<Button type={"primary"} size="default">*/}
        {/*  <Icon style={{fontSize: 13, color: '#ffffff', marginLeft: 5}} type="down" />*/}
        {/*</Button>*/}
        <Icon className={'topbarUser'} type="user" />
      </Popover>
    );
  }
}

const {fetchAdvertisers, changeAdvertiser, showModal} = advertiserActions;
export default connect(
  state => ({
    user: state.Auth.user,
    advertisers: state.Advertisers.advertisers,
    isFetchingAdvertisers: state.Advertisers.isFetching,
  }),
  { logout, fetchAdvertisers, changeAdvertiser, showModal }
)(TopbarUser);
