const actions = {
  FETCH_ADS: 'FETCH_ADS',
  FETCH_ADS_ERROR: 'FETCH_ADS_ERROR',
  RECEIVE_ADS: 'RECEIVE_ADS',

  FETCH_AD_FACEBOOK: 'FETCH_AD_FACEBOOK',
  FETCH_AD_FACEBOOK_ERROR: 'FETCH_AD_FACEBOOK_ERROR',
  RECEIVE_AD_FACEBOOK: 'RECEIVE_AD_FACEBOOK',

  SET_AD_PREVIEW_MODAL: 'SET_AD_PREVIEW_MODAL',
  SET_AD_PREVIEW_MODAL_OPEN: 'SET_AD_PREVIEW_MODAL_OPEN',
  SET_AD_PREVIEW_MODAL_CLOSE: 'SET_AD_PREVIEW_MODAL_CLOSE',

  SET_AD_PREVIEW_MODAL_FETCHING: 'SET_AD_PREVIEW_MODAL_FETCHING',
  UNSET_AD_PREVIEW_MODAL_FETCHING: 'UNSET_AD_PREVIEW_MODAL_FETCHING',

  fetchAds: (prospect) => ({
    type: actions.FETCH_ADS,
    prospect
  }),
  
  fetchAdFacebook: (ad) => ({
    type: actions.FETCH_AD_FACEBOOK,
    ad
  }),

  setModal: (modalContent) => ({
    type: actions.SET_AD_PREVIEW_MODAL,
    modal: modalContent
  }),

  setModalOpen: (lead) => ({
    type: actions.SET_AD_PREVIEW_MODAL_OPEN,
    lead
  }),

  setModalClose: () => ({
    type: actions.SET_AD_PREVIEW_MODAL_CLOSE,
  }),

  setModalFetching: () => ({
    type: actions.SET_AD_PREVIEW_MODAL_FETCHING
  }),

  unsetModalFetching: () => ({
    type: actions.UNSET_AD_PREVIEW_MODAL_FETCHING
  }),
};
export default actions;
