import {Col, Row} from "antd";
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
import DateRangeFilter from './Campaign/dateRangeFilter';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard')),
  },
  {
    path: 'blankPage',
    component: asyncComponent(() => import('../blankPage')),
  },
  {
    path: 'campaigns/:campaignId/:sourceId',
    component: asyncComponent(() => import('./Source/single')),
    exact: true
  },
  {
    path: 'campaigns/:campaignId',
    component: asyncComponent(() => import('./Campaign/single')),
    exact: true
  },
  {
    path: 'campaigns',
    component: asyncComponent(() => import('./Campaign/')),
  },
];

class ReportsRouter extends Component {
  
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default ReportsRouter;
