import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import LeadsFetch from "../../helpers/leadsFetch";

export function* fetchOppos() {
    yield takeEvery(actions.FETCH_OPPOS, function*(payload) {
        const {prospectId} = payload;
        
        const query = '?prospect=' + prospectId;
        
        const apiResponse = yield LeadsFetch.get('opportunities' + query)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(apiResponse.status === 200)
        {
            const notes = apiResponse.data;
            yield put({
                type: actions.RECEIVE_OPPOS,
                notes
            });
        }
    });
}

export function* addOppo() {
    yield takeEvery(actions.ADD_OPPO, function*(payload) {
        
        const {newNote, oldNotes, prospectId} = payload;
        
        const apiResponse = yield LeadsFetch.post('opportunities', newNote)
            .then(response => (response))
            .catch(error => ({error}));
        
        if(apiResponse.status === 201)
        {
            newNote.id = apiResponse.data.id;
            newNote.created_time = apiResponse.data.created_time;
            newNote.user_id = apiResponse.data.user_id;
            const notes = [newNote, ...oldNotes];
            yield put({
                type: actions.RECEIVE_OPPOS,
                newNote,
                notes
            });
        }
        
    });
}
export function* editOppo() {
    yield takeEvery(actions.EDIT_OPPO, function*() {});
}
export function* saveOppo() {
    yield takeEvery(actions.SAVE_OPPO, function*(payload) {
        const {newText, id} = payload;
        
        const apiResponse = yield LeadsFetch.PATCH('opportunities/' + id, {note: newText})
            .then(response => (response))
            .catch(error => ({error}));
        
    });
}
export function* deleteOppo() {
    yield takeEvery(actions.DELETE_OPPO, function*(payload) {
        const {deleteId} = payload;
        
        const apiResponse = yield LeadsFetch.delete('opportunities/' + deleteId, null)
            .then(response => (response))
            .catch(error => ({error}));
    });
}
export default function* rootSaga() {
    yield all([
        fork(fetchOppos),
        fork(addOppo),
        fork(saveOppo),
        fork(editOppo),
        fork(deleteOppo),
    ]);
}
