const actions = {
  FETCH_PROSPECTS: 'FETCH_PROSPECTS',
  SET_PROSPECTS_PAGE: 'SET_PROSPECTS_PAGE',
  
  RECEIVE_PROSPECTS: 'RECEIVE_PROSPECTS',
  SET_CURRENT_PROSPECT: 'SET_CURRENT_PROSPECT',
  FETCH_PROSPECT_DATA: 'FETCH_PROSPECT_DATA',
  RECEIVE_PROSPECT_DATA: 'RECEIVE_PROSPECT_DATA',
  FETCH_PROSPECT_ERROR: 'FETCH_PROSPECT_ERROR',
  
  DELETE_PROSPECT: 'DELETE_PROSPECT',
  DELETE_PROSPECT_ERROR: 'DELETE_PROSPECT_ERROR',
  PROSPECT_DELETED: 'PROSPECT_DELETED',
  
  SET_PROSPECT_STATUS: 'SET_PROSPECT_STATUS',
  SET_PROSPECT_STATUS_ERROR: 'SET_PROSPECT_STATUS_ERROR',
  PROSPECT_STATUS_SET: 'PROSPECT_STATUS_SET',
  
  ASSIGN_PROSPECT_USER: 'ASSIGN_PROSPECT_USER',
  ASSIGN_PROSPECT_USER_ERROR: 'ASSIGN_PROSPECT_USER_ERROR',
  PROSPECT_USER_ASSIGNED: 'PROSPECT_USER_ASSIGNED',

  EDIT_PROSPECT: 'EDIT_PROSPECT',
  EDIT_PROSPECT_ERROR: 'EDIT_PROSPECT_ERROR',
  
  DELETE_PROSPECT_BASIC_FIELD: 'DELETE_PROSPECT_BASIC_FIELD',
  DELETE_PROSPECT_BASIC_FIELD_ERROR: 'DELETE_PROSPECT_BASIC_FIELD_ERROR',
  PROSPECT_BASIC_FIELD_DELETED: 'PROSPECT_BASIC_FIELD_DELETED',
  
  ADD_PROSPECT_CUSTOM_FIELD: 'ADD_PROSPECT_FIELD',
  ADD_PROSPECT_FIELD_ERROR: 'ADD_PROSPECT_FIELD_ERROR',
  PROSPECT_FIELD_ADDED: 'PROSPECT_FIELD_ADDED',
  EXPORT_PROSPECTS: 'EXPORT_PROSPECTS',
  PROSPECTS_EXPORTED: 'PROSPECTS_EXPORTED',
  EXPORT_PROSPECTS_WITH_NOTES: 'EXPORT_PROSPECTS_WITH_NOTES',
  PROSPECTS_WITH_NOTES_EXPORTED: 'PROSPECTS_WITH_NOTES_EXPORTED',
  EXPORT_ATHLON_ACCOUNT_TEMPLATE: 'EXPORT_ATHLON_ACCOUNT_TEMPLATE',
  ATHLON_ACCOUNT_TEMPLATE_EXPORTED: 'ATHLON_ACCOUNT_TEMPLATE_EXPORTED',
  
  PROSPECT_REMOVE_AGREE: 'PROSPECT_REMOVE_AGREE',
  PROSPECT_REMOVE_AGREE_ERROR: 'PROSPECT_REMOVE_AGREE_ERROR',
  PROSPECT_AGREE_REMOVED: 'PROSPECT_AGREE_REMOVED',
  
  CREATE_PROSPECTS_AUDIENCE: 'CREATE_PROSPECTS_AUDIENCE',
  CREATE_PROSPECTS_AUDIENCE_ERROR: 'CREATE_PROSPECTS_AUDIENCE_ERROR',
  PROSPECTS_AUDIENCE_CREATED: 'PROSPECTS_AUDIENCE_CREATED',
  
  // checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  
  fetchProspects: () => ({
    type: actions.FETCH_PROSPECTS
  }),
  
  createProspectsAudience: () => ({
    type: actions.CREATE_PROSPECTS_AUDIENCE
  }),
  
  setPage: (page) => ({
    type: actions.SET_PROSPECTS_PAGE,
    page
  }),
  
  fetchProspectData: (prospectId) => ({
    type: actions.FETCH_PROSPECT_DATA,
    prospectId: prospectId
  }),
  
  deleteProspect: (prospectId) => ({
    type: actions.DELETE_PROSPECT,
    prospectId: prospectId
  }),
  
  setStatus: (prospectId, statusId) => ({
    type: actions.SET_PROSPECT_STATUS,
    statusId,
    prospectId
  }),
  
  assignUser: (prospectId, userId) => ({
    type: actions.ASSIGN_PROSPECT_USER,
    userId,
    prospectId
  }),

  patchProspect: (prospectPath, editProspect) => ({
    type: actions.EDIT_PROSPECT,
    prospectPath,
    editProspect
  }),
  
  removeBasicField: (prospectHasBasicFieldId) => ({
    type: actions.DELETE_PROSPECT_BASIC_FIELD,
    prospectHasBasicFieldId
  }),
  
  addCustomField: (prospectId, fieldId, fieldValue, eventView = false) => ({
    type: actions.ADD_PROSPECT_CUSTOM_FIELD,
    fieldId,
    fieldValue,
    prospectId,
    eventView
  }),
  
  exportProspects: () => ({
    type: actions.EXPORT_PROSPECTS
  }),
  
  exportAthlonAccountTemplate: () => ({
    type: actions.EXPORT_ATHLON_ACCOUNT_TEMPLATE
  }),
  
  exportProspectsWithNotes: () => ({
    type: actions.EXPORT_PROSPECTS_WITH_NOTES
  }),
  
  removeAgree: (agreeId, prospectId) => ({
    type: actions.PROSPECT_REMOVE_AGREE,
    agreeId,
    prospectId
  }),
  
};
export default actions;
