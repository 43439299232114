const actions = {
  CLEAR_ERROR: 'CLEAR_ERROR',
  PUSH_ERROR: 'PUSH_ERROR',
  
  clearError: () => ({
    type: actions.CLEAR_ERROR
  }),
  
};
export default actions;
